import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";
import FeedbackFormSmall from "../FeedbackForm/FeedbackFormSmall";
import FeedbackFormBig from "../FeedbackForm/FeedbackFormBig";
import Navbar from "../Navbar/Navbar";
import ServicesDropdownPages from "../Services/ServicesDropdown/ServicesDropdownPages";

class Audit extends React.Component {
  constructor(props) {
    super(props);

    this.servicesHover = this.servicesHover.bind(this);
    this.state = {
      style: "borderTop: 2px solid",
      buttonOrder: false,
      activeServices: true,
      isSolutionsHovering: false,
      isServicesHovering: false,
      isMobile: window.innerWidth <= 576
    };
  }

  setActiveCat(category) {
    this.setState({ activeCat: category });
  }

  uppendTitle() {
    document.querySelector("head title").innerHTML =
      "Аудит инженерных систем и монтажных работ | Умный дом в Москве | Homeсhain";
  }

  uppendMeta() {
    let description = document.querySelector("meta[name=description]");
    let keywords = document.querySelector("meta[name=keywords]");
    if (description) {
      description.content =
        'Аудит инженерных систем и строительно-монтажных работ. Умный дом в Москве "под ключ" - проектирование, комплектация, монтаж, пусконаладка. Homeсhain +7 (499) 113 10 10.';
    } else {
      description = document.createElement("meta");
      description.name = "description";
      description.content =
        'Аудит инженерных систем и строительно-монтажных работ. Умный дом в Москве "под ключ" - проектирование, комплектация, монтаж, пусконаладка. Homeсhain +7 (499) 113 10 10.';
      document.querySelector("head").appendChild(description);
    }

    if (keywords) {
      keywords.content =
        "аудит инженерных систем, технический аудит инженерных систем, аудит строительно монтажных работ";
    } else {
      keywords = document.createElement("meta");
      keywords.name = "keywords";
      keywords.content =
        "аудит инженерных систем, технический аудит инженерных систем, аудит строительно монтажных работ";
      document.querySelector("head").appendChild(keywords);
    }
  }

  servicesHover(state) {
    this.setState(this.toggleServicesHover);
  }

  toggleDropdown(state) {
    return {
      isServicesHovering: false,
      isSolutionsHovering: false
    };
  }

  toggleServicesHover(state) {
    return {
      isServicesHovering: !state.isServicesHovering,
      isSolutionsHovering: false
    };
  }

  giveOrder() {
    this.setState({
      order: true
    });
  }
  render() {
    return (
      <div>
        {this.uppendTitle()}
        {this.uppendMeta()}
        <Navbar activeServices={this.state.activeServices} />
        <Container
          fluid
          className="page"
          style={{
            backgroundImage: `url(${require("../../images/Services/Audit/audit-bg.png")})`
          }}
        >
          <header>
            <Container fluid>
              <Row className="page-row custom-container">
                {!this.state.isMobile && (
                  <div className="page-nav">
                    <ul>
                      <li>
                        <Link to="/">Главная</Link>
                      </li>
                      <img src={require("../../images/arrow.svg")} alt="" />
                      <li onClick={this.servicesHover.bind(this)}>
                        <HashLink>Услуги</HashLink>
                      </li>
                      <img src={require("../../images/arrow.svg")} alt="" />
                      <li style={{ color: "#181818" }}>Аудит</li>
                    </ul>
                  </div>
                )}

                {this.state.isMobile && (
                  <div className="page-nav">
                    <ul>
                      <img
                        style={{ width: "6px" }}
                        src={require("../../images/arrow-left.svg")}
                        alt=""
                      />
                      <li onClick={this.servicesHover.bind(this)}>
                        <HashLink>Услуги</HashLink>
                      </li>
                    </ul>
                  </div>
                )}

                <div className="page-header">
                  <h1>Аудит</h1>
                  <HashLink smooth to="#feedback-big">
                    <button className="hover-button">Оставить заявку</button>
                  </HashLink>
                </div>
              </Row>
              {this.state.isServicesHovering && (
                <ServicesDropdownPages
                  handler={this.servicesHover.bind(this)}
                />
              )}
            </Container>
          </header>
        </Container>
        <Container fluid className="custom-container">
          <Row className="page-description">
            <Col xl="12">
              <h3>Объективная оценка проекта</h3>
              <p>
                В homechain поступает множество заявок с просьбой оценить
                качество проведенных проектных и монтажных работ. Многие
                заказчики просят реализовать проект, разработанный не нами, или
                произвести пусконаладку и программирование уже смонтированной
                сторонним подрядчиком системы. С целью проведения технического
                аудита инженерных систем, спроектированных и установленных на
                объекте сторонними организациями, мы создали отдельную услугу,
                воспользовавшись которой клиент получает объективную оценку
                выполненных работ. Данная услуга позволяет нашим специалистам
                оценить свои возможности и принять решение о целесообразности
                реализации проекта или необходимости его доработки.
              </p>
            </Col>
          </Row>
          <Row>
            <img
              width="100%"
              className="page-photo lazy"
              data-src={require("../../images/Services/Audit/audit-photo.png")}
              alt="аудит инженерных систем заказчика"
              src="/images/image-preloader.png"
            />
          </Row>

          <Row className="page-description">
            <Col xl="12">
              <h3> Экспертное заключение</h3>
              <p className="text-block-without-space">
                При проведении аудита строительно-монтажных работ, специалисты
                детально изучают проект и на основании опыта эксплуатации
                инженерных коммуникаций определяют:
              </p>

              <ul>
                <li>
                  соответствие выполненных операций требованиям заказчика;
                </li>
                <li>работоспособность системы на объекте;</li>
                <li>
                  качество и функциональность установленного оборудования.
                </li>
              </ul>
              <p>
                После проведения оценки клиенту выдается экспертное заключение
                относительно проекта. В документе мы подробно описываем
                выявленные недостатки и делимся опытом по эксплуатации
                оборудования, планируемого к установке на объекте. В выводе
                экспертного заключения заказчику сообщается о возможности
                реализации нашими специалистами проекта или необходимости
                доработки (переработки) технической документации.
              </p>
            </Col>
          </Row>
        </Container>
        <FeedbackFormSmall />

        <Container
          fluid
          className="custom-container container-space-top-50"
        >
          <div className="ecosystem-description">
            <Row className="ecosystem-row">
              <Col xl="12" lg="12" md="12">
                <h3>Тщательное и продолжительное обследование</h3>
                <p>
                  <img
                    data-src={require("../../images/Services/Audit/audit-img.png")}
                    alt="технический аудит инженерных систем специалистом"
                    class="lazy"
                    src="/images/image-preloader.png"
                  />
                  Оценка монтажных работ выполняется путем визуального и
                  аппаратного досмотра объекта. Например, при инженерной
                  проверке сетей определяется марка проложенного кабеля, радиусы
                  сгиба, используемые трубы и типы крепления.
                  <span />
                  Аудит инженерных систем представляет собой сложный и
                  кропотливый процесс. Это связано с тем, что в этом случае
                  нашими инженерами не осуществлялся надзор правильности и
                  грамотности выполнения монтажных работ на объекте, что
                  является самым ответственным и специфичным этапом. К примеру,
                  щит силовой электрики при визуальном осмотре может выглядеть
                  идеально, но при проверке его тепловизором определяется
                  некачественный монтаж, а иногда и проектные ошибки. Для
                  ускорения процесса оценки выполненных на объекте операций, мы
                  рекомендуем клиентам делать фото- и видеосъемку всех этапов
                  монтажа. Это позволит нашим инженерам существенно сократить
                  срок аудита строительно-монтажных работ и представить
                  заказчику заключение по качеству реализации проекта в
                  максимально короткий срок.
                </p>
              </Col>
            </Row>
          </div>
        </Container>
        <FeedbackFormBig bigForm={true} />
      </div>
    );
  }
}

export default Audit;
