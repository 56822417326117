import * as React from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";

import MapMarker from "../../images/map-marker.png";

const styles = require("./GoogleMapStyles.json");

const GoogleMapComponentWithMarker = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={15}
      defaultCenter={{
        lat: 55.768573,
        lng: 37.57288
      }}
      defaultOptions={{
        disableDefaultUI: true,
        draggable: true,
        keyboardShortcuts: false,
        scaleControl: true,
        scrollwheel: true,
        styles: styles
      }}
    >
      <Marker
        icon={{
          url: `${MapMarker}`
        }}
        position={{
          lat: 55.768573,
          lng: 37.57288
        }}
      />
    </GoogleMap>
  ))
);

export default GoogleMapComponentWithMarker;
