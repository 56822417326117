import React from "react";
import { Container, Col } from "react-bootstrap";
import Slider from "react-slick";

import "../../Tabs/Control/control.css";

function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: "block"
            }}
            onClick={onClick}
        />
    );
}

function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: "block"
            }}
            onClick={onClick}
        />
    );
}

class IndividualDevices extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            renderResponsive: null,

            individual: [
                {
                    deviceName: "Смартфоны",
                    imgSrc: require("../../../images/AmazonEcho/smartphone.png"),
                    alt: "приложение умный дом — разработка для смартфона",
                    text: "Разработаем оптимальное приложения для вашего смартфона."
                },
                {
                    deviceName: "Планшеты",
                    imgSrc: require("../../../images/AmazonEcho/tablet.png"),
                    alt: "приложение умный дом — разработка для планшета",
                    text: "Предоставим возможность использовать планшет в качестве центра управления умным домом. При необходимости встроим в стену."
                },
                {
                    deviceName: "Персональные компьютеры",
                    imgSrc: require("../../../images/AmazonEcho/pc.png"),
                    alt: "приложение умный дом — разработка для компьютера",
                    text: "Разработаем программу для Windows и Mac и предоставим широкие возможности настройки и персонализации."
                }
            ]
        };
    }

    render() {
        let settings = {
            infinite: false,
            autoplay: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1
                    }
                },

                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const renderIndividual = this.state.individual.map((item) => {
            return (
                <div key={item}>
                    <Col xl='3' lg='3' md='4' sm='6' xs='12' className='ecosystem-devices'>
                        <p className="ecosystem-devices-device-name">{item.deviceName}</p>
                        <div>
                            <img data-src={item.imgSrc} alt={item.alt} class='lazy' src='/images/image-preloader.png' />
                        </div>
                        <p className='control-hover-text'>{item.text}</p>
                    </Col>
                </div>
            );
        });

        return (
            <div>
                <Container fluid className='devices control-devices control-devices-bottom-space'>
                    <Slider {...settings}>{renderIndividual}</Slider>
                </Container>
            </div>
        );
    }
}

export default IndividualDevices;
