import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Navbar from "../../Navbar/Navbar";
import FeedbackFormSmall from "../../FeedbackForm/FeedbackFormSmall";
import FeedbackFormBig from "../../FeedbackForm/FeedbackFormBig";
import { HashLink } from "react-router-hash-link";
import styles from "./BuiltinV2.module.scss";
import FeedbackFormSmallV2 from "../../FeedbackForm/FeedbackFormSmallV2";
import phoneIcon from "../../../images/Services/BuiltinV2/builtinv2-phone-icon.svg";
import {
  advantagesItems,
  companyItems,
  infrastructureItems,
  installationItems,
  lowCurrentLineCablesItems,
  noRoomForErrorItems,
  onlyByProjectItems,
  powerLineCablesItems,
} from "./consts";
import useWindowSize from "../../../hooks/useWindowSize";
import ImageCard from "../../ImageCard/ImageCard";

const containerStyles = { maxWidth: "930px", padding: "0 15px" };

const MainV2 = () => {
  const uppendTitle = () => {
    document.querySelector("head title").innerHTML =
      "Монтаж | Умный дом в Москве | Homeсhain";
  };

  const uppendMeta = () => {
    let description = document.querySelector("meta[name=description]");
    let keywords = document.querySelector("meta[name=keywords]");
    if (description) {
      description.content =
        'Монтаж. Умный дом в Москве "под ключ" - проектирование, комплектация, пусконаладка. Homeсhain +7 (499) 113 10 10.';
    } else {
      description = document.createElement("meta");
      description.name = "description";
      description.content =
        'Монтаж. Умный дом в Москве "под ключ" - проектирование, комплектация, пусконаладка. Homeсhain +7 (499) 113 10 10.';
      document.querySelector("head").appendChild(description);
    }

    if (keywords) {
      keywords.content =
        'умный дом установка, монтаж системы умный дом, умный дом монтаж, установка систем умный дом, установка умного дома цена, подключение умного дома, умный дом установка "под ключ", установка умный дом москва';
    } else {
      keywords = document.createElement("meta");
      keywords.name = "keywords";
      keywords.content =
        'умный дом установка, монтаж системы умный дом, умный дом монтаж, установка систем умный дом, установка умного дома цена, подключение умного дома, умный дом установка "под ключ", установка умный дом москва';
      document.querySelector("head").appendChild(keywords);
    }
  };

  const { isTablet, isMobile } = useWindowSize();

  return (
    <div className={styles.container} id="main">
      {uppendTitle()}
      {uppendMeta()}
      <Navbar activeServices />

      <div className="header-container">
        <header>
          <h1>Монтаж</h1>
          <div className="header-actions">
            <HashLink smooth to="#feedback-big">
              <button className="btn-submit">Оставить заявку</button>
            </HashLink>
            <HashLink smooth to="#feedback">
              <button className="btn-callback">
                <div>
                  <img src={phoneIcon} alt="phone icon" />
                </div>
                заказать обратный звонок
              </button>
            </HashLink>
          </div>
        </header>
      </div>

      <Container style={containerStyles} id="installation-work">
        <h2>Комплексное управление монтажными работами</h2>
        <p>
          Монтаж системы умный дом “под ключ” - этап, который не прощает ошибок.
          С целью успешного проведения пусконаладки важна точная разработка,
          соблюдение стандартизированных схем, последовательности коммутации
          оборудования и управление человеческими ресурсами. Проложенные
          коммуникации и смонтированные устройства в помещении должны строго
          соответствовать утвержденной проектной документации.
        </p>
        <br />
        <p>
          Умный дом “под ключ” подразумевает прокладку всех кабельных линий,
          которые делятся на две составляющие.
        </p>

        <div className="installation-variant">
          <div className="rounded-text">1</div>
          <h3>Силовые кабельные линии</h3>
          <Row noGutters>
            {powerLineCablesItems.map(({ title, imgSrc }, index) => (
              <Col key={title} xl={3} lg={4} md={6} sm={12}>
                <ImageCard title={title} imgSrc={imgSrc} />
              </Col>
            ))}
          </Row>
        </div>

        <div className="installation-variant">
          <div className="rounded-text">2</div>
          <h3>Слаботочные кабельные линии</h3>
          <Row noGutters>
            {lowCurrentLineCablesItems.map(({ title, imgSrc }, index) => (
              <Col key={title} xl={3} lg={4} md={6} sm={12}>
                <ImageCard title={title} imgSrc={imgSrc} />
              </Col>
            ))}
          </Row>
        </div>
      </Container>

      <Container style={containerStyles}>
        <FeedbackFormSmallV2 />
      </Container>

      <Container style={containerStyles} id="advantages">
        <p>
          Компания Homechain предоставляет услугу по комплексному управлению
          всеми монтажными работами, включающую сборку системы умный дом и
          электромонтаж “под ключ”. Цена и стоимость напрямую зависит от
          желаемого функционала. Мы за комплексный подход к автоматизации и
          управлению, означающий разработку и прокладку всех кабельных линий
          специалистами Homechain. Обозначенный сценарий содержит неоспоримые
          преимущества для заказчика и исполнителя
        </p>

        <div>
          <Row noGutters className="advantages-container">
            {advantagesItems.map(({ title, description, iconSrc }) => (
              <Col key={title} lg={4} md={6} sm={12}>
                <div className="icon-item">
                  <div className="image">
                    <img src={iconSrc} alt="circle" />
                  </div>
                  <p className="title">{title}</p>
                  <p className="description">{description}</p>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Container>

      <Container style={containerStyles} id="no-room-for-error">
        <h2>Без права на ошибку</h2>
        <p>
          При разработке и установке системы умный дом “под ключ” не существует
          важных и неважных этапов с точки зрения проектного управления. Это
          обусловлено тем, что ошибка на любой из стадий реализации системы
          приведёт к проблеме на последующем этапе.
        </p>
        <p>
          Тем не менее ошибка на этапе прокладки кабельных линий и
          электромонтажа умного дома “под ключ” может являться одной из самых
          дорогих, если вовремя не выявлена, используя предварительный или
          промежуточный аудит, стоимость которого уникальна для каждого объекта.
          При выявлении ошибки после завершения чистовых работ в помещении, у
          заказчика будет два сценария на выбор:
        </p>

        <div>
          <Row noGutters>
            {noRoomForErrorItems.map(({ title, imgSrc }, index) => (
              <Col
                style={{
                  marginBottom: index === 0 && isTablet ? "60px" : "40px",
                }}
                key={index}
                md={6}
                sm={12}
              >
                <div className="item">
                  <div className="rounded-text">{index + 1}</div>
                </div>
                <ImageCard
                  imgHeight={isMobile ? 170 : 250}
                  title={title}
                  imgSrc={imgSrc}
                />
              </Col>
            ))}
          </Row>
        </div>

        <p>
          Ни один из обозначенных сценариев не устраивает ни нас как
          исполнителя, ни заказчика по причине увеличения стоимости ремонта.
          Поэтому специалисты компании Homechain обеспечивают управление,
          безопасность и тотальный контроль за работами на каждом сантиметре
          объекта, вне зависимости от типа объекта: дом, квартира,
          производственное помещение или коммерческая недвижимость.
        </p>
        <p>
          Электромонтаж системы умный дом “под ключ” - многоэтапный процесс,
          требующий тщательной подготовки объекта. Согласно внутреннему
          регламенту, мы не приступаем к сборке автоматизации и не делаем
          коммерческие предложения (с указанием приблизительной конечной цены и
          стоимости) по монтажу электрики, слаботочных систем и оборудования без
          проектной документации или на основе проекта ненадлежащего качества.
          Мы полностью солидарны с русской пословицей «Семь раз отмерь, один раз
          отрежь».
        </p>
      </Container>

      <FeedbackFormSmall updatedTitle />

      <div id="work-stages">
        <Container style={containerStyles}>
          <h2>Этапы работ</h2>
          <div>
            <div className="stage-number">
              <div className="rounded-text">1</div>
              <p>Разметка</p>
            </div>
            <Row>
              <Col
                className="align-center"
                className="align-center"
                md={7}
                sm={12}
              >
                <p>
                  Разметка - этап между окончанием проектирования системы умный
                  дом и началом электромонтажных работ. Вне зависимости от типа
                  (умный дом, офис или производственное помещение), любой объект
                  требует разметки кабельных линий, подрозетников, датчиков,
                  систем управления и другого оборудования, обозначенных в
                  проектной документации.
                </p>
              </Col>
              <Col className={isTablet && "mt-40"} md={5} sm={12}>
                <div className="image-container">
                  <img
                    src={require("../../../images/Services/BuiltinV2/builtinv2-razmetka.png")}
                    alt=""
                  />
                </div>
              </Col>
            </Row>
            <p>
              Для максимальной аккуратности разметки, наши специалисты
              используют передовое европейское оборудование: лазерные уровни и
              нивелиры. Умный и надежный инструмент обеспечивает высокую
              точность и безопасность разметки. Все подрозетники размечаются с
              точностью до миллиметра, тем самым исключая любое отклонение по
              высоте подрозетников. На первый взгляд может показаться, что
              несколько миллиметров ни на что не могут повлиять, но это не так.
              Минимальная разница в высоте розеток и выключателей становится
              заметна на стенах с вертикальными или горизонтальными полосами
              будь то швы плитки или декоративные узоры.
            </p>
            <Row>
              <Col className={isTablet && "mb-40"} md={5} sm={12}>
                <div className="image-container">
                  <img
                    src={require("../../../images/Services/BuiltinV2/builtinv2-Pol copy.png")}
                    alt=""
                  />
                </div>
              </Col>
              <Col
                className="align-center"
                className="align-center"
                md={7}
                sm={12}
              >
                <p>
                  Специалисты компании Homechain всегда учитывают поднятие пола
                  при разметке подрозетников и выводов, а все высоты отмечаются
                  от чистового уровня пола с учётом будущей стяжки и напольного
                  покрытия. После разметки выводов и подрозетников
                  осуществляется разметка точек установки выключателей,
                  датчиков, моторов для штор, осветительного и акустического
                  оборудования на стенах и потолке.
                </p>
              </Col>
            </Row>
            <p>
              Освещение - жизненно важный элемент любой системы умный дом.
              Источники света должны быть расположены в строго обозначенных
              местах помещения. С этой целью делается дополнительная разметка,
              определяющая геометрический центр помещения. Сначала наносится
              осевая линия и комната делится пополам, после проводятся диагонали
              в образованных прямоугольниках.
            </p>
            <p>
              На финальном этапе наносятся маршруты кабельных линий от щита
              умного дома (модуля управления) или электрощита до конкретных
              точек: подрозетников, осветительных приборов и электровыводов.
              Стоимость и сроки разметки объекта зависит от масштаба системы
              умный дом.
            </p>
          </div>
          <div>
            <div className="stage-number">
              <div className="rounded-text">2</div>
              <p>Высверливание подрозетников и распределительных коробок</p>
            </div>
            <p>
              На данном этапе начинается самая шумная и пыльная часть
              электромонтажа - штробление и сверление.
              <br />
              Мы придерживаемся философии, что везде должен быть порядок, а
              строительные работы не могут служить оправданием для пыльного
              объекта. Именно поэтому специалисты компании Homechain используют
              только оборудование с пылеудалением, подключенное к строительному
              пылесосу. Наш подход обеспечивает порядок и максимально возможную
              чистоту и защиту от пыли объекта на всех этапах.
              <br />
              Подрозетники - места установки розеток и выключателей на финальном
              этапе. Стандартная высота установки:
            </p>
            <div>
              <Row noGutters>
                <Col md={6} sm={12}>
                  <ImageCard
                    imgHeight={isMobile ? 170 : 250}
                    title={"Розетки = 300мм"}
                    imgSrc={require("../../../images/Services/BuiltinV2/builtinv2-visverlivanie-2 1.png")}
                  />
                </Col>
                <Col md={6} sm={12}>
                  <ImageCard
                    imgHeight={isMobile ? 170 : 250}
                    title={"Выключатели = 900мм"}
                    imgSrc={require("../../../images/Services/BuiltinV2/builtinv2-visverlivanie.png")}
                  />
                </Col>
              </Row>
            </div>
            <p>
              Высоты указаны от уровня чистового пола. Точные значения
              определяются на этапе проектирования системы и могут отличаться от
              вышеобозначенных. Например, баскетболистам будет удобно управление
              выключателями расположенными выше 900мм, а людям низкого роста -
              наоборот.
              <br />
              При высверливании подрозетников сначала сверлится центр с помощью
              бура, а затем углубление. Инструмент для углубления зависит от
              материала стен, применяются:
            </p>
            <div>
              <Row noGutters>
                <Col lg={4} md={6} sm={12}>
                  <ImageCard
                    imgHeight={isMobile ? 170 : 200}
                    title="Средний перфоратор с алмазной коронкой"
                    imgSrc={require("../../../images/Services/BuiltinV2/builtinv2-perforator.png")}
                  />
                </Col>
                <Col lg={4} md={6} sm={12}>
                  <ImageCard
                    imgHeight={isMobile ? 170 : 200}
                    title="Большой перфоратор с ударной коронкой"
                    imgSrc={require("../../../images/Services/BuiltinV2/builtinv2-kak-pravilno-vybrat-perforator-dlia-remonta.png")}
                  />
                </Col>
                <Col lg={4} md={6} sm={12}>
                  <ImageCard
                    imgHeight={isMobile ? 170 : 200}
                    title="Штроборез с запилом 60мм в глубину"
                    imgSrc={require("../../../images/Services/BuiltinV2/builtinv2-vse-o-shtroborezah-4.png")}
                  />
                </Col>
              </Row>
            </div>
            <p>
              Европейский стандарт подрозетников имеет диаметр 68мм. При
              расположении нескольких подрозетников в ряд, расстояние между
              центрами должно составлять 71мм. Стандартные глубины
              подрозетников: 60мм и 45мм, более глубокие подрозетники
              используются при необходимости коммутации проводов.
              <br />
              Распределительные коробки устанавливаются на потолке, в местах,
              где требуется дополнительная коммутация кабельных линий. Например,
              для светодиодных лент коммутируется силовой кабель сечением 1,5мм2
              из щита и гибкий кабель сечением 0,75мм2 к светодиодной ленте.
            </p>
          </div>
          <div>
            <div className="stage-number">
              <div className="rounded-text">3</div>
              <p>Штробление стен.</p>
            </div>

            <p>
              На данном этапе установки умного дома создаются “штробы” -
              канавки\борозды в стенах. Впоследствии штробы используются для
              укладки кабельных линий, что обеспечивает скрытый монтаж
              коммуникаций.
            </p>
            <p>
              Штробление осуществляется строго после высверливания
              подрозетников, так как при обратном порядке действий центровой бур
              будет смещаться в штробу.
            </p>
            <p>Перед штроблением необходимо определить требуемый размер.</p>

            <div>
              <Row className="box-shadow-container">
                <Col md={6} sm={12}>
                  <div className="icon-item">
                    <div className="image">
                      <img
                        src={require("../../../images/Services/BuiltinV2/builtinv2-Width.svg")}
                        alt="circle"
                      />
                    </div>
                    <p className="title">
                      Ширина штробы = суммарная толщина кабелей{" "}
                    </p>
                  </div>
                </Col>
                <Col md={6} sm={12}>
                  <div className="icon-item">
                    <div className="image">
                      <img
                        src={require("../../../images/Services/BuiltinV2/builtinv2-Height.svg")}
                        alt="circle"
                      />
                    </div>
                    <p className="title">
                      Глубина штробы = диаметр кабеля с самым большим сечением.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>

            <p>
              Создание штроб осуществляется с помощью штробореза и лазерного
              уровня. По линиям, отмеченным при разметке, штроборезом
              выпиливаются борозды в стене. Важно это делать после полного
              высыхания слоя нанесенной штукатурки.
            </p>
          </div>
        </Container>

        <FeedbackFormSmall updatedTitle />

        <Container style={containerStyles}>
          <div>
            <div className="stage-number">
              <div className="rounded-text">4</div>
              <p>Протяжка и маркировка кабельных линий</p>
            </div>
            <p className="text-align-center">
              Homechain придерживается следующего стандарта прокладки кабельных
              линий:
            </p>

            <div>
              <Row className="box-shadow-container">
                <Col md={6} sm={12}>
                  <div className="icon-item">
                    <div className="image">
                      <img
                        src={require("../../../images/Services/BuiltinV2/builtinv2-Иконка пол.svg")}
                        alt="circle"
                      />
                    </div>
                    <div className="title">Пол</div>
                    <p className="description">
                      Силовые розетки, теплые полы, освещение ниже уровня
                      розеток
                    </p>
                  </div>
                </Col>
                <Col md={6} sm={12}>
                  <div className="icon-item">
                    <div className="image">
                      <img
                        src={require("../../../images/Services/BuiltinV2/builtinv2-potolok.svg")}
                        alt="circle"
                      />
                    </div>
                    <div className="title">Потолок</div>
                    <p className="description">
                      Выключатели, освещение, системы автоматизации, шины
                      управления (KNX, Modbus) и прочие.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>

            <p>
              Также в расположении кабельных линий важную роль играют возводимые
              в дальнейшем стены, поэтому наличие проекта и планировочных
              решений для объекта — это обязательное условие качественной сборки
              инженерных систем.
            </p>

            <Row>
              <Col
                className="align-center"
                className="align-center"
                md={7}
                sm={12}
              >
                <p>
                  По полу кабельные линии прокладываются в гофрах, по потолку в
                  лотке, гофрах или открытом виде (пучками) с соблюдением
                  необходимого стандартного расстояния между силовыми и
                  слаботочными линиями, а также маркой и типом кабелей в
                  соответствии с действующими нормативными документами по
                  строительству.
                </p>
              </Col>
              <Col className={isTablet && "mt-40"} md={5} sm={12}>
                <div className="image-container">
                  <img
                    src={require("../../../images/Services/BuiltinV2/builtinv2-POL protyjka.png")}
                    alt=""
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col className={isTablet && "mb-40"} md={5} sm={12}>
                <div className="image-container">
                  <img
                    src={require("../../../images/Services/BuiltinV2/builtinv2-POtolok protyjka.png")}
                    alt=""
                  />
                </div>
              </Col>
              <Col className="align-center" md={7} sm={12}>
                <p>
                  Далее идёт установка клипс для гофр, крепления для лотка (при
                  наличии) и держателей кабелей. Осуществляется прокладка и
                  маркировка кабельных линий в строгом соответствии с проектной
                  документацией умного дома и кабельным журналом. Все кабельные
                  линии маркируются строго с обоих концов. Стоимость и цена
                  протяжки кабельных линий индивидуальны для каждого объекта.
                </p>
              </Col>
            </Row>
          </div>

          <div>
            <div className="stage-number">
              <div className="rounded-text">5</div>
              <p>Установка подрозетников и распределительных коробок.</p>
            </div>
            <p>
              После готовности всех кабельных линий устанавливаются
              распределительные и монтажные коробки, а также подрозетники со
              строгим соблюдением разметки, осуществленной в соответствии с
              проектной документацией.
            </p>
          </div>

          <div>
            <div className="stage-number">
              <div className="rounded-text">6</div>
              <p>Проверка электромонтажа</p>
            </div>
            <Row>
              <Col className="align-center" md={7} sm={12}>
                <p>
                  Результат вышеобозначенных этапов проверяется главным
                  инженером проекта на предмет строгого соответствия
                  документации. При отсутствии необходимости правок системы,
                  подписывается промежуточный акт, и заказчик приступает к
                  предчистовому (white box) этапу.
                </p>
              </Col>
              <Col className={isTablet && "mt-40"} md={5} sm={12}>
                <div className="image-container">
                  <img
                    src={require("../../../images/Services/BuiltinV2/builtinv2-proverka elctro.png")}
                    alt=""
                  />
                </div>
              </Col>
            </Row>
          </div>

          <div>
            <div className="stage-number">
              <div className="rounded-text">7</div>
              <p>Проверка целостности кабельных линий</p>
            </div>
            <Row>
              <Col className={isTablet && "mb-40"} md={5} sm={12}>
                <div className="image-container">
                  <img
                    src={require("../../../images/Services/BuiltinV2/builtinv2-proverka celostnosti.png")}
                    alt=""
                  />
                </div>
              </Col>
              <Col className="align-center" md={7} sm={12}>
                <p>
                  Специалисты Homechain проверяют целостность кабельных линий
                  после завершения этапа white box путём визуального осмотра и
                  прозвонки всех кабельных линий: силовых, слаботочный, шин
                  (KNX, Modbus) и прочих. Проверяется целостность маркировки на
                  обоих концах кабеля. После завершения данного этапа заказчик
                  приступает к чистовым ремонтным работам, а мы к сборке щита и
                  модулей управления.{" "}
                </p>
              </Col>
            </Row>
          </div>

          <div>
            <div className="stage-number">
              <div className="rounded-text">8</div>
              <p>Сборка щита</p>
            </div>
            <p>
              Homechain преследует философию единого щита. Это означает, что
              силовая электрика, блоки питания и модули автоматики находятся в
              одном месте. Это позволяет экономить пространство, уменьшает
              стоимость, упрощает установку системы и сервисное обслуживание
              умного дома. Центральный щит является централью управления
              оборудованием.
            </p>
            <Row>
              <Col className="align-center" md={7} sm={12}>
                <p>
                  Сборка щита осуществляется в производственном цеху Homechain,
                  что позволяет обеспечить профессиональную сборку без
                  необходимости присутствия на объекте. Цена щитового
                  оборудования обуславливается выбранным функционалом.
                </p>
              </Col>
              <Col className={isTablet && "mt-40"} md={5} sm={12}>
                <div className="image-container">
                  <img
                    src={require("../../../images/Services/BuiltinV2/builtinv2-proverka 2.png")}
                    alt=""
                  />
                </div>
              </Col>
            </Row>
          </div>

          <div>
            <div className="stage-number">
              <div className="rounded-text">9</div>
              <p>Подключение щита</p>
            </div>
            <p>
              Щит подключается только после завершения всех пыльных работ на
              объекте и осуществления влажной уборки в местонахождении щита.
              Оборудование и модули управления для щита доставляется на объект в
              собранном виде, что дает возможность быстро его подключить.
              Подключение кабельных линий к щиту осуществляется через клеммы,
              спроектированные и установленные под каждый кабель на объекте.{" "}
            </p>

            <Row>
              <Col className={isTablet && "mb-40"} md={5} sm={12}>
                <div className="image-container">
                  <img
                    src={require("../../../images/Services/BuiltinV2/builtinv2-podkluchenie 2.png")}
                    alt=""
                  />
                </div>
              </Col>
              <Col className="align-center" md={7} sm={12}>
                <p>
                  После подключения кабелей к клеммам производится финальная
                  проверка системы подключения клемм в соответствии с проектной
                  документацией. После проверки осуществляется первый запуск
                  щита и проверки работоспособности элементов управления.
                </p>
              </Col>
            </Row>
          </div>

          <div>
            <div className="stage-number">
              <div className="rounded-text">10</div>
              <p>Чистовой электромонтаж</p>
            </div>
            <p>
              Чистовой электромонтаж - процесс установки и подключения всей
              “видимой” электрики умного дома:
            </p>
            <Row noGutters>
              {installationItems.map(({ title, imgSrc }, index) => (
                <Col key={index} xl={3} lg={4} md={6} sm={12}>
                  <ImageCard title={title} imgSrc={imgSrc} />
                </Col>
              ))}
            </Row>

            <p>
              Цена чистовых элементов и стоимость данного этапа утверждаются при
              проектировании. При строительстве энергоэффективного дома с
              использованием возобновляемых источников электроэнергии (солнце и
              ветер), устанавливаются 3 категории оборудования:
            </p>

            <Row>
              <Col md={7} sm={12}>
                <p>
                  <strong>Солнечные панели</strong> <br />
                  Фактически солнечные панели – это блоки из модулей, состоящих
                  их соединённых полупроводниковых фотоэлементов. Они
                  преобразуют свет солнца в энергию. Интересно, что для
                  автоматического функционирования панелей ежедневный свет
                  солнца не является необходимостью. Аккумулятор накапливает
                  энергию в солнечные дни и расходует её в пасмурные.
                </p>
              </Col>
              <Col className={isTablet && "mt-40"} md={5} sm={12}>
                <div className="image-container">
                  <img
                    src={require("../../../images/Services/BuiltinV2/builtinv2-Sun batterie.png")}
                    alt=""
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col className={isTablet && "mb-40"} md={5} sm={12}>
                <div className="image-container">
                  <img
                    src={require("../../../images/Services/BuiltinV2/builtinv2-Accum battery.png")}
                    alt=""
                  />
                </div>
              </Col>
              <Col md={7} sm={12}>
                <p>
                  <strong>Аккумуляторные батареи</strong> <br />
                  Один из источников бесперебойного питания, защита дома от
                  отключений и скачков напряжения. В случае отключения или
                  скачков напряжения устройство автоматически возьмет нагрузку
                  на себя и уведомит вас об этом. Как правило, аккумуляторные
                  батареи устанавливаются в отдельное помещение.
                </p>
              </Col>
            </Row>

            <Row>
              <Col md={7} sm={12}>
                <p>
                  <strong>Ветрогенераторы</strong> <br />
                  Энергия ветра – экологичный источник электроэнергии.
                  Устройством используется поток ветра, направленный на лопасти
                  ветряка, что приводит к их вращению. Лопасти передают
                  вращательный момент на ротор, генерирующий переменный ток.
                  Ветрогенератор является надежной установкой, выдерживающей
                  суровые климатические условия и низкие температуры.
                </p>
              </Col>
              <Col className={isTablet && "mt-40"} md={5} sm={12}>
                <div className="image-container">
                  <img
                    src={require("../../../images/Services/BuiltinV2/battery wind.png")}
                    alt=""
                  />
                </div>
              </Col>
            </Row>

            <p>
              По завершению данного этапа электромонтаж системы умный дом
              считается завершенным и объект переходит на стадию пусконаладки,
              где создаётся логика управления.
            </p>
          </div>
        </Container>

        <Container style={containerStyles}>
          <p className="text-align-center">Цена вопроса</p>
          <p>
            Нам часто поступают запросы о стоимости реализации системы умный
            дом. Цена зависит от множества факторов, для удобства приводим
            пример расчета стоимости для квартиры площадью 100м2 в г. Москва:
          </p>

          <div>
            <table className="price-table">
              <tr>
                <th>№</th>
                <th>Наименование</th>
                <th>Стоимость (€)</th>
                <th>Сумма (€)</th>
              </tr>
              <tr>
                <td>1</td>
                <td>
                  Черновой электромонтаж <br /> (ЭОМ, СКС, Умный дом)
                </td>
                <td>
                  30/м<sup>2</sup>
                </td>
                <td>3 000</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Сборка щита</td>
                <td>1 500</td>
                <td>1 500</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Подключение щита</td>
                <td>500</td>
                <td>500</td>
              </tr>
              <tr>
                <td>4</td>
                <td>
                  Чистовой электромонтаж <br /> (ЭОМ, СКС, Умный дом)
                </td>
                <td>
                  30/м<sup>2</sup>
                </td>
                <td>3 000</td>
              </tr>
              <tr className="tr-footer">
                <td></td>
                <td></td>
                <td>Итого (€)</td>
                <td>8 000</td>
              </tr>
            </table>
          </div>
        </Container>

        <Container style={containerStyles}>
          <div>
            <div className="request-container">
              <h2>Заказать расчет стоимости</h2>
              <HashLink smooth to="#feedback-big">
                <button>Оставить заявку</button>
              </HashLink>
            </div>
          </div>
        </Container>

        <Container style={containerStyles}>
          <h2>Сетевая инфраструктура</h2>
          <p>
            СКС (Структурированная Кабельная Система) в контексте умного дома -
            это локальная сеть объекта с постоянным доступом в сеть интернет или
            без него.
          </p>
          <p>СКС необходима для следующий целей:</p>

          <div style={{ gap: 0 }}>
            <Row noGutters>
              {infrastructureItems
                .slice(0, 8)
                .map(({ title, imgSrc }, index) => (
                  <Col key={index} xl={3} lg={4} md={6} sm={12}>
                    <ImageCard title={title} imgSrc={imgSrc} />
                  </Col>
                ))}
            </Row>
            <Row noGutters>
              {infrastructureItems.slice(8).map(({ title, imgSrc }, index) => (
                <Col key={index} lg={4} md={6} sm={12}>
                  <ImageCard
                    imgHeight={isTablet ? 168 : 200}
                    title={title}
                    imgSrc={imgSrc}
                  />
                </Col>
              ))}
            </Row>
          </div>

          <p>
            Homechain придерживается позиции, что все кабельные линии на объекте
            должны проектироваться и монтироваться одним исполнителем. Это
            обеспечит отсутствие недочетов в проекте СКС. По этой причине мы
            всегда включаем СКС в проектирование электрики и автоматизации.
          </p>
          <p>
            В ходе проектирования СКС осуществляется подбор сетевого
            оборудования в зависимости от предполагаемого количества сетевых
            устройств..
          </p>
          <p>
            Очевидно, что современный быт невозможен без использования
            интернета. Количество сетевых устройств, размер файлов, скорость
            передачи данных всегда растут. По этой причине Homechain проектирует
            СКС с учётом запаса на будущее
          </p>

          <div>
            <p className="text-align-center">Только по проекту</p>

            <p>
              Отсутствие проекта сетевой инфраструктуры может привести к
              следующим проблемам:
            </p>

            <Row noGutters>
              {onlyByProjectItems.map(({ title, imgSrc }, index) => (
                <Col key={index} lg={4} md={6} sm={12}>
                  <ImageCard
                    imgHeight={isMobile ? 168 : 200}
                    title={title}
                    imgSrc={imgSrc}
                  />
                </Col>
              ))}
            </Row>

            <p>
              В связи с этим включение СКС в проектирование систем автоматизации
              обязательно.
            </p>
          </div>

          <div>
            <p className="text-align-center">Wi-Fi или кабель?</p>
            <p>
              Сегодня существует мнение, что подключение сетевых устройств по
              кабелю - пережиток прошлого. На самом деле это не так: проводное
              подключение является самым стабильным и надёжным методом
              подключения сетевых устройств.{" "}
            </p>

            <Row>
              <Col className={isTablet && "mb-40"} md={5} sm={12}>
                <div className="image-container">
                  <img
                    src={require("../../../images/Services/BuiltinV2/builtinv2-last section 1 1.png")}
                    alt=""
                  />
                </div>
              </Col>
              <Col className="align-center" md={7} sm={12}>
                <div>
                  <p>
                    Мы придерживаемся простой позиции: все стационарные сетевые
                    устройства должны быть подключены к сети по только проводу,
                    в то время как мобильные устройства (телефоны, планшеты,
                    ноутбуки, портативные колонки) подключаются к сети по Wi-Fi.
                  </p>
                  <br />
                  <p>
                    Данный подход обеспечивает максимальную производительность
                    Wi-Fi сети.
                  </p>
                </div>
              </Col>
            </Row>
          </div>

          <div>
            <p className="text-align-center">Телекоммуникационный шкаф</p>

            <Row>
              <Col className="align-center" md={7} sm={12}>
                <div>
                  <p>
                    Телекоммуникационный шкаф - “дом СКС” на объекте. Как
                    правило, сетевой шкаф располагается на стене. В зависимости
                    от конфигурации объекта возможен монтаж внутрь гардеробных
                    шкафов. Размер сетевого шкафа зависит от количества сетевых
                    устройств на объекте.
                  </p>
                  <br />
                  <p>
                    Homechain в своих проектах использует только качественное
                    сетевое оборудование от ведущих мировых производителей,
                    основные производители:
                  </p>
                </div>
              </Col>
              <Col className={isTablet && "mt-40"} md={5} sm={12}>
                <div className="image-container">
                  <img
                    src={require("../../../images/Services/BuiltinV2/builtinv2-Шкаф тклкеос.png")}
                    alt=""
                  />
                </div>
              </Col>
            </Row>

            <Row noGutters>
              {companyItems.map(({ title, imgSrc }, index) => (
                <Col key={index} lg={4} md={6} sm={12}>
                  <ImageCard
                    imgHeight={isMobile ? 168 : 200}
                    title={title}
                    imgSrc={imgSrc}
                  />
                </Col>
              ))}
            </Row>
          </div>

          <div>
            <p className="text-align-center">Монтаж слаботочных систем (СКС)</p>
            <Row>
              <Col className={isTablet && "mb-40"} md={5} sm={12}>
                <div className="image-container">
                  <img
                    src={require("../../../images/Services/BuiltinV2/builtinv2-Монтаж-СКС.jpg")}
                    alt=""
                  />
                </div>
              </Col>
              <Col className="align-center" md={7} sm={12}>
                <p>
                  Монтаж слаботочных систем осуществляется параллельно с
                  электромонтажными работами и включает такие же этапы, как и
                  при электромонтаже (от разметки до чистового электромонтажа).
                </p>
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      <FeedbackFormSmallV2 secondVersion />

      <FeedbackFormBig hideBig={() => null} solution={true} />
    </div>
  );
};

export default MainV2;
