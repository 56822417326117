import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";
import Navbar from "../Navbar/Navbar";
import FeedbackFormSmall from "../FeedbackForm/FeedbackFormSmall";
import FeedbackFormBig from "../FeedbackForm/FeedbackFormBig";

import Sufit from "../../images/Services/Painting/sufit.png";
import ServiceBg from "../../images/Services/Painting/painting-bg.png";
import ServicesDropdownPages from "../Services/ServicesDropdown/ServicesDropdownPages";

class Painting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      style: "borderTop: 2px solid",
      buttonOrder: false,

      activeServices: true,
      isSolutionsHovering: false,
      isServicesHovering: false,
      isMobile: window.innerWidth <= 576
    };
  }

  uppendTitle() {
    document.querySelector("head title").innerHTML =
      "Покраска изделий из пластика | Умный дом в Москве | Homeсhain";
  }

  uppendMeta() {
    let description = document.querySelector("meta[name=description]");
    let keywords = document.querySelector("meta[name=keywords]");
    if (description) {
      description.content =
        'Покраска изделий из пластика. Умный дом в Москве "под ключ" - проектирование, комплектация, монтаж, пусконаладка. Homeсhain +7 (499) 113 10 10.';
    } else {
      description = document.createElement("meta");
      description.name = "description";
      description.content =
        'Покраска изделий из пластика. Умный дом в Москве "под ключ" - проектирование, комплектация, монтаж, пусконаладка. Homeсhain +7 (499) 113 10 10.';
      document.querySelector("head").appendChild(description);
    }

    if (keywords) {
      keywords.content =
        "покраска пластиковых изделий в москве, покраска изделий из пластика, покраска бытовой техники, покраска кондиционера, покраска пластикового подоконника";
    } else {
      keywords = document.createElement("meta");
      keywords.name = "keywords";
      document.querySelector("head").appendChild(keywords);
    }
  }

  servicesHover(state) {
    this.setState(this.toggleServicesHover);
  }

  toggleDropdown(state) {
    return {
      isServicesHovering: false,
      isSolutionsHovering: false
    };
  }

  toggleServicesHover(state) {
    return {
      isServicesHovering: !state.isServicesHovering,
      isSolutionsHovering: false
    };
  }

  render() {
    return (
      <div>
        {this.uppendTitle()}
        {this.uppendMeta()}
        <Navbar activeServices={this.state.activeServices} />
        <Container
          fluid
          className="page"
          style={{ backgroundImage: `url(${ServiceBg})` }}
        >
          <header>
            <Container fluid>
              <Row className="page-row custom-container">
                {!this.state.isMobile && (
                  <div className="page-nav">
                    <ul>
                      <li>
                        <Link to="/">Главная</Link>
                      </li>
                      <img src={require("../../images/arrow.svg")} alt="" />
                      <li onClick={this.servicesHover.bind(this)}>
                        <HashLink>Услуги</HashLink>
                      </li>
                      <img src={require("../../images/arrow.svg")} alt="" />
                      <li style={{ color: "#181818" }}>
                        Покраска оборудования
                      </li>
                    </ul>
                  </div>
                )}

                {this.state.isMobile && (
                  <div className="page-nav">
                    <ul>
                      <img
                        style={{ width: "6px" }}
                        src={require("../../images/arrow-left.svg")}
                        alt=""
                      />
                      <li onClick={this.servicesHover.bind(this)}>
                        <HashLink>Услуги</HashLink>
                      </li>
                    </ul>
                  </div>
                )}
                <div className="page-header">
                  <h1>Покраска оборудования</h1>
                  <HashLink smooth to="#feedback-big">
                    <button
                      onClick={() => {
                        this.giveOrder();
                      }}
                      className="hover-button"
                    >
                      Оставить заявку
                    </button>
                  </HashLink>
                </div>
              </Row>
              {this.state.isServicesHovering && (
                <ServicesDropdownPages
                  handler={this.servicesHover.bind(this)}
                />
              )}
            </Container>
          </header>
        </Container>
        <Container fluid className="custom-container">
          <Row className="page-description">
            <Col xl="12">
              <h3>Унификация оборудования</h3>
              <p>
                Автоматизированная система независимо от типа и стоимости
                предусматривает установку в умном доме множества розеток,
                выключателей, датчиков и электронных приборов. Смонтированное
                оборудование постоянно находится в поле зрения пользователей.
                Производители, преследуя цель улучшения технических
                характеристик и удешевления продукции, как правило, не
                анализируют тенденции и особенности оформления современных
                интерьеров в домах и квартирах.
              </p>
              <p>
                По причине экономии на дизайне покупателям предлагается
                оборудование для системы автоматики в стандартных оттенках:
                черном, белом и серебристом. В результате пользователи не могут
                в полной мере реализовать свои дизайнерские идеи. Проблема
                решается покраской бытовой техники и электронных устройств,
                предназначенных для умного дома.
              </p>
            </Col>
          </Row>
          <Row>
            <img
              style={{ marginTop: "0px" }}
              className="page-photo lazy"
              data-src={Sufit}
              alt="покраска пластиковых изделий в москве"
              src="/images/image-preloader.png"
            />
          </Row>
          <Row className="page-description">
            <Col xl="12">
              <h3>Красота и разнообразие цветовой гаммы</h3>
              <p>
                В нашем веке тотальная унификация является серьезным
                препятствием на пути индивидуализации интерьера частных домов и
                квартир. Благодаря внедрению в быт человека новых технологий
                активно формируется стиль современного жилья, особенность
                которого в преобладании компьютерной техники, электрических
                кухонных помощников, телевизоров, холодильников, и другой
                бытовой аппаратуры.
              </p>
              <p>
                Вся техника поставляется с конвейера производителя в однотипной
                расцветке: в белом, черном и серебристом (сером) цвете.
                Унификация привела к комплектованию жилья одинаковой по
                расцветке аудио, видеоаппаратурой и бытовой техникой. Но
                технический бум заканчивается, и пользователи желают для
                обустройства интерьера большего разнообразия в дизайне и
                цветовом оформлении электронного оборудования.
              </p>
            </Col>
          </Row>
          <Row />
        </Container>
        <FeedbackFormSmall />

        <Container fluid className="custom-container">
          <Row
            style={{ marginTop: "60px", marginBottom: "20px" }}
            className="ecosystem-description"
          >
            <Col xl="12">
              <h3>Преимущества покраски</h3>
              <p>
                <img
                  data-src={require("../../images/Services/Painting/painting-img.png")}
                  alt="покраска бытовой техники"
                  class="lazy"
                  src="/images/image-preloader.png"
                />
                Предлагаемая нами покраска пластиковых изделий в Москве
                позволяет оборудованию получить прочное покрытие, отличающееся
                эксклюзивным внешним видом, а также высокими эксплуатационными
                характеристиками, проявляющиеся в устойчивости к истиранию,
                воздействию влаги и ультрафиолетовых лучей.
                <span />
                Главное преимущество сервиса в возможности заказчикам обустроить
                интерьер помещения, в котором устройства автоматизированной
                системы будут гармонично сочетаться с отделочными материалами и
                предметами мебели. Для покрытия элементов применяются
                экологические расходные материалы от ведущих западных
                производителей. На покраску изделий распространяется гарантийный
                период использования без ухудшения внешнего вида.
              </p>
            </Col>
          </Row>
        </Container>
        <FeedbackFormBig bigForm={true} />
      </div>
    );
  }
}

export default Painting;
