import React from "react";
import { Container } from "react-bootstrap";
import ServicesDropdownBlocks from "./ServicesDropdownBlocks";

class ServicesDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      blocks: [
        {
          link: "proektirovanie-umnogo-doma",
          name: "Проектирование",
          imgSrc: require("../../../images/Services/Project/project.png"),
          xl: "9",
          lg: "12",
          md: "12",
          sm: "12",
        },
        {
          link: "avtorskij-nadzor-za-proektom",
          name: "Авторский надзор",
          imgSrc: require("../../../images/Services/Authorize/authorize.png"),
          xl: "3",
          lg: "4",
          md: "6",
          sm: "12",
        },
        {
          link: "umnyj-dom-stoimost",
          name: "Поставка оборудования",
          imgSrc: require("../../../images/Services/Delivery/delivery.png"),
          xl: "3",
          lg: "4",
          md: "6",
          sm: "12",
        },
        {
          link: "pokraska-izdelij-iz-plastika",
          name: "Покраска оборудования",
          imgSrc: require("../../../images/Services/Painting/painting.png"),
          xl: "3",
          lg: "4",
          md: "6",
          sm: "12",
        },

        {
          link: "podklyuchenie-sistemy-umnyj-dom",
          name: "Пусконаладка",
          imgSrc: require("../../../images/Services/Starting/starting.png"),
          xl: "6",
          lg: "4",
          md: "6",
          sm: "12",
        },

        {
          link: "audit-inzhenernyh-sistem",
          name: "Аудит",
          imgSrc: require("../../../images/Services/Audit/audit.png"),
          xl: "3",
          lg: "4",
          md: "6",
          sm: "12",
        },
        {
          link: "umnyj-dom-informaciya",
          name: "Консультации",
          imgSrc: require("../../../images/Services/Support/support.png"),
          xl: "3",
          lg: "4",
          md: "6",
          sm: "12",
        },
        {
          link: "umnyj-dom-ustanovka",
          name: "Монтаж",
          imgSrc: require("../../../images/Services/BuiltinV2/builtinv2-service.png"),
          xl: "6",
          lg: "12",
          md: "12",
          sm: "12",
        },
      ],
    };
  }

  render() {
    return (
      <Container fluid className="services-block services-block-pages">
        <Container
          style={{ padding: "0px" }}
          fluid
          className="custom-container"
        >
          <ServicesDropdownBlocks />
        </Container>
      </Container>
    );
  }
}

export default ServicesDropdown;
