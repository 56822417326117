import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

import classNames from "classnames";
import Fade from "react-bootstrap/Fade";

class ServicesBlock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      blocks: [
        {
          link: "umnyj-dom-informaciya",
          name: "Консультации",
          imgSrc: require("../../images/Services/Support/support.png"),
          xl: "3",
          lg: "4",
          md: "6",
          sm: "12",
        },
        {
          link: "proektirovanie-umnogo-doma",
          name: "Проектирование",
          imgSrc: require("../../images/Services/Project/project.png"),
          xl: "9",
          lg: "12",
          md: "12",
          sm: "12",
        },
        {
          link: "umnyj-dom-ustanovka",
          name: "Монтаж",
          imgSrc: require("../../images/Services/BuiltinV2/builtinv2-service.png"),
          xl: "6",
          lg: "12",
          md: "12",
          sm: "12",
        },
        {
          link: "podklyuchenie-sistemy-umnyj-dom",
          name: "Пусконаладка",
          imgSrc: require("../../images/Services/Starting/starting.png"),
          xl: "6",
          lg: "4",
          md: "6",
          sm: "12",
        },
        {
          link: "avtorskij-nadzor-za-proektom",
          name: "Авторский надзор",
          imgSrc: require("../../images/Services/Authorize/authorize.png"),
          xl: "3",
          lg: "4",
          md: "6",
          sm: "12",
        },
        {
          link: "umnyj-dom-stoimost",
          name: "Поставка оборудования",
          imgSrc: require("../../images/Services/Delivery/delivery.png"),
          xl: "3",
          lg: "4",
          md: "6",
          sm: "12",
        },
        {
          link: "pokraska-izdelij-iz-plastika",
          name: "Покраска оборудования",
          imgSrc: require("../../images/Services/Painting/painting.png"),
          xl: "3",
          lg: "4",
          md: "6",
          sm: "12",
        },
        {
          link: "audit-inzhenernyh-sistem",
          name: "Аудит",
          imgSrc: require("../../images/Services/Audit/audit.png"),
          xl: "3",
          lg: "4",
          md: "6",
          sm: "12",
        },
      ],
      blocksShow: 4,
      showButton: true,
      animation: false,
    };
  }

  showMore = () => {
    this.setState(
      {
        blocksShow: this.state.blocks.length,
      },
      () => {
        setTimeout(() => {
          this.setState({
            showButton: false,
          });
        }, 700);
      }
    );
  };

  animate = () => {
    this.setState({
      animation: true,
    });
  };

  render() {
    const { blocks, blocksShow, showButton } = this.state;
    const renderBlocks = blocks.slice(0, blocksShow).map((item, i) => {
      return (
        <Fade in={this.animate} appear key={i} className="servicesFade">
          <Col xl="3" lg="3" md="6" sm="6" xs="12">
            <Link to={`/services/${item.link}`} onClick={this.props.linkClick}>
              <div
                style={{
                  boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.18)",
                  backgroundColor: "#fff",
                }}
              >
                <div className="services-thumbnail services-block-thumbnail">
                  <div
                    style={{
                      backgroundImage: `url(${item.imgSrc})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center center",
                      backgroundSize: "cover",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                  <div>
                    <p>{item.name}</p>
                  </div>
                </div>
              </div>
            </Link>
          </Col>
        </Fade>
      );
    });

    return (
      <div className="services-block">
        <h2 style={{ marginBottom: "48px" }}>Наши услуги</h2>
        <Row className="blocksContainer">
          {renderBlocks}
          {showButton && (
            <button
              className={classNames("showMoreButton", "hover-button", {
                ["hide"]: blocksShow === blocks.length,
              })}
              onClick={this.showMore}
            >
              Все услуги
            </button>
          )}
        </Row>
      </div>
    );
  }
}

export default ServicesBlock;
