import React from "react";
import { Link } from "react-router-dom";
import { Row, Container, Col } from "react-bootstrap";
import Navbar from "../../components/Navbar/Navbar";
import Phone from "../../images/Footer/phone.svg";
import Message from "../../images/Footer/message.svg";
import Geo from "../../images/Footer/geo.svg";
import GoogleMap from "../../components/Contacts/GoogleMap";

class Contacts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeContacts: true,
      isMobile: window.innerWidth <= 576
    };
  }

  uppendTitle() {
    document.querySelector("head title").innerHTML =
      "Контакты | Умный дом в Москве | Homeсhain";
  }

  uppendMeta() {
    let description = document.querySelector("meta[name=description]");
    if (description) {
      description.content =
        'Контакты. Умный дом в Москве "под ключ" - проектирование, комплектация, монтаж, пусконаладка. Homeсhain +7 (499) 113 10 10.';
    } else {
      description = document.createElement("meta");
      description.name = "description";
      description.content =
        'Контакты. Умный дом в Москве "под ключ" - проектирование, комплектация, монтаж, пусконаладка. Homeсhain +7 (499) 113 10 10.';
      document.querySelector("head").appendChild(description);
    }
  }

  handleTelClick = () => {
    if (process.env.NODE_ENV === "production") {
      window.ym(54938986,'reachGoal','click_tel');
    }
  };

  render() {
    return (
      <div>
        {this.uppendTitle()}
        {this.uppendMeta()}
        <Navbar activeContacts={this.state.activeContacts} />
        <div className="page">
          <header className="contacts-header">
            <Container fluid className="custom-container">
              <Row className="page-row">
                {!this.state.isMobile && (
                  <div className="page-nav">
                    <ul>
                      <li>
                        <Link to="/">Главная</Link>
                      </li>
                      <img src={require("../../images/arrow.svg")} alt="" />
                      <li style={{ color: "#181818" }}>Контакты</li>
                    </ul>
                  </div>
                )}

                {this.state.isMobile && (
                  <div style={{ marginLeft: "15px" }} className="page-nav">
                    <ul>
                      <img
                        style={{ width: "6px" }}
                        src={require("../../images/arrow-left.svg")}
                        alt=""
                      />
                      <li>
                        <Link to="/">Главная</Link>
                      </li>
                    </ul>
                  </div>
                )}
              </Row>
            </Container>
            <Container fluid className="contacts-map-fluid-wrapper">
              <div className="contacts-map-wrapper">
                <GoogleMap
                  googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyB-kYxZ7N6mYQr8gaBWRZtMUW5_zgX_D18"
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div className="contacts-map-container-element" />}
                  mapElement={<div className="contacts-map-element" />}
                />
              </div>
            </Container>
          </header>
        </div>
        <Container className="contacts">
          <Row className="contacts-row">
            <Col xl="3" lg="3" md="3">
              <img
                data-src={Phone}
                alt=""
                class="lazy"
                src="/images/image-preloader.png"
              />
              <a onClick={this.handleTelClick} href="tel:+7(499)113-10-10">+7 (499) 113-10-10</a>
            </Col>
            <Col xl="6" lg="6" md="6">
              <img
                data-src={Geo}
                alt=""
                class="lazy"
                src="/images/image-preloader.png"
              />
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://goo.gl/maps/1GH3KkuMY91sJoJS9"
              >
                г. Москва, ул. Малая Грузинская 38, офис 316
              </a>
            </Col>
            <Col xl="3" lg="3" md="3">
              <img
                data-src={Message}
                alt=""
                class="lazy"
                src="/images/image-preloader.png"
              />
              <a href="mailto:hello@homechain.ai">hello@homechain.ai</a>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Contacts;
