import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import ControlBlock from "../../components/Tabs/Control/ControlBlock";

import { HashLink } from "react-router-hash-link";
import SolutionsBlockPages from "../Solutions/SolutionsBlock/SolutionsBlockPages";

const body = document.getElementById("body");
function deleteStupidDiv() {
    for (let i = 0; i < body.childNodes.length; i++) {
        if (body.childNodes[i].id === "footer") {
            body.childNodes[i].remove();
        }
    }
}

class Control extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: window.innerWidth <= 576
        };
    }

    setActiveCat(category) {
        this.setState({ activeCat: category });
    }

    uppendTitle() {
        document.querySelector("head title").innerHTML = "Управление | Умный дом в Москве | Homeсhain";
    }
    uppendMeta() {
        let description = document.querySelector("meta[name=description]");
        if (description) {
            description.content =
                'Управление умным домом Loxone, Apple HomeKit, Google Home, Яндекс, Amazon Alexa. Проектирование, монтаж, пусконаладка. Homeсhain +7 (499) 113 10 10.';
        } else {
            description = document.createElement("meta");
            description.name = "description";
            description.content =
                'Управление умным домом Loxone, Apple HomeKit, Google Home, Яндекс, Amazon Alexa. Проектирование, монтаж, пусконаладка. Homeсhain +7 (499) 113 10 10.';
            document.querySelector("head").appendChild(description);
        }
    }

    solutionsHover() {
        this.setState(this.toggleSolutionsHover);
    }

    toggleDropdown() {
        return {
            isServicesHovering: false,
            isSolutionsHovering: false
        };
    }

    toggleSolutionsHover(state) {
        return {
            isSolutionsHovering: !state.isSolutionsHovering,
            isServicesHovering: false
        };
    }

    render() {
        return (
            <div>
                {deleteStupidDiv()}
                {this.uppendTitle()}
                {this.uppendMeta()}
                <Navbar activeCat={"control"} activeSolutions={true} />
                <div className='page'>
                    <header style={{ height: "unset" }}>
                        <div className='container'>
                            <div className='row page-row'>
                                {!this.state.isMobile ? (
                                    <div>
                                        <div className='page-nav'>
                                            <ul>
                                                <li>
                                                    <Link to='/'>Главная</Link>
                                                </li>
                                                <img src={require("../../images/arrow.svg")} alt="" />

                                                <li onClick={this.solutionsHover.bind(this)}>
                                                    <HashLink>
                                                        Решения
                                                        <img src={require("../../images/arrow.svg")} style={{ marginLeft: "5px", marginRight: "-5px" }} alt='' />
                                                    </HashLink>
                                                </li>

                                                <li style={{ color: "#181818" }}>Экосистемы</li>
                                            </ul>
                                        </div>
                                        {this.state.isSolutionsHovering && <SolutionsBlockPages activeCat={`control`} handler={this.solutionsHover.bind(this)} />}
                                    </div>
                                ) : (
                                    <div>
                                        <div className='page-nav'>
                                            <ul>
                                                <li>
                                                    <Link to='/'>Главная</Link>
                                                </li>
                                                <img src={require("../../images/arrow.svg")} alt="" />
                                                <li style={{ color: "#181818" }}>Экосистемы</li>
                                            </ul>
                                        </div>
                                    </div>
                                )}
                                <div className='page-header'>
                                    <h1 className='control-title'>Управление умным домом</h1>
                                    <p>
                                        Мы осуществляем комплексную реализацию решений умный дом с управлением из единого центра. <br /> Это означает, что все элементы
                                        умного дома доступны в едином приложении и голосовом ассистенте. Одна экосистема, одно приложение, один голосовой ассистент.
                                        <br /> Забудьте о сложностях.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </header>
                </div>

                <ControlBlock styles={{ marginBottom: "80px" }} />

                <div className='container-fluid custom-container'>
                    <div className='row page-description control-historical-reference-container'>
                        <div className='col-xl-12'>
                            <h3>Историческая справка:</h3>

                            <p className='control-historical-reference-text'>
                                Первые единичные попытки домашней автоматизации в современном понимании было зафиксированы в середине XX века, задолго до появления apple
                                homekit. Они не были широко распространены, поскольку имели вид определенных экспериментов и изобретательных причуд для устоев того
                                времени. В «Доме с кнопками», разработанном специалистом Матиасом (США), кнопки находились по всему дому и автоматизировали выполнение
                                главных задач бытового характера.
                            </p>
                            <p className='control-historical-reference-text'>
                                Словосочетание «умный дом» было придумано в 1984 году специалистами Ассоциации компаний по возведению жилья для домов, в которых
                                использовалась автоматизация. Идея управления умным домом была представлена миру в 1999 году компанией Disney, которая осуществила выпуск
                                фильма Smart House. В наши дни, для осуществления управления умным домом широко используется приложение google home.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Control;
