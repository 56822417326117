import React from "react";

class Temp extends React.Component {
  render() {
    return (
      <div>
        <svg
          width="35"
          height="45"
          viewBox="0 0 35 45"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="35" height="45" fill="#E5E5E5" />
          <g clipPath="url(#clip0)">
            <rect
              width="1600"
              height="5598"
              transform="translate(-565 -2072)"
              fill="white"
            />
            <rect
              x="-565"
              y="-211"
              width="1600"
              height="690.761"
              fill="#F7F7F7"
            />
            <g filter="url(#filter0_d)">
              <rect
                x="-47"
                y="-22.7811"
                width="129"
                height="115"
                rx="2"
                fill="white"
              />
            </g>

            <path
              d="M33.7626 9.48184H31.1103V8.44693C31.1103 7.96286 30.7178 7.57043 30.2338 7.57043H21.2158C20.7318 7.57043 20.3394 7.96286 20.3394 8.44693V9.48191H10.695C4.79782 9.48191 0 14.2797 0 20.1769V29.7176C0 30.2017 0.392495 30.5941 0.876495 30.5941H10.1885C10.6725 30.5941 11.0649 30.2017 11.0649 29.7176V22.4314C11.0649 21.3923 11.9103 20.547 12.9494 20.547H20.3393V21.582C20.3393 22.0661 20.7318 22.4585 21.2158 22.4585H30.2337C30.7177 22.4585 31.1102 22.0661 31.1102 21.582V20.547H33.7625C34.2465 20.547 34.639 20.1546 34.639 19.6705V10.3583C34.6391 9.8742 34.2466 9.48184 33.7626 9.48184ZM20.3394 18.7937H12.9495V18.7938C10.9439 18.7938 9.3121 20.4256 9.3121 22.4312V28.8409H1.75299V20.1767C1.75299 15.2461 5.76435 11.2347 10.695 11.2347H20.3394V18.7937ZM29.3573 10.3583V19.6703V20.7053H22.0923V19.6703V10.3583V9.32336H29.3572V10.3583H29.3573ZM32.8861 18.7938H31.1103V11.2348H32.8861V18.7938Z"
              fill="#818181"
              stroke="#818181"
            />

            <path
              d="M13.812 14.3225H15.7724C16.2564 14.3225 16.6489 13.9301 16.6489 13.446C16.6489 12.962 16.2564 12.5695 15.7724 12.5695H13.812C13.328 12.5695 12.9355 12.962 12.9355 13.446C12.9355 13.9301 13.328 14.3225 13.812 14.3225Z"
              fill="#818181"
              stroke="#818181"
            />

            <path
              d="M3.08789 20.3811C3.08789 20.8652 3.48039 21.2576 3.96439 21.2576C4.44839 21.2576 4.84089 20.8652 4.84089 20.3811C4.84089 17.0404 7.55873 14.3225 10.8994 14.3225C11.3834 14.3225 11.7759 13.93 11.7759 13.446C11.7759 12.9619 11.3834 12.5695 10.8994 12.5695C6.59213 12.5695 3.08789 16.0738 3.08789 20.3811Z"
              fill="#818181"
              stroke="#818181"
            />

            <path
              d="M19.8438 1.97187H24.9466V5.0162C24.9466 5.50027 25.3391 5.8927 25.8231 5.8927C26.3071 5.8927 26.6996 5.50027 26.6996 5.0162V1.97187H31.8025C32.2865 1.97187 32.679 1.57944 32.679 1.09537C32.679 0.611299 32.2864 0.218872 31.8024 0.218872H19.8438C19.3598 0.218872 18.9673 0.611299 18.9673 1.09537C18.9673 1.57944 19.3597 1.97187 19.8438 1.97187Z"
              fill="#818181"
              stroke="#818181"
            />

            <path
              d="M5.04214 34.0361C4.7408 34.0361 4.46047 34.191 4.3001 34.446C3.82976 35.1942 1.4834 39.0098 1.4834 40.6601C1.4834 42.6224 3.07987 44.2189 5.04214 44.2189C7.00442 44.2189 8.60089 42.6224 8.60089 40.6601C8.60089 39.0097 6.25453 35.1941 5.78419 34.446C5.62367 34.1909 5.34349 34.0361 5.04214 34.0361ZM5.04214 42.4659C4.04654 42.4659 3.23639 41.6558 3.23639 40.6601C3.23639 40.0552 4.07898 38.2934 5.04214 36.6156C6.00524 38.2934 6.84789 40.0552 6.84789 40.6601C6.84782 41.6558 6.03775 42.4659 5.04214 42.4659Z"
              fill="#818181"
              stroke="#818181"
            />
          </g>
          <defs>
            <filter
              id="filter0_d"
              x="-52"
              y="-27.7811"
              width="143"
              height="129"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              />
              <feOffset dx="2" dy="2" />
              <feGaussianBlur stdDeviation="3.5" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow"
                result="shape"
              />
            </filter>
            <clipPath id="clip0">
              <rect
                width="1600"
                height="5598"
                fill="white"
                transform="translate(-565 -2072)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
    );
  }
}

export default Temp;
