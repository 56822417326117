import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";
import FeedbackFormSmall from "../FeedbackForm/FeedbackFormSmall";
import FeedbackFormBig from "../FeedbackForm/FeedbackFormBig";
import Navbar from "../Navbar/Navbar";

import ServicesDropdownPages from "../Services/ServicesDropdown/ServicesDropdownPages";

class Starting extends React.Component {
  constructor(props) {
    super(props);
    this.servicesHover = this.servicesHover.bind(this);
    this.state = {
      style: "borderTop: 2px solid",
      buttonOrder: false,

      activeServices: true,
      isSolutionsHovering: false,
      isServicesHovering: false,
      isMobile: window.innerWidth <= 576
    };
  }

  uppendTitle() {
    document.querySelector("head title").innerHTML =
      "Пусконаладка | Умный дом в Москве | Homeсhain";
  }

  uppendMeta() {
    let description = document.querySelector("meta[name=description]");
    let keywords = document.querySelector("meta[name=keywords]");
    if (description) {
      description.content =
        'Пусконаладка. Умный дом в Москве "под ключ" - проектирование, комплектация, монтаж, запуск. Homeсhain +7 (499) 113 10 10.';
    } else {
      description = document.createElement("meta");
      description.name = "description";
      description.content =
        'Пусконаладка. Умный дом в Москве "под ключ" - проектирование, комплектация, монтаж, запуск. Homeсhain +7 (499) 113 10 10.';
      document.querySelector("head").appendChild(description);
    }

    if (keywords) {
      keywords.content =
        "программирование умного дома, подключение системы умный дом";
    } else {
      keywords = document.createElement("meta");
      keywords.name = "keywords";
      keywords.content =
        "программирование умного дома, подключение системы умный дом";
      document.querySelector("head").appendChild(keywords);
    }
  }

  servicesHover(state) {
    this.setState(this.toggleServicesHover);
  }

  toggleDropdown(state) {
    return {
      isServicesHovering: false,
      isSolutionsHovering: false
    };
  }

  toggleServicesHover(state) {
    return {
      isServicesHovering: !state.isServicesHovering,
      isSolutionsHovering: false
    };
  }

  render() {
    return (
      <div>
        {this.uppendTitle()}
        {this.uppendMeta()}
        <Navbar activeServices={this.state.activeServices} />
        <Container
          fluid
          className="page"
          style={{
            backgroundImage: `url(${require("../../images/Services/Starting/starting-bg.png")})`
          }}
        >
          <header>
            <Container fluid>
              <Row className="page-row custom-container">
                {!this.state.isMobile && (
                  <div className="page-nav">
                    <ul>
                      <li>
                        <Link to="/">Главная</Link>
                      </li>
                      <img src={require("../../images/arrow.svg")} alt="" />
                      <li onClick={this.servicesHover.bind(this)}>
                        <HashLink>Услуги</HashLink>
                      </li>
                      <img src={require("../../images/arrow.svg")} alt="" />
                      <li style={{ color: "#181818" }}>Пусконаладка</li>
                    </ul>
                  </div>
                )}

                {this.state.isMobile && (
                  <div className="page-nav">
                    <ul>
                      <img
                        style={{ width: "6px" }}
                        src={require("../../images/arrow-left.svg")}
                        alt=""
                      />
                      <li onClick={this.servicesHover.bind(this)}>
                        <HashLink>Услуги</HashLink>
                      </li>
                    </ul>
                  </div>
                )}

                <div className="page-header">
                  <h1>Пусконаладка</h1>
                  <HashLink smooth to="#feedback-big">
                    <button className="hover-button">Оставить заявку</button>
                  </HashLink>
                </div>
              </Row>
              {this.state.isServicesHovering && (
                <ServicesDropdownPages
                  handler={this.servicesHover.bind(this)}
                />
              )}
            </Container>
          </header>
        </Container>
        <Container fluid className="custom-container">
          <div className="ecosystem-description">
            <Row className="ecosystem-row">
              <Col xl="12" lg="12" md="12">
                <h3>Настройка и регулировка оборудования</h3>
                <p>
                  <img
                    data-src={require("../../images/Services/Starting/starting-photo-2.png")}
                    alt="подключение системы умный дом"
                    class="lazy"
                    src="/images/image-preloader.png"
                  />
                  Пусконаладочные работы являются залогом правильного и
                  безотказного функционирования умного дома. На протяжении
                  данного этапа главным инженером проекта коммутируются в единую
                  систему все электронные устройства, задается алгоритм и логика
                  их взаимодействия и обеспечивается работа оборудования
                  согласно определенных проектной документацией задач. Мы
                  приступаем к установке умного дома под ключ после проверки
                  правильности выполнения и приемки работ по монтажу
                  коммуникаций и электронных устройств.
                  <span />
                  Любая инженерная система требует выполнения пусконаладочных
                  работ. После подсоединения и включения оборудования проводится
                  тонкая аппаратная настройка. Например, корректировка давления
                  в контуре отопления или регулировка системы кондиционирования
                  и вентиляции для обеспечения предусмотренной проектом
                  кратности воздухообмена и притока в каждое помещение свежего
                  воздуха, соответствующего санитарным нормам.
                </p>
              </Col>
            </Row>
          </div>
          <Row className="page-description">
            <Col xl="12">
              <h3>Программирование сценариев</h3>
              <p>
                После проведения работ по подключению инженерных коммуникаций,
                наш инженер проводит программирование умного дома на выполнение
                определенных функций с учетом пожеланий заказчика. Например,
                прописывается принадлежность выключателей света определенной
                группе устройств, выполняется адресация аварийных сигналов,
                указывающих на протечку воды, диапазоны, в пределах которых
                осуществляется регулировка температуры, разрабатываются сценарии
                и решаются другие вопросы. Работы по программированию проводятся
                непосредственно на объекте. В некоторых ситуациях возможна
                дистанционная (удаленная) настройка программного обеспечения.
              </p>
            </Col>
          </Row>
          <Row>
            <img
              className="page-photo lazy"
              data-src={require("../../images/Services/Starting/starting-photo.png")}
              alt="программирование сценариев умного дома"
              src="/images/image-preloader.png"
            />
          </Row>
          <Row className="page-description">
            <Col xl="12">
              <h3>Настройка смежных устройств</h3>
              <p>
                Помимо подключения системы умный дом наши специалисты могут
                провести настройку смежных подсистем, таких как контроллеры,
                выключатели, датчики и другие электронные устройства. При
                необходимости настроят теле-аппаратуру, локальную сеть,
                мультимедийную технику и любую другую электронику.
              </p>
            </Col>
          </Row>
        </Container>
        <FeedbackFormSmall />

        <Container fluid className="custom-container">
          <Row className="page-description container-space-top-50">
            <Col xl="12">
              <h3>Тестовая эксплуатация</h3>
              <p>
                После завершения этапа пусконаладки автоматики и
                программирования умного дома мы сдаем объект заказчику для
                тестовой эксплуатации. В процессе проверки функционирования
                оборудования заказчик проживает в своем доме или квартире и
                полноценно без ограничений использует смонтированную систему
                автоматизации. Информацию о работе смарт-устройств, оценку
                функциональности (что нравится и не нравится), свои замечания и
                пожелания по расширению\уменьшению функционала, возникающие в
                период тестовой эксплуатации, заказчик передает в нашу компанию
                для обработки и безотлагательного принятия мер.
              </p>
              <p>
                На следующем этапе, учитывая пожелания и требования заказчика,
                специалисты вносят изменения в пользовательские настройки.
                Изменять (корректировать) многие функции мы можем дистанционно.
                Как правило, тестирование оборудования выполняется на протяжении
                2­‒3 месяцев с момента заселения хозяев в дом или квартиру.
              </p>
            </Col>
          </Row>
        </Container>
        <FeedbackFormBig bigForm={true} />
      </div>
    );
  }
}

export default Starting;
