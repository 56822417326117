import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Shield from "../../../images/solutions/Shield.jsx";
import Electric from "../../../images/solutions/Electric.jsx";
import Tube from "../../../images/solutions/Tube.jsx";
import Temp from "../../../images/solutions/Temp.jsx";
import Multi from "../../../images/solutions/Multi.jsx";
import Health from "../../../images/solutions/Health.jsx";
import Comfort from "../../../images/solutions/Comfort.jsx";
import Control from "../../../images/solutions/Control.jsx";
import Slider from "react-slick";
//import { paths } from "../../../paths";
import { map } from "../../../map";
import axios from "axios";

function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: "block",
                right: "0%"
            }}
            onClick={onClick}
        />
    );
}

function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: "block",
                left: "0%"
            }}
            onClick={onClick}
        />
    );
}

class solutionsDropdown extends React.Component {
    icons = {
        security: <Shield />,
        electrics: <Electric />,
        santechnics: <Tube />,
        climate: <Temp />,
        multimedia: <Multi />,
        diagnostics: <Health />,
        comfort: <Comfort />,
        control: <Control />
    };

    names = ["security", "electrics", "santechnics", "climate", "multimedia", "diagnostics", "comfort", "control"];

    indexes = [];

    constructor(props) {
        super(props);
        this.state = {
            renderBlock: this.props.activeCat || "security",
            data: [],
            ignored: []
        };

        if (window.innerWidth > 991) {
            this.state.isMobile = false;
        } else {
            this.state.isMobile = true;
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.activeCat !== this.props.activeCat) {
            this.setState({ renderBlock: this.props.activeCat });
        }
    }

    componentDidMount() {
        axios
            .get(process.env.NODE_ENV === "production" ? `https://homechain.ai/s/api/page/get-cats` : `http://localhost:4000/api/page/get-cats`)
            .then((res) => {
                const data = res.data.data;
                const ignored = Object.values(data)
                    .map((cat) => {
                        const tinySubs = [];

                        for (const subCat in cat) if (Object.keys(cat[subCat]).length <= 2) tinySubs.push(subCat);

                        return tinySubs;
                    })
                    .flat();

                this.setState({
                    data,
                    ignored
                });
            })
            .catch((err) => {
                this.setState({ isNotFound: true });
                console.log(err);
            });
    }

    renderBlock = (blockName) => {
        this.setState({
            renderBlock: blockName
        });
    };

    render() {
        let activeIndex = 0;
        if (this.state.renderBlock === "security") {
            activeIndex = 0;
        } else if (this.state.renderBlock === "electrics") {
            activeIndex = 1;
        } else if (this.state.renderBlock === "santechnics") {
            activeIndex = 2;
        } else if (this.state.renderBlock === "climate") {
            activeIndex = 3;
        } else if (this.state.renderBlock === "multimedia") {
            activeIndex = 4;
        } else if (this.state.renderBlock === "diagnostics") {
            activeIndex = 5;
        } else if (this.state.renderBlock === "comfort") {
            activeIndex = 6;
        } else if (this.state.renderBlock === "control") {
            activeIndex = 7;
        }

        let settings = {
            infinite: false,
            autoplay: false,
            arrows: false,
            slidesToShow: 8,
            slidesToScroll: 1,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
            initialSlide: activeIndex - 1,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        arrows: true,
                        slidesToShow: 5,
                        initialSlide: activeIndex - 1,
                        slidesToScroll: 3
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        arrows: true,
                        slidesToShow: 3,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        arrows: true,
                        slidesToShow: 3,
                        initialSlide: activeIndex - 1,
                        slidesToScroll: 3
                    }
                },

                {
                    breakpoint: 420,
                    settings: {
                        arrows: true,
                        slidesToShow: 3,
                        initialSlide: activeIndex,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 380,
                    settings: {
                        arrows: true,
                        slidesToShow: 3,
                        initialSlide: activeIndex,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 360,
                    settings: {
                        arrows: true,
                        slidesToShow: 2,
                        initialSlide: activeIndex - 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        const renBlock = (block) => {
            const rows = [];
            const keys = Object.keys(Object.assign({}, this.state.data[block]));
            const newRows = [];

            Object.keys(keys).map((i) => {
                return rows.push(keys[i]);
            });

            for (let i = 0; i < rows.length; i += 3) {
                newRows.push(rows.slice(i, i + 3));
            }

            return newRows.map((item) => (
                <Row>
                    {item.map((element) => {
                        return (
                            <Col xl='4' lg='4' md='6' sm='10'>
                                <div className='solutions-block-list'>
                                    {this.state.ignored.includes(element) ? (
                                        <h4 style={{ cursor: "default" }}>
                                            <span>{this.state.data[block][element].subCatName}</span>
                                        </h4>
                                    ) : (
                                        <Link className='solutions-block-list-link' to={`/solutions/${element}`}>
                                            <h4 style={{ fontWeight: "500", lineHeight: "1.2" }}>
                                                <span>{this.state.data[block][element].subCatName}</span>
                                            </h4>
                                        </Link>
                                    )}
                                    <div>
                                        <ul>
                                            {
                                                (element !== 'ecosystemi'
                                                        ? Object.keys(Object.assign({}, this.state.data[block][element]))
                                                        : ["loxone","subCatName","apple-homekit","prilozhenije-google-home","alexa-amazon","razrabotka-prilozhenij","yandex-umnyj-dom"]
                                                )
                                                    .map(
                                                (i) =>
                                                    i !== "subCatName" && (
                                                        <li>
                                                            <Link to={`/solutions/${element}/${i}`}>{this.state.data[block][element][i]}</Link>

                                                            {/* {element === i ? (
                                <Link to={`/solutions/${i}`}>
                                  {this.state.data[block][element][i]}
                                </Link>
                              ) : (
                                <Link to={`/solutions/${element}/${i}`}>
                                  {this.state.data[block][element][i]}
                                </Link>
                              )} */}
                                                        </li>
                                                    )
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                        );
                    })}
                    <Col xl='4' lg='4' md='6' sm='10' />
                </Row>
            ));
        };

        return (
            <Container fluid className={`solutions-block ${this.props.class} `}>
                <Container>
                    <div>
                        <Slider {...settings}>
                            {this.names.map((path, i) => (
                                <div key={i}>
                                    <button
                                        onClick={() => this.renderBlock(path)}
                                        className={this.state.renderBlock === path ? "solutions-block-item button-active" : "solutions-block-item"}
                                        style={{
                                            transform: this.state.renderBlock === path ? "translateY(-10px)" : "",
                                            borderBottom: this.state.renderBlock === path ? "1px solid #0F75BC" : ""
                                        }}>
                                        {this.icons[path]}
                                        <p>{map[path]}</p>
                                    </button>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </Container>

                <Container style={{ marginTop: "57px" }}>{renBlock(this.state.renderBlock)}</Container>
            </Container>
        );
    }
}

export default solutionsDropdown;
