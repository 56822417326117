import React from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import HomechainLogo from "../../images/homechain-logo.svg";
import SolutionsDropdown from "../../components/Solutions/SolutionsBlock/SolutionsDropdown";
import ServicesDropdown from "../../components/Services/ServicesDropdown/ServicesDropdown";

import SearchIcon from "../../images/search.svg";
import Delimiter from "../../images/delimiter-nav.svg";
import ServicesDropdownBlocks from "../Services/ServicesDropdown/ServicesDropdownBlocks";
import ChevronLeft from "../../images/chevron-left-active.svg";

import onClickOutside from "react-onclickoutside";

class Navbar extends React.Component {
  constructor(props) {
    super(props);

    this.inputDataChange = this.inputDataChange.bind(this);
    this.searchNarrowClick = this.searchNarrowClick.bind(this);
    this.solutionsHover = this.solutionsHover.bind(this);
    this.servicesHover = this.servicesHover.bind(this);
    this.burgerToggle = this.burgerToggle.bind(this);
    this.menuClose = this.menuClose.bind(this);
    this.menuOpen = this.menuOpen.bind(this);

    this.state = {
      menuClosed: false,
      isMobile: window.innerWidth <= 991,
      clicked: false,
      isSolutionsHovering: false,
      isServicesHovering: false,
      searchRequest: "",
      clickedOutside: false
    };

    this.menuIcon = React.createRef();
    this.linksEl = React.createRef();
  }

  componentDidUpdate(prevProps, PrevState) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      if (this.state.clickedOutside === false) {
        this.menuOpen();
        this.burgerToggle();
        this.navbarClosingFunc();
      } else {
        this.menuOpen();
      }
    }
  }

  solutionsHover() {
    this.setState(this.toggleSolutionsHover);
  }

  servicesHover() {
    this.setState(this.toggleServicesHover);
  }

  navbarClosingFunc() {
    this.setState(() => {
      return {
        isSolutionsHovering: false,
        isServicesHovering: false
      };
    });
  }

  toggleDropdown() {
    return {
      isServicesHovering: false,
      isSolutionsHovering: false
    };
  }

  toggleSolutionsHover(state) {
    return {
      isSolutionsHovering: !state.isSolutionsHovering,
      isServicesHovering: false
    };
  }

  toggleServicesHover(state) {
    return {
      isServicesHovering: !state.isServicesHovering,
      isSolutionsHovering: false
    };
  }

  clickedLink(state) {
    this.setState({
      isServicesHovering: false
    });
  }

  burgerToggle(state) {
    this.setState({
      clicked: !this.state.clicked
    });
    this.menuIcon.current.classList.toggle("navbar-icon-active");

    const linksElStyle = this.linksEl.current.style;

    if (linksElStyle.display === 'flex') {
        linksElStyle.display = 'none';
    } else {
        linksElStyle.display = 'flex';
    }
  }

  handleClickOutside = evt => {
    this.setState({ clickedOutside: true });
  };

  menuClose() {
    document.getElementById("closed-li-1").style.display = "none";
    document.getElementById("closed-li-2").style.display = "none";
    document.getElementById("closed-li-3").style.display = "none";
    document.getElementById("closed-li-4").style.display = "none";
    document.getElementById("closed-li-5").style.display = "none";
    document.getElementById("closed-li-6").style.display = "none";
  }
  menuOpen() {
    this.setState(this.toggleDropdown);
    document.getElementById("closed-li-1").style.display = "block";
    document.getElementById("closed-li-2").style.display = "block";
    document.getElementById("closed-li-3").style.display = "block";
    document.getElementById("closed-li-4").style.display = "block";
    document.getElementById("closed-li-5").style.display = "block";
    document.getElementById("closed-li-6").style.display = "block";
  }

  inputDataChange(e) {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  searchNarrowClick(e) {
    e.preventDefault();

    const reqProp = this.state.searchRequest;
    if (this.state.searchRequest !== "") {
      this.props.history.push({
        pathname: "/search",
        state: { searchRequest: reqProp }
      });
    }
  }

  render() {
    return (
      <div
        onMouseLeave={() => {
          this.navbarClosingFunc();
        }}
        onClick={() => {
          this.setState({
            clickedOutside: false
          });
        }}
      >
        <Container fluid className="nav">
          <Container fluid className="custom-container">
            <nav className="navbar">
              <ul className="navbar-ul">
                <Link to="/">
                  <img
                    style={{ width: "180px", height: "38px" }}
                    src={HomechainLogo}
                    alt=""
                  />
                </Link>

                <li>
                  <Link
                    style={{
                      color: this.props.activeMain ? "#0F75BC" : ""
                    }}
                    to="/"
                  >
                    Главная
                  </Link>
                </li>
                <li
                  onClick={this.solutionsHover}
                  className={
                    this.props.activeSolutions
                      ? "solutions-dropdown-active"
                      : "solutions-dropdown"
                  }
                >
                  <Link
                    style={{
                      color: this.props.activeSolutions ? "#0F75BC" : ""
                    }}
                    to="#"
                  >
                    Решения
                  </Link>
                </li>
                <li
                  onClick={this.servicesHover}
                  className={
                    this.props.activeServices
                      ? "services-dropdown-active"
                      : "services-dropdown"
                  }
                >
                  <Link
                    style={{
                      color: this.props.activeServices ? "#0F75BC" : ""
                    }}
                    to="#"
                  >
                    Услуги
                  </Link>
                </li>

                <li>
                  <Link
                    style={{
                      color: this.props.activeCoop ? "#0F75BC" : "#"
                    }}
                    to="/cooperation"
                  >
                    Сотрудничество
                  </Link>
                </li>

                {/*<li>*/}
                {/*  <Link*/}
                {/*    style={{*/}
                {/*      color: this.props.activeBlog ? "#0F75BC" : "#"*/}
                {/*    }}*/}
                {/*    to="/umnyj-dom-blog"*/}
                {/*  >*/}
                {/*    Блог*/}
                {/*  </Link>*/}
                {/*</li>*/}
                <li>
                  <Link
                    style={{
                      color: this.props.activeContacts ? "#0F75BC" : "#"
                    }}
                    to="/contacts"
                  >
                    Контакты
                  </Link>
                </li>
                <li
                  className="search-icon"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Link
                    style={{
                      color: this.props.activeSearch ? "#0F75BC" : "",
                      paddingLeft: "10px"
                    }}
                    to="/search"
                  >
                    Поиск
                  </Link>
                </li>
              </ul>
            </nav>

            {this.state.isSolutionsHovering && (
              <SolutionsDropdown activeCat={this.props.activeCat} />
            )}
            {this.state.isServicesHovering && (
              <ServicesDropdown linkClick={this.clickedLink.bind(this)} />
            )}
          </Container>
        </Container>
        <Container fluid className="nav-narrow">
          <div className="navbar-narrow">
            <Link to="/">
              <img
                style={{ width: "180px", height: "38px", paddingLeft: "20px" }}
                src={HomechainLogo}
                alt=""
              />
            </Link>

            <div
              onClick={this.burgerToggle}
              style={{ paddingRight: "20px" }}
              className="menu-icon navbar-icon"
              ref={this.menuIcon}
            >
              <div style={{ marginTop: "9px", marginBottom: "9px" }} />
            </div>
            <ul className="navbar-narrow-list" ref={this.linksEl}>
              <li id="closed-li-1">
                <Link onClick={this.burgerToggle} to="/">
                  Главная
                </Link>
              </li>
              <li
                onClick={this.handleMouseHover}
                className="solutions-dropdown navbar-narrow-list-dropdown"
                style={{
                  width: "100%",
                  textAlign: "center"
                }}
              >
                <Link
                  id="closed-li-2"
                  onClick={e => {
                    e.preventDefault();
                    this.solutionsHover();
                    this.menuClose();
                  }}
                  to="#"
                >
                  Решения
                </Link>
                <div />
                {this.state.isSolutionsHovering && (
                  <div style={{ position: "relative", marginTop: "50px" }}>
                    <button
                      onClick={this.menuOpen}
                      style={{
                        position: "absolute",
                        left: "5%",
                        top: "-50px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "80px",
                        border: "none",
                        background: "transparent"
                      }}
                    >
                      <img style={{ width: "18px" }} src={ChevronLeft} alt="" />
                      <p
                        style={{
                          fontSize: "14px",
                          margin: "0",
                          marginLeft: "15px",
                          textTransform: "uppercase"
                        }}
                      >
                        Меню
                      </p>
                    </button>
                    <SolutionsDropdown activeCat={this.props.activeCat} />
                    <button
                      onClick={this.menuOpen}
                      style={{
                        position: "absolute",
                        left: "5%",
                        bottom: "-20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "80px",
                        border: "none",
                        background: "transparent"
                      }}
                    >
                      <img style={{ width: "18px" }} src={ChevronLeft} alt="" />
                      <p
                        style={{
                          fontSize: "14px",
                          margin: "0",
                          marginLeft: "15px",
                          textTransform: "uppercase"
                        }}
                      >
                        Меню
                      </p>
                    </button>
                  </div>
                )}
              </li>
              <li
                className="services-dropdown"
                style={{ width: "100%", textAlign: "center" }}
              >
                <Link
                  id="closed-li-3"
                  onClick={e => {
                    e.preventDefault();
                    this.servicesHover();
                    this.menuClose();
                  }}
                  to="#"
                >
                  Услуги
                </Link>
                {this.state.isServicesHovering && (
                  <div style={{ position: "relative", marginBottom: "40px" }}>
                    <button
                      onClick={this.menuOpen}
                      style={{
                        position: "absolute",
                        left: "5%",
                        top: "-30px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "80px",
                        border: "none",
                        background: "transparent"
                      }}
                    >
                      <img style={{ width: "18px" }} src={ChevronLeft} alt="" />
                      <p
                        style={{
                          fontSize: "14px",
                          margin: "0",
                          marginLeft: "15px",
                          textTransform: "uppercase"
                        }}
                      >
                        Меню
                      </p>
                    </button>
                    {!this.state.isMobile ? (
                      <ServicesDropdown />
                    ) : (
                      <ServicesDropdownBlocks />
                    )}
                    <button
                      onClick={this.menuOpen}
                      style={{
                        position: "absolute",
                        left: "5%",
                        bottom: "-50px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "80px",
                        border: "none",
                        background: "transparent"
                      }}
                    >
                      <img style={{ width: "18px" }} src={ChevronLeft} alt="" />
                      <p
                        style={{
                          fontSize: "14px",
                          margin: "0",
                          marginLeft: "15px",
                          textTransform: "uppercase"
                        }}
                      >
                        Меню
                      </p>
                    </button>
                  </div>
                )}
              </li>
              {/* <li>
                <Link id='closed-li-3' onClick={this.burgerToggle} to="/portfolio">
                  Портфолио
                </Link>
              </li> */}
              <li>
                <Link
                  id="closed-li-4"
                  onClick={this.burgerToggle}
                  to="/cooperation"
                >
                  Сотрудничество
                </Link>
              </li>
              <li id="closed-li-5">
                <Link onClick={this.burgerToggle} to="/contacts">
                  Контакты
                </Link>
              </li>
              <li
                id="closed-li-6"
                className="search-narrow "
                style={{
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                <img
                  className="search-narrow-delimiter"
                  style={{ marginBottom: "20px" }}
                  src={Delimiter}
                  alt=""
                />
                <form onSubmit={this.searchNarrowClick}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "20px"
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        padding: "0px 30px",
                        borderBottom: "1px solid #aeaeae"
                      }}
                    >
                      <img src={SearchIcon} alt="" />
                      <input
                        name="searchRequest"
                        value={this.state.value}
                        onChange={e => {
                          this.inputDataChange(e);
                        }}
                        type="text"
                        placeholder="Введите запрос"
                      />
                    </div>

                    <button className="search-narrow-button">Поиск</button>
                  </div>
                </form>
              </li>
            </ul>
          </div>
        </Container>
      </div>
    );
  }
}

export default withRouter(onClickOutside(Navbar));
