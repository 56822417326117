import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Modal } from "react-bootstrap";
import ScrollTop from "../../components/ScrollTop/ScrollTop";
import Phone from "../../images/Footer/phone.svg";
import Message from "../../images/Footer/message.svg";
import Geo from "../../images/Footer/geo.svg";
import Instagram from "../../images/Footer/instagram.svg";
import Facebook from "../../images/Footer/facebook.svg";
import Telegram from "../../images/Footer/telegram.svg";
import Twitter from "../../images/Footer/twitter.svg";
import Delimiter from "../../images/delimiter-nav.svg";
import CloseButton from "../../images/close-button.svg";
import { HashLink } from "react-router-hash-link";

class Footer extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      publicDataShow: false,
      privacyShow: false,
      render: false
    };

    const hideJivo = setInterval(() => {
      const jvlabelWrap = document.querySelector("#jvlabelWrap");
      const jcont = document.querySelector("#jcont");
      const logoIcon_2z = document.querySelector(".logoIcon_2z");

      if (jvlabelWrap && jcont && logoIcon_2z) {
        jvlabelWrap.style.zIndex = 2;
        jcont.style.zIndex = 2;
        logoIcon_2z.style.display = "none";
        clearInterval(hideJivo);
      }
    }, 1000);
  }

  componentDidMount() {
    if (process.env.NODE_ENV === "production") {
      this.setState({ render: true });
    } else {
      this.setState({ render: true });
    }
  }

  handleTelClick = () => {
      if (process.env.NODE_ENV === 'production') {
          console.log('Событие клика на телефон v2');
          // eslint-disable-next-line no-undef
          ym(54938986,'reachGoal','click_tel');
      }
  };

  render() {
    let publicDataClose = () => this.setState({ publicDataShow: false });
    let privacyClose = () => this.setState({ privacyShow: false });
    return (
      this.state.render && (
        <div id="footer" className="footer container-fluid">
          <div
            className={`container-fluid custom-container footer-container ${
              this.props.delimiterState ? "" : "footer-delimiter"
            }`}
          >
            {!this.state.publicDataShow && (
              <div>{!this.state.privacyShow && <ScrollTop />}</div>
            )}
            <div className="row footer-row">
              <div className="footer-block">
                <h4>Меню</h4>
                <ul>
                  <li>
                    <HashLink smooth to="/#main">
                      Главная
                    </HashLink>
                  </li>
                  <li>
                    <HashLink smooth to="/#solutions">
                      Решения
                    </HashLink>
                  </li>
                  <li>
                    <HashLink smooth to="/#services">
                      Услуги
                    </HashLink>
                  </li>
                  <li>
                    <HashLink smooth to="/cooperation">
                      Сотрудничество
                    </HashLink>
                  </li>
                  <li>
                    <Link to="/contacts">Контакты</Link>
                  </li>
                </ul>
              </div>
              <div className="footer-block">
                <h4>Решения</h4>
                <div className="footer-block-item">
                  <div>
                    <ul>
                      <li>
                        <HashLink
                          onClick={() => {
                            this.props.setActiveCat("security");
                          }}
                          smooth
                          to="/#solutions"
                        >
                          Безопасность
                        </HashLink>
                      </li>
                      <li>
                        <HashLink
                          onClick={() => {
                            this.props.setActiveCat("electrics");
                          }}
                          smooth
                          to="/#solutions"
                        >
                          Электрика
                        </HashLink>
                      </li>
                      <li>
                        <HashLink
                          onClick={() => {
                            this.props.setActiveCat("multimedia");
                          }}
                          smooth
                          to="/#solutions"
                        >
                          Мультимедиа
                        </HashLink>
                      </li>
                      <li>
                        <HashLink
                          onClick={() => {
                            this.props.setActiveCat("diagnostics");
                          }}
                          smooth
                          to="/#solutions"
                        >
                          Здоровье
                        </HashLink>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <ul>
                      <li>
                        <HashLink
                          onClick={() => {
                            this.props.setActiveCat("santechnics");
                          }}
                          smooth
                          to="/#solutions"
                        >
                          Сантехника
                        </HashLink>
                      </li>
                      <li>
                        <HashLink
                          onClick={() => {
                            this.props.setActiveCat("climate");
                          }}
                          smooth
                          to="/#solutions"
                        >
                          Климат
                        </HashLink>
                      </li>
                      <li>
                        <HashLink
                          onClick={() => {
                            this.props.setActiveCat("comfort");
                          }}
                          smooth
                          to="/#solutions"
                        >
                          Комфорт
                        </HashLink>
                      </li>
                      <li>
                        <HashLink
                          onClick={() => {
                            this.props.setActiveCat("control");
                          }}
                          smooth
                          to="/#solutions"
                        >
                          Управление
                        </HashLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="footer-block">
                <h4>Услуги</h4>
                <div className="footer-block-item">
                  <div>
                    <ul>
                      <li>
                        <Link to="/services/proektirovanie-umnogo-doma">
                          Проектирование
                        </Link>
                      </li>
                      <li>
                        <Link to="/services/avtorskij-nadzor-za-proektom">
                          Авторский надзор
                        </Link>
                      </li>
                      <li>
                        <Link to="/services/umnyj-dom-stoimost">
                          Поставка оборудования
                        </Link>
                      </li>
                      <li>
                        <Link to="/services/umnyj-dom-ustanovka">Монтаж</Link>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <ul>
                      <li>
                        <Link to="/services/podklyuchenie-sistemy-umnyj-dom">
                          Пусконаладка
                        </Link>
                      </li>
                      <li>
                        <Link to="/services/audit-inzhenernyh-sistem">
                          Аудит
                        </Link>
                      </li>
                      <li>
                        <Link to="/services/umnyj-dom-informaciya">
                          Консультации
                        </Link>
                      </li>
                      <li>
                        <Link to="/services/pokraska-izdelij-iz-plastika">
                          Покраска оборудования
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/*<div className="footer-block">*/}
              {/*  <h4>Соц.сети</h4>*/}
              {/*  <div>*/}
              {/*    <div className="footer-social-block">*/}
              {/*      <a*/}
              {/*        rel="noopener noreferrer"*/}
              {/*        target="_blank"*/}
              {/*        className="footer-social"*/}
              {/*        href="https://t.me/officialhomechain"*/}
              {/*      >*/}
              {/*        <img data-src={Telegram} alt="" class="lazy" src="/images/image-preloader.png" />*/}
              {/*      </a>*/}
              {/*      <a*/}
              {/*        rel="noopener noreferrer"*/}
              {/*        target="_blank"*/}
              {/*        className="footer-social"*/}
              {/*        href="https://www.facebook.com/ai.homechain/"*/}
              {/*      >*/}
              {/*        <img data-src={Facebook} alt="" class="lazy" src="/images/image-preloader.png" />*/}
              {/*      </a>*/}
              {/*    </div>*/}
              {/*    <div className="footer-social-block">*/}
              {/*      <a*/}
              {/*        rel="noopener noreferrer"*/}
              {/*        target="_blank"*/}
              {/*        className="footer-social"*/}
              {/*        href="https://www.instagram.com/homechain/"*/}
              {/*      >*/}
              {/*        <img data-src={Instagram} alt="" class="lazy" src="/images/image-preloader.png" />*/}
              {/*      </a>*/}
              {/*      <a*/}
              {/*        rel="noopener noreferrer"*/}
              {/*        target="_blank"*/}
              {/*        className="footer-social"*/}
              {/*        href="https://twitter.com/homechain_ai"*/}
              {/*      >*/}
              {/*        <img data-src={Twitter} alt="" class="lazy" src="/images/image-preloader.png" />*/}
              {/*      </a>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
              <div className="footer-block ">
                <h4>Контакты</h4>
                <div className="footer-contacts">
                  <div className="footer-contacts-item">
                    <img data-src={Phone} alt="" class="lazy" src="/images/image-preloader.png" />
                    <a onClick={this.handleTelClick} href="tel:+7 (499) 113-10-10">+7 (499) 113-10-10</a>
                  </div>
                  <div className="footer-contacts-item">
                    <img data-src={Message} alt="" class="lazy" src="/images/image-preloader.png" />
                    <a href="mailto:hello@homechain.ai">hello@homechain.ai</a>
                  </div>
                  <div className="footer-contacts-item">
                    <img data-src={Geo} alt="" class="lazy" src="/images/image-preloader.png" />
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://goo.gl/maps/1GH3KkuMY91sJoJS9"
                    >
                      г. Москва, ул. Малая Грузинская 38,
                      <br /> офис 316
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row footer-row footer-credentials footer-credentials-first">
              <div id="footer-first">
                <button
                  onClick={() => {
                    this.setState({ publicDataShow: true });
                  }}
                >
                  Обработка персональных данных
                </button>
              </div>
              <div id="footer-second">
                <p>ИНН: 7704430115, ОГРН: 1177746642138, КПП 770301001</p>
              </div>
              <div id="footer-third">
                <button
                  onClick={() => {
                    this.setState({ privacyShow: true });
                  }}
                >
                  Пользовательское соглашение
                </button>
              </div>
            </div>
            <div className="row footer-credentials footer-credentials-row">
              <div>
                <p>
                  ООО «Хоумчейн Системс» Все права защищены 2019 г. <br />
                  Homechain и логотип Homechain являются зарегистрированными
                  товарными знаками ООО «Хоумчейн Системс» в Российской
                  Федерации.
                </p>
              </div>
            </div>
          </div>

          <div className="footer-modal">
            <Modal
              size="lg"
              show={this.state.publicDataShow}
              onHide={publicDataClose}
              aria-labelledby="public-data"
            >
              <Modal.Header>
                <Modal.Title id="public-data">
                  <h2>Политика в отношении обработки персональных данных</h2>
                  <img data-src={Delimiter} alt="" class="lazy" src="/images/image-preloader.png" />
                </Modal.Title>
                <button
                  className="modal-close-button"
                  onClick={() => publicDataClose()}
                >
                  <img data-src={CloseButton} alt="" class="lazy" src="/images/image-preloader.png" />
                </button>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <h3>1. Общие положения</h3>
                  <p>
                    Настоящая политика обработки персональных данных составлена
                    в соответствии с требованиями Федерального закона от
                    27.07.2006. №152-ФЗ «О персональных данных» и определяет
                    порядок обработки персональных данных и меры по обеспечению
                    безопасности персональных данных ООО "Хоумчейн Системс"
                    (далее – Оператор).
                  </p>
                  <p>
                    1. Оператор ставит своей важнейшей целью и условием
                    осуществления своей деятельности соблюдение прав и свобод
                    человека и гражданина при обработке его персональных данных,
                    в том числе защиты прав на неприкосновенность частной жизни,
                    личную и семейную тайну.
                    <br />
                    2. Настоящая политика Оператора в отношении обработки
                    персональных данных (далее – Политика) применяется ко всей
                    информации, которую Оператор может получить о посетителях
                    веб-сайта https://homechain.ai.
                  </p>
                </div>
                <div>
                  <h3>2. Основные понятия, используемые в Политике</h3>
                  <p>
                    1. Автоматизированная обработка персональных данных –
                    обработка персональных данных с помощью средств
                    вычислительной техники;
                    <br />
                    2. Блокирование персональных данных – временное прекращение
                    обработки персональных данных (за исключением случаев, если
                    обработка необходима для уточнения персональных данных);
                    <br />
                    3. Веб-сайт – совокупность графических и информационных
                    материалов, а также программ для ЭВМ и баз данных,
                    обеспечивающих их доступность в сети интернет по сетевому
                    адресу https://homechain.ai;
                    <br />
                    4. Информационная система персональных данных — совокупность
                    содержащихся в базах данных персональных данных, и
                    обеспечивающих их обработку информационных технологий и
                    технических средств;
                    <br />
                    5. Обезличивание персональных данных — действия, в
                    результате которых невозможно определить без использования
                    дополнительной информации принадлежность персональных данных
                    конкретному Пользователю или иному субъекту персональных
                    данных;
                    <br />
                    6. Обработка персональных данных – любое действие (операция)
                    или совокупность действий (операций), совершаемых с
                    использованием средств автоматизации или без использования
                    таких средств с персональными данными, включая сбор, запись,
                    систематизацию, накопление, хранение, уточнение (обновление,
                    изменение), извлечение, использование, передачу
                    (распространение, предоставление, доступ), обезличивание,
                    блокирование, удаление, уничтожение персональных данных;
                    <br />
                    7. Оператор – государственный орган, муниципальный орган,
                    юридическое или физическое лицо, самостоятельно или
                    совместно с другими лицами организующие и (или)
                    осуществляющие обработку персональных данных, а также
                    определяющие цели обработки персональных данных, состав
                    персональных данных, подлежащих обработке, действия
                    (операции), совершаемые с персональными данными;
                    <br />
                    8. Персональные данные – любая информация, относящаяся прямо
                    или косвенно к определенному или определяемому Пользователю
                    веб-сайта https://homechain.ai;
                    <br />
                    9. Пользователь – любой посетитель веб-сайта
                    https://homechain.ai;
                    <br />
                    10. Предоставление персональных данных – действия,
                    направленные на раскрытие персональных данных определенному
                    лицу или определенному кругу лиц;
                    <br />
                    11. Распространение персональных данных – любые действия,
                    направленные на раскрытие персональных данных
                    неопределенному кругу лиц (передача персональных данных) или
                    на ознакомление с персональными данными неограниченного
                    круга лиц, в том числе обнародование персональных данных в
                    средствах массовой информации, размещение в
                    информационно-телекоммуникационных сетях или предоставление
                    доступа к персональным данным каким-либо иным способом;
                    <br />
                    12. Трансграничная передача персональных данных – передача
                    персональных данных на территорию иностранного государства
                    органу власти иностранного государства, иностранному
                    физическому или иностранному юридическому лицу;
                    <br />
                    13. Уничтожение персональных данных – любые действия, в
                    результате которых персональные данные уничтожаются
                    безвозвратно с невозможностью дальнейшего восстановления
                    содержания персональных данных в информационной системе
                    персональных данных и (или) результате которых уничтожаются
                    материальные носители персональных данных.
                  </p>
                </div>
                <div>
                  <h3>
                    3. Оператор может обрабатывать следующие персональные данные
                    Пользователя
                  </h3>
                  <p>
                    1. Фамилия, имя, отчество;
                    <br />
                    2. Электронный адрес;
                    <br />
                    3. Номера телефонов;
                    <br />
                    4. Также на сайте происходит сбор и обработка обезличенных
                    данных о посетителях (в т.ч. файлов «cookie») с помощью
                    сервисов интернет-статистики (Яндекс Метрика и Гугл
                    Аналитика и других).
                    <br />
                    5. Вышеперечисленные данные далее по тексту Политики
                    объединены общим понятием Персональные данные.
                  </p>
                </div>
                <div>
                  <h3>4. Цели обработки персональных данных</h3>
                  <p>
                    1. Цель обработки персональных данных Пользователя —
                    информирование Пользователя посредством отправки электронных
                    писем.
                    <br />
                    2. Также Оператор имеет право направлять Пользователю
                    уведомления о новых продуктах и услугах, специальных
                    предложениях и различных событиях. Пользователь всегда может
                    отказаться от получения информационных сообщений, направив
                    Оператору письмо на адрес электронной почты
                    hello@homechain.ai с пометкой «Отказ от уведомлениях о новых
                    продуктах и услугах и специальных предложениях».
                    <br />
                    3. Обезличенные данные Пользователей, собираемые с помощью
                    сервисов интернет-статистики, служат для сбора информации о
                    действиях Пользователей на сайте, улучшения качества сайта и
                    его содержания.
                  </p>
                </div>
                <div>
                  <h3>5. Правовые основания обработки персональных данных</h3>
                  <p>
                    1. Оператор обрабатывает персональные данные Пользователя
                    только в случае их заполнения и/или отправки Пользователем
                    самостоятельно через специальные формы, расположенные на
                    сайте https://homechain.ai. Заполняя соответствующие формы
                    и/или отправляя свои персональные данные Оператору,
                    Пользователь выражает свое согласие с данной Политикой.
                    <br />
                    2. Оператор обрабатывает обезличенные данные о Пользователе
                    в случае, если это разрешено в настройках браузера
                    Пользователя (включено сохранение файлов «cookie» и
                    использование технологии JavaScript).
                  </p>
                </div>
                <div>
                  <h3>
                    6. Порядок сбора, хранения, передачи и других видов
                    обработки персональных данных
                  </h3>
                  <p>
                    Безопасность персональных данных, которые обрабатываются
                    Оператором, обеспечивается путем реализации правовых,
                    организационных и технических мер, необходимых для
                    выполнения в полном объеме требований действующего
                    законодательства в области защиты персональных данных.
                  </p>
                  <p>
                    1. Оператор обеспечивает сохранность персональных данных и
                    принимает все возможные меры, исключающие доступ к
                    персональным данным неуполномоченных лиц.
                    <br />
                    2. Персональные данные Пользователя никогда, ни при каких
                    условиях не будут переданы третьим лицам, за исключением
                    случаев, связанных с исполнением действующего
                    законодательства.
                    <br />
                    3. В случае выявления неточностей в персональных данных,
                    Пользователь может актуализировать их самостоятельно, путем
                    направления Оператору уведомление на адрес электронной почты
                    Оператора hello@homechain.ai с пометкой «Актуализация
                    персональных данных».
                    <br />
                    4. Срок обработки персональных данных является
                    неограниченным. Пользователь может в любой момент отозвать
                    свое согласие на обработку персональных данных, направив
                    Оператору уведомление посредством электронной почты на
                    электронный адрес Оператора hello@homechain.aiс пометкой
                    «Отзыв согласия на обработку персональных данных».
                  </p>
                </div>
                <div>
                  <h3>7. Трансграничная передача персональных данных</h3>
                  <p>
                    1. Оператор до начала осуществления трансграничной передачи
                    персональных данных обязан убедиться в том, что иностранным
                    государством, на территорию которого предполагается
                    осуществлять передачу персональных данных, обеспечивается
                    надежная защита прав субъектов персональных данных.
                    <br />
                    2. Трансграничная передача персональных данных на территории
                    иностранных государств, не отвечающих вышеуказанным
                    требованиям, может осуществляться только в случае наличия
                    согласия в письменной форме субъекта персональных данных на
                    трансграничную передачу его персональных данных и/или
                    исполнения договора, стороной которого является субъект
                    персональных данных.
                  </p>
                </div>
                <div>
                  <h3>8. Заключительные положения</h3>
                  <p>
                    1. Пользователь может получить любые разъяснения по
                    интересующим вопросам, касающимся обработки его персональных
                    данных, обратившись к Оператору с помощью электронной почты
                    hello@homechain.ai.
                    <br />
                    2. В данном документе будут отражены любые изменения
                    политики обработки персональных данных Оператором. Политика
                    действует бессрочно до замены ее новой версией.
                    <br />
                    3. Актуальная версия Политики в свободном доступе
                    расположена в сети Интернет по адресу https://homechain.ai.
                  </p>
                </div>
              </Modal.Body>
            </Modal>

            <Modal
              size="lg"
              show={this.state.privacyShow}
              onHide={privacyClose}
              aria-labelledby="privacy"
            >
              <Modal.Header>
                <Modal.Title id="privacy">
                  <h2>Пользовательское Соглашение</h2>
                  <img data-src={Delimiter} alt="" class="lazy" src="/images/image-preloader.png" />
                </Modal.Title>
                <button
                  className="modal-close-button"
                  onClick={() => privacyClose()}
                >
                  <img data-src={CloseButton} alt="" class="lazy" src="/images/image-preloader.png" />
                </button>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <p>
                    Настоящее Пользовательское Соглашение (Далее Соглашение)
                    регулирует отношения между ООО "Хоумчейн Системс" (далее
                    homechain или Администрация) с одной стороны и пользователем
                    сайта с другой.
                    <br />
                    Сайт homechain не является средством массовой информации.
                    <br />
                    Используя сайт, Вы соглашаетесь с условиями данного
                    соглашения. Если Вы не согласны с условиями данного
                    соглашения, не используйте сайт homechain!
                  </p>
                </div>
                <div>
                  <Row className="services-description">
                    <Col xl="12">
                      <h3>1. Предмет соглашения</h3>
                      <p className="modal-user-agreement-text">
                        Администрация предоставляет пользователю право на
                        размещение на сайте следующей информации:
                      </p>

                      <ul>
                        <li>Текстовой информации </li>
                        <li>Аудиоматериалов </li>
                        <li>Видеоматериалов </li>
                        <li>Фотоматериалов </li>
                        <li>
                          Ссылок на материалы, размещенные на других сайтах{" "}
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
                <div>
                  <Row className="services-description modal-block-space-top">
                    <Col xl="12">
                      <h3>2. Права и обязанности сторон</h3>
                      <h3>Пользователь имеет право:</h3>
                      <p className="modal-user-agreement-text">
                        Администрация предоставляет пользователю право на
                        размещение на сайте следующей информации:
                      </p>

                      <ul>
                        <li>осуществлять поиск информации на сайте</li>
                        <li>получать информацию на сайте </li>
                        <li>
                          копировать информацию на другие сайты с разрешения
                          Администрации сайта
                        </li>
                        <li>
                          требовать от администрации скрытия любой информации
                          переданной пользователем сайту
                        </li>
                        <li>
                          использовать информацию сайта в личных некоммерческих
                          целях
                        </li>
                      </ul>
                    </Col>
                  </Row>
                  <Row className="services-description modal-block-space-top">
                    <Col xl="12">
                      <h3>Администрация имеет право:</h3>

                      <ul>
                        <li>
                          по своему усмотрению и необходимости создавать,
                          изменять, отменять правила
                        </li>
                        <li>ограничивать доступ к любой информации на сайте</li>
                        <li>создавать, изменять, удалять информацию</li>
                        <li>удалять учетные записи</li>
                        <li>отказывать в регистрации без объяснения причин</li>
                      </ul>
                    </Col>
                  </Row>
                  <Row className="services-description modal-block-space-top">
                    <Col xl="12">
                      <h3>Пользователь обязуется:</h3>
                      <p className="modal-user-agreement-text">
                        Администрация предоставляет пользователю право на
                        размещение на сайте следующей информации:
                      </p>

                      <ul>
                        <li>
                          обеспечить достоверность предоставляемой информации
                        </li>
                        <li>
                          обеспечивать сохранность личных данных от доступа
                          третьих лиц
                        </li>
                        <li>
                          обновлять Персональные данные, предоставленные при
                          регистрации, в случае их изменения
                        </li>
                        <li>не копировать информацию с других источников</li>
                        <li>
                          не распространять информацию, которая направлена на
                          пропаганду войны, разжигание национальной, расовой или
                          религиозной ненависти и вражды, а также иной
                          информации, за распространение которой предусмотрена
                          уголовная или административная ответственность
                        </li>
                        <li>не нарушать работоспособность сайта</li>
                        <li>
                          не создавать несколько учётных записей на Сайте, если
                          фактически они принадлежат одному и тому же лицу
                        </li>
                        <li>
                          не совершать действия, направленные на введение других
                          Пользователей в заблуждение
                        </li>
                        <li>
                          не передавать в пользование свою учетную запись и/или
                          логин и пароль своей учетной записи третьим лицам
                        </li>
                        <li>
                          не регистрировать учетную запись от имени или вместо
                          другого лица за исключением случаев, предусмотренных
                          законодательством РФ
                        </li>
                        <li>
                          не размещать материалы рекламного, эротического,
                          порнографического или оскорбительного характера, а
                          также иную информацию, размещение которой запрещено
                          или противоречит нормам действующего законодательства
                          РФ
                        </li>
                        <li>
                          не использовать скрипты (программы) для
                          автоматизированного сбора информации и/или
                          взаимодействия с Сайтом и его Сервисами
                        </li>
                      </ul>
                    </Col>
                  </Row>
                  <Row className="services-description modal-block-space-top">
                    <Col xl="12">
                      <h3>Администрация обязуется:</h3>

                      <ul>
                        <li>
                          поддерживать работоспособность сайта за исключением
                          случаев, когда это невозможно по независящим от
                          Администрации причинам
                        </li>
                        <li>
                          осуществлять разностороннюю защиту учетной записи
                          Пользователя
                        </li>
                        <li>
                          защищать информацию, распространение которой
                          ограничено или запрещено законами путем вынесения
                          предупреждения либо удалением учетной записи
                          пользователя, нарушившего правила
                        </li>
                        <li>
                          предоставить всю доступную информацию о Пользователе
                          уполномоченным на то органам государственной власти в
                          случаях, установленных законом
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
                <div>
                  <Row className="services-description modal-block-space-top">
                    <Col xl="12">
                      <h3>3. Ответственность сторон</h3>

                      <ul>
                        <li>
                          пользователь лично несет полную ответственность за
                          распространяемую им информацию{" "}
                        </li>
                        <li>
                          администрация не несет никакой ответственности за
                          достоверность информации, скопированной из других
                          источников{" "}
                        </li>
                        <li>
                          администрация не несёт ответственность за несовпадение
                          ожидаемых Пользователем и реально полученных услуг{" "}
                        </li>
                        <li>
                          администрация не несет никакой ответственности за
                          услуги, предоставляемые третьими лицами{" "}
                        </li>
                        <li>
                          в случае возникновения форс-мажорной ситуации (боевые
                          действия, чрезвычайное положение, стихийное бедствие и
                          т. д.) Администрация не гарантирует сохранность
                          информации, размещённой Пользователем, а также
                          бесперебойную работу информационного ресурса
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
                <div>
                  <Row className="services-description modal-block-space-top">
                    <Col xl="12">
                      <h3>4. Условия действия Соглашения</h3>
                      <p>
                        Данное Соглашение вступает в силу при любом
                        использовании данного сайта. Соглашение перестает
                        действовать при появлении его новой версии.
                        Администрация оставляет за собой право в одностороннем
                        порядке изменять данное соглашение по своему усмотрению.
                        При изменении соглашения, в некоторых случаях,
                        администрация может оповестить пользователей удобным для
                        нее способом.
                      </p>
                    </Col>
                  </Row>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      )
    );
  }
}

export default Footer;
