const map = {
  "": "Главная",
  solutions: "Решения",
  security: "Безопасность",
  electrics: "Электрика",
  multimedia: "Мультимедиа",
  diagnostics: "Здоровье",
  santechnics: "Сантехника",
  "umnyj-dom-upravlenie-vodoj-polivom": "Водоснабжение",
  climate: "Климат",
  comfort: "Комфорт",
  control: "Управление",
  "umnyj-dom-videonablyudenie": "Видеонаблюдение",
  "umnyj-dom-ohrannye-sistemy": "Охранные системы",
  "umnyj-dom-upravlenie-dostupom-s-mobilnogo": "Контроль доступа",
  "umnyj-dom-pozharnaya-signalizaciya": "Пожарная сигнализация",
  "umnyj-dom-zamok": "Умный замок",
  "svet-v-umnom-dome": "Освещение",
  rozetki: "Розетки",
  schetchik: "Счётчики",
  "umnyj-dom-audio": "Аудио",
  "umnyj-dom-video": "Видео",
  razvlechenia: "Развлечения",
  infrastructura: "Инфраструктура",
  "gadzhety-dlya-zdorovya": "Диагностика",
  "umnyj-dom-kondicioner-ventilyacija": "Микроклимат",
  "umnyj-dom-otoplenie": "Отопление",
  zapahi: "Запахи",
  "umnye-shtory-zhalyuz": "Умные шторы",
  "umnaya-kuhnya-wifi": "Умная кухонная техника",
  "umnaya-bytovaya-tekhnika": "Умная бытовая техника",
  "umnyj-avtomobil": "Транспорт",
  ecosystemi: "Экосистемы",
  "umnye-shtory-zhalyuzi": "Умные шторы"
};

const ignored = [
  "umnyj-dom-pozharnaya-signalizaciya",
  "rozetki",
  "schetchik",
  "zapahi",
  "razvlechenia",
  "infrastructura"
];

export { map, ignored };
