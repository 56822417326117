import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Navbar from "../../../components/Navbar/Navbar";
import FeedbackFormSmall from "../../FeedbackForm/FeedbackFormSmall";
import GoogleBg from "../../../images/GoogleHome/googlehome-bg.png";
import GoogleDevices from "./GoogleDevices";
import GoogleHomeScenario from "../../../images/GoogleHome/googlehome-scenario.png";
import GoogleHomePod from "../../../images/GoogleHome/googlehome-pod.png";
import GoogleHomeAssistant from "../../../images/GoogleHome/googlehome-assistant.png";
import GoogleHomeDevices from "../../../images/GoogleHome/googlehome-devices.png";
import { HashLink } from "react-router-hash-link";
import SolutionsBlockPages from "../../Solutions/SolutionsBlock/SolutionsBlockPages";
import ServicesBlock from "../../ServicesBlock/ServicesBlock";

class GoogleHome extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: window.innerWidth <= 576
    };
  }

  uppendTitle() {
    document.querySelector("head title").innerHTML =
      "Google Home | Умный дом в Москве | Homeсhain";
  }
  uppendMeta() {
    let description = document.querySelector("meta[name=description]");
    let keywords = document.querySelector("meta[name=keywords]");
    if (description) {
      description.content =
        'Google Home. Умный дом в Москве "под ключ" - проектирование, комплектация, монтаж, пусконаладка. Homeсhain +7 (499) 113 10 10.';
    } else {
      description = document.createElement("meta");
      description.name = "description";
      description.content =
        'Google Home. Умный дом в Москве "под ключ" - проектирование, комплектация, монтаж, пусконаладка. Homeсhain +7 (499) 113 10 10.';
      document.querySelector("head").appendChild(description);
    }

    if (keywords) {
      keywords.content = "google home умный дом приложение google home";
    } else {
      keywords = document.createElement("meta");
      keywords.name = "keywords";
      keywords.content = "google home умный дом приложение google home";
      document.querySelector("head").appendChild(keywords);
    }
  }

  solutionsHover() {
    this.setState(this.toggleSolutionsHover);
  }

  toggleDropdown() {
    return {
      isServicesHovering: false,
      isSolutionsHovering: false
    };
  }

  toggleSolutionsHover(state) {
    return {
      isSolutionsHovering: !state.isSolutionsHovering,
      isServicesHovering: false
    };
  }

  render() {
    return (
      <div>
        {this.uppendTitle()}
        {this.uppendMeta()}
        <Navbar activeCat={"control"} activeSolutions={true} />
        <Container
          fluid
          className="ecosystem"
          style={{
            backgroundImage: `url(${GoogleBg})`
          }}
        >
          <header>
            <Container fluid>
              <Row className="ecosystem-row custom-container">
                {!this.state.isMobile ? (
                  <div>
                    <div className="ecosystem-nav">
                      <ul>
                        <li>
                          <Link to="/">Главная</Link>
                        </li>
                        <img
                          src={require("../../../images/arrow.svg")}
                          alt=""
                        />
                        <li onClick={this.solutionsHover.bind(this)}>
                          <HashLink>Решения</HashLink>
                        </li>
                        <img
                          src={require("../../../images/arrow.svg")}
                          alt=""
                        />
                        <li style={{ color: "#0F75BC" }}>
                          <Link to="/solutions/ecosystemi">Экосистемы</Link>
                        </li>
                        <img
                          src={require("../../../images/arrow.svg")}
                          alt=""
                        />
                        <li style={{ color: "#000" }}>Google Home</li>
                      </ul>
                    </div>
                    {this.state.isSolutionsHovering && (
                      <SolutionsBlockPages
                        activeCat={`control`}
                        handler={this.solutionsHover.bind(this)}
                      />
                    )}
                  </div>
                ) : (
                  <div>
                    <div className="page-nav">
                      <ul>
                        <li
                          style={{ margin: "0px" }}
                          onClick={this.solutionsHover.bind(this)}
                        >
                          <img
                            style={{
                              width: "6px",
                              marginLeft: "5px",
                              marginRight: "5px"
                            }}
                            alt=""
                            src={require("../../../images/arrow-left.svg")}
                          />

                          <HashLink>Решения</HashLink>
                        </li>
                      </ul>
                    </div>
                    {this.state.isSolutionsHovering && (
                      <SolutionsBlockPages
                        activeCat={`control`}
                        handler={this.solutionsHover.bind(this)}
                      />
                    )}
                  </div>
                )}

                <div className="ecosystem-header">
                  <h1>Google Home</h1>
                </div>
              </Row>
            </Container>
          </header>
        </Container>
        <Container fluid className="custom-container">
          <Row className="ecosystem-row">
            <div className="ecosystem-description">
              <Col xl="12" lg="12" md="12">
                <h2>Основные возможности</h2>
              </Col>
              <Col xl="12" lg="12" md="12">
                <h3>Персональная платформа</h3>
                <p>
                  Google Home умный дом представляет собой программный комплекс,
                  в который входит специально разработанное мобильное приложение
                  и персональный ассистент с голосовым управлением. Впервые
                  программа для автоматизации жилья представлена пользователям
                  весной 2016 года на «Гугл» I/O. Преимущество использования
                  продукта в возможности управления смонтированным электронным
                  оборудованием с помощью персонального помощника Assistant. По
                  концепции построения и функционалу программная система схожа с
                  продуктами Amazon Echo и Apple HomePod, поэтому считается
                  серьезным конкурентом и эффективным персональным помощником с
                  функцией распознавания голоса.
                </p>
              </Col>
            </div>
          </Row>

          <Row className="ecosystem-row">
            <div className="ecosystem-description ecosystem-description-image-wrapper">
              <Col xl="12" lg="12" md="12">
                <img
                  data-src={GoogleHomeScenario}
                  alt=""
                  src="/images/image-preloader.png"
                  className="lazy ecosystem-description-image"
                />
              </Col>
            </div>
          </Row>

          <div className="ecosystem-description">
            <Row className="ecosystem-row">
              <Col xl="12" lg="12" md="12">
                <h3>Контроль с одного устройства</h3>
                <p>
                  <img
                    data-src={GoogleHomePod}
                    alt=""
                    class="lazy"
                    src="/images/image-preloader.png"
                  />
                  Контроль над функционированием электронных гаджетов с одного
                  места обеспечивается благодаря приложению Google Home,
                  прошедшее глобальное изменение осенью 2018 года. Программный
                  модуль успешно работает с поддержкой Assistant на
                  смарт-приборах, изготовленных разными производителями. В новой
                  версии добавлен обновленный экран и представлен русскоязычный
                  интерфейс в качественном переводе. По функциональным
                  возможностям приложение для смарт-дома от компании «Гугл»
                  является аналогом HomeKit, разработанной Apple.
                </p>
              </Col>
            </Row>
          </div>
          <Row className="ecosystem-row">
            <div className="ecosystem-description">
              <Col xl="12" lg="12" md="12">
                <h3>Новая версия</h3>
                <p>
                  До создания и выхода последней обновленной версии, программа
                  Google Home умный дом применялась для связки электроприборов,
                  смонтированных в помещении, с Assistant. Все управление, даже
                  простыми функциями как «включить» и «выключить» электроприбор,
                  осуществлялось с помощью голосовых команд, выполняющихся
                  благодаря Ассистенту. Кроме того, программный комплекс
                  поддерживал только английский язык. После обновления
                  приложение предоставляет возможность контролировать и
                  управлять подключенными в системе электронными устройствами
                  непосредственно с программы Google Home по аналогии приложения
                  «Дом», разработанного под iOS для Apple HomeKit.
                </p>
              </Col>
            </div>
          </Row>
          <div className="ecosystem-description">
            <Row className="ecosystem-row">
              <Col xl="12" lg="12" md="12">
                <h3>Голосовой Ассистент</h3>
                <p>
                  <img
                    data-src={GoogleHomeAssistant}
                    alt=""
                    class="lazy"
                    src="/images/image-preloader.png"
                  />
                  Обновленная программа работает со всеми приложениями «Гугл».
                  Отличительной чертой является голосовой помощник. Если до
                  обновления платформы русский язык не поддерживался, то в новой
                  версии можно услышать из динамика родную речь. К недостаткам
                  Assistant относится плохое произношение русского языка. Если
                  сравнивать голосовые ассистенты для управления умным домом,
                  представленные Google и Apple, то последний находится в более
                  выигрышном положении. Удается сразу определить, что Siri
                  отличается более четким и разборчивым произношением.
                </p>
              </Col>
            </Row>
          </div>
          <Row className="ecosystem-row">
            <div className="ecosystem-description ecosystem-description-image-wrapper">
              <Col xl="12" lg="12" md="12">
                <img
                  data-src={GoogleHomeDevices}
                  alt=""
                  src="/images/image-preloader.png"
                  className="lazy ecosystem-description-image"
                />
              </Col>
            </div>
          </Row>

          <Row className="ecosystem-row">
            <div className="ecosystem-description">
              <Col xl="12" lg="12" md="12">
                <h2>Управление</h2>
              </Col>
              <Col xl="12" lg="12" md="12">
                <p>
                  Для контроля функционирования электронных приборов,
                  составляющие автоматизированную систему, приложение Google
                  Home умный дом устанавливается на мобильные гаджеты. С целью
                  дистанционного контроля применяется:
                </p>
              </Col>
            </div>
          </Row>
        </Container>
        <GoogleDevices />
        <Container>
          <Row className="ecosystem-row">
            <div className="ecosystem-description">
              <Col xl="12" lg="12" md="12">
                <p>
                  Программа эффективно и надежно работает на всех устройствах,
                  независимо от производителя. Интуитивно понятный интерфейс
                  предоставляет удобство пользователям в полном использовании
                  функций электронных приборов умного дома.
                </p>
              </Col>
            </div>
          </Row>
        </Container>
        <ServicesBlock />
        <FeedbackFormSmall />
      </div>
    );
  }
}

export default GoogleHome;
