import React from "react";
import { Container } from "react-bootstrap";
import SolutionsDropdown from "./SolutionsDropdown";
import onClickOutside from "react-onclickoutside";

class SolutionsBlockPages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleClickOutside = evt => {
    this.props.handler();
  };
  render() {
    return (
      <Container fluid className="solutions" id="solutions">
        <SolutionsDropdown
          activeCat={this.props.activeCat}
          class="solutions-block-pages"
        />
      </Container>
    );
  }
}

export default onClickOutside(SolutionsBlockPages);
