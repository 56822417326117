import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import MainBg from "../../images/main-bg.png";
import Ticket from "../../images/ticket.svg";
import Navbar from "../../components/Navbar/Navbar";
import FeedbackFormSmall from "../../components/FeedbackForm/FeedbackFormSmall";
import FeedbackFormBig from "../../components/FeedbackForm/FeedbackFormBig";
import MainThumbnail from "../../components/BlockThumbnail/MainThumbnail";
import Control from "../../components/Tabs/Control/Control";
import SolutionsBlock from "../Solutions/SolutionsBlock/SolutionsBlock";

import { HashLink } from "react-router-hash-link";
import HowWeWork from "../Tabs/HowWeWork/HowWeWork";

import classNames from "classnames";
import axios from "axios";

class Main extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      buttonOrder: false,
      tmp: null,
      order: false,
      isBigForm: false,
      feedbackHeight: "170px",
      activeMain: true,
      breakWord: false,
      isSent: false,
      posts: [],
      blocks: [
        {
          link: "proektirovanie-umnogo-doma",
          name: "Проектирование",
          imgSrc: require("../../images/Services/Project/project.png"),
          xl: "9",
          lg: "12",
          md: "12",
          sm: "12",
        },
        {
          link: "avtorskij-nadzor-za-proektom",
          name: "Авторский надзор",
          imgSrc: require("../../images/Services/Authorize/authorize.png"),
          xl: "3",
          lg: "4",
          md: "6",
          sm: "12",
        },
        {
          link: "umnyj-dom-stoimost",
          name: "Поставка оборудования",
          imgSrc: require("../../images/Services/Delivery/delivery.png"),
          xl: "3",
          lg: "4",
          md: "6",
          sm: "12",
        },
        {
          link: "pokraska-izdelij-iz-plastika",
          name: "Покраска оборудования",
          imgSrc: require("../../images/Services/Painting/painting.png"),
          xl: "3",
          lg: "4",
          md: "6",
          sm: "12",
        },
        {
          link: "umnyj-dom-ustanovka",
          name: "Монтаж",
          imgSrc: require("../../images/Services/BuiltinV2/builtinv2-service.png"),
          xl: "6",
          lg: "12",
          md: "12",
          sm: "12",
        },
        {
          link: "podklyuchenie-sistemy-umnyj-dom",
          name: "Пусконаладка",
          imgSrc: require("../../images/Services/Starting/starting.png"),
          xl: "6",
          lg: "4",
          md: "6",
          sm: "12",
        },

        {
          link: "audit-inzhenernyh-sistem",
          name: "Аудит",
          imgSrc: require("../../images/Services/Audit/audit.png"),
          xl: "3",
          lg: "4",
          md: "6",
          sm: "12",
        },
        {
          link: "umnyj-dom-informaciya",
          name: "Консультации",
          imgSrc: require("../../images/Services/Support/support.png"),
          xl: "3",
          lg: "4",
          md: "6",
          sm: "12",
        },
      ],
    };
    if (window.innerWidth < 991) {
      this.state.tmp = this.state.blocks[3];
      this.state.blocks[3] = this.state.blocks[4];
      this.state.blocks[4] = this.state.tmp;
    } else {
      this.state.isMobile = true;
    }

    if (window.innerWidth > 992 && window.innerWidth < 1199) {
      this.state.feedbackHeight = "287px";
    }
    if (window.innerWidth > 769 && window.innerWidth < 991) {
      this.state.feedbackHeight = "287px";
    }

    if (window.innerWidth < 576) {
      this.state.feedbackHeight = "475px";
      this.state.breakWord = true;
    }
  }

  componentDidMount() {
    this.setState({
      isSent: true,
    });

    axios
      .get(
        process.env.NODE_ENV === "production"
          ? `https://homechain.ai/s/api/instagram`
          : `http://localhost:4000/api/instagram`
      )
      .then(({ data }) => {
        this.setState({
          posts: data.posts.slice(0, 8),
        });
      });
  }

  openBigForm() {
    this.setState({ isBigForm: true });
  }

  hideBigForm = () => {
    this.setState({ isBigForm: false });
  };

  uppendTitle() {
    document.querySelector("head title").innerHTML =
      "Умный дом в Москве –  от проекта до запуска системы | Homeсhain";
  }

  uppendMeta() {
    let description = document.querySelector("meta[name=description]");
    if (description) {
      description.content =
        'Умный дом в Москве "под ключ" - проектирование, монтаж, пусконаладка. Loxone, Apple HomeKit, Google Home, Яндекс, Amazon Alexa. Homeсhain +7 (499) 113 10 10.';
    } else {
      description = document.createElement("meta");
      description.name = "description";
      description.content =
        'Умный дом в Москве "под ключ" - проектирование, монтаж, пусконаладка. Loxone, Apple HomeKit, Google Home, Яндекс, Amazon Alexa. Homeсhain +7 (499) 113 10 10.';
      document.querySelector("head").appendChild(description);
    }
  }

  render() {
    const link = "/services/";
    const renderBlocks = this.state.blocks.map((item) => {
      return (
        <Col
          key={item.link}
          xl={item.xl}
          lg={item.lg}
          md={item.md}
          sm={item.sm}
        >
          <Link to={link + item.link}>
            <MainThumbnail name={item.name} imgSrc={item.imgSrc} />
          </Link>
        </Col>
      );
    });
    const renderSolutions = () => {
      return <SolutionsBlock activeCat={this.props.activeCat} />;
    };

    return (
      <div id="main">
        {this.uppendTitle()}
        {this.uppendMeta()}
        <Navbar activeMain={this.state.activeMain} />
        <div className="page" style={{ backgroundImage: `url(${MainBg})` }}>
          <header className="main-header">
            <Container>
              <Row className="page-row">
                <div className="page-header">
                  <h1 className="main-smart-home-title">
                    Умный дом {this.state.breakWord && <br />} &#171;под
                    ключ&#187;
                  </h1>
                  <p className="main-smart-home-subtitle">
                    Комплексные решения от проекта до запуска
                  </p>
                  <div>
                    <img data-src={Ticket} alt="" />
                    <span>
                      от 100€/м<sup>2</sup>
                    </span>
                  </div>
                  <HashLink
                    smooth
                    to="#feedback-big"
                    className="main-application-button-space-top"
                  >
                    <button
                      onClick={this.openBigForm.bind(this)}
                      className="hover-button"
                    >
                      Оставить заявку
                    </button>
                  </HashLink>
                </div>
              </Row>
            </Container>
          </header>
        </div>
        <Container id="services">
          <Row>{renderBlocks}</Row>
        </Container>
        {renderSolutions()}
        <Control />
        <div className="main-application-button-container">
          <HashLink smooth to="#feedback-big">
            <button
              onClick={this.openBigForm.bind(this)}
              className="control-button hover-button main-application-button"
            >
              Оставить заявку
            </button>
          </HashLink>
        </div>
        <HowWeWork />
        <div
          style={{
            height: !this.state.isSent
              ? this.state.isBigForm
                ? "1205px"
                : this.state.feedbackHeight
              : "unset",
            transition: "all 2s ease",
          }}
        >
          <div
            className={classNames("feedback-form-container", {
              ["visible-form"]: this.state.isBigForm,
            })}
          >
            {this.state.isBigForm && (
              <FeedbackFormBig hideBig={this.hideBigForm} solution={true} />
            )}
          </div>
          <div
            className={classNames("feedback-form-container", {
              ["visible-form"]: !this.state.isBigForm,
            })}
          >
            {!this.state.isBigForm && <FeedbackFormSmall />}
          </div>
        </div>

        {/* <FeedbackFormBig order={this.state.order} solution={true} /> */}
        {!!this.state.posts.length && (
          <div>
            <Container>
              <Row className="page-description">
                <Col xl="12">
                  <h3 className="main-our-instagram-title">Наш Инстаграм</h3>
                </Col>
              </Row>
            </Container>
            <Container fluid className="instagram-container">
              <Row>
                <Col xl="12">
                  <div id="instafeed">
                    {this.state.posts.map((post, i) => {
                      return (
                        <div
                          className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 instagram"
                          key={i}
                        >
                          <div className=" instagram-thumbnail">
                            <a target="_blank" href={post.permalink}>
                              <img
                                data-src={
                                  post.media_type === "IMAGE" ||
                                  post.media_type === "CAROUSEL_ALBUM"
                                    ? post.media_url
                                    : post.thumbnail_url
                                }
                                className="lazy"
                                alt=""
                                src="/images/image-preloader.png"
                              />
                              <p className="instagram-hover-text">
                                {post.caption}
                              </p>
                            </a>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </div>
    );
  }
}

export default Main;
