import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";
import Navbar from "../../components/Navbar/Navbar";
import CoopBg from "../../images/cooperation-bg.png";
import FeedbackFormCoop from "../FeedbackForm/FeedbackFormCoop";

class Cooperation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeCoop: true,
      isMobile: window.innerWidth <= 576
    };
  }

  uppendTitle() {
    document.querySelector("head title").innerHTML =
      "Сотрудничество | Умный дом в Москве | Homeсhain";
  }

  uppendMeta() {
    let description = document.querySelector("meta[name=description]");
    if (description) {
      description.content =
        'Сотрудничество. Умный дом в Москве "под ключ" - проектирование, комплектация, монтаж, пусконаладка. Homeсhain +7 (499) 113 10 10.';
    } else {
      description = document.createElement("meta");
      description.name = "description";
      description.content =
        'Сотрудничество. Умный дом в Москве "под ключ" - проектирование, комплектация, монтаж, пусконаладка. Homeсhain +7 (499) 113 10 10.';
      document.querySelector("head").appendChild(description);
    }
  }

  render() {
    return (
      <div>
        {this.uppendTitle()}
        {this.uppendMeta()}
        <Navbar activeCoop={this.state.activeCoop} />
        <Container
          fluid
          className="page "
          style={{ backgroundImage: `url(${CoopBg})` }}
        >
          <header>
            <Container fluid>
              <Row className="page-row custom-container">
                {!this.state.isMobile && (
                  <div className="page-nav">
                    <ul>
                      <li>
                        <Link to="/">Главная</Link>
                      </li>
                      <img src={require("../../images/arrow.svg")} alt="" />
                      <li style={{ color: "#181818" }}>Сотрудничество</li>
                    </ul>
                  </div>
                )}

                {this.state.isMobile && (
                  <div className="page-nav">
                    <ul>
                      <img
                        style={{ width: "6px" }}
                        src={require("../../images/arrow-left.svg")}
                        alt=""
                      />
                      <li>
                        <Link to="/">Главная</Link>
                      </li>
                    </ul>
                  </div>
                )}

                <div className="page-header">
                  <h1>Сотрудничество</h1>
                  <HashLink
                    smooth
                    to={`${window.location.pathname}#feedback-coop`}
                  >
                    <button className="hover-button">Оставить заявку</button>
                  </HashLink>
                </div>
              </Row>
            </Container>
          </header>
        </Container>

        <Container fluid>
          <div className="custom-container">
            <Row className="page-description cooperation-container">
              <Col xl="12" className="cooperation-wrapper">
                <h3 className="cooperation-text-title">
                  Будем рады сотрудничеству на взаимовыгодных условиях.{" "}
                </h3>
                <p className="cooperation-text">
                  Заполните заявку, и мы обязательно обсудим ваше предложение!
                </p>
              </Col>
            </Row>
          </div>
        </Container>
        <FeedbackFormCoop />
      </div>
    );
  }
}

export default Cooperation;
