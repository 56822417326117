import React from "react";
import { Container } from "react-bootstrap";
import ControlBlock from "./ControlBlock";

class Control extends React.Component {
  render() {
    return (
      <div className="control">
        <div className="page-description" style={{ padding: "0px" }}>
          <Container>
            <h2 style={{ marginBottom: "38px" }}>Управление умным домом</h2>
            <p style={{ marginBottom: "20px", textAlign: "center" }}>
              Управляйте вашим домом с любимых устройств
            </p>
          </Container>

          <ControlBlock />
        </div>
      </div>
    );
  }
}

export default Control;
