import React, { Component } from "react";
import { Row, Container, Col } from "react-bootstrap";
import Slider from "react-slick";
import ChevronLeft from "../../images/chevron-left.svg";
import ChevronRight from "../../images/chevron-right.svg";
import ChevronLeftActive from "../../images/chevron-left-active.svg";
import ChevronRightActive from "../../images/chevron-right-active.svg";
import "../Tabs/Control/control.css";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block"
      }}
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block"
      }}
      onClick={onClick}
    />
  );
}

class TabsConstructor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: window.innerWidth <= 991,
      activeTab: 0,
      tabName: Object.keys(this.props.tabs).shift()
    };

    this.state.tabs = this.props.tabs[this.state.tabName].rows.map(row => {
      return {
        name: row.shift(),
        content: row.shift()
      };
    });
  }

  isActiveBlock(i) {
    return this.state.activeTab === i;
  }

  renderTab() {
    return (
      <Col
        key={this.state.activeTab}
        xl="12"
        lg="12"
        md="12"
        sm="12"
        xs="12"
        className="authorize-description"
      >
        <div className="howwework-description-item">
          <button
            disabled={this.state.tabs[this.state.activeTab - 1] ? false : true}
            className="arrow"
            style={{
              backgroundColor: this.state.tabs[this.state.activeTab - 1]
                ? ""
                : "#f7f7f7",
              position: "absolute",
              left: "-30%",
              boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.13)"
            }}
            onClick={() => {
              this.setState({ activeTab: this.state.activeTab - 1 });
              this.renderTab();
            }}
          >
            <img
              src={
                this.state.tabs[this.state.activeTab - 1]
                  ? ChevronLeftActive
                  : ChevronLeft
              }
              alt=""
            />
          </button>
          <h6 style={{ left: "-20%" }}>
            {this.state.tabs[this.state.activeTab - 1] &&
              this.state.tabs[this.state.activeTab - 1].name}
          </h6>
          <h6 style={{ right: "-20%" }}>
            {this.state.tabs[this.state.activeTab + 1] &&
              this.state.tabs[this.state.activeTab + 1].name}
          </h6>
          <button
            disabled={this.state.tabs[this.state.activeTab + 1] ? false : true}
            className="arrow active"
            style={{
              backgroundColor: this.state.tabs[this.state.activeTab + 1]
                ? ""
                : "#f7f7f7",
              position: "absolute",
              right: "-30%",
              boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.13)"
            }}
            onClick={() => {
              this.setState({ activeTab: this.state.activeTab + 1 });
              this.renderTab();
            }}
          >
            <img
              src={
                this.state.tabs[this.state.activeTab + 1]
                  ? ChevronRightActive
                  : ChevronRight
              }
              alt=""
            />
          </button>
          <p>{this.state.tabs[this.state.activeTab].content}</p>
        </div>
      </Col>
    );
  }

  render() {
    let settings = {
      infinite: false,
      autoplay: false,
      arrows: false,
      slidesToScroll: 1,
      initialSlide: 0,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            arrows: true,
            slidesToShow: 7,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 768,
          settings: {
            arrows: true,
            slidesToShow: 5,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 576,
          settings: {
            arrows: true,
            slidesToShow: 4,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 420,
          settings: {
            arrows: true,
            slidesToShow: 3,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <div style={{ marginTop: "40px", marginBottom: "60px" }}>
        <Container fluid className="howwework-block">
          <div className={"tabs-render-ssr"}>
            <div className={"tabs-render-ssr"}>
              <Slider {...settings}>
                {this.state.tabs.map((tab, index) => (
                  <div>
                    <button
                      onClick={() => {
                        this.setState({ activeTab: index });
                        this.renderTab();
                      }}
                      style={
                        this.isActiveBlock(index)
                          ? { borderBottom: "1px solid #0F75BC" }
                          : { borderBottom: "1px solid #d1d1d1" }
                      }
                    >
                      <h3
                        style={
                          this.isActiveBlock(index)
                            ? { color: "#0F75BC" }
                            : { color: "#000" }
                        }
                      >
                        {index + 1}
                      </h3>
                      <p
                        style={
                          this.isActiveBlock(index)
                            ? { color: "#0F75BC" }
                            : { color: "#000" }
                        }
                      >
                        {tab.name}
                      </p>
                    </button>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </Container>

        <Container fluid>
          {this.state.tabs.map((tab, index) => (
            <Row>{this.isActiveBlock(index) && this.renderTab()}</Row>
          ))}
        </Container>
      </div>
    );
  }
}

export default TabsConstructor;
