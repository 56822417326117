import React from "react";
import { Container, Col } from "react-bootstrap";
import Slider from "react-slick";

import "../../Tabs/Control/control.css";

function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: "block"
            }}
            onClick={onClick}
        />
    );
}

function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: "block"
            }}
            onClick={onClick}
        />
    );
}

class AmazonDevices extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            renderResponsive: null,

            amazon: [
                {
                    deviceName: "Смартфоны",
                    imgSrc: require("../../../images/AmazonEcho/smartphone.png"),
                    alt: "амазон эхо на смартфоне",
                    text: "Голосовой помощник Алекса и центр управления умным домом на любом Android или iOS смартфоне."
                },
                {
                    deviceName: "Планшеты",
                    imgSrc: require("../../../images/AmazonEcho/tablet.png"),
                    alt: "amazon echo на планшете",
                    text: "Управляйте всеми устройствами голосовым помощником Алекса с помощью любого Android или iOS планшета."
                },
                {
                    deviceName: "Персональные компьютеры",
                    imgSrc: require("../../../images/AmazonEcho/pc.png"),
                    alt: "амазон эхо на компьютере",
                    text: "Большие возможности для персонализации в интуитивно понятном интерфейсе."
                },
                {
                    deviceName: "Умные часы",
                    imgSrc: require("../../../images/AmazonEcho/smart-watch.png"),
                    alt: "amazon echo на умных часах",
                    text: "Умные часы не дадут пропустить уведомления и позволят оперативно управлять системой."
                },
                {
                    deviceName: "Колонки Amazon Echo",
                    imgSrc: require("../../../images/AmazonEcho/speaker-echo.png"),
                    alt: "amazon echo на колонках",
                    text: "Умная колонка от Amazon с голосовым помощником Алекса позволяет управлять умными устройствами, музыкой и приложениями."
                },
                {
                    deviceName: "Колонки с поддержкой Alexa",
                    imgSrc: require("../../../images/AmazonEcho/speaker-alexa.png"),
                    alt: "амазон эхо на колонках алекса",
                    text: "Колонки с поддержкой голосового помощника Алекса от различных производителей сильно расширяют выбор дизайна и качества звучания."
                }
            ]
        };
    }

    render() {
        let settings = {
            infinite: false,
            autoplay: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const renderAmazon = this.state.amazon.map((item) => {
            return (
                <div key={item}>
                    <Col xl='3' lg='3' md='4' sm='6' xs='12' className='ecosystem-devices'>
                        <p className="ecosystem-devices-device-name">{item.deviceName}</p>
                        <div>
                            <img data-src={item.imgSrc} alt={item.alt} class='lazy' src='/images/image-preloader.png' />
                        </div>
                        <p className='control-hover-text'>{item.text}</p>
                    </Col>
                </div>
            );
        });

        return (
            <div>
                <Container fluid className='devices control-devices'>
                    <Slider {...settings}>{renderAmazon}</Slider>
                </Container>
            </div>
        );
    }
}

export default AmazonDevices;
