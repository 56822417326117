import React from "react";
import { Container } from "react-bootstrap";
import SolutionsHeader from "./SolutionsHeader";
import SolutionsDropdown from "./SolutionsDropdown";

class SolutionsBlock extends React.PureComponent {
  render() {
    return (
      <Container
        fluid
        className="solutions"
        id="solutions">
        <SolutionsHeader />
        <SolutionsDropdown activeCat={this.props.activeCat} />
      </Container>
    );
  }
}

export default SolutionsBlock;
