import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Navbar from "../../components/Navbar/Navbar";
import HomePod from "../../images/HomePod.png";

class Painting extends React.Component {
  uppendTitle() {
    document.querySelector("head title").innerHTML =
      "Упс! Кажется такой страницы нет";
  }
  render() {
    return (
      <div>
        {this.uppendTitle()}
        <Navbar />
        <div className="not-found">
          <Container>
            <Row>
              <Col xl="12">
                <h1>Упс! Кажется такой страницы нет.</h1>
              </Col>
            </Row>
            <Row>
              <Col xl="12">
                <img
                  data-src={HomePod}
                  alt=""
                  class="lazy"
                  src="/images/image-preloader.png"
                />
              </Col>
            </Row>
            <Row className="not-found-button-container">
              <Link to="/">
                <button className="hover-button">
                  На главную
                </button>
              </Link>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default Painting;
