import React from "react";

class MainThumbnail extends React.Component {
  render() {
    return (
      <div className="main-thumbnail-container">
        <div className="main-thumbnail">
          <div
            className="main-thumbnail-image"
            style={{ backgroundImage: `url(${this.props.imgSrc})` }}
          />
          <div style={this.props?.withPadding && {padding: '15px 5px'}}>
            <p>{this.props.name}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default MainThumbnail;
