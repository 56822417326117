import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Navbar from "../../../components/Navbar/Navbar";
import FeedbackFormSmall from "../../FeedbackForm/FeedbackFormSmall";
import { HashLink } from "react-router-hash-link";
import AppleBg from "../../../images/AppleHomeKit/apple-home-bg.png";

import ServicesBlock from "../../ServicesBlock/ServicesBlock";

import AppleHomePod from "../../../images/AppleHomeKit/apple-homepod.png";
import AppleIpad from "../../../images/AppleHomeKit/apple-ipad.png";
import Morning from "../../../images/AppleHomeKit/morning.png";
import Evening from "../../../images/AppleHomeKit/evening.png";
import SolutionsBlockPages from "../../Solutions/SolutionsBlock/SolutionsBlockPages";
import AppleDevices from "./AppleDevices";

class AppleHomeKit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: window.innerWidth <= 576,
      isSolutionsHovering: false
    };
  }

  uppendTitle() {
    document.querySelector("head title").innerHTML =
      "Apple HomeKit | Умный дом в Москве | Homeсhain";
  }
  uppendMeta() {
    let description = document.querySelector("meta[name=description]");
    let keywords = document.querySelector("meta[name=keywords]");
    if (description) {
      description.content =
        'Apple HomeKit. Умный дом в Москве "под ключ" - проектирование, комплектация, монтаж, пусконаладка. Homeсhain +7 (499) 113 10 10.';
    } else {
      description = document.createElement("meta");
      description.name = "description";
      description.content =
        'Apple HomeKit. Умный дом в Москве "под ключ" - проектирование, комплектация, монтаж, пусконаладка. Homeсhain +7 (499) 113 10 10.';
      document.querySelector("head").appendChild(description);
    }

    if (keywords) {
      keywords.content = "умный дом apple home kit apple homekit";
    } else {
      keywords = document.createElement("meta");
      keywords.name = "keywords";
      keywords.content = "умный дом apple home kit apple homekit";
      document.querySelector("head").appendChild(keywords);
    }
  }

  solutionsHover() {
    this.setState(this.toggleSolutionsHover);
  }

  toggleDropdown() {
    return {
      isServicesHovering: false,
      isSolutionsHovering: false
    };
  }

  toggleSolutionsHover(state) {
    return {
      isSolutionsHovering: !state.isSolutionsHovering,
      isServicesHovering: false
    };
  }

  render() {
    return (
      <div>
        {this.uppendTitle()}
        {this.uppendMeta()}
        <Navbar activeCat={"control"} activeSolutions={true} />
        <Container
          fluid
          className="ecosystem"
          style={{
            backgroundImage: `url(${AppleBg})`
          }}
        >
          <header>
            <Container fluid>
              <Row
                style={{ flexDirection: "column" }}
                className="ecosystem-row custom-container"
              >
                {!this.state.isMobile ? (
                  <div>
                    <div className="ecosystem-nav">
                      <ul>
                        <li>
                          <Link to="/">Главная</Link>
                        </li>
                        <img
                          src={require("../../../images/arrow.svg")}
                          alt=""
                        />
                        <li onClick={this.solutionsHover.bind(this)}>
                          <HashLink>Решения</HashLink>
                        </li>
                        <img
                          src={require("../../../images/arrow.svg")}
                          alt=""
                        />
                        <li style={{ color: "#0F75BC" }}>
                          <Link to="/solutions/ecosystemi">Экосистемы</Link>
                        </li>
                        <img
                          src={require("../../../images/arrow.svg")}
                          alt=""
                        />
                        <li style={{ color: "#000" }}>Apple HomeKit</li>
                      </ul>
                    </div>
                    {this.state.isSolutionsHovering && (
                      <SolutionsBlockPages
                        activeCat={`control`}
                        handler={this.solutionsHover.bind(this)}
                      />
                    )}
                  </div>
                ) : (
                  <div>
                    <div className="page-nav">
                      <ul>
                        <li
                          style={{ margin: "0px" }}
                          onClick={this.solutionsHover.bind(this)}
                        >
                          <img
                            style={{
                              width: "6px",
                              marginLeft: "5px",
                              marginRight: "5px"
                            }}
                            src={require("../../../images/arrow-left.svg")}
                            alt=""
                          />

                          <HashLink>Решения</HashLink>
                        </li>
                      </ul>
                    </div>
                    {this.state.isSolutionsHovering && (
                      <SolutionsBlockPages
                        activeCat={`control`}
                        handler={this.solutionsHover.bind(this)}
                      />
                    )}
                  </div>
                )}

                <div className="ecosystem-header">
                  <h1>Apple HomeKit</h1>
                </div>
              </Row>
            </Container>
          </header>
        </Container>
        <Container fluid className="custom-container">
          <Row className="ecosystem-row">
            <div className="ecosystem-description">
              <Col xl="12" lg="12" md="12">
                <h2>Основные возможности</h2>
              </Col>
              <Col xl="12" lg="12" md="12">
                <h3>Работает на всех устройствах</h3>
                <p>
                  Программный комплекс Apple HomeKit позволяет управлять
                  электронным оборудованием умного дома и обмениваться
                  мультимедийной информацией по единому интерфейсу. Преимущество
                  приложения в отсутствии необходимости устанавливать и
                  использовать другие программные продукты.
                </p>
                <p>
                  Программа разработана под iOS 8 в сентябре 2014 года.
                  Поддержка продукта предусмотрена в перспективной операционной
                  системе MacOS Mojave и функционирует на всех без исключения
                  гаджетах, рассчитанных под iOS, с помощью электронного
                  помощника Siri.
                </p>
              </Col>
            </div>
          </Row>

          <div className="ecosystem-description">
            <Row className="ecosystem-row">
              <Col xl="12" lg="12" md="12">
                <h3>Подключение приборов, не поддерживающих программу</h3>
                <p>
                  <img
                    data-src={AppleHomePod}
                    alt=""
                    class="lazy"
                    src="/images/image-preloader.png"
                  />
                  Программа умный дом Apple HomeKit может использоваться
                  оборудованием, не поддерживающим данное приложение из коробки
                  или от производителя. С этой целью мы подключаем приборы к
                  автоматизированной системе через «шлюз», в качестве которого
                  используем концентратор или хаб. В результате все устройства
                  клиента объединяются в единый интерфейс HomeKit без
                  необходимости использования сторонних приложений.
                </p>
              </Col>
            </Row>
          </div>
          <Row className="ecosystem-row">
            <div className="ecosystem-description">
              <Col xl="12" lg="12" md="12">
                <h3>Интуитивно понятный интерфейс</h3>
                <p>
                  Для безопасного и легкого управления подключенными к
                  автоматизированной системе приборами и аксессуарами,
                  поддерживающими Apple HomeKit, разработано удобное в
                  использовании приложение «Дом». Принцип работы программы
                  простой и понятный. Пользователям достаточно взять iPhone и
                  обратиться к электронному помощнику Siri, с просьбой,
                  например, включить/выключить свет в помещении. Взглянув на
                  iPad, вы узнаете, кто пришел в гости и находится у входной
                  двери. Используя Apple TV, клиентам предоставляется
                  возможность на удалении контролировать режимы функционирования
                  домашней техники. Программа повышает оперативность и
                  эффективность работы подключенных электронных устройств.
                </p>
              </Col>
            </div>
          </Row>
          <Row className="ecosystem-row">
            <div className="ecosystem-description ecosystem-description-image-wrapper">
              <Col xl="12" lg="12" md="12">
                <img
                  data-src={AppleIpad}
                  alt=""
                  src="/images/image-preloader.png"
                  className="lazy ecosystem-description-image"
                />
              </Col>
            </div>
          </Row>
          <Row className="ecosystem-row">
            <div className="ecosystem-description">
              <Col xl="12" lg="12" md="12">
                <h3>Распределение функционала по комнатам</h3>
                <p>
                  Управление умным домом Apple Home Kit может осуществляться из
                  любого помещения одним касанием. Все представленные в
                  приложении устройства сгруппированы по комнатам для удобства
                  клиентов. Для управления основными функциями
                  автоматизированной системы можно использовать помощник Siri.
                  Достаточно дать команду, например, «включи свет в прихожей»
                  или «установи температуру кондиционера на 20 градусов» и
                  пожелание будет выполнено.
                </p>
                <p>
                  Для сложных задач, связанных с регулировкой цветовой гаммы и
                  мощности источника света в комнате, а также изменением
                  настройки терморегулятора теплого пола и выполнением прочих
                  операций, необходимо нажать и удерживать соответствующий
                  значок.
                </p>
                <p>
                  Если телеаппаратура поддерживает AirPlay 2, то устройство
                  добавляется в программу «Дом». Управлять таким телевизором
                  удобно, как и другими электронными приборами. Приложение имеет
                  возможность включать и отключать электропитание, изменять
                  громкость, переключать источники входящей информации.
                </p>
                <div className="ecosystem-description-siri">
                  <Row>
                    <Col xl="12" lg="12" md="12">
                      <div className="ecosystem-description-siri-block">
                        <h4>Привет, Siri, включи свет в прихожей</h4>
                        <span>
                          Используйте Siri для выполения основных функций
                        </span>
                      </div>
                    </Col>
                    <Col xl="12" lg="12" md="12">
                      <div className="ecosystem-description-siri-block">
                        <h4>
                          Привет, Siri, установи температуру кондиционера на 20
                          градусов
                        </h4>
                        <span>Управляйте аксессуарами HomeKit</span>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </div>
          </Row>
          <div className="ecosystem-description">
            <Row className="ecosystem-row">
              <Col xl="12" lg="12" md="12">
                <h3>Создание сценариев</h3>
                <p>
                  Приложение позволяет создавать собственные сценарии управления
                  жестом руки или словом. Предоставляется возможность
                  программировать сценарии, рассчитанные на несколько устройств.
                  Активация оборудования обеспечивается одной фразой. К примеру,
                  при активации сценария фразой «выхожу из квартиры», в
                  помещении одновременно гаснет свет, закрываются двери,
                  отключается система отопления, и выполняются прочие действия.
                </p>
                <p>
                  По сценарию «киновечер» автоматически включится телевизионный
                  приемник, поддерживающий AirPlay 2, закроются шторы\жалюзи,
                  освещение станет приглушенным для создания комфортной
                  обстановки в комнате.
                </p>
              </Col>
              <Row className="scenario">
                <Col xl="6" lg="6" md="6" sm="6">
                  <img className="apple-home-kit-image-morning" src={Morning} alt="" />
                  <h5>Утро</h5>
                  <h6>
                    Создайте сценарий на утро: он откроет шторы, включит
                    отопление и кофеварку.
                  </h6>
                </Col>
                <Col xl="6" lg="6" md="6" sm="6">
                  <img
                    data-src={Evening}
                    alt=""
                    src="/images/image-preloader.png"
                    className="lazy apple-home-kit-image-evening"
                  />
                  <h5>Ночь</h5>
                  <h6>
                    Когда вы решите, что пора ложиться спать, вечерний сценарий
                    выключит свет, понизит температуру в комнате и закроет все
                    двери.
                  </h6>
                </Col>
              </Row>
            </Row>
          </div>
          <Row className="ecosystem-row">
            <div className="ecosystem-description">
              <Col xl="12" lg="12" md="12">
                <h2>Управление</h2>
              </Col>
              <Col xl="12" lg="12" md="12">
                <p>
                  Умным домом Apple HomeKit можно управлять дистанционно с
                  использованием мобильных гаджетов, выполняющих функции пультов
                  дистанционного управления. Приложение устанавливается на
                  следующие устройства:
                </p>
              </Col>
            </div>
          </Row>
        </Container>
        <AppleDevices />
        <ServicesBlock />
        <FeedbackFormSmall />
      </div>
    );
  }
}

export default AppleHomeKit;
