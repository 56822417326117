import React, { Component } from "react";
import { Row, Container, Col } from "react-bootstrap";
import Check from "../../images/check.svg";

class TableConstructor extends Component {
  setSize(size) {
    return { 2: "6", 3: "4", 4: "3" }[size] || "2";
  }

  createTableHeaders = (headers, size) => {
    const styleReg = new RegExp('"color:.*"', "g");
    const nameReg = new RegExp('"color:.*"', "g");

    const mainColor = "#0f75bc";
    const defaultColor = "#818181";
    const hSize = this.setSize(size); // table header size

    headers = headers.map(header => {
      const name = header.replace(nameReg, "");
      return {
        name,
        color: header.match(styleReg) ? mainColor : defaultColor
      };
    });

    return headers.map(header => (
      <Col xl={hSize} lg={hSize} md={hSize} sm={hSize} xs={hSize}>
        <h4 className="h4-table" style={{ color: header.color }}>
          {header.name}
        </h4>
      </Col>
    ));
  };

  createTableRows = (rows, size) => {
    const rSize = this.setSize(size);
    return rows.map(row => (
      <Row className="services-table-row">
        {row.map((line, i) => (
          <Col xl={rSize} lg={rSize} md={rSize} sm={rSize} xs={rSize}>
            {line === "li" ? (
              <img src={Check} alt="" />
            ) : i !== 0 ? (
              <h5 className="h4-table">{line}</h5>
            ) : (
              <h4 className="h4-table">{line}</h4>
            )}
          </Col>
        ))}
      </Row>
    ));
  };

  render() {
    return this.props.tables.map(table => (
      <div style={{ marginBottom: "60px" }} className="services">
        <Container className="services-table ">
          <Row style={{ marginBottom: "40px" }}>
            <Col xl="12" lg="12" md="12" sm="12">
              <h2 style={{ textAlign: "center" }}>{table.name}</h2>
            </Col>
          </Row>

          <Row className="services-table-header-row">
            {this.createTableHeaders(table.headers, table.columnCount)}
          </Row>

          <div>{this.createTableRows(table.rows, table.columnCount)}</div>
        </Container>
      </div>
    ));
  }
}

export default TableConstructor;
