export const advantagesItems = [
  {
    title: "Понимание",
    description: "Исключение непонимания между подрядчиками",
    iconSrc: require("../../../images/Services/BuiltinV2/builtinv2-Иконка понимание.svg"),
  },
  {
    title: "Экономия",
    description: "Экономия средств на межэтапные аудиты",
    iconSrc: require("../../../images/Services/BuiltinV2/builtinv2-piggy-saving-icon.svg"),
  },
  {
    title: "Профессионализм",
    description: "Исключение ошибок и халатности",
    iconSrc: require("../../../images/Services/BuiltinV2/builtinv2-Иконка профессионализм.svg"),
  },
  {
    title: "Скорость",
    description: "Существенное ускорение реализации",
    iconSrc: require("../../../images/Services/BuiltinV2/builtinv2-Иконка скорость.svg"),
  },
  {
    title: "Контроль",
    description: "Непрерывный стандартизированный контроль и управление",
    iconSrc: require("../../../images/Services/BuiltinV2/builtinv2-Иконка контроль.svg"),
  },
  {
    title: "Гарантия",
    description: "Гарантии на все работы от одного исполнителя",
    iconSrc: require("../../../images/Services/BuiltinV2/builtinv2-Иконка гарантия.svg"),
  },
];

export const noRoomForErrorItems = [
  {
    title: "Устранять ошибку и переделывать ремонт",
    imgSrc: require("../../../images/Services/BuiltinV2/builtinv2-ustranyat oshibku 1.png"),
  },
  {
    title:
      "Отказаться от части функционала оборудования\\управления системы умный дом",
    imgSrc: require("../../../images/Services/BuiltinV2/builtinv2-Krestiki 1.png"),
  },
];

export const powerLineCablesItems = [
  {
    title: "Питание щитов",
    imgSrc: require("../../../images/Services/BuiltinV2/builtinv2-SCHITOV Pitanie.png"),
  },
  {
    title: "Розетки",
    imgSrc: require("../../../images/Services/BuiltinV2/builtinv2-rosetki 2.png"),
  },
  {
    title: "Питание электрооборудования",
    imgSrc: require("../../../images/Services/BuiltinV2/builtinv2-Pitanie 2 1.png"),
  },
  {
    title: "Освещение",
    imgSrc: require("../../../images/Services/BuiltinV2/builtinv2-osveshenie.png"),
  },
];

export const lowCurrentLineCablesItems = [
  {
    title: "Локальная сеть (СКС)",
    imgSrc: require("../../../images/Services/BuiltinV2/builtinv2-Файл_000 copy copy.png"),
  },
  {
    title: "Выключатели и панели управления",
    imgSrc: require("../../../images/Services/BuiltinV2/builtinv2-IMG_3306.png"),
  },
  {
    title: "Мультимедиа",
    imgSrc: require("../../../images/Services/BuiltinV2/builtinv2-IMG_3337.png"),
  },
  {
    title: "Датчики",
    imgSrc: require("../../../images/Services/BuiltinV2/builtinv2-IMG_3314.png"),
  },
  {
    title: "Телефония",
    imgSrc: require("../../../images/Services/BuiltinV2/builtinv2-telefon copy.png"),
  },
  {
    title: "Телевизионные системы",
    imgSrc: require("../../../images/Services/BuiltinV2/builtinv2-domashniy_kinozal_toxmedia_k2 1.png"),
  },
  {
    title: "Системы домофонии",
    imgSrc: require("../../../images/Services/BuiltinV2/builtinv2-Option 1.png"),
  },
  {
    title: "Шины автоматизации (KNX, Modbus, RS485 и прочие)",
    imgSrc: require("../../../images/Services/BuiltinV2/builtinv2-шины.png"),
  },
];

export const installationItems = [
  {
    title: "Розетки",
    imgSrc: require("../../../images/Services/BuiltinV2/builtinv2-rosetki.png"),
  },
  {
    title: "Выключатели",
    imgSrc: require("../../../images/Services/BuiltinV2/builtinv2-vikluchateli (1).png"),
  },
  {
    title: "Осветительные приборы (люстры, бра, подсветка и другие)",
    imgSrc: require("../../../images/Services/BuiltinV2/builtinv2-osveshenie-1.png"),
  },
  {
    title: "Датчики (температуры, освещенности, защиты от протечек и другие)",
    imgSrc: require("../../../images/Services/BuiltinV2/builtinv2-datchiki 3.png"),
  },
  {
    title: "Встраиваемая акустика",
    imgSrc: require("../../../images/Services/BuiltinV2/builtinv2-akustika.png"),
  },
  {
    title: "Настенные панели управления",
    imgSrc: require("../../../images/Services/BuiltinV2/builtinv2-Panely upravlenia (1).png"),
  },
  {
    title: "Мультимедийные устройства",
    imgSrc: require("../../../images/Services/BuiltinV2/builtinv2-multimedia.png"),
  },
  {
    title: "Электроприборы",
    imgSrc: require("../../../images/Services/BuiltinV2/builtinv2-Elektropribori 1.png"),
  },
  {
    title: "Сетевое оборудование",
    imgSrc: require("../../../images/Services/BuiltinV2/builtinv2-Setevoe wap.png"),
  },
  {
    title: "Усилители сотового сигнала",
    imgSrc: require("../../../images/Services/BuiltinV2/builtinv2-Usiliteli.png"),
  },
];

export const infrastructureItems = [
  {
    title: "Wi-Fi точки доступа",
    imgSrc: require("../../../images/Services/BuiltinV2/builtinv2-wifi 2.png"),
  },
  {
    title: "Сетевые розетки",
    imgSrc: require("../../../images/Services/BuiltinV2/builtinv2-Frame 6.png"),
  },
  {
    title: "Телевизоры",
    imgSrc: require("../../../images/Services/BuiltinV2/builtinv2-Tele 1.png"),
  },
  {
    title: "ТВ приставки",
    imgSrc: require("../../../images/Services/BuiltinV2/builtinv2-tv.png"),
  },
  {
    title: "Игровые системы",
    imgSrc: require("../../../images/Services/BuiltinV2/builtinv2-Igrovyie 1.png"),
  },
  {
    title: "Аудиосистемы",
    imgSrc: require("../../../images/Services/BuiltinV2/builtinv2-audio.png"),
  },
  {
    title: "Видеосистемы",
    imgSrc: require("../../../images/Services/BuiltinV2/builtinv2-video c.png"),
  },
  {
    title: "Видеонаблюдение",
    imgSrc: require("../../../images/Services/BuiltinV2/builtinv2-Videonab 2.png"),
  },
  {
    title: "Домофония",
    imgSrc: require("../../../images/Services/BuiltinV2/builtinv2-Domofone 2.png"),
  },
  {
    title: "Оргтехника",
    imgSrc: require("../../../images/Services/BuiltinV2/builtinv2-orgtex.png"),
  },
  {
    title: "Умный дом",
    imgSrc: require("../../../images/Services/BuiltinV2/builtinv2-umniy.png"),
  },
];

export const onlyByProjectItems = [
  {
    title: "Невозможность подключения устройств",
    imgSrc: require("../../../images/Services/BuiltinV2/builtinv2-Cabel broken.png"),
  },
  {
    title: "Плохое покрытие Wi-Fi сети",
    imgSrc: require("../../../images/Services/BuiltinV2/builtinv2-wifi broken.png"),
  },
  {
    title: "Низкая скорость подключения",
    imgSrc: require("../../../images/Services/BuiltinV2/builtinv2-slow spped.png"),
  },
];

export const companyItems = [
  {
    title: "Ubiquity (США)",
    imgSrc: require("../../../images/Services/BuiltinV2/builtinv2-Ubiquiti_Netwos.png"),
  },
  {
    title: "Cisco (США)",
    imgSrc: require("../../../images/Services/BuiltinV2/builtinv2-cisco 2.png"),
  },
  {
    title: "Mikrotik (Латвия)",
    imgSrc: require("../../../images/Services/BuiltinV2/builtinv2-mikrotik.png"),
  },
];
