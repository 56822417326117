import React from "react";

class FeaturesBlock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      features: this.props.features
    }
  }

  render() {
    const { features } = this.state;
    return (
      <div className="featuresRoot">
        {
          !!features.length && features.map((feature, i) =>
              <div className="feature" key={i}>
                <div className="feature-image">
                  <img
                      data-src={require(`../../images/Loxone/features/${feature.src}`)}
                      alt=""
                      className="lazy"
                      src="/images/image-preloader.png"
                  />
                </div>
                {
                  feature.title && <h3 className="feature-title">{feature.title}</h3>
                }
                {
                  feature.text && <p className="feature-description">{feature.text}</p>
                }
              </div>
          )
        }

      </div>
    );
  }
}

export default FeaturesBlock;
