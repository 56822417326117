import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Navbar from "../../Navbar/Navbar";
import { Row, Container, Col } from "react-bootstrap";
import BreadcrumbsConstructor from "./BreadcrumbsConstructor";
import MainThumbnail from "../../../components/BlockThumbnail/MainThumbnail";
import SolutionsBlockPages from "../../Solutions/SolutionsBlock/SolutionsBlockPages";

import NotFound from "../../NotFound/NotFound";

class CategoryPageConstructor extends Component {
  constructor(props) {
    super(props);
    this.hoverSolutions = this.hoverSolutions.bind(this);
    this.state = {
      page: null,
      isNotFound: false,
      openDropdown: false
    };

    if (window.innerWidth > 576) {
      this.state.isMobile = false;
    } else {
      this.state.isMobile = true;
    }
  }

  componentDidMount() {
    axios
      .get(
        process.env.NODE_ENV === "production"
          ? `https://homechain.ai/s/api/page/get-subcat/${this.props.match.params.subcat}`
          : `http://localhost:4000/api/page/get-subcat/${this.props.match.params.subcat}`
      )
      .then(res => {
        this.setState({ page: res.data.data, isNotFound: false });
      })
      .catch(err => {
        this.setState({ isNotFound: true });
      });
  }

  componentDidUpdate(prevProps, PrevState) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      axios
        .get(
          process.env.NODE_ENV === "production"
            ? `https://homechain.ai/s/api/page/get-subcat/${this.props.match.params.subcat}`
            : `http://localhost:4000/api/page/get-subcat/${this.props.match.params.subcat}`
        )
        .then(res => {
          this.setState({ page: res.data.data, isNotFound: false });
        })
        .catch(err => {
          this.setState({ isNotFound: true });
        });
    }
  }

  uppendTitle() {
    document.querySelector("head title").innerHTML = this.state.page.header;
  }

  uppendMeta() {
    let description = document.querySelector("meta[name=description]");
    if (description) {
      description.content = this.state.page.description;
    } else {
      description = document.createElement("meta");
      description.name = "description";
      description.content = this.state.page.description;
      document.querySelector("head").appendChild(description);
    }
  }

  hoverSolutions() {
    this.setState({
      openDropdown: true
    });
  }

  render() {
    return this.state.isNotFound ? (
      <NotFound />
    ) : (
      this.state.page && (
        <div>
          {this.uppendTitle()}
          {this.uppendMeta()}
          <Navbar activeCat={this.state.page.category} activeSolutions={true} />
          <Container style={{ marginBottom: "40px" }} fluid className="page">
            <header style={{ height: "unset" }}>
              <Container fluid>
                <Row className="page-row custom-container">
                  <BreadcrumbsConstructor
                    mobile={this.state.isMobile}
                    path={window.location.pathname}
                    lastPath={this.state.page.name}
                    hoverSolutions={this.hoverSolutions.bind(this)}
                    activeCat={this.state.page.category}
                  />
                  <div className="page-header">
                    <h1 style={{ marginTop: "35px", marginBottom: "0px" }}>
                      {this.state.page.name}
                    </h1>
                  </div>
                </Row>
              </Container>
            </header>
            {this.state.openDropdown && <SolutionsBlockPages />}
          </Container>

          {this.state.page.blocks.length ? (
            <Container
              style={{ marginBottom: "120px" }}
              fluid
              className="custom-container"
            >
              <Row>
                {this.state.page.blocks.map((block, i) => (
                  <Col xl="6" lg="6" md="6" sm="6" xs="12" key={i}>
                    <Link
                      to={`${window.location.pathname + "/" + block.alias}`}
                    >
                      <MainThumbnail
                        imgSrc={[
                          `${
                            process.env.NODE_ENV === "production"
                              ? "https://homechain.ai/s"
                              : "http://localhost:4000"
                          }/storage/pages`,
                          block.alias,
                          block.backgroundImage
                        ].join("/")}
                        name={block.name}
                      />
                    </Link>
                  </Col>
                ))}
              </Row>
            </Container>
          ) : (
            ""
          )}

          <Container fluid className="custom-container">
            <Row style={{ padding: "0px" }} className="page-description">
              <Col xl="12">
                <h3>{this.state.page.title}</h3>
                {this.state.page.paragraphs.map((p, i) => (
                  <p style={{ marginBottom: "30px" }} key={i}>{p}</p>
                ))}
              </Col>
            </Row>
          </Container>

          {this.state.page.additions.length ? (
            <Container fluid className="custom-container">
              <Row
                style={{ padding: "0px", marginTop: "0px" }}
                className="page-description"
              >
                <Col xl="12">
                  <h2 style={{ marginTop: "60px" }}>Дополняющие решения </h2>
                </Col>
              </Row>

              <Row
                style={{ padding: "0px", marginBottom: "120px" }}
                className="page-description"
              >
                {this.state.page.additions.map((addition, i) => (
                  <Col xl="3" lg="3" md="3" sm="6" xs="12" key={i}>
                    <Link to={`/services/${addition.link}`}>
                      <div
                        style={{ boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.18)" }}
                      >
                        <div className="services-thumbnail">
                          <div
                            style={{
                              backgroundImage: `url(${addition.imgSrc})`,
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "center center",
                              backgroundSize: "cover",
                              width: "100%",
                              height: "100%"
                            }}
                          />
                          <div>
                            <p
                              style={{
                                color: "#0F75BC",
                                fontSize: "13px",
                                marginBottom: "0px"
                              }}
                            >
                              {addition.name}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </Col>
                ))}
              </Row>
            </Container>
          ) : (
            ""
          )}
        </div>
      )
    );
  }
}

export default CategoryPageConstructor;
