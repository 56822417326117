import React from "react";
import { Container, Col } from "react-bootstrap";
import Slider from "react-slick";

import "../../Tabs/Control/control.css";

function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: "block"
            }}
            onClick={onClick}
        />
    );
}

function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: "block"
            }}
            onClick={onClick}
        />
    );
}

class YandexDevices extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            renderResponsive: null,

            yandex: [
                {
                    deviceName: "Смартфоны",
                    imgSrc: require("../../../images/Yandex/yandex-device-smarphone.png"),
                    text: "Управляйте устройствами в приложении Яндекс, а также с помощью голосового ассистента Алиса на любом Android или iOS смартфоне."
                },
                {
                    deviceName: "Планшеты",
                    imgSrc: require("../../../images/Yandex/yandex-device-tablet.png"),
                    text: "Установите приложение Яндекс с ассистентом Алиса на ваш iOS или Android планшет и управляйте устройствами."
                },
                {
                    deviceName: "Умные часы",
                    imgSrc: require("../../../images/Yandex/yandex-device-smart-watch.png"),
                    text: "Ваши устройства всегда под контролем на любых умных часах с поддержкой Алисы."
                },
                {
                    deviceName: "Яндекс Станция",
                    imgSrc: require("../../../images/Yandex/yandex-device-stantia.png"),
                    text: "Весь функционал Алисы в умной колонке от Яндекс. Включайте музыку, управляйте устройствами, создавайте напоминания."
                },
                {
                    deviceName: "Умные колонки",
                    imgSrc: require("../../../images/Yandex/yandex-device-speacker.png"),
                    text: "Не подходит Яндекс Станция? Любая умная колонка с поддержкой Алисы может полноценно заменить Яндекс Станцию."
                }
            ]
        };
    }

    render() {
        let settings = {
            infinite: false,
            autoplay: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1
                    }
                },

                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const renderYandex = this.state.yandex.map((item) => {
            return (
                <div key={item}>
                    <Col xl='3' lg='3' md='4' sm='6' xs='12' className='ecosystem-devices'>
                        <p className="ecosystem-devices-device-name">{item.deviceName}</p>
                        <div>
                            <img data-src={item.imgSrc} alt={item.alt} class='lazy' src='/images/image-preloader.png' />
                        </div>
                        <p className='control-hover-text'>{item.text}</p>
                    </Col>
                </div>
            );
        });

        return (
            <div>
                <Container fluid className='devices control-devices control-devices-bottom-space'>
                    <Slider {...settings}>{renderYandex}</Slider>
                </Container>
            </div>
        );
    }
}

export default YandexDevices;
