import React from "react";
import { Container, Col } from "react-bootstrap";
import Slider from "react-slick";

import "../../Tabs/Control/control.css";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block"
      }}
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block"
      }}
      onClick={onClick}
    />
  );
}

class GoogleDevices extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      renderResponsive: null,

      google: [
        {
          deviceName: "Смартфоны",
          imgSrc: require("../../../images/GoogleHome/smartphone.png"),
          alt: "google home умный дом на смартфоне",
          text:
            "Управляйте Google Home и голосовым Ассистентом с помощью любого Android или iOS смартфона. "
        },
        {
          deviceName: "Планшеты",
          imgSrc: require("../../../images/GoogleHome/tablet.png"),
          alt: "google home умный дом на планшете",
          text:
            "Голосовой Ассистент и управление Google Home на любом Android или iOS планшете. Доступен настенный монтаж в качестве центра управления."
        },

        {
          deviceName: "Умные часы",
          imgSrc: require("../../../images/GoogleHome/smart-watch.png"),
          alt: "google home умный дом на умных часах",
          text:
            "Оперативное управление и контроль на любых умных часах. Не пропустите ни одного уведомления. "
        },
        {
          deviceName: "Колонки",
          imgSrc: require("../../../images/GoogleHome/speaker.png"),
          alt: "google home умный дом на колонках",
          text:
            "Колонки с поддержкой Ассистента сочетают управление умным домом и медиастанцию. Попросите Ассистента закрыть шторы или включить любимый жанр музыки."
        },
        {
          deviceName: "Google Chromecast",
          imgSrc: require("../../../images/GoogleHome/chromecast.png"),
          alt: "google home умный дом на chromecast",
          text:
            "Транслируйте фильмы, сериалы, фотографии и веб-страницы  из Chrome, YouTube и Google Play на телевизор или проектор."
        }
      ]
    };
  }

  render() {
    let settings = {
      infinite: false,
      autoplay: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },

        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    const renderGoogle = this.state.google.map(item => {
      return (
        <div key={item}>
          <Col xl="3" lg="3" md="4" sm="6" xs="12" className="ecosystem-devices">
            <p className="ecosystem-devices-device-name">{item.deviceName}</p>
            <div>
              <img
                data-src={item.imgSrc}
                alt={item.alt}
                class="lazy"
                src="/images/image-preloader.png"
              />
            </div>
            <p className="control-hover-text">{item.text}</p>
          </Col>
        </div>
      );
    });

    return (
      <div>
        <Container fluid className="devices control-devices control-devices-bottom-space">
          <Slider {...settings}>{renderGoogle}</Slider>
        </Container>
      </div>
    );
  }
}

export default GoogleDevices;
