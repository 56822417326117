import React from "react";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";
import classNames from "classnames";

import { phoneHandler } from "./phoneValidation";

class FeedbackFormSmall extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: "",
      phone: "",
      loading: false,
      message: "",
      isFilled: false,
      worked: true,
      country: "",
      formHidden: false,
    };
  }

  async dataChange(event) {
    event.preventDefault();
    const ph = phoneHandler(event.target.value);
    await this.setState({
      [event.target.name]: ph.phone,
      isFilled: ph.isValid,
    });
  }

  // phoneHandler(event) {
  //   const phone = (this.state.phone);
  //   console.log(phone);
  //   this.setState({
  //     phone
  //   });
  // }

  postData(ev) {
    if (this.state.isFilled) {
      ev.preventDefault();
      this.setState({
        formHidden: true,
      });

      this.setState({
        worked: !this.isNotWorkingHours(),
      });

      const phone = this.state.phone.replace(/\D/g, "");

      const data = {
        phone,
      };
      axios
        .get("https://worldtimeapi.org/api/timezone/Europe/Moscow")
        .then((response) => {
          const now = new Date(response.data.datetime);

          axios
            .post(
              process.env.NODE_ENV === "production"
                ? `https://homechain.ai/s/api/fronthook/call`
                : `http://localhost:4000/api/fronthook/call`,
              data
            )
            .then((response) => {
              if (process.env.NODE_ENV === "production") {
                window.ym(54938986, "reachGoal", "small_tel");
              }
              this.setState({
                loading: false,
                sent: true,
                message: response.data,
                worked: !this.isNotWorkingHours(now),
              });
            })
            .catch((err) => {
              // console.log(err);
            });

          if (this.isNotWorkingHours(now)) {
            axios
              .post(
                process.env.NODE_ENV === "production"
                  ? `https://homechain.ai/s/api/fronthook/call-order`
                  : `http://localhost:4000/api/fronthook/call-order`,
                data
              )
              .then((res) => {
                console.log(res);
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            axios
              .get(`https://cb.zadarma.com/887be50f72/?n=${phone}`, {
                crossdomain: true,
                headers: { "Access-Control-Allow-Origin": "*" },
              })
              .then((response) => {
                console.log("data response from zadarma", response);
                this.setState({
                  loading: false,
                  sent: true,
                  message: response.data,
                });
              })
              .catch((err) => {
                this.setState({
                  loading: false,
                });
              });
          }
        });
    } else {
      ev.preventDefault();
    }
  }

  handleKeyDown() {
    if (this.state.isFilled && this.state.buttonDisabled) {
      this.setState({
        formHidden: true,
      });

      this.setState({
        worked: !this.isNotWorkingHours(),
      });

      const phone = this.state.phone.replace(/\D/g, "");

      this.setState({
        loading: true,
      });

      const data = {
        phone,
      };

      axios
        .post(
          process.env.NODE_ENV === "production"
            ? `https://homechain.ai/s/api/fronthook/call`
            : `http://localhost:4000/api/fronthook/call`,
          data
        )
        .then((response) => {
          this.setState({
            loading: false,
            sent: true,
            message: response.data,
          });
        })
        .catch((err) => {
          this.setState({
            loading: false,
          });
        });
      if (this.isNotWorkingHours()) {
        axios
          .post(
            process.env.NODE_ENV === "production"
              ? `https://homechain.ai/s/api/fronthook/call-order`
              : `http://localhost:4000/api/fronthook/call-order`,
            data
          )
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        axios
          .get(`https://cb.zadarma.com/887be50f72/?n=${phone}`)
          .then((response) => {
            console.log("data response from zadarma", response);
            this.setState({
              loading: false,
              sent: true,
              message: response.data,
            });
          })
          .catch((err) => {
            console.log(err);
            this.setState({
              loading: false,
            });
          });
      }
    }
  }

  isNotWorkingHours(now = new Date()) {
    const weekend = [0, 6];
    const workHours = [11, 12, 13, 14, 15, 16, 17, 18, 19];

    return (
      weekend.includes(now.getDay()) ||
      !workHours.includes(now.getUTCHours() + 3)
    );
  }

  loadOrShowMsg() {
    if (this.state.loading) {
      return <p>Loading...</p>;
    } else {
      return <p>{this.state.message}</p>;
    }
  }

  render() {
    return (
      <div>
        <Container fluid id="feedback">
          <Row>
            <Col className="feedback-small" xl="12" lg="12">
              <Container>
                <div>
                  {this.props?.updatedTitle ? (
                    <p>
                      Оставьте номер телефона и{" "}
                      <strong>запишитесь на бесплатное</strong> посещение нашего
                      шоурума.
                    </p>
                  ) : (
                    <p>
                      Оставьте номер телефона и
                      <strong> получите консультацию </strong>
                      инженера по вашему объекту.
                    </p>
                  )}
                </div>
                <div className="feedback-small-buttons">
                  {!this.state.formHidden && (
                    <form onSubmit={this.postData.bind(this)}>
                      <div className="floating-label-small">
                        {/* <input

                          style={{
                            backgroundColor: this.state.isFilled
                              ? "#f7f7f7"
                              : ""
                          }}
                          autoComplete="off"
                          type="tel"
                          name="phone"
                          placeholder="Введите ваш телефон&#42;"
                          maxLength="14"
                          value={this.state.phone}
                          onFocus={event => {
                            if (!event.target.value.length)
                              event.target.value = this.patterns[
                                this.state.country
                              ]
                                ? this.patterns[this.state.country]
                                : "+";
                          }}
                          onBlur={event => {
                            if (event.target.value.length === 1) {
                              event.target.value = "";
                              this.setState({
                                phone: "",
                                activeButton: true
                              });
                            }
                          }}
                          onKeyPress={event => {
                            if (!/\d/.test(event.key)) event.preventDefault();
                          }}
                          onChange={this.dataChange.bind(this)}
                          required
                        /> */}

                        <input
                          className={classNames({
                            ["feedback-form-small-phone-input-filled"]:
                              this.state.isFilled,
                          })}
                          onKeyDown={this.handleKeyDown.bind(this)}
                          autoComplete="off"
                          type="text"
                          name="phone"
                          placeholder="Введите ваш телефон&#42;"
                          value={this.state.phone}
                          onFocus={(event) => {
                            if (this.state.phone) {
                              event.target.value = `${this.state.phone}`;
                            } else {
                              event.target.value = `+`;
                            }
                          }}
                          onBlur={(event) => {
                            if (event.target.value.length === 1) {
                              event.target.value = "";
                              this.setState({
                                phone: "",
                                activeButton: true,
                              });
                            }
                          }}
                          onChange={this.dataChange.bind(this)}
                          // onInput={this.phoneHandler.bind(this)}
                          required
                        />
                        <label className="small-floating" htmlFor="phone">
                          Телефон&#42;
                        </label>
                      </div>

                      {!this.state.phone && (
                        <button
                          type="submit"
                          disabled={
                            !this.state.formHidden &&
                            !this.state.isFilled &&
                            false
                          }
                          className={classNames({
                            ["feedback-form-small-submit-button"]:
                              !this.state.phone,
                          })}
                        >
                          Отправить
                        </button>
                      )}
                      {this.state.phone && (
                        <button
                          type="submit"
                          disabled={
                            this.state.formHidden && this.state.isFilled && true
                          }
                          className={classNames(
                            {
                              ["feedback-form-small-submit-button"]:
                                this.state.phone,
                            },
                            { ["filled"]: this.state.isFilled }
                          )}
                        >
                          Отправить
                        </button>
                      )}
                    </form>
                  )}
                  {this.state.sent && (
                    <div>
                      {this.state.worked && (
                        <h3>
                          Спасибо, заявка принята! <br /> Мы перезвоним в
                          ближайшее время.
                        </h3>
                      )}

                      {!this.state.worked && (
                        <h3>
                          Спасибо, заявка принята! <br /> Мы перезвоним в
                          рабочее время.
                        </h3>
                      )}
                    </div>
                  )}
                </div>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default FeedbackFormSmall;
