import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import ChevronLeft from "../../../images/chevron-left.svg";
import ChevronRight from "../../../images/chevron-right.svg";
import ChevronLeftActive from "../../../images/chevron-left-active.svg";
import ChevronRightActive from "../../../images/chevron-right-active.svg";
import "../Control/control.css";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        right: "0%"
      }}
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        left: "0%"
      }}
      onClick={onClick}
    />
  );
}

class StagesBlock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: window.innerWidth <= 991,
      isRenderBlock1: true,
      isRenderBlock2: false,
      isRenderBlock3: false,
      isRenderBlock4: false,
      isRenderBlock5: false,
      isRenderBlock6: false,
      isRenderBlock7: false,
      isRenderBlock8: false,
      blocks: [
        {
          id: "1",
          name: "знакомство"
        },
        {
          id: "2",
          name: "выезд на объект"
        },
        {
          id: "3",
          name: "Тех.задание"
        },
        {
          id: "4",
          name: "коммерческое предложение"
        },
        {
          id: "5",
          name: "договор"
        },
        {
          id: "6",
          name: "концепт и бюджет"
        },
        {
          id: "7",
          name: "сдача документации"
        },
        {
          id: "8",
          name: "авторский надзор"
        }
      ],
      meeting: [
        {
          id: "1",
          name: "знакомство",
          prev: "",
          next: "выезд на объект",
          description: "Знакомство с потребностями."
        }
      ],
      proposition: [
        {
          id: "2",
          name: "выезд на объект",
          prev: "знакомство",
          next: "Тех.задание",
          description: "Знакомство с объектом."
        }
      ],
      project: [
        {
          id: "3",
          name: "Тех.задание",
          prev: "выезд на объект",
          next: "коммерческое предложение",
          description: "Составление технического задания."
        }
      ],
      inbox: [
        {
          id: "4",
          name: "коммерческое предложение",
          prev: "Тех.задание",
          next: "договор",
          description: "Коммерческое предложение."
        }
      ],
      builtin: [
        {
          id: "5",
          name: "договор",
          prev: "коммерческое предложение",
          next: "концепт и бюджет",
          description: "Заключение договора."
        }
      ],
      setting: [
        {
          id: "6",
          name: "концепт и бюджет",
          prev: "договор",
          next: "сдача документации",
          description: "Создание и согласование концепта и бюджета."
        }
      ],
      start: [
        {
          id: "7",
          name: "сдача документации",
          prev: "концепт и бюджет",
          next: "авторский надзор",
          description: "Сдача рабочей документации."
        }
      ],
      test: [
        {
          id: "8",
          name: "авторский надзор",
          prev: "сдача документации",
          next: "",
          description: "Авторский надзор."
        }
      ]
    };
  }

  renderBlock1 = (state) => {
      this.setState(this.toggleRenderBlock1);
  };

  renderBlock2 = (state) => {
      this.setState(this.toggleRenderBlock2);
  };

  renderBlock3 = (state) => {
      this.setState(this.toggleRenderBlock3);
  };

  renderBlock4 = (state) => {
      this.setState(this.toggleRenderBlock4);
  };

  renderBlock5 = (state) => {
      this.setState(this.toggleRenderBlock5);
  };

  renderBlock6 = (state) => {
      this.setState(this.toggleRenderBlock6);
  };

  renderBlock7 = (state) => {
      this.setState(this.toggleRenderBlock7);
  };

  renderBlock8 = (state) => {
      this.setState(this.toggleRenderBlock8);
  };

  toggleRenderBlock1(state) {
    return {
      isRenderBlock1: true,
      isRenderBlock2: false,
      isRenderBlock3: false,
      isRenderBlock4: false,
      isRenderBlock5: false,
      isRenderBlock6: false,
      isRenderBlock7: false,
      isRenderBlock8: false
    };
  }

  toggleRenderBlock2(state) {
    return {
      isRenderBlock1: false,
      isRenderBlock2: true,
      isRenderBlock3: false,
      isRenderBlock4: false,
      isRenderBlock5: false,
      isRenderBlock6: false,
      isRenderBlock7: false,
      isRenderBlock8: false
    };
  }
  toggleRenderBlock3(state) {
    return {
      isRenderBlock1: false,
      isRenderBlock2: false,
      isRenderBlock3: true,
      isRenderBlock4: false,
      isRenderBlock5: false,
      isRenderBlock6: false,
      isRenderBlock7: false,
      isRenderBlock8: false
    };
  }
  toggleRenderBlock4(state) {
    return {
      isRenderBlock1: false,
      isRenderBlock2: false,
      isRenderBlock3: false,
      isRenderBlock4: true,
      isRenderBlock5: false,
      isRenderBlock6: false,
      isRenderBlock7: false,
      isRenderBlock8: false
    };
  }
  toggleRenderBlock5(state) {
    return {
      isRenderBlock1: false,
      isRenderBlock2: false,
      isRenderBlock3: false,
      isRenderBlock4: false,
      isRenderBlock5: true,
      isRenderBlock6: false,
      isRenderBlock7: false,
      isRenderBlock8: false
    };
  }
  toggleRenderBlock6(state) {
    return {
      isRenderBlock1: false,
      isRenderBlock2: false,
      isRenderBlock3: false,
      isRenderBlock4: false,
      isRenderBlock5: false,
      isRenderBlock6: true,
      isRenderBlock7: false,
      isRenderBlock8: false
    };
  }
  toggleRenderBlock7(state) {
    return {
      isRenderBlock1: false,
      isRenderBlock2: false,
      isRenderBlock3: false,
      isRenderBlock4: false,
      isRenderBlock5: false,
      isRenderBlock6: false,
      isRenderBlock7: true,
      isRenderBlock8: false
    };
  }
  toggleRenderBlock8(state) {
    return {
      isRenderBlock1: false,
      isRenderBlock2: false,
      isRenderBlock3: false,
      isRenderBlock4: false,
      isRenderBlock5: false,
      isRenderBlock6: false,
      isRenderBlock7: false,
      isRenderBlock8: true
    };
  }

  render() {
    let settings = {
      infinite: false,
      autoplay: false,
      arrows: false,
      slidesToScroll: 1,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            arrows: true,
            slidesToShow: 6,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 768,
          settings: {
            arrows: true,
            slidesToShow: 5,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 576,
          settings: {
            arrows: true,
            slidesToShow: 4,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 420,
          settings: {
            arrows: true,
            slidesToShow: 3,
            slidesToScroll: 1
          }
        }
      ]
    };
    const renderBlock1 = this.state.meeting.map(item => {
      return (
        <Col
          key={item}
          xl="12"
          lg="12"
          md="12"
          sm="12"
          xs="12"
          className="howwework-description"
        >
          <div className="howwework-description-item">
            <button disabled className="arrow stages-button-active-left">
              <img src={ChevronLeft} alt="" />
            </button>
            <h6 className="h6-left">{item.prev}</h6>
            <h6 className="h6-right">{item.next}</h6>
            <button
              className="arrow active stages-button-active-right"
              onClick={this.renderBlock2}
            >
              <img src={ChevronRightActive} alt="" />
            </button>
            <p>{item.description}</p>
          </div>
        </Col>
      );
    });

    const renderBlock2 = this.state.proposition.map(item => {
      return (
        <Col
          key={item}
          xl="12"
          lg="12"
          md="12"
          sm="12"
          xs="12"
          className="howwework-description"
        >
          <div className="howwework-description-item">
            <button
              className="arrow stages-button-active-left"
              onClick={this.renderBlock1}
            >
              <img src={ChevronLeftActive} alt="" />
            </button>
            <h6 className="h6-left">{item.prev}</h6>
            <h6 className="h6-right">{item.next}</h6>
            <button
              className="arrow active stages-button-active-right"
              onClick={this.renderBlock3}
            >
              <img src={ChevronRightActive} alt="" />
            </button>
            <p>{item.description}</p>
          </div>
        </Col>
      );
    });

    const renderBlock3 = this.state.project.map(item => {
      return (
        <Col
          key={item}
          xl="12"
          lg="12"
          md="12"
          sm="12"
          xs="12"
          className="howwework-description"
        >
          <div className="howwework-description-item">
            <button
              className="arrow stages-button-active-left"
              onClick={this.renderBlock2}
            >
              <img src={ChevronLeftActive} alt="" />
            </button>
            <h6 className="h6-left">{item.prev}</h6>
            <h6 className="h6-right">{item.next}</h6>
            <button
              className="arrow active stages-button-active-right"
              onClick={this.renderBlock4}
            >
              <img src={ChevronRightActive} alt="" />
            </button>
            <p>{item.description}</p>
          </div>
        </Col>
      );
    });

    const renderBlock4 = this.state.inbox.map(item => {
      return (
        <Col
          key={item}
          xl="12"
          lg="12"
          md="12"
          sm="12"
          xs="12"
          className="howwework-description"
        >
          <div className="howwework-description-item">
            <button
              className="arrow stages-button-active-left"
              onClick={this.renderBlock3}
            >
              <img src={ChevronLeftActive} alt="" />
            </button>
            <h6 className="h6-left">{item.prev}</h6>
            <h6 className="h6-right">{item.next}</h6>
            <button
              className="arrow active stages-button-active-right"
              onClick={this.renderBlock5}
            >
              <img src={ChevronRightActive} alt="" />
            </button>
            <p>{item.description}</p>
          </div>
        </Col>
      );
    });

    const renderBlock5 = this.state.builtin.map(item => {
      return (
        <Col
          key={item}
          xl="12"
          lg="12"
          md="12"
          sm="12"
          xs="12"
          className="howwework-description"
        >
          <div className="howwework-description-item">
            <button
              className="arrow stages-button-active-left"
              onClick={this.renderBlock4}
            >
              <img src={ChevronLeftActive} alt="" />
            </button>
            <h6 className="h6-left">{item.prev}</h6>
            <h6 className="h6-right">{item.next}</h6>
            <button
              className="arrow active stages-button-active-right"
              onClick={this.renderBlock6}
            >
              <img src={ChevronRightActive} alt="" />
            </button>
            <p>{item.description}</p>
          </div>
        </Col>
      );
    });

    const renderBlock6 = this.state.setting.map(item => {
      return (
        <Col
          key={item}
          xl="12"
          lg="12"
          md="12"
          sm="12"
          xs="12"
          className="howwework-description"
        >
          <div className="howwework-description-item">
            <button
              className="arrow stages-button-active-left"
              onClick={this.renderBlock5}
            >
              <img src={ChevronLeftActive} alt="" />
            </button>
            <h6 className="h6-left">{item.prev}</h6>
            <h6 className="h6-right">{item.next}</h6>
            <button
              className="arrow active stages-button-active-right"
              onClick={this.renderBlock7}
            >
              <img src={ChevronRightActive} alt="" />
            </button>
            <p>{item.description}</p>
          </div>
        </Col>
      );
    });

    const renderBlock7 = this.state.start.map(item => {
      return (
        <Col
          key={item}
          xl="12"
          lg="12"
          md="12"
          sm="12"
          xs="12"
          className="howwework-description"
        >
          <div className="howwework-description-item">
            <button
              className="arrow stages-button-active-left"
              onClick={this.renderBlock6}
            >
              <img src={ChevronLeftActive} alt="" />
            </button>
            <h6 className="h6-left">{item.prev}</h6>
            <h6 className="h6-right">{item.next}</h6>
            <button
              className="arrow active stages-button-active-right"
              onClick={this.renderBlock8}
            >
              <img src={ChevronRightActive} alt="" />
            </button>
            <p>{item.description}</p>
          </div>
        </Col>
      );
    });

    const renderBlock8 = this.state.test.map(item => {
      return (
        <Col
          key={item}
          xl="12"
          lg="12"
          md="12"
          sm="12"
          xs="12"
          className="howwework-description"
        >
          <div className="howwework-description-item">
            <button
              className="arrow stages-button-active-left"
              onClick={this.renderBlock7}
            >
              <img src={ChevronLeftActive} alt="" />
            </button>
            <h6 className="h6-left">{item.prev}</h6>
            <h6 className="h6-right">{item.next}</h6>
            <button
              className="arrow active stages-button-active-right"
              onClick={this.renderBlock8}
            >
              <img src={ChevronRight} alt="" />
            </button>
            <p>{item.description}</p>
          </div>
        </Col>
      );
    });

    return (
      <div>
        <Container fluid className="howwework-block">
          <div className={"tabs-render-ssr"}>
            <div className={"tabs-render-ssr"}>
              <Slider {...settings}>
                <div>
                  <button
                    onClick={this.renderBlock1}
                    style={
                      this.state.isRenderBlock1
                        ? { borderBottom: "1px solid #0F75BC" }
                        : { borderBottom: "1px solid #d1d1d1" }
                    }
                  >
                    <h3
                      style={
                        this.state.isRenderBlock1
                          ? { color: "#0F75BC" }
                          : { color: "#000" }
                      }
                    >
                      {this.state.blocks[0].id}
                    </h3>
                    <p
                      style={
                        this.state.isRenderBlock1
                          ? { color: "#0F75BC" }
                          : { color: "#000" }
                      }
                    >
                      {this.state.blocks[0].name}
                    </p>
                  </button>
                </div>
                <div>
                  <button
                    onClick={this.renderBlock2}
                    style={
                      this.state.isRenderBlock2
                        ? { borderBottom: "1px solid #0F75BC" }
                        : { borderBottom: "1px solid #d1d1d1" }
                    }
                  >
                    <h3
                      style={
                        this.state.isRenderBlock2
                          ? { color: "#0F75BC" }
                          : { color: "#000" }
                      }
                    >
                      {this.state.blocks[1].id}
                    </h3>
                    <p
                      style={
                        this.state.isRenderBlock2
                          ? { color: "#0F75BC" }
                          : { color: "#000" }
                      }
                    >
                      {this.state.blocks[1].name}
                    </p>
                  </button>
                </div>
                <div>
                  <button
                    onClick={this.renderBlock3}
                    style={
                      this.state.isRenderBlock3
                        ? { borderBottom: "1px solid #0F75BC" }
                        : { borderBottom: "1px solid #d1d1d1" }
                    }
                  >
                    <h3
                      style={
                        this.state.isRenderBlock3
                          ? { color: "#0F75BC" }
                          : { color: "#000" }
                      }
                    >
                      {this.state.blocks[2].id}
                    </h3>
                    <p
                      style={
                        this.state.isRenderBlock3
                          ? { color: "#0F75BC" }
                          : { color: "#000" }
                      }
                    >
                      {this.state.blocks[2].name}
                    </p>
                  </button>
                </div>
                <div>
                  <button
                    onClick={this.renderBlock4}
                    style={
                      this.state.isRenderBlock4
                        ? { borderBottom: "1px solid #0F75BC" }
                        : { borderBottom: "1px solid #d1d1d1" }
                    }
                  >
                    <h3
                      style={
                        this.state.isRenderBlock4
                          ? { color: "#0F75BC" }
                          : { color: "#000" }
                      }
                    >
                      {this.state.blocks[3].id}
                    </h3>
                    <p
                      style={
                        this.state.isRenderBlock4
                          ? { color: "#0F75BC" }
                          : { color: "#000" }
                      }
                    >
                      {this.state.blocks[3].name}
                    </p>
                  </button>
                </div>
                <div>
                  <button
                    onClick={this.renderBlock5}
                    style={
                      this.state.isRenderBlock5
                        ? { borderBottom: "1px solid #0F75BC" }
                        : { borderBottom: "1px solid #d1d1d1" }
                    }
                  >
                    <h3
                      style={
                        this.state.isRenderBlock5
                          ? { color: "#0F75BC" }
                          : { color: "#000" }
                      }
                    >
                      {this.state.blocks[4].id}
                    </h3>
                    <p
                      style={
                        this.state.isRenderBlock5
                          ? { color: "#0F75BC" }
                          : { color: "#000" }
                      }
                    >
                      {this.state.blocks[4].name}
                    </p>
                  </button>
                </div>
                <div>
                  <button
                    onClick={this.renderBlock6}
                    style={
                      this.state.isRenderBlock6
                        ? { borderBottom: "1px solid #0F75BC" }
                        : { borderBottom: "1px solid #d1d1d1" }
                    }
                  >
                    <h3
                      style={
                        this.state.isRenderBlock6
                          ? { color: "#0F75BC" }
                          : { color: "#000" }
                      }
                    >
                      {this.state.blocks[5].id}
                    </h3>
                    <p
                      style={
                        this.state.isRenderBlock6
                          ? { color: "#0F75BC" }
                          : { color: "#000" }
                      }
                    >
                      {this.state.blocks[5].name}
                    </p>
                  </button>
                </div>
                <div>
                  <button
                    onClick={this.renderBlock7}
                    style={
                      this.state.isRenderBlock7
                        ? { borderBottom: "1px solid #0F75BC" }
                        : { borderBottom: "1px solid #d1d1d1" }
                    }
                  >
                    <h3
                      style={
                        this.state.isRenderBlock7
                          ? { color: "#0F75BC" }
                          : { color: "#000" }
                      }
                    >
                      {this.state.blocks[6].id}
                    </h3>
                    <p
                      style={
                        this.state.isRenderBlock7
                          ? { color: "#0F75BC" }
                          : { color: "#000" }
                      }
                    >
                      {this.state.blocks[6].name}
                    </p>
                  </button>
                </div>
                <div>
                  <button
                    onClick={this.renderBlock8}
                    style={
                      this.state.isRenderBlock8
                        ? { borderBottom: "1px solid #0F75BC" }
                        : { borderBottom: "1px solid #d1d1d1" }
                    }
                  >
                    <h3
                      style={
                        this.state.isRenderBlock8
                          ? { color: "#0F75BC" }
                          : { color: "#000" }
                      }
                    >
                      {this.state.blocks[7].id}
                    </h3>
                    <p
                      style={
                        this.state.isRenderBlock8
                          ? { color: "#0F75BC" }
                          : { color: "#000" }
                      }
                    >
                      {this.state.blocks[7].name}
                    </p>
                  </button>
                </div>
              </Slider>
            </div>
          </div>
        </Container>

        <Container fluid>
          <Row>{this.state.isRenderBlock1 && renderBlock1}</Row>
          <Row>{this.state.isRenderBlock2 && renderBlock2}</Row>
          <Row>{this.state.isRenderBlock3 && renderBlock3}</Row>
          <Row>{this.state.isRenderBlock4 && renderBlock4}</Row>
          <Row>{this.state.isRenderBlock5 && renderBlock5}</Row>
          <Row>{this.state.isRenderBlock6 && renderBlock6}</Row>
          <Row>{this.state.isRenderBlock7 && renderBlock7}</Row>
          <Row>{this.state.isRenderBlock8 && renderBlock8}</Row>
        </Container>
      </div>
    );
  }
}

export default StagesBlock;
