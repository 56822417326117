import React from "react";

class Electric extends React.Component {
  render() {
    return (
      <div>
        <svg
          width="35"
          height="45"
          viewBox="0 0 35 45"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="35" height="45" fill="#E5E5E5" />
          <g clipPath="url(#clip0)">
            <rect
              width="1600"
              height="5598"
              transform="translate(-419 -2072)"
              fill="white"
            />
            <rect
              x="-419"
              y="-211"
              width="1600"
              height="690.761"
              fill="#F7F7F7"
            />
            <g filter="url(#filter0_d)">
              <rect
                x="-47"
                y="-22.7811"
                width="129"
                height="115"
                rx="2"
                fill="white"
              />
            </g>

            <path
              d="M24.8428 11.4546C22.7232 9.41586 19.939 8.36503 16.9931 8.47281C11.3438 8.69734 6.73629 13.4755 6.71833 19.1338C6.70935 21.6396 7.58953 24.0825 9.1972 26.0045C11.5144 28.7888 12.7898 32.3005 12.7898 35.9021C12.7898 35.9021 12.5473 37.5547 13.7238 37.5547H21.1245C22.328 37.5547 22.0586 35.9021 22.0586 35.9021C22.0586 32.3814 23.3519 28.8966 25.8039 25.8249C27.3217 23.9119 28.13 21.6126 28.13 19.1697C28.13 16.2328 26.9625 13.4844 24.8428 11.4546ZM24.3399 24.6573C21.6724 27.9984 20.2444 31.8065 20.1905 35.6865H14.6579C14.604 31.7257 13.185 27.8727 10.6342 24.81C9.31396 23.2203 8.58647 21.2085 8.59545 19.1427C8.61341 14.4724 12.4126 10.5296 17.0739 10.3409C19.5079 10.2511 21.8071 11.1223 23.5495 12.8019C25.3009 14.4814 26.2619 16.7447 26.2619 19.1697C26.2529 21.1905 25.5973 23.0856 24.3399 24.6573Z"
              fill="#818181"
              stroke="#818181"
            />

            <path
              d="M29.7464 5.32032C29.3781 4.95208 28.7854 4.95208 28.4261 5.32032L26.1448 7.6016C25.7766 7.96984 25.7766 8.56262 26.1448 8.92187C26.5131 9.28113 27.1058 9.29011 27.4651 8.92187L29.7464 6.64059C30.1146 6.28133 30.1146 5.68856 29.7464 5.32032Z"
              fill="#818181"
              stroke="#818181"
            />

            <path
              d="M21.16 38.83H13.6875C13.1666 38.83 12.7534 39.2521 12.7534 39.7641C12.7534 40.285 13.1755 40.6982 13.6875 40.6982H21.16C21.681 40.6982 22.0941 40.276 22.0941 39.7641C22.0941 39.2521 21.672 38.83 21.16 38.83Z"
              fill="#818181"
              stroke="#818181"
            />

            <path
              d="M19.7503 42.3507H15.1069C14.586 42.3507 14.1729 42.7729 14.1729 43.2848C14.1729 43.8057 14.595 44.2189 15.1069 44.2189H19.7503C20.2712 44.2189 20.6844 43.7967 20.6844 43.2848C20.6844 42.7729 20.2623 42.3507 19.7503 42.3507Z"
              fill="#818181"
              stroke="#818181"
            />

            <path
              d="M17.4238 5.31134C17.9447 5.31134 18.3579 4.88921 18.3579 4.37727V1.15294C18.3579 0.632018 17.9358 0.218872 17.4238 0.218872C16.9119 0.218872 16.4897 0.640999 16.4897 1.15294V4.37727C16.4897 4.89819 16.9029 5.31134 17.4238 5.31134Z"
              fill="#818181"
              stroke="#818181"
            />

            <path
              d="M8.70299 8.93086C9.07122 8.56262 9.07122 7.96985 8.70299 7.61059L6.4217 5.32931C6.05347 4.96107 5.46069 4.96107 5.10143 5.32931C4.74218 5.69755 4.7332 6.29032 5.10143 6.64958L7.38272 8.93086C7.75095 9.29012 8.34373 9.29012 8.70299 8.93086Z"
              fill="#818181"
              stroke="#818181"
            />

            <path
              d="M33.9138 16.7088H30.6894C30.1685 16.7088 29.7554 17.1309 29.7554 17.6428C29.7554 18.1548 30.1775 18.5769 30.6894 18.5769H33.9138C34.4347 18.5769 34.8478 18.1548 34.8478 17.6428C34.8478 17.1309 34.4257 16.7088 33.9138 16.7088Z"
              fill="#818181"
              stroke="#818181"
            />

            <path
              d="M4.1584 16.7088H0.934068C0.413146 16.7088 0 17.1309 0 17.6428C0 18.1548 0.422127 18.5769 0.934068 18.5769H4.1584C4.67932 18.5769 5.09247 18.1548 5.09247 17.6428C5.09247 17.1309 4.67932 16.7088 4.1584 16.7088Z"
              fill="#818181"
              stroke="#818181"
            />
          </g>
          <defs>
            <filter
              id="filter0_d"
              x="-52"
              y="-27.7811"
              width="143"
              height="129"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              />
              <feOffset dx="2" dy="2" />
              <feGaussianBlur stdDeviation="3.5" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow"
                result="shape"
              />
            </filter>
            <clipPath id="clip0">
              <rect
                width="1600"
                height="5598"
                fill="white"
                transform="translate(-419 -2072)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
    );
  }
}

export default Electric;
