import React from "react";

import ScrollUp from "react-scroll-up";
import ChevronUp from "../../images/scroll-up.svg";

class ScrollTop extends React.Component {
  render() {
    return (
      <ScrollUp
        style={{ bottom: "120px", zIndex: "500" }}
        showUnder={100}
        duration={900}
      >
        <div className="scroll-top">
          <img src={ChevronUp} alt="" />
        </div>
      </ScrollUp>
    );
  }
}

export default ScrollTop;
