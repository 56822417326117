import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";
import FeedbackFormSmall from "../FeedbackForm/FeedbackFormSmall";
import FeedbackFormBig from "../FeedbackForm/FeedbackFormBig";
import Navbar from "../Navbar/Navbar";
import ServicesDropdownPages from "../Services/ServicesDropdown/ServicesDropdownPages";

class Support extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      style: "borderTop: 2px solid",
      buttonOrder: false,
      activeServices: true,
      isSolutionsHovering: false,
      isServicesHovering: false,
      isMobile: window.innerWidth <= 576
    };
  }

  uppendTitle() {
    document.querySelector("head title").innerHTML =
      "Консультации | Умный дом в Москве | Homeсhain";
  }

  uppendMeta() {
    let description = document.querySelector("meta[name=description]");
    let keywords = document.querySelector("meta[name=keywords]");
    if (description) {
      description.content =
        'Консультации. Умный дом в Москве "под ключ" - проектирование, комплектация, монтаж, пусконаладка. Homeсhain +7 (499) 113 10 10.';
    } else {
      description = document.createElement("meta");
      description.name = "description";
      description.content =
        'Консультации. Умный дом в Москве "под ключ" - проектирование, комплектация, монтаж, пусконаладка. Homeсhain +7 (499) 113 10 10.';
      document.querySelector("head").appendChild(description);
    }

    if (keywords) {
      keywords.content = "умный дом информация";
    } else {
      keywords = document.createElement("meta");
      keywords.name = "keywords";
      keywords.content = "умный дом информация";
      document.querySelector("head").appendChild(keywords);
    }
  }

  servicesHover(state) {
    this.setState(this.toggleServicesHover);
  }

  toggleDropdown(state) {
    return {
      isServicesHovering: false,
      isSolutionsHovering: false
    };
  }

  toggleServicesHover(state) {
    return {
      isServicesHovering: !state.isServicesHovering,
      isSolutionsHovering: false
    };
  }

  render() {
    return (
      <div>
        {this.uppendTitle()}
        {this.uppendMeta()}
        <Navbar activeServices={this.state.activeServices} />
        <Container
          fluid
          className="page"
          style={{
            backgroundImage: `url(${require("../../images/Services/Support/support-bg.png")})`
          }}
        >
          <header>
            <Container fluid>
              <Row className="page-row custom-container">
                {!this.state.isMobile && (
                  <div className="page-nav">
                    <ul>
                      <li>
                        <Link to="/">Главная</Link>
                      </li>
                      <img src={require("../../images/arrow.svg")} alt="" />
                      <li onClick={this.servicesHover.bind(this)}>
                        <HashLink>Услуги</HashLink>
                      </li>
                      <img src={require("../../images/arrow.svg")} alt="" />
                      <li style={{ color: "#181818" }}>Консультации</li>
                    </ul>
                  </div>
                )}

                {this.state.isMobile && (
                  <div className="page-nav">
                    <ul>
                      <img
                        style={{ width: "6px" }}
                        src={require("../../images/arrow-left.svg")}
                        alt=""
                      />
                      <li onClick={this.servicesHover.bind(this)}>
                        <HashLink>Услуги</HashLink>
                      </li>
                    </ul>
                  </div>
                )}

                <div className="page-header">
                  <h1>Консультации</h1>
                  <HashLink smooth to="#feedback-big">
                    <button className="hover-button">Оставить заявку</button>
                  </HashLink>
                </div>
              </Row>
              {this.state.isServicesHovering && (
                <ServicesDropdownPages
                  handler={this.servicesHover.bind(this)}
                />
              )}
            </Container>
          </header>
        </Container>
        <Container fluid className="custom-container">
          <div className="ecosystem-description">
            <Row className="ecosystem-row">
              <Col xl="12" lg="12" md="12">
                <h3>Понимание современных систем </h3>
                <p>
                  <img
                    data-src={require("../../images/Services/Support/support-img.png")}
                    alt="умный дом информация от специалиста"
                    class="lazy"
                    src="/images/image-preloader.png"
                  />
                  Консультация квалифицированного специалиста­ ‒ эффективный
                  способ узнать полную и объективную информацию про умный дом.
                  Знания современных перспективных решений и понимание
                  функциональности автоматизированных систем позволяют правильно
                  спроектировать и реализовать проекты, полностью
                  удовлетворяющие требования заказчиков. Незнание нюансов
                  современных технологий, предназначенных для повышения комфорта
                  и безопасности дома, квартиры или офиса, повышает вероятность
                  неправильного понимания возможностей и порядка реализации и
                  использования незнакомых инженерных систем.
                </p>
              </Col>
            </Row>
          </div>
          <Row className="page-description">
            <Col xl="12">
              <h3>Консультации без договора</h3>
              <p>
                Специалисты homechain готовы помочь разобраться в инновационных
                разработках, чтобы клиенты могли ориентироваться в современных
                технологиях и получать осязаемые преимущества от различных
                нововведений. Достоверная информация про умный дом, полученная в
                ходе консультаций со специалистами, позволит понять, что вы
                хотите получить от автоматизированной системы и как грамотно и
                эффективно использовать функционал электронного оборудования на
                объекте.
              </p>
              <p>
                Большинство инженерных организаций начинают взаимодействие с
                клиентом только после заключения договора и выполнения
                предоплаты услуг. Такой подход является системной ошибкой, ведь
                заказчик не знаком с функциональностью, и не понимает тонкостей
                и особенностей работы автоматики умного дома, поэтому не сможет
                грамотно сформулировать требования (пожелания). Мы готовы на
                первом этапе сотрудничества проконсультировать потенциальных
                заказчиков и предоставить полную информацию без каких-либо
                договорных обязательств.
              </p>
            </Col>
          </Row>
        </Container>
        <FeedbackFormSmall />

        <Container fluid className="custom-container">
          <div className="ecosystem-description container-space-top-50">
            <Row className="ecosystem-row">
              <Col xl="12" lg="12" md="12">
                <h3>Важная информация для принятия решения</h3>
                <p>
                  <img
                    data-src={require("../../images/Services/Support/support-img-2.png")}
                    alt="умный дом -  информация от инженеров"
                    class="lazy"
                    src="/images/image-preloader.png"
                  />
                  Для эффективного проектирования и монтажа системы заказчик
                  должен принимать решение после получения исчерпывающей
                  информации о возможностях оборудования, особенностях монтажных
                  операций и ознакомления с примерами работ на других объектах,
                  тем самым оценив уровень предоставляемых услуг. Кроме
                  получения ответов на вопросы, консультация позволяет клиентам
                  определиться с выбором экосистемы и решить спорные моменты
                  относительно расположения и автоматизации инженерных систем.
                  <span />
                  Мы ценим время заказчиков и стараемся максимально доступно
                  информировать о последних достижениях технологий. Наши
                  инженеры пояснят все возможности, конструктивные особенности и
                  преимущества автоматизированных систем, а также предоставят
                  любую другую информацию про умный дом, которая поможет
                  заказчикам принять правильное решение.
                </p>
              </Col>
            </Row>
          </div>
        </Container>
        <FeedbackFormBig bigForm={true} />
      </div>
    );
  }
}

export default Support;
