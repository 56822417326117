import React from "react";
import { Container, Row } from "react-bootstrap";

class SolutionsHeader extends React.Component {
  render() {
    return (
      <Container
        fluid
        className="solutions-block solutions-title-wrapper"
      >
        <Row>
          <h3>Решения</h3>
        </Row>
      </Container>
    );
  }
}

export default SolutionsHeader;
