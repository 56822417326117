import React from "react";
import { Link } from "react-router-dom";
import { Col } from "react-bootstrap";
import Slider from "react-slick";
import classNames from "classnames";

import "./control.css";

function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: "block"
            }}
            onClick={onClick}
        />
    );
}

function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: "block"
            }}
            onClick={onClick}
        />
    );
}

class ControlBlock extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            renderResponsive: null,
            isRenderLoxone: true,
            isRenderApple: false,
            isRenderGoogle: false,
            isRenderYandex: false,
            isRenderAmazon: false,
            isRenderIndividual: false,
            blocks: [
                {
                    pageSrc: "/solutions/ecosystemi/loxone",
                    id: "Loxone",
                    imgSrc: require("../../../images/Loxone/loxone-icon.svg"),
                    text:
                        "Голосовой ассистент, вы можете взаимодействовать со всеми устройствами подключенными в HomeKit, попросите Siri  включить свет, поставить сериал или включить любимую песню."
                },
                {
                    pageSrc: "/solutions/ecosystemi/apple-homekit",
                    id: "Apple HomeKit",
                    imgSrc: require("../../../images/AppleHomeKit/apple-homekit-icon.svg"),
                    text:
                        "Голосовой ассистент, вы можете взаимодействовать со всеми устройствами подключенными в HomeKit, попросите Siri  включить свет, поставить сериал или включить любимую песню."
                },
                {
                    pageSrc: "/solutions/ecosystemi/prilozhenije-google-home",
                    id: "Google Home",
                    imgSrc: require("../../../images/GoogleHome/googlehome-icon.svg"),
                    text:
                        "Голосовой ассистент, вы можете взаимодействовать со всеми устройствами подключенными в HomeKit, попросите Siri  включить свет, поставить сериал или включить любимую песню."
                },
                {
                    pageSrc: "/solutions/ecosystemi/yandex-umnyj-dom",
                    id: "Яндекс Алиса",
                    imgSrc: require("../../../images/Yandex/yandex-icon.svg"),
                    text:
                        "Голосовой ассистент, вы можете взаимодействовать со всеми устройствами подключенными в HomeKit, попросите Siri  включить свет, поставить сериал или включить любимую песню."
                },
                {
                    pageSrc: "/solutions/ecosystemi/alexa-amazon",
                    id: "Amazon Echo",
                    imgSrc: require("../../../images/AmazonEcho/amazonecho-icon.svg"),
                    text:
                        "Голосовой ассистент, вы можете взаимодействовать со всеми устройствами подключенными в HomeKit, попросите Siri  включить свет, поставить сериал или включить любимую песню."
                },
                {
                    pageSrc: "/solutions/ecosystemi/razrabotka-prilozhenij",
                    id: `Индивидуальные решения`,
                    imgSrc: require("../../../images/Individual/individual-icon.svg"),
                    text:
                        "Голосовой ассистент, вы можете взаимодействовать со всеми устройствами подключенными в HomeKit, попросите Siri  включить свет, поставить сериал или включить любимую песню."
                }
            ],
            loxone: [
                {
                    deviceName: "Планшеты",
                    imgSrc: require("../../../images/Loxone/Devices/tablet.png"),
                    text:
                        "Приложение Loxone оптимизировано для всех планшетов на iOS и Android."
                },
                {
                    deviceName: "Смартфоны",
                    imgSrc: require("../../../images/Loxone/Devices/smartphone.png"),
                    text: "Полный контроль над системой Loxone с любого смартфона."
                },
                {
                    deviceName: "Ноутбуки",
                    imgSrc: require("../../../images/Loxone/Devices/notebook.png"),
                    text: "Программа Loxone для Windows, MacOS и Linux."
                },
                {
                    deviceName: "Умные часы",
                    imgSrc: require("../../../images/Loxone/Devices/watch.png"),
                    text: "Оперативный контроль с помощью Apple Watch."
                }
            ],
            apple: [
                {
                    deviceName: "HomePod",
                    imgSrc: require("../../../images/AppleHomeKit/Devices/homepod.png"),
                    text:
                        "Умная колонка, сочетающая в себе возможности голосового помощника и качественное звучание. Попросите Siri включить свет, запустить видео или любимую песню."
                },
                {
                    deviceName: "iPhone",
                    imgSrc: require("../../../images/AppleHomeKit/Devices/iphone.png"),
                    text: "Центр управления умным домом и голосовой ассисстент Siri всегда под рукой."
                },
                {
                    deviceName: "iPad",
                    imgSrc: require("../../../images/AppleHomeKit/Devices/ipad.png"),
                    text: "Управляйте умным домом с помощью iPad и Siri. Доступен настенный монтаж в качестве центра управления."
                },
                {
                    deviceName: "Apple TV",
                    imgSrc: require("../../../images/AppleHomeKit/Devices/apple-tv.png"),
                    text: "Управляйте звуком и картинкой на всех устройствах. Наслаждайтесь любимым контентом."
                },
                {
                    deviceName: "MacBook",
                    imgSrc: require("../../../images/AppleHomeKit/Devices/macbook.png"),
                    text: "Самый удобный способ настройки и создания сценариев."
                },
                {
                    deviceName: "Apple Watch",
                    imgSrc: require("../../../images/AppleHomeKit/Devices/apple-watch.png"),
                    text: "Оперативное управление и контроль. С AppleWatch вы не пропустите ни одного уведомления."
                }
            ],
            google: [
                {
                    deviceName: "Смартфоны",
                    imgSrc: require("../../../images/GoogleHome/smartphone.png"),
                    text: "Управляйте Google Home и голосовым Ассистентом с помощью любого Android или iOS смартфона. "
                },
                {
                    deviceName: "Планшеты",
                    imgSrc: require("../../../images/GoogleHome/tablet.png"),
                    text: "Голосовой Ассистент и управление Google Home на любом Android или iOS планшете. Доступен настенный монтаж в качестве центра управления."
                },

                {
                    deviceName: "Умные часы",
                    imgSrc: require("../../../images/GoogleHome/smart-watch.png"),
                    text: "Оперативное управление и контроль на любых умных часах. Не пропустите ни одного уведомления. "
                },
                {
                    deviceName: "Колонки",
                    imgSrc: require("../../../images/GoogleHome/speaker.png"),
                    text:
                        "Колонки с поддержкой Ассистента сочетают управление умным домом и медиастанцию. Попросите Ассистента закрыть шторы или включить любимый жанр музыки."
                },
                {
                    deviceName: "Google Chromecast",
                    imgSrc: require("../../../images/GoogleHome/chromecast.png"),
                    text: "Транслируйте фильмы, сериалы, фотографии и веб-страницы  из Chrome, YouTube и Google Play на телевизор или проектор."
                }
            ],
            yandex: [
                {
                    deviceName: "Смартфоны",
                    imgSrc: require("../../../images/Yandex/yandex-device-smarphone.png"),
                    text: "Управляйте устройствами в приложении Яндекс, а также с помощью голосового ассистента Алиса на любом Android или iOS смартфоне."
                },
                {
                    deviceName: "Планшеты",
                    imgSrc: require("../../../images/Yandex/yandex-device-tablet.png"),
                    text: "Установите приложение Яндекс с ассистентом Алиса на ваш iOS или Android планшет и управляйте устройствами."
                },
                {
                    deviceName: "Умные часы",
                    imgSrc: require("../../../images/Yandex/yandex-device-smart-watch.png"),
                    text: "Ваши устройства всегда под контролем на любых умных часах с поддержкой Алисы."
                },
                {
                    deviceName: "Яндекс Станция",
                    imgSrc: require("../../../images/Yandex/yandex-device-stantia.png"),
                    text: "Весь функционал Алисы в умной колонке от Яндекс. Включайте музыку, управляйте устройствами, создавайте напоминания."
                },
                {
                    deviceName: "Умные колонки",
                    imgSrc: require("../../../images/Yandex/yandex-device-speacker.png"),
                    text: "Не подходит Яндекс Станция? Любая умная колонка с поддержкой Алисы может полноценно заменить Яндекс Станцию."
                }
            ],
            amazon: [
                {
                    deviceName: "Смартфоны",
                    imgSrc: require("../../../images/AmazonEcho/smartphone.png"),
                    text: "Голосовой помощник Алекса и центр управления умным домом на любом Android или iOS смартфоне."
                },
                {
                    deviceName: "Планшеты",
                    imgSrc: require("../../../images/AmazonEcho/tablet.png"),

                    text: "Управляйте всеми устройствами голосовым помощником Алекса с помощью любого Android или iOS планшета."
                },
                {
                    deviceName: "Персональные компьютеры",
                    imgSrc: require("../../../images/AmazonEcho/pc.png"),
                    text: "Большие возможности для персонализации в интуитивно понятном интерфейсе."
                },
                {
                    deviceName: "Умные часы",
                    imgSrc: require("../../../images/AmazonEcho/smart-watch.png"),
                    text: "Умные часы не дадут пропустить уведомления и позволят оперативно управлять системой."
                },
                {
                    deviceName: "Колонки Amazon Echo",
                    imgSrc: require("../../../images/AmazonEcho/speaker-echo.png"),
                    text: "Умная колонка от Amazon с голосовым помощником Алекса позволяет управлять умными устройствами, музыкой и приложениями."
                },
                {
                    deviceName: "Колонки с поддержкой Alexa",
                    imgSrc: require("../../../images/AmazonEcho/speaker-alexa.png"),
                    text: "Колонки с поддержкой голосового помощника Алекса от различных производителей сильно расширяют выбор дизайна и качества звучания."
                }
            ],
            individual: [
                {
                    deviceName: "Смартфоны",
                    imgSrc: require("../../../images/AmazonEcho/smartphone.png"),
                    text: "Разработаем оптимальное приложения для вашего смартфона."
                },
                {
                    deviceName: "Планшеты",
                    imgSrc: require("../../../images/AmazonEcho/tablet.png"),
                    text: "Предоставим возможность использовать планшет в качестве центра управления умным домом. При необходимости встроим в стену."
                },
                {
                    deviceName: "Персональные компьютеры",
                    imgSrc: require("../../../images/AmazonEcho/pc.png"),
                    text: "Разработаем программу для Windows и Mac и предоставим широкие возможности настройки и персонализации."
                }
            ]
        };

        // if (window.innerWidth < 991) {
        //   this.state.tmp = this.state.blocks[3];
        //   this.state.blocks[3] = this.state.blocks[4];
        //   this.state.blocks[4] = this.state.tmp;
        // } else {
        //   this.state.isMobile = true;
        // }
    }
    renderLoxone = (state) => {
        this.setState(this.toggleRenderLoxone);
    }
    renderApple = (state) => {
        this.setState(this.toggleRenderApple);
    }
    renderGoogle = (state) => {
        this.setState(this.toggleRenderGoogle);
    }

    renderYandex = (state) => {
        this.setState(this.toggleRenderYandex);
    }

    renderAmazon = (state) => {
        this.setState(this.toggleRenderAmazon);
    }
    renderIndividual = (state) => {
        this.setState(this.toggleRenderIndividual);
    }

    toggleRenderLoxone(state) {
        return {
            isRenderLoxone: true,
            isRenderApple: false,
            isRenderGoogle: false,
            isRenderYandex: false,
            isRenderAmazon: false,
            isRenderIndividual: false
        };
    }
    toggleRenderApple(state) {
        return {
            isRenderLoxone: false,
            isRenderApple: true,
            isRenderGoogle: false,
            isRenderYandex: false,
            isRenderAmazon: false,
            isRenderIndividual: false
        };
    }
    toggleRenderGoogle(state) {
        return {
            isRenderGoogle: true,
            isRenderApple: false,
            isRenderLoxone: false,
            isRenderYandex: false,
            isRenderAmazon: false,
            isRenderIndividual: false
        };
    }
    toggleRenderYandex(state) {
        return {
            isRenderYandex: true,
            isRenderGoogle: false,
            isRenderLoxone: false,
            isRenderApple: false,
            isRenderAmazon: false,
            isRenderIndividual: false
        };
    }

    toggleRenderAmazon(state) {
        return {
            isRenderApple: false,
            isRenderGoogle: false,
            isRenderLoxone: false,
            isRenderYandex: false,
            isRenderAmazon: true,
            isRenderIndividual: false
        };
    }
    toggleRenderIndividual(state) {
        return {
            isRenderGoogle: false,
            isRenderLoxone: false,
            isRenderApple: false,
            isRenderYandex: false,
            isRenderAmazon: false,
            isRenderIndividual: true
        };
    }

    render() {
        let settings = {
            infinite: false,
            autoplay: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        const renderLoxone = this.state.loxone.map((item) => {
            return (
                <div style={{ width: "100%" }} key={item}>
                    <Col xl='3' lg='3' md='4' sm='6' xs='12' className='ecosystem-devices'>
                        <p className='ecosystem-devices-p'>{item.deviceName}</p>
                        <div>
                            <img data-src={item.imgSrc} alt='' class='lazy' src='/images/image-preloader.png' />
                        </div>

                        <p className='control-hover-text'>{item.text}</p>
                    </Col>
                </div>
            );
        });
        const renderApple = this.state.apple.map((item) => {
            return (
                <div style={{ width: "100%" }} key={item}>
                    <Col xl='3' lg='3' md='4' sm='6' xs='12' className='ecosystem-devices'>
                        <p className='ecosystem-devices-p'>{item.deviceName}</p>
                        <div>
                            <img data-src={item.imgSrc} alt='' class='lazy' src='/images/image-preloader.png' />
                        </div>

                        <p className='control-hover-text'>{item.text}</p>
                    </Col>
                </div>
            );
        });
        const renderGoogle = this.state.google.map((item) => {
            return (
                <div style={{ width: "100%" }} key={item}>
                    <Col xl='3' lg='3' md='4' sm='6' xs='12' className='ecosystem-devices'>
                        <p className='ecosystem-devices-p'>{item.deviceName}</p>
                        <div>
                            <img data-src={item.imgSrc} alt='' class='lazy' src='/images/image-preloader.png' />
                        </div>
                        <p className='control-hover-text'>{item.text}</p>
                    </Col>
                </div>
            );
        });

        const renderYandex = this.state.yandex.map((item) => {
            return (
                <div style={{ width: "100%" }} key={item}>
                    <Col xl='3' lg='3' md='4' sm='6' xs='12' className='ecosystem-devices'>
                        <p className='ecosystem-devices-p'>{item.deviceName}</p>
                        <div>
                            <img data-src={item.imgSrc} alt='' class='lazy' src='/images/image-preloader.png' />
                        </div>
                        <p className='control-hover-text'>{item.text}</p>
                    </Col>
                </div>
            );
        });

        const renderAmazon = this.state.amazon.map((item) => {
            return (
                <div style={{ width: "100%" }} key={item}>
                    <Col xl='3' lg='3' md='4' sm='6' xs='12' className='ecosystem-devices'>
                        <p className='ecosystem-devices-p'>{item.deviceName}</p>
                        <div>
                            <img data-src={item.imgSrc} alt='' class='lazy' src='/images/image-preloader.png' />
                        </div>
                        <p className='control-hover-text'>{item.text}</p>
                    </Col>
                </div>
            );
        });

        const renderIndividual = this.state.individual.map((item) => {
            return (
                <div style={{ width: "100%" }} key={item}>
                    <div className='ecosystem-devices col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12'>
                        <p className='ecosystem-devices-p'>{item.deviceName}</p>
                        <div>
                            <img data-src={item.imgSrc} alt='' class='lazy' src='/images/image-preloader.png' />
                        </div>
                        <p className='control-hover-text'>{item.text}</p>
                    </div>
                </div>
            );
        });

        return (
            <div style={this.props.styles}>
                <div>
                    <div className='container-fluid control-block'>
                        <div className='row control-block-row'>
                            <div className='control-block-header '>
                                <button onClick={this.renderLoxone}
                                        className={classNames({['active']: this.state.isRenderLoxone})}>
                                    <div className='control-block-item'>
                                        <img data-src={this.state.blocks[0].imgSrc} alt='' className='lazy'
                                             src='/images/image-preloader.png'/>
                                        <h3>{this.state.blocks[0].id}</h3>
                                        {this.state.isRenderLoxone && (
                                            <div className='control-block-item-button'>
                                                <Link to={this.state.blocks[0].pageSrc}>
                                                    <button className='hover-button'>Узнать больше</button>
                                                </Link>
                                            </div>
                                        )}
                                    </div>
                                </button>
                            </div>

                            <div className='control-block-header '>
                                <button onClick={this.renderApple} className={classNames({ ['active']: this.state.isRenderApple } )}>
                                    <div className='control-block-item'>
                                        <img data-src={this.state.blocks[1].imgSrc} alt='' class='lazy' src='/images/image-preloader.png' />
                                        <h3>{this.state.blocks[1].id}</h3>
                                        {this.state.isRenderApple && (
                                            <div className='control-block-item-button'>
                                                <Link to={this.state.blocks[1].pageSrc}>
                                                    <button className='hover-button'>Узнать больше</button>
                                                </Link>
                                            </div>
                                        )}
                                    </div>
                                </button>
                            </div>

                            <div className='control-block-header'>
                                <button onClick={this.renderGoogle} className={classNames({ ['active']: this.state.isRenderGoogle } )}>
                                    <div className='control-block-item'>
                                        <img data-src={this.state.blocks[2].imgSrc} alt='' class='lazy' src='/images/image-preloader.png' />
                                        <h3>{this.state.blocks[2].id}</h3>
                                        {this.state.isRenderGoogle && (
                                            <div className='control-block-item-button'>
                                                <Link to={this.state.blocks[2].pageSrc}>
                                                    <button className='hover-button'>Узнать больше</button>
                                                </Link>
                                            </div>
                                        )}
                                    </div>
                                </button>
                            </div>
                            <div className='control-block-header '>
                                <button onClick={this.renderYandex} className={classNames({ ['active']: this.state.isRenderYandex } )}>
                                    <div className='control-block-item'>
                                        <img data-src={this.state.blocks[3].imgSrc} alt='' class='lazy' src='/images/image-preloader.png' />
                                        <h3>{this.state.blocks[3].id}</h3>
                                        {this.state.isRenderYandex && (
                                            <div className='control-block-item-button'>
                                                <Link to={this.state.blocks[3].pageSrc}>
                                                    <button className='hover-button'>Узнать больше</button>
                                                </Link>
                                            </div>
                                        )}
                                    </div>
                                </button>
                            </div>
                            <div className='control-block-header '>
                                <button onClick={this.renderAmazon} className={classNames({ ['active']: this.state.isRenderAmazon } )}>
                                    <div className='control-block-item'>
                                        <img data-src={this.state.blocks[4].imgSrc} alt='' class='lazy' src='/images/image-preloader.png' />
                                        <h3>{this.state.blocks[4].id}</h3>
                                        {this.state.isRenderAmazon && (
                                            <div className='control-block-item-button'>
                                                <Link to={this.state.blocks[4].pageSrc}>
                                                    <button className='hover-button'>Узнать больше</button>
                                                </Link>
                                            </div>
                                        )}
                                    </div>
                                </button>
                            </div>
                            <div className='control-block-header '>
                                <button onClick={this.renderIndividual} className={classNames({ ['active']: this.state.isRenderIndividual } )}>
                                    <div className='control-block-item'>
                                        <img data-src={this.state.blocks[5].imgSrc} alt='' class='lazy' src='/images/image-preloader.png' />
                                        <h3>
                                            Индивидуальные <br /> решения
                                        </h3>
                                        {this.state.isRenderIndividual && (
                                            <div className='control-block-item-button'>
                                                <Link to={this.state.blocks[5].pageSrc}>
                                                    <button className='hover-button individual'>
                                                        Узнать больше
                                                    </button>
                                                </Link>
                                            </div>
                                        )}
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ width: "100%" }}>
                    <div style={{ width: "100%" }} className='container-fluid control-devices '>
                        <Slider {...settings}>{this.state.isRenderLoxone && renderLoxone}</Slider>

                        <Slider {...settings}>{this.state.isRenderApple && renderApple}</Slider>

                        <Slider {...settings}>{this.state.isRenderGoogle && renderGoogle}</Slider>

                        <Slider {...settings}>{this.state.isRenderYandex && renderYandex}</Slider>

                        <Slider {...settings}>{this.state.isRenderAmazon && renderAmazon}</Slider>

                        <Slider {...settings}>{this.state.isRenderIndividual && renderIndividual}</Slider>
                    </div>
                </div>
            </div>
        );
    }
}

export default ControlBlock;
