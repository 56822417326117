import React, { Component } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import SolutionsBlockPages from "../../Solutions/SolutionsBlock/SolutionsBlockPages";
import axios from "axios";

import { map } from "../../../map";

class BreadcrumbsConstructor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      path: this.props.path.split("/"),
      isSolutionsHovering: false,
      isServicesHovering: false,
      ignored: []
    };

    this.state.lastPath = this.state.path.pop();
    this.state.prevPath = this.state.path.slice(-1);
  }

  buildLink(s) {
    const path = this.state.path.slice(0, this.state.path.indexOf(s) + 1);
    return path.join("/");
  }

  solutionsHover() {
    this.setState(this.toggleSolutionsHover);
  }

  toggleDropdown() {
    return {
      isServicesHovering: false,
      isSolutionsHovering: false
    };
  }

  toggleSolutionsHover(state) {
    return {
      isSolutionsHovering: !state.isSolutionsHovering,
      isServicesHovering: false
    };
  }

  componentDidMount() {
    axios
      .get(
        process.env.NODE_ENV === "production"
          ? `https://homechain.ai/s/api/page/get-cats`
          : `http://localhost:4000/api/page/get-cats`
      )
      .then(res => {
        const data = res.data.data;
        const ignored = Object.values(data)
          .map(cat => {
            const tinySubs = [];

            for (const subCat in cat)
              if (Object.keys(cat[subCat]).length <= 2) tinySubs.push(subCat);

            return tinySubs;
          })
          .flat();

        this.setState({
          data,
          ignored
        });
      })
      .catch(err => {
        this.setState({ isNotFound: true });
        console.log(err);
      });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.path !== this.props.path) {
      const path = this.props.path.split("/");
      this.setState({
        path: path,
        lastPath: path.pop(),
        isSolutionsHovering: false
      });
    }
  }

  render() {
    return !this.props.mobile ? (
      <div>
        <div className="page-nav">
          <ul>
            {this.state.path.map(path =>
              path === "solutions" ? (
                <li onClick={this.solutionsHover.bind(this)}>
                  <HashLink>
                    {map[path]}
                    <img
                      src={require("../../../images/arrow.svg")}
                      style={{ marginLeft: "5px", marginRight: "-5px" }}
                      alt=""
                    />
                  </HashLink>
                </li>
              ) : (
                <li>
                  {this.state.ignored.includes(path) ? (
                    <div>
                      {this.props.subCatName}
                      <img
                        src={require("../../../images/arrow.svg")}
                        style={{ marginLeft: "5px", marginRight: "-5px" }}
                        alt=""
                      />
                    </div>
                  ) : (
                    <Link to={this.buildLink(path)}>
                      {path === this.state.path[2]
                        ? this.props.subCatName
                        : map[path]}
                      <img
                        src={require("../../../images/arrow.svg")}
                        style={{ marginLeft: "5px", marginRight: "-5px" }}
                        alt=""
                      />
                    </Link>
                  )}
                </li>
              )
            )}
            <li style={{ color: "#000" }}>{this.props.lastPath}</li>
          </ul>
        </div>
        {this.state.isSolutionsHovering && (
          <SolutionsBlockPages
            activeCat={this.props.activeCat}
            handler={this.solutionsHover.bind(this)}
          />
        )}
      </div>
    ) : (
      <div>
        <div className="page-nav">
          <ul>
            {this.state.path.map(
              path =>
                path === "solutions" && (
                  <li
                    style={{ margin: "0px" }}
                    onClick={this.solutionsHover.bind(this)}
                  >
                    <img
                      style={{
                        width: "6px",
                        marginLeft: "5px",
                        marginRight: "5px"
                      }}
                      src={require("../../../images/arrow-left.svg")}
                      alt=""
                    />

                    <HashLink>{map[path]}</HashLink>
                  </li>
                )
            )}
          </ul>
        </div>
        {this.state.isSolutionsHovering && (
          <SolutionsBlockPages
            activeCat={this.props.activeCat}
            handler={this.solutionsHover.bind(this)}
          />
        )}
      </div>
    );
  }
}

export default BreadcrumbsConstructor;
