import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";
import Navbar from "../Navbar/Navbar";
import FeedbackFormSmall from "../FeedbackForm/FeedbackFormSmall";
import FeedbackFormBig from "../FeedbackForm/FeedbackFormBig";
import BlockThumbnail from "../../components/BlockThumbnail/BlockThumbnail";
import AuthorizeStagesBlock from "../../components/Tabs/AuthorizeStages/AuthorizeStagesBlock";
import ServicesDropdownPages from "../Services/ServicesDropdown/ServicesDropdownPages";

class Authorize extends React.Component {
  constructor(props) {
    super(props);
    this.servicesHover = this.servicesHover.bind(this);
    this.state = {
      style: "borderTop: 2px solid",
      isSolutionsHovering: false,
      isServicesHovering: false,
      buttonOrder: false,
      activeServices: true,
      blocks: [
        {
          name: "Вентиляционное и климатическое оборудование",
          imgSrc: require("../../images/Services/Authorize/vent.png"),
          alt: "авторский надзор за проектом — вентиляция"
        },
        {
          name: "Трубопроводы водообеспечения и отопления",
          imgSrc: require("../../images/Services/Authorize/tubes.png"),
          alt: "авторский надзор за проектом — вода и отопление"
        },
        {
          name: "Канализационные системы",
          imgSrc: require("../../images/Services/Authorize/stock.png"),
          alt: "авторский надзор за проектом — канализация"
        },
        {
          name: "Кабельные магистрали",
          imgSrc: require("../../images/Services/Authorize/cabel.png"),
          alt: "авторский надзор за проектом — кабели"
        }
      ],
      blocksSecond: [
        {
          name: "Отравление людей и животных с возможным летальным исходом",
          imgSrc: require("../../images/Services/Authorize/block-second.png")
        },
        {
          name: "Отравление людей и животных с возможным летальным исходом",
          imgSrc: require("../../images/Services/Authorize/block-second.png")
        },
        {
          name:
            "Ненормированный расход газа, который подлежит обязательной оплате",
          imgSrc: require("../../images/Services/Authorize/block-second.png")
        }
      ],
      blocksThird: [
        {
          name: "Вентиляционное и климатическое оборудование",
          imgSrc: require("../../images/Services/Authorize/block-second.png")
        },
        {
          name: "Трубопроводы водообеспечения и отопления",
          imgSrc: require("../../images/Services/Authorize/block-second.png")
        },
        {
          name: "Канализационные системы",
          imgSrc: require("../../images/Services/Authorize/block-second.png")
        },
        {
          name: "Кабельные магистрали",
          imgSrc: require("../../images/Services/Authorize/block-second.png")
        }
      ],
      isMobile: window.innerWidth <= 576
    };
  }

  uppendTitle() {
    document.querySelector("head title").innerHTML =
      "Авторский надзор за проектом | Умный дом в Москве | Homeсhain";
  }

  uppendMeta() {
    let description = document.querySelector("meta[name=description]");
    let keywords = document.querySelector("meta[name=keywords]");
    if (description) {
      description.content =
        'Авторский надзор за проектом. Умный дом в Москве "под ключ" - проектирование, комплектация, монтаж, пусконаладка. Homeсhain +7 (499) 113 10 10.';
    } else {
      description = document.createElement("meta");
      description.name = "description";
      description.content =
        'Авторский надзор за проектом. Умный дом в Москве "под ключ" - проектирование, комплектация, монтаж, пусконаладка. Homeсhain +7 (499) 113 10 10.';
      document.querySelector("head").appendChild(description);
    }

    if (keywords) {
      keywords.content =
        "авторский надзор за проектом, авторский надзор за строительством";
    } else {
      keywords = document.createElement("meta");
      keywords.name = "keywords";
      keywords.content =
        "авторский надзор за проектом, авторский надзор за строительством";
      document.querySelector("head").appendChild(keywords);
    }
  }

  servicesHover(state) {
    this.setState(this.toggleServicesHover);
  }

  toggleDropdown(state) {
    return {
      isServicesHovering: false,
      isSolutionsHovering: false
    };
  }

  toggleServicesHover(state) {
    return {
      isServicesHovering: !state.isServicesHovering,
      isSolutionsHovering: false
    };
  }

  render() {
    const renderBlocksFirst = this.state.blocks.map((item, i) => {
      return (
        <Col xl="3" lg="3" md="3" sm="6" xs="6" key={i}>
          <BlockThumbnail
            alt={item.alt}
            imgSrc={item.imgSrc}
            name={item.name}
          />
        </Col>
      );
    });

    return (
      <div>
        {this.uppendTitle()}
        {this.uppendMeta()}
        <Navbar activeServices={this.state.activeServices} />
        <Container
          fluid
          className="page"
          style={{
            backgroundImage: `url(${require("../../images/Services/Authorize/authorize-bg.png")})`
          }}
        >
          <header>
            <Container fluid>
              <Row className="page-row custom-container">
                {!this.state.isMobile && (
                  <div className="page-nav">
                    <ul>
                      <li>
                        <Link to="/">Главная</Link>
                      </li>
                      <img src={require("../../images/arrow.svg")} alt="" />
                      <li onClick={this.servicesHover.bind(this)}>
                        <HashLink>Услуги</HashLink>
                      </li>
                      <img src={require("../../images/arrow.svg")} alt="" />
                      <li style={{ color: "#181818" }}>Авторский надзор</li>
                    </ul>
                  </div>
                )}

                {this.state.isMobile && (
                  <div className="page-nav">
                    <ul>
                      <img
                        style={{ width: "6px" }}
                        src={require("../../images/arrow-left.svg")}
                        alt=""
                      />
                      <li onClick={this.servicesHover.bind(this)}>
                        <HashLink>Услуги</HashLink>
                      </li>
                    </ul>
                  </div>
                )}

                <div className="page-header">
                  <h1>Авторский надзор</h1>
                  <HashLink smooth to="#feedback-big">
                    <button className="hover-button">Оставить заявку</button>
                  </HashLink>
                </div>
              </Row>
              {this.state.isServicesHovering && (
                <ServicesDropdownPages
                  handler={this.servicesHover.bind(this)}
                />
              )}
            </Container>
          </header>
        </Container>
        <Container fluid className="custom-container">
          <Row className="page-description">
            <Col xl="12">
              <h3>Гарантия правильности и безопасности строительства</h3>
              <p>
                Авторский надзор за строительством осуществляется должностным
                лицом, разработавшим проектную документацию. Контроль проведения
                работ выполняется с целью соблюдения требований технических
                нормативов и разработанных документов в процессе возведения
                объекта. Услуга предоставляется как дополнительная опция к
                проектированию.
              </p>
              <p className="text-block-without-space">
                После разработки инженерами и технологами проектной
                документации, а также согласования и утверждения пакета в
                соответствующих инстанциях на объекте выполняется первый этап ‒
                монтаж инженерных систем (коммуникаций), которые в процессе
                возведения здания оказываются скрытыми за строительными
                конструкциями. Мастерами монтируется:
              </p>
            </Col>
          </Row>
        </Container>
        <Container className="container-space-top-70" fluid>
          <Row className="page-description">{renderBlocksFirst}</Row>
        </Container>
        <Container
          style={{ marginBottom: "40px" }}
          fluid
          className="custom-container"
        >
          <Row style={{ marginTop: "0px" }} className="page-description">
            <Col xl="12">
              <p>
                Чистовая отделка помещений может начинаться только после
                проверки правильности монтажа инженерных коммуникаций.
                Устранение выявленных ошибок и неточностей до проведения
                отделочных работ позволяет избежать дополнительных финансовых
                растрат и сэкономить время. Надзор за проектом или шеф-надзор
                уполномоченным лицом предоставляет возможность гарантировать
                заказчику соответствие выполняемых строительно-монтажных
                операций требованиям нормативной документации и успешную
                реализацию системы в целом.
              </p>
            </Col>
          </Row>
        </Container>
        <FeedbackFormSmall />
        <Container
          fluid
          className="custom-container container-space-top-90"
        >
          <Row className="page-description">
            <Col xl="12">
              <h3>Полный комплекс мероприятий</h3>
              <p>
                Авторский надзор за строительством осуществляется установленным
                порядком главным инженером, участвующим в разработке проектной
                документации. При необходимости для контроля над соблюдением
                технологии возведения объекта привлекаются
                инженеры-проектировщики, специализирующиеся по каждой отдельной
                инженерной системе. Надзор за проектом предусматривает
                выполнение следующих мероприятий:
              </p>
            </Col>
          </Row>
        </Container>
        <AuthorizeStagesBlock />
        <Container fluid className="custom-container">
          <Row className="page-description">
            <Col xl="12">
              <p>
                Своевременный авторский контроль над технологичностью и
                правильностью строительства, а также полнотой проведения
                монтажных операций, выполняемый опытными специалистами,
                позволяет гарантировать безопасность возводимого объекта.
              </p>
            </Col>
          </Row>
        </Container>
        <FeedbackFormBig bigForm={true} />
      </div>
    );
  }
}

export default Authorize;
