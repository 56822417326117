import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import Cloud from "../../images/Cloud.svg";
import BlueCloud from "../../images/blue-cloud.svg";
import FilledCloud from "../../images/filled-cloud.svg";
import Delete from "../../images/drag-delete.svg";
import classNames from 'classnames';

function DragnDrop(props) {
  const onDrop = useCallback(acceptedFiles => {
    const reader = new FileReader();
    reader.onabort = () => console.log("file reading was aborted");
    reader.onerror = () => console.log("file reading has failed");
    reader.onload = () => {
      const binaryStr = reader.result;

      props.onDropping(acceptedFiles[0].name, binaryStr);
    };

    acceptedFiles.forEach(file => reader.readAsDataURL(file));
  }, [props]);

  const onDropRejected = useCallback(rejectedFiles => {
    document.addEventListener("drop", function(e) {
      e.preventDefault();
      e.stopPropagation();
    });
    document.addEventListener("dragover", function(e) {
      e.preventDefault();
      e.stopPropagation();
    });
  }, []);

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const {
    acceptedFiles,

    getRootProps,
    getInputProps,
    isDragActive,

    isDragReject
  } = useDropzone({
    onDrop,
    onDropRejected,
    multiple: false,
    maxSize: "26214400",
    accept:
      " image/jpeg, image/png, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, application/zip, application/x-rar-compressed, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, video/mp4, application/mp4, video/webm, video/x-msvideo, "
  });

  const files = acceptedFiles.map(file => file.path);

  const file = files[0];

  const removeFile = files => () => {
    acceptedFiles.splice(acceptedFiles.indexOf(files), 1);
    acceptedFiles.length = 0;
    forceUpdate();
  };

  return (
    <div className={classNames('dragndrop', { ['fileDropped']: acceptedFiles.length !== 0, ['fileDropping']: isDragActive } )}>
      <div {...getRootProps({ className: "dropzone" })}>
        <input multiple="false" {...getInputProps()} />
        {acceptedFiles.length !== 0 && (
          <span
            style={{ margin: "0px", color: "#0F75BC", paddingBottom: "40px" }}
          >
            Файл принят, спасибо
          </span>
        )}
        {acceptedFiles.length === 0 && (
          <div>
            {isDragActive && (
              <span style={{ color: "#0F75BC", margin: "0px" }}>
                Перетащите файл до 25MB
              </span>
            )}
            {isDragReject && (
              <span
                style={{
                  margin: "0px",
                  color: "#DB0020",
                  backgroundColor: "#fff"
                }}
              >
                Ошибка загрузки (проверьте размер или тип файла)
              </span>
            )}
            {!isDragActive && (
              <span style={{ margin: "0px" }}>Перетащите файл до 25MB</span>
            )}
          </div>
        )}
      </div>
      <aside>
        {acceptedFiles.length !== 0 && (
          <img style={{ marginTop: "-10px" }} data-src={FilledCloud} alt="" class="lazy" src="/images/image-preloader.png" />
        )}
        {acceptedFiles.length === 0 && (
          <div style={{ textAlign: "center" }}>
            {isDragActive && <img data-src={BlueCloud} alt="" class="lazy" src="/images/image-preloader.png" />}

            {!isDragActive && <img data-src={Cloud} alt="" class="lazy" src="/images/image-preloader.png" />}
          </div>
        )}

        <div>
          <p
            style={{
              padding: "0px 10px",
              margin: "0px",
              color: "#0F75BC",
              paddingTop: "20px"
            }}
          >
            {acceptedFiles.length !== 0 && (
              <button className="dragndrop-button" onClick={removeFile(files)}>
                <img data-src={Delete} alt="" class="lazy" src="/images/image-preloader.png" />
              </button>
            )}
            <div>{file && file.substr(0, 15) + "..."}</div>
          </p>
        </div>
      </aside>
    </div>
  );
}

export default DragnDrop;
