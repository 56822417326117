import React from "react";
import { Container, Col } from "react-bootstrap";
import Slider from "react-slick";

import "../../Tabs/Control/control.css";

function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: "block"
            }}
            onClick={onClick}
        />
    );
}

function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: "block"
            }}
            onClick={onClick}
        />
    );
}

class LoxoneDevices extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            renderResponsive: null,

            loxone: [
                {
                    deviceName: "Планшеты",
                    imgSrc: require("../../../images/Loxone/Devices/tablet.png"),
                    alt: "Loxone на планшетах",
                    text:
                        "Приложение Loxone оптимизировано для всех планшетов на iOS и Android."
                },
                {
                    deviceName: "Смартфоны",
                    imgSrc: require("../../../images/Loxone/Devices/smartphone.png"),
                    alt: "Loxone на смартфонах",
                    text: "Полный контроль над системой Loxone с любого смартфона."
                },
                {
                    deviceName: "Ноутбуки",
                    imgSrc: require("../../../images/Loxone/Devices/notebook.png"),
                    alt: "Loxone на ноутбуках",
                    text: "Программа Loxone для Windows, MacOS и Linux."
                },
                {
                    deviceName: "Умные часы",
                    imgSrc: require("../../../images/Loxone/Devices/watch.png"),
                    alt: "Loxone на умных часах",
                    text: "Оперативный контроль с помощью Apple Watch."
                }
            ]
        };
    }

    render() {
        let settings = {
            infinite: false,
            autoplay: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            // nextArrow: <NextArrow />,
            // prevArrow: <PrevArrow />,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1
                    }
                },

                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const renderLoxone = this.state.loxone.map((item) => {
            return (
                <div key={item}>
                    <Col xl='3' lg='3' md='4' sm='6' xs='12' className='ecosystem-devices'>
                        <p className="ecosystem-devices-device-name">{item.deviceName}</p>
                        <div>
                            <img data-src={item.imgSrc} alt={item.alt} class='lazy' src='/images/image-preloader.png' />
                        </div>
                        <p className='control-hover-text'>{item.text}</p>
                    </Col>
                </div>
            );
        });

        return (
            <div>
                <Container fluid className='devices control-devices control-devices-bottom-space'>
                    <Slider {...settings}>{renderLoxone}</Slider>
                </Container>
            </div>
        );
    }
}

export default LoxoneDevices;
