import React from "react";

class BlogPreview extends React.Component {
  render() {
    return (
      <div className="main-thumbnail-container blog-page__preview-item">
        <div className='blog-page__preview-info blog-page__preview-category'>{this.props.category}</div>
        <div className='blog-page__preview-info blog-page__preview-date'>{this.props.date}</div>
        <div className="main-thumbnail">
          <div
            className="main-thumbnail-image"
            style={{ backgroundImage: `url(${this.props.imgSrc})` }}
          />
          <div>
            <p>{this.props.name}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default BlogPreview;
