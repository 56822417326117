import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";
import FeedbackFormSmall from "../FeedbackForm/FeedbackFormSmall";
import FeedbackFormBig from "../FeedbackForm/FeedbackFormBig";
import Navbar from "../Navbar/Navbar";
import ServicesDropdownPages from "../Services/ServicesDropdown/ServicesDropdownPages";

class Builtin extends React.Component {
  constructor(props) {
    super(props);
    this.servicesHover = this.servicesHover.bind(this);
    this.state = {
      style: "borderTop: 2px solid",
      buttonOrder: false,

      activeServices: true,
      isSolutionsHovering: false,
      isServicesHovering: false,
      isMobile: window.innerWidth <= 576
    };
  }

  uppendTitle() {
    document.querySelector("head title").innerHTML =
      "Монтаж | Умный дом в Москве | Homeсhain";
  }

  uppendMeta() {
    let description = document.querySelector("meta[name=description]");
    let keywords = document.querySelector("meta[name=keywords]");
    if (description) {
      description.content =
        'Монтаж. Умный дом в Москве "под ключ" - проектирование, комплектация, пусконаладка. Homeсhain +7 (499) 113 10 10.';
    } else {
      description = document.createElement("meta");
      description.name = "description";
      description.content =
        'Монтаж. Умный дом в Москве "под ключ" - проектирование, комплектация, пусконаладка. Homeсhain +7 (499) 113 10 10.';
      document.querySelector("head").appendChild(description);
    }

    if (keywords) {
      keywords.content =
        'умный дом установка, монтаж системы умный дом, умный дом монтаж, установка систем умный дом, установка умного дома цена, подключение умного дома, умный дом установка "под ключ", установка умный дом москва';
    } else {
      keywords = document.createElement("meta");
      keywords.name = "keywords";
      keywords.content =
        'умный дом установка, монтаж системы умный дом, умный дом монтаж, установка систем умный дом, установка умного дома цена, подключение умного дома, умный дом установка "под ключ", установка умный дом москва';
      document.querySelector("head").appendChild(keywords);
    }
  }

  servicesHover(state) {
    this.setState(this.toggleServicesHover);
  }

  toggleDropdown(state) {
    return {
      isServicesHovering: false,
      isSolutionsHovering: false
    };
  }

  toggleServicesHover(state) {
    return {
      isServicesHovering: !state.isServicesHovering,
      isSolutionsHovering: false
    };
  }

  render() {
    return (
      <div>
        {this.uppendTitle()}
        {this.uppendMeta()}
        <Navbar activeServices={this.state.activeServices} />
        <Container
          fluid
          className="page"
          style={{
            backgroundImage: `url(${require("../../images/Services/Builtin/builtin-bg.png")})`
          }}
        >
          <header>
            <Container fluid>
              <Row className="page-row custom-container">
                {!this.state.isMobile && (
                  <div className="page-nav">
                    <ul>
                      <li>
                        <Link to="/">Главная</Link>
                      </li>
                      <img src={require("../../images/arrow.svg")} alt="" />
                      <li onClick={this.servicesHover.bind(this)}>
                        <HashLink>Услуги</HashLink>
                      </li>
                      <img src={require("../../images/arrow.svg")} alt="" />
                      <li style={{ color: "#181818" }}>Монтаж</li>
                    </ul>
                  </div>
                )}

                {this.state.isMobile && (
                  <div className="page-nav">
                    <ul>
                      <img
                        style={{ width: "6px" }}
                        src={require("../../images/arrow-left.svg")}
                        alt=""
                      />
                      <li onClick={this.servicesHover.bind(this)}>
                        <HashLink>Услуги</HashLink>
                      </li>
                    </ul>
                  </div>
                )}

                <div className="page-header">
                  <h1>Монтаж</h1>
                  <HashLink smooth to="#feedback-big">
                    <button className="hover-button">Оставить заявку</button>
                  </HashLink>
                </div>
              </Row>
              {this.state.isServicesHovering && (
                <ServicesDropdownPages
                  handler={this.servicesHover.bind(this)}
                />
              )}
            </Container>
          </header>
        </Container>
        <Container fluid className="custom-container">
          <Row className="page-description">
            <Col xl="12">
              <h3>Поэтапный процесс</h3>
              <p>
                Качественный монтаж системы умный дом является залогом
                безопасной и правильной работы оборудования, а также длительного
                и бесперебойного функционирования сети. Согласно внутреннему
                регламенту мы не приступаем к монтажу автоматизации и не делаем
                коммерческие предложения по установке и пусконаладке без
                проектной документации или на основе планирующей информации
                ненадлежащего качества. Мы полностью солидарны с русской
                пословицей «Семь раз отмерь, один раз отрежь». Процесс монтажа
                умного дома выполняется поэтапно. Начало этапов определяется
                степенью готовности объекта, которая зависит от оперативности
                проведения строительных и отделочных работ.
              </p>
            </Col>
          </Row>
          <Row className="page-description">
            <Col xl="12">
              <h3>Растяжка кабеля</h3>
              <p>
                После готовности помещения и утверждения рабочей документации
                выполняется растяжка кабеля. Цель данного этапа ‒ проложить
                кабельные линии и провести маркировку согласно проекту. Комплекс
                работ предусматривает проведение шеф-монтажа и проверку полного
                соответствия кабельных трасс утвержденному плану. В процессе
                установки умного дома растяжка кабеля несложный, но
                ответственный этап, поскольку после обшивки и отделки стен и
                потолков доступа к кабелю для внесения изменений не будет.
              </p>
            </Col>
          </Row>

          <div className="ecosystem-description">
            <Row style={{ marginTop: "0px" }} className="ecosystem-row">
              <Col xl="12" lg="12" md="12">
                <h3>Непрерывный контроль</h3>
                <p>
                  <img
                    data-src={require("../../images/Services/Builtin/builtin-img.png")}
                    alt="монтаж системы умный дом"
                    class="lazy"
                    src="/images/image-preloader.png"
                  />
                  С целью успешного проведения пусконаладочных работ с первой
                  попытки, важно грамотное и точное соблюдение схем и
                  последовательности коммутации оборудования. Проложенные
                  коммуникации и смонтированные электронные устройства должны
                  строго соответствовать утвержденным проектным документам. С
                  этой целью мы организуем непрерывный контроль процесса монтажа
                  умного дома от начала и до конца благодаря авторскому надзору,
                  выполняемому главным инженером, участвующим в проектировании
                  объекта.
                </p>
              </Col>
            </Row>
          </div>
          <Row className="page-description container-space-top-50">
            <Col xl="12">
              <h3>Электромонтажные работы</h3>
              <p>
                На следующем этапе в помещении проводятся строительные операции.
                Во время выполнения на объекте отделочных работ мы не проводим
                сборку электрощитов по причине большой вероятности попадания на
                оборудование строительной пыли. Монтаж электрических щитов,
                автоматики и конечных электронных устройств выполняется после
                полного завершения «грязных» работ. Параллельно обеспечиваем
                установку и подключение слаботочных систем и мультимедийной
                техники.
              </p>
            </Col>
          </Row>
        </Container>
        <FeedbackFormSmall />

        <Container fluid className="custom-container">
          <Row className="page-description container-space-top-50">
            <Col xl="12">
              <h3>Приоритетное направление</h3>
              <p>
                Наша главная цель по установке умного дома ‒ создать собственную
                автоматизированную сеть домашних устройств посредством
                разработки программного обеспечения с дальнейшим запуском
                производства умного электронного оборудования. В этом
                заключается основная причина отказа от собственного отдела
                электромонтажа. Мы считаем, что энергию и ресурсы, необходимые
                на решение организационных вопросов, контроля и управления
                электромонтажными бригадами, гораздо эффективнее пустить в
                область обучения персонала последним технологиям и разработку
                собственных программных приложений.
              </p>
            </Col>
          </Row>
          <Row style={{ marginTop: "0px" }} className="page-description">
            <Col xl="12">
              <h3>Гарантии качества</h3>
              <p>
                Отсутствие отдела электромонтажа абсолютно не означает, что мы
                не сможем сделать сборку и установку электрооборудования на
                объекте. Для выполнения электромонтажа привлекаются
                высококвалифицированные компании-партнеры, за которых мы
                ручаемся и несем ответственность на словах и документально.
                Договор на монтажные работы заключается с нашей организацией, и
                мы отвечаем за грамотность и правильность операций, а также
                выполняем гарантийные обязательства за объект. Более того,
                штатный инженер разрабатывающий проект ведет авторский надзор на
                всех этапах установки умного дома. Наша компания готова
                гарантировать высокое качество работ по монтажу
                электрооборудования, не требуя какой-либо вовлеченности с вашей
                стороны.
              </p>
            </Col>
          </Row>
        </Container>
        <FeedbackFormBig bigForm={true} />
      </div>
    );
  }
}

export default Builtin;
