import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Navbar from "../../../components/Navbar/Navbar";
import FeedbackFormSmall from "../../FeedbackForm/FeedbackFormSmall";
import YandexBg from "../../../images/Yandex/8.1.3.yandex_alisa_banner.png";
import YandexMusic from "../../../images/Yandex/8.1.3.1.yandex_alisa_muzika.png";
import YandexApp from "../../../images/Yandex/8.1.3.2.yandex_alisa_prilozhenie.png";
import YandexRemind from "../../../images/Yandex/8.1.3.3.yandex_alisa_napominanie.png";
import YandexRadio from "../../../images/Yandex/8.1.3.4.yandex_stanciya_radio.png";

import YandexDevices from "./YandexDevices";

import { HashLink } from "react-router-hash-link";
import SolutionsBlockPages from "../../Solutions/SolutionsBlock/SolutionsBlockPages";
import ServicesBlock from "../../ServicesBlock/ServicesBlock";

class Yandex extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isMobile: window.innerWidth <= 576,
            activeCat: ""
        };
    }

    setActiveCat(category) {
        this.setState({ activeCat: category });
    }

    uppendTitle() {
        document.querySelector("head title").innerHTML = "Яндекс. Умный дом с Алисой | Умный дом в Москве | Homeсhain";
    }

    uppendMeta() {
        let description = document.querySelector("meta[name=description]");
        let keywords = document.querySelector("meta[name=keywords]");
        if (description) {
            description.content =
                'Яндекс. Умный дом с Алисой. Умный дом в Москве "под ключ" - проектирование, комплектация, монтаж, пусконаладка. Homeсhain +7 (499) 113 10 10.';
        } else {
            description = document.createElement("meta");
            description.name = "description";
            description.content =
                'Яндекс. Умный дом с Алисой. Умный дом в Москве "под ключ" - проектирование, комплектация, монтаж, пусконаладка. Homeсhain +7 (499) 113 10 10.';
            document.querySelector("head").appendChild(description);
        }

        if (keywords) {
            keywords.content = "умный дом яндекс, умный дом с алисой, яндекс станция умный дом, яндекс умный дом приложение";
        } else {
            keywords = document.createElement("meta");
            keywords.name = "keywords";
            keywords.content = "умный дом яндекс, умный дом с алисой, яндекс станция умный дом, яндекс умный дом приложение";
            document.querySelector("head").appendChild(keywords);
        }
    }

    solutionsHover() {
        this.setState(this.toggleSolutionsHover);
    }

    toggleDropdown() {
        return {
            isServicesHovering: false,
            isSolutionsHovering: false
        };
    }

    toggleSolutionsHover(state) {
        return {
            isSolutionsHovering: !state.isSolutionsHovering,
            isServicesHovering: false
        };
    }

    render() {
        return (
            <div>
                {this.uppendTitle()}
                {this.uppendMeta()}
                <Navbar activeCat={"control"} activeSolutions={true} />
                <Container
                    fluid
                    className='ecosystem'
                    style={{
                        backgroundImage: `url(${YandexBg})`,
                        marginBottom: "0px"
                    }}>
                    <header>
                        <Container fluid>
                            <Row className='ecosystem-row custom-container' style={{ flexDirection: "column" }}>
                                {!this.state.isMobile ? (
                                    <div>
                                        <div className='ecosystem-nav'>
                                            <ul>
                                                <li>
                                                    <Link to='/'>Главная</Link>
                                                </li>
                                                <img src={require("../../../images/arrow.svg")} alt='' />
                                                <li onClick={this.solutionsHover.bind(this)}>
                                                    <HashLink>Решения</HashLink>
                                                </li>
                                                <img src={require("../../../images/arrow.svg")} alt='' />
                                                <li style={{ color: "#0F75BC" }}>
                                                    <Link to='/solutions/ecosystemi'>Экосистемы</Link>
                                                </li>
                                                <img src={require("../../../images/arrow.svg")} alt='' />
                                                <li style={{ color: "#000" }}>Яндекс умный дом</li>
                                            </ul>
                                        </div>
                                        {this.state.isSolutionsHovering && <SolutionsBlockPages activeCat={`control`} handler={this.solutionsHover.bind(this)} />}
                                    </div>
                                ) : (
                                    <div>
                                        <div className='page-nav'>
                                            <ul>
                                                <li style={{ margin: "0px" }} onClick={this.solutionsHover.bind(this)}>
                                                    <img
                                                        style={{
                                                            width: "6px",
                                                            marginLeft: "5px",
                                                            marginRight: "5px"
                                                        }}
                                                        alt=''
                                                        src={require("../../../images/arrow-left.svg")}
                                                    />

                                                    <HashLink>Решения</HashLink>
                                                </li>
                                            </ul>
                                        </div>
                                        {this.state.isSolutionsHovering && <SolutionsBlockPages activeCat={`control`} handler={this.solutionsHover.bind(this)} />}
                                    </div>
                                )}

                                <div className='ecosystem-header ecosystem-header-space-top'>
                                    <h1 className="ecosystem-header-title">Яндекс умный дом</h1>
                                    <img alt='' />
                                </div>
                            </Row>
                        </Container>
                    </header>
                </Container>
                <Container fluid className='custom-container'>
                    <Row className='ecosystem-row'>
                        <div className='ecosystem-description ecosystem-description-yandex-space-top'>
                            <Col xl='12' lg='12' md='12'>
                                <p>
                                    Для управления системами умный дом в 2019 году Яндексом запущен собственный сервис «Умный дом Яндекс» со встроенным голосовым
                                    помощником Алиса. Используя активный голосовой ассистент, можно управлять «умными устройствами» дома посредством голосовых команд на
                                    русском языке. Это могут быть как собственные устройства Яндекса, так и техника производителей-партнеров, которая аппаратно
                                    поддерживает Алису.
                                </p>
                            </Col>
                            <Col xl='12' lg='12' md='12'>
                                <h2 className='ecosystem-description-key-features-title'>Основные возможности</h2>
                            </Col>
                            <Col xl='12' lg='12' md='12'>
                                <p>
                                    Роль центра управления устройствами и системами умного дома может играть любое устройство, поддерживающее работу с голосовым
                                    помощником Алиса. Это может быть станция умный дом Яндекс, умные колонки или смартфон со специальным приложением Яндекса умный дом.
                                    Обязательным требованием к этим устройствам является наличие подключения к интернету. Без него Алиса не сможет поддерживать диалог с
                                    пользователем и передавать исполнительные команды «умным устройствам». Дело в том, что все пользовательские команды обрабатываются не
                                    аппаратной платформой станции умный дом, а на серверах Яндекса.
                                </p>
                            </Col>
                        </div>
                    </Row>

                    <div className='ecosystem-description'>
                        <Row className='ecosystem-row'>
                            <Col xl='12' lg='12' md='12'>
                                <p>
                                    <img
                                        data-src={YandexMusic}
                                        alt='Управление музыкой в Яндекс станции.'
                                        src='/images/image-preloader.png'
                                        className='lazy ecosystem-description-yandex-music-image'
                                    />
                                    Подавая голосовые команды Алисе можно управлять:
                                </p>
                                <ul>
                                    <li>освещением (включать/выключать лампочки, розетки, изменять уровень освещения);</li>
                                    <li>термостатами радиаторов отопления, системы теплого пола;</li>
                                    <li>работой вытяжек, систем кондиционирования, вентиляции;</li>
                                    <li>совместимыми с Алисой бытовыми приборами.</li>
                                </ul>
                            </Col>
                        </Row>
                    </div>

                    <Row className='ecosystem-row'>
                        <div className='ecosystem-description'>
                            <Col xl='12' lg='12' md='12'>
                                <p>
                                    <img
                                        className='ecosystem-description-leftimg lazy'
                                        data-src={YandexApp}
                                        alt='Приложение Яндекс умный дом с Алисой.'
                                        src='/images/image-preloader.png'
                                    />
                                    С приложением Яндекса умный дом (поддерживается ОС Android и iOS) легко формировать единую структуру из всех «умных устройств и
                                    приборов», которые есть в доме. Достаточно выбрать подключенные устройства, задать им имена (желательно короткие, поскольку они будут
                                    использоваться при работе с Алисой), чтобы их работой можно было управлять голосовыми командами.
                                </p>
                            </Col>
                        </div>
                    </Row>
                    <div className='ecosystem-description'>
                        <Row className='ecosystem-row'>
                            <Col xl='12' lg='12' md='12'>
                                <p>
                                    Умный дом с Алисой не ограничивается только простыми командами. Платформа от Яндекса расширяет возможности управления, позволяя
                                    работать сразу с несколькими устройствами. Например, можно создать собственный сценарий пробуждения ранним утром. На фразу «Алиса, с
                                    добрым утром» помощник выключит ночник, включит бодрую музыку для зарядки, включит чайник или кофеварку. Используя приложение Яндекс,
                                    пользователь может придумать любой сценарий и ключевую фразу к нему.
                                </p>
                            </Col>
                        </Row>
                    </div>
                    <Row className='ecosystem-images'>
                        <Col xl='6' lg='6' md='6' sm='6'>
                            <img className='yandex-reminder-image' src={YandexRemind} alt='Напоминания от Яндекс Алисы.' />
                        </Col>
                        <Col xl='6' lg='6' md='6' sm='6'>
                            <img
                                className='lazy yandex-radio-image'
                                data-src={YandexRadio}
                                alt='Управление радио в Яндекс станции.'
                                src='/images/image-preloader.png'
                            />
                        </Col>
                    </Row>
                    <div className='ecosystem-description'>
                        <Row className='ecosystem-row'>
                            <Col xl='12' lg='12' md='12'>
                                <p>
                                    Умный дом Яндекс позволяет управлять не только отдельными устройствами, а и группой одинаковых. Например, в одну группу можно объединить
                                    несколько лампочек или отопительные радиаторы в разных комнатах, для которых нужно поддерживать одинаковую температуру. Вариантов может
                                    быть море, все зависит от потребностей пользователя.
                                </p>
                                <p>
                                    Особенностью Яндекс станции умный дом является возможность удаленного управления. Например, возвращаясь с работы, через бортовой компьютер
                                    автомобиля, который поддерживает работу с голосовым помощником Алиса, можно включить систему обогрева на полную мощность, чтобы к приезду в
                                    доме было тепло.
                                </p>
                            </Col>
                        </Row>
                    </div>
                    <Row className='ecosystem-row'>
                        <div className='ecosystem-description'>
                            <Col xl='12' lg='12' md='12'>
                                <h2>Недостатки платформы умный дом Яндекс</h2>
                            </Col>
                            <Col xl='12' lg='12' md='12'>
                                <p>В отличие платформы Apple HomeKit, Яндекс-решение имеет ряд недостатков:</p>
                                <ul>
                                    <li>поддерживается ограниченное число устройств;</li>
                                    <li>
                                        отсутствует возможность добавления в систему умного дома датчиков движения, температуры, контроля утечки воды, газа и любых других
                                        датчиков;
                                    </li>
                                    <li>
                                        станция умный дом Яндекс недоступна при отсутствии интернета, даже при подключении к домашней беспроводной сети Алиса не будет
                                        работать (Apple HomeKit достаточно подключения к локальной сети дома (Wi-Fi)).
                                    </li>
                                </ul>
                            </Col>
                        </div>
                    </Row>
                    <Row className='ecosystem-row'>
                        <div className='ecosystem-description'>
                            <Col xl='12' lg='12' md='12'>
                                <h2>И как они решаются?</h2>
                            </Col>
                            <Col xl='12' lg='12' md='12'>
                                <p>
                                    Homechain поможет раскрыть все возможности системы «Умный дом Яндекс». Мы готовы интегрировать в систему умного дома любые «умные
                                    устройства». Процесс взаимодействия реализуется через специальный локальный сервер, что позволяет максимально использовать все
                                    возможности нового решения Яндекса.
                                </p>
                                <p>
                                    Наши специалисты могут организовать управление только через платформу Яндекса или сделать комбинированное решение с поддержкой функций
                                    и Яндекса, и Apple HomeKit. Интеграцию автоматической системы управления вашим домом мы реализуем как на этапе проектирования и его
                                    строительства, так и уже на введенных в эксплуатацию объектах.
                                </p>
                            </Col>
                        </div>
                    </Row>
                </Container>

                <YandexDevices />
                <ServicesBlock />
                <FeedbackFormSmall />
            </div>
        );
    }
}

export default Yandex;
