import React, { Component } from "react";

import classNames from "classnames";
import axios from "axios";
import Navbar from "../Navbar/Navbar";
import BlogPreview from "./BlogPreview";
import { Link } from "react-router-dom";
import NotFound from "../NotFound/NotFound";

import format from 'date-fns/format';

class BlogPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      categories: [],
      blogs: [],
      activeCategory: this.props.match.params.categoryAlias || 'all'
    };

    if (window.innerWidth > 576) {
      this.state.isMobile = false;
    } else {
      this.state.isMobile = true;
    }
  }

  componentDidMount() {
    Promise.all([
      axios
        .get(
          process.env.NODE_ENV === "production"
            ? `https://homechain.ai/s/api/blog/undefined`
            : `http://localhost:4000/api/blog/undefined`
        ),
      axios
        .get(
          process.env.NODE_ENV === "production"
            ? `https://homechain.ai/s/api/blog`
            : `http://localhost:4000/api/blog`
        )
    ])
      .then(([resCategories, resBlog]) => {
        this.setState({ categories: resCategories.data.categories, blogs: resBlog.data.blog });
      });
  }

  componentWillReceiveProps(nextProps) {
      if (this.props.match.params.categoryAlias !== nextProps.match.params.categoryAlias) {
        this.setState({
            activeCategory: nextProps.match.params.categoryAlias || 'all'
          })
      }
  }

  uppendTitle() {
	  const { categories, activeCategory } = this.state;
	  const category = categories.find(category => category.alias === activeCategory);

	  if (!category && activeCategory !== 'all') {
		  return;
	  }
	  document.querySelector("head title").innerHTML = category ? category.title : 'Блог — база знаний, оборудование, портфолио | Умный дом в Москве | Homeсhain';
  }

  uppendMeta() {
    const { categories, activeCategory } = this.state;
    const category = categories.find(category => category.alias === activeCategory);

    if (!category && activeCategory !== 'all') {
      return;
    }

	  if (category) {
      let description = document.querySelector("meta[name=description]");
      let keywords = document.querySelector("meta[name=keywords]");
      if (description) description.content = category.description;
      else {
        description = document.createElement("meta");
        description.name = "description";
        description.content = category.description;
        document.querySelector("head").appendChild(description);
      }

      if (keywords) keywords.content = category.keywords;
      else {
        keywords = document.createElement("meta");
        keywords.name = "keywords";
        keywords.content = category.keywords;
        document.querySelector("head").appendChild(keywords);
      }
    } else {
      let description = document.querySelector("meta[name=description]");
      if (description) {
        description.content = 'Блог об умном доме в Москве "под ключ" — база знаний, обзоры оборудования и софта, портфолио проектов. Homechain';
      } else {
        description = document.createElement("meta");
        description.name = "description";
        description.content = 'Блог об умном доме в Москве "под ключ" — база знаний, обзоры оборудования и софта, портфолио проектов. Homechain';
        document.querySelector("head").appendChild(description);
      }
    }
  }

  handleClickCategory = id => () => {
    this.setState({
      activeCategory: id
    })
  };

  renderFilters = () => {
    const { categories, activeCategory } = this.state;

    if (categories.length <= 1) {
      return null;
    }

    return <div className='blog-page__categories-block'>
      <ul className='blog-page__categories'>
        {[
          { alias: 'all', name: 'Все публикации' },
          ...categories
        ].map(category => <Link className='blog-page__category-link' to={category.alias === 'all' ? '/umnyj-dom-blog' :`/umnyj-dom-blog/${category.alias}`}>
            <li
              onClick={this.handleClickCategory(category.alias)}
              className={classNames('blog-page__category', {
                'blog-page__category_active': activeCategory === category.alias
              })}
              key={category.alias}
            >
              {category.name}
            </li>
          </Link>
        )}
      </ul>
    </div>
  };

  renderPreviews = (category) => {
    const { blogs, categories, activeCategory } = this.state;
    const filteredBlog = blogs.filter(blog => activeCategory === 'all' ? true : blog.categoryId === category._id);

    return <div>
      <div className='blog-page__preview-list'>
        {
          filteredBlog.map(blog => {
            const currentCategory = categories.find(category => category._id === blog.categoryId);

            return <Link to={`/umnyj-dom-blog/${currentCategory.alias}/${blog.alias}`} key={blog._id} className='blog-page__preview'>
              <BlogPreview
                category={currentCategory.name}
                date={format(blog.date, 'dd-MM-yyyy')}
                name={blog.name}
                imgSrc={`"${
                  process.env.NODE_ENV === "production"
                    ? "https://homechain.ai/s/storage/blog"
                    : "http://localhost:4000/storage/blog"
                  }/${blog.alias}/${blog.backgroundImage}"`}
              />
            </Link>
          })
        }
      </div>
    </div>
  };

  render() {
    const { categories, activeCategory } = this.state;
    const category = categories.find(category => category.alias === activeCategory);

    if (!category && activeCategory !== 'all') {
      return <NotFound />
    }

    return <div className='blog-page'>
		{this.uppendTitle()}
		{this.uppendMeta()}
      <Navbar activeBlog />
      <h1 className='blog-page__main-title'>Homechain Блог</h1>
      <div>
        {this.renderFilters()}
        {this.renderPreviews(category)}
      </div>
    </div>
  }
}

export default BlogPage;
