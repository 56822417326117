import React from "react";
import HowWeWorkBlock from "./HowWeWorkBlock";

class Control extends React.Component {
  render() {
    return (
      <div className="howwework">
        <div className="page-description" style={{ padding: "0px" }}>
          <h2 style={{ marginBottom: "48px" }}>Как мы работаем</h2>

          <HowWeWorkBlock />
        </div>
      </div>
    );
  }
}

export default Control;
