import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";
import FeedbackFormSmall from "../FeedbackForm/FeedbackFormSmall";
import FeedbackFormBig from "../FeedbackForm/FeedbackFormBig";
import Navbar from "../Navbar/Navbar";

import ServicesDropdownPages from "../Services/ServicesDropdown/ServicesDropdownPages";

class Delivery extends React.Component {
  constructor(props) {
    super(props);
    this.servicesHover = this.servicesHover.bind(this);
    this.state = {
      style: "borderTop: 2px solid",
      buttonOrder: false,

      activeServices: true,
      isSolutionsHovering: false,
      isServicesHovering: false,
      isMobile: window.innerWidth <= 576
    };
  }

  uppendTitle() {
    document.querySelector("head title").innerHTML =
      "Комплектация объекта и поставка оборудования | Умный дом в Москве | Homeсhain";
  }

  uppendMeta() {
    let description = document.querySelector("meta[name=description]");
    let keywords = document.querySelector("meta[name=keywords]");
    if (description) {
      description.content =
        'Комплектация объекта и поставка оборудования. Умный дом в Москве "под ключ" - проектирование, монтаж, пусконаладка. Homeсhain +7 (499) 113 10 10.';
    } else {
      description = document.createElement("meta");
      description.name = "description";
      description.content =
        'Комплектация объекта и поставка оборудования. Умный дом в Москве "под ключ" - проектирование, монтаж, пусконаладка. Homeсhain +7 (499) 113 10 10.';
      document.querySelector("head").appendChild(description);
    }

    if (keywords) {
      keywords.content =
        "расчет стоимости умного дома, стоимость системы умный дом, умный дом стоимость";
    } else {
      keywords = document.createElement("meta");
      keywords.name = "keywords";
      keywords.content =
        "расчет стоимости умного дома, стоимость системы умный дом, умный дом стоимость";
      document.querySelector("head").appendChild(keywords);
    }
  }

  servicesHover(state) {
    this.setState(this.toggleServicesHover);
  }

  toggleDropdown(state) {
    return {
      isServicesHovering: false,
      isSolutionsHovering: false
    };
  }

  toggleServicesHover(state) {
    return {
      isServicesHovering: !state.isServicesHovering,
      isSolutionsHovering: false
    };
  }

  render() {
    return (
      <div>
        {this.uppendTitle()}
        {this.uppendMeta()}
        <Navbar activeServices={this.state.activeServices} />
        <Container
          fluid
          className="page"
          style={{
            backgroundImage: `url(${require("../../images/Services/Delivery/delivery-bg.png")})`
          }}
        >
          <header>
            <Container fluid>
              <Row className="page-row custom-container">
                {!this.state.isMobile && (
                  <div className="page-nav">
                    <ul>
                      <li>
                        <Link to="/">Главная</Link>
                      </li>
                      <img src={require("../../images/arrow.svg")} alt="" />
                      <li onClick={this.servicesHover.bind(this)}>
                        <HashLink>Услуги</HashLink>
                      </li>
                      <img src={require("../../images/arrow.svg")} alt="" />
                      <li style={{ color: "#181818" }}>
                        Поставка оборудования
                      </li>
                    </ul>
                  </div>
                )}

                {this.state.isMobile && (
                  <div className="page-nav">
                    <ul>
                      <img
                        style={{ width: "6px" }}
                        src={require("../../images/arrow-left.svg")}
                        alt=""
                      />
                      <li onClick={this.servicesHover.bind(this)}>
                        <HashLink>Услуги</HashLink>
                      </li>
                    </ul>
                  </div>
                )}

                <div className="page-header">
                  <h1>Поставка оборудования</h1>
                  <HashLink smooth to="#feedback-big">
                    <button className="hover-button">Оставить заявку</button>
                  </HashLink>
                </div>
              </Row>
              {this.state.isServicesHovering && (
                <ServicesDropdownPages
                  handler={this.servicesHover.bind(this)}
                />
              )}
            </Container>
          </header>
        </Container>
        <Container fluid className="custom-container">
          <Row className="page-description">
            <Col xl="12">
              <h3>Гарантия своевременного получения техники</h3>
              <p>
                Гарантированная поставка технологичного оборудования в
                оговоренные сроки является залогом своевременного выполнения
                работ по оснащению жилья автоматизированными системами,
                запланированных на каждом этапе проектной документацией. Поэтому
                важно выполнить грамотный расчет стоимости умного дома с учетом
                услуг по доставке электронных устройств в ходе разработки
                проекта. Поставка оборудования раньше срока способствует
                оперативному выполнению операций, даже в случае возникновения
                непредвиденных (аварийных) задержек.
              </p>
              <p>
                Мы оказываем комплексные услуги по расчету стоимости системы
                умный дом и разработке графиков, согласно которым комплектуется
                и поставляется:
              </p>
              <div className="page-products">
                <div>
                  <img
                    data-src={require("../../images/Services/Delivery/Products/automation.png")}
                    alt="умный дом стоимость — оборудование для автоматизации"
                    class="lazy"
                    src="/images/image-preloader.png"
                  />
                  <span>Оборудование для автоматизации</span>
                </div>
                <div>
                  <img
                    data-src={require("../../images/Services/Delivery/Products/communication.png")}
                    alt="умный дом стоимость — телеком оборудование"
                    class="lazy"
                    src="/images/image-preloader.png"
                  />
                  <span>Телекоммуникационное оборудование</span>
                </div>
                <div>
                  <img
                    data-src={require("../../images/Services/Delivery/Products/multimedia.png")}
                    alt="стоимость системы умный дом — мультимедиа"
                    class="lazy"
                    src="/images/image-preloader.png"
                  />
                  <span>Мультимедийные устройства</span>
                </div>
                <div>
                  <img
                    data-src={require("../../images/Services/Delivery/Products/smart-devices.png")}
                    alt="стоимость системы умный дом — бытовая техника"
                    class="lazy"
                    src="/images/image-preloader.png"
                  />
                  <span>Умная бытовая техника</span>
                </div>
                <div>
                  <img
                    data-src={require("../../images/Services/Delivery/Products/unavaible.png")}
                    alt="умный дом стоимость — уникальное оборудование"
                    class="lazy"
                    src="/images/image-preloader.png"
                  />
                  <span>Оборудование, недоступное в СНГ </span>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="page-description">
            <Col xl="12">
              <h3>Взаимовыгодное сотрудничество</h3>
              <p>
                Технология «умный дом» базируется на инновационных технических
                решениях. При выборе оборудования, которое планируется
                эксплуатировать в едином комплексе, основным фактором для нас
                является надежность и долговечность применения на действующих
                объектах, а также положительные отзывы заказчиков, использующих
                данные устройства.
              </p>
              <p className="text-block-without-space">
                С целью своевременной поставки цифрового оборудования мы
                организовали выгодное сотрудничество с проверенными
                производителями, которые:
              </p>
              <ul style={{ marginBottom: "70px" }}>
                <li>предлагают сертифицированные смарт-устройства;</li>
                <li>отличаются кристальной репутацией;</li>
                <li>заботятся о качестве своей продукции;</li>
                <li>предоставляют длительную гарантию;</li>
                <li>являются надежными поставщиками.</li>
              </ul>
            </Col>
          </Row>
        </Container>
        <FeedbackFormSmall />

        <Container fluid className="custom-container">
          <Row className="page-description">
            <Col xl="12">
              <h3>Индивидуальный подход</h3>
              <p>
                Кроме детального расчета стоимости умного дома мы можем
                проверить любую смету и дизайн-проект с целью выбора и поставки
                необходимого оборудования для комплектации объектов.
                Обеспечиваем максимальное соответствие эксплуатационных
                параметров и функциональных возможностей электронной аппаратуры
                требованиям заказчиков.
              </p>
              <p>
                Кроме своевременных поставок технологичных устройств компанией
                предоставляется консультация касательно технических
                характеристик, а также даются квалифицированные советы по
                практическому использованию оборудования. Обеспечиваем выезд
                наших специалистов на объект с целью выполнения необходимых
                замеров.
              </p>
            </Col>
          </Row>
        </Container>
        <FeedbackFormBig bigForm={true} />
      </div>
    );
  }
}

export default Delivery;
