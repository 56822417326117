import React from "react";
import {Container} from "react-bootstrap";
import classNames from "classnames";
import {HashLink} from "react-router-hash-link";

class ApplicationForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            align: this.props.align
        };

        this.openBigForm = this.props.openBigForm
    }

    render() {
        const { align } = this.state;

        return (
            <div className={classNames("feedback-small feedback-application", {
                ["center-align"]: align === 'center',
                ["left-align"]: align === 'left'
            })}>
                <div className="applicationLabel">
                    <img src={require("../../images/Feedback/applicationLabel.png")} alt=""/>
                </div>
                <Container>
                    <p>
                        Homechain - официальный партнёр и инсталлятор Loxone.
                    </p>
                    <div className="feedback-small-buttons">
                        <HashLink smooth to='#feedback-big'>
                            <button
                                type="submit"
                                className={classNames('feedback-form-small-submit-button hover-button')}
                                onClick={this.openBigForm}
                            >
                                Оставить заявку
                            </button>
                        </HashLink>
                    </div>
                </Container>
            </div>
        );
    }
}

export default ApplicationForm;
