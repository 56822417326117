import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Navbar from "../../../components/Navbar/Navbar";
import FeedbackFormSmall from "../../FeedbackForm/FeedbackFormSmall";
import AmazonBg from "../../../images/AmazonEcho/amazon-bg.png";

import AmazonEchoPod from "../../../images/AmazonEcho/amazonecho-pod.png";
import AmazonEchoTable from "../../../images/AmazonEcho/amazonecho-table.png";
import AmazonEchoDot from "../../../images/AmazonEcho/amazonecho-dot.png";

import AmazonDevices from "./AmazonDevices";

import { HashLink } from "react-router-hash-link";
import SolutionsBlockPages from "../../Solutions/SolutionsBlock/SolutionsBlockPages";
import ServicesBlock from "../../ServicesBlock/ServicesBlock";

class AmazonEcho extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: window.innerWidth <= 576
    };
  }

  uppendTitle() {
    document.querySelector("head title").innerHTML =
      "Амазон Алекса, Amazon Echo | Умный дом в Москве | Homeсhain";
  }

  uppendMeta() {
    let description = document.querySelector("meta[name=description]");
    let keywords = document.querySelector("meta[name=keywords]");
    if (description) {
      description.content =
        'Амазон Алекса, Amazon Echo. Умный дом в Москве "под ключ" - проектирование, комплектация, монтаж, пусконаладка. Homeсhain +7 (499) 113 10 10.';
    } else {
      description = document.createElement("meta");
      description.name = "description";
      description.content =
        'Амазон Алекса, Amazon Echo. Умный дом в Москве "под ключ" - проектирование, комплектация, монтаж, пусконаладка. Homeсhain +7 (499) 113 10 10.';
      document.querySelector("head").appendChild(description);
    }

    if (keywords) {
      keywords.content = "алекса амазон амазон алекса на русском";
    } else {
      keywords = document.createElement("meta");
      keywords.name = "keywords";
      keywords.content = "алекса амазон амазон алекса на русском";
      document.querySelector("head").appendChild(keywords);
    }
  }

  solutionsHover() {
    this.setState(this.toggleSolutionsHover);
  }

  toggleDropdown() {
    return {
      isServicesHovering: false,
      isSolutionsHovering: false
    };
  }

  toggleSolutionsHover(state) {
    return {
      isSolutionsHovering: !state.isSolutionsHovering,
      isServicesHovering: false
    };
  }

  render() {
    return (
      <div>
        {this.uppendTitle()}
        {this.uppendMeta()}
        <Navbar activeCat={"control"} activeSolutions={true} />
        <Container
          fluid
          className="ecosystem"
          style={{
            backgroundImage: `url(${AmazonBg})`
          }}
        >
          <header>
            <Container fluid>
              <Row className="ecosystem-row custom-container">
                {!this.state.isMobile ? (
                  <div>
                    <div className="ecosystem-nav">
                      <ul>
                        <li>
                          <Link to="/">Главная</Link>
                        </li>
                        <img
                          src={require("../../../images/arrow.svg")}
                          alt=""
                        />
                        <li onClick={this.solutionsHover.bind(this)}>
                          <HashLink>Решения</HashLink>
                        </li>
                        <img
                          src={require("../../../images/arrow.svg")}
                          alt=""
                        />
                        <li style={{ color: "#0F75BC" }}>
                          <Link to="/solutions/ecosystemi">Экосистемы</Link>
                        </li>
                        <img
                          src={require("../../../images/arrow.svg")}
                          alt=""
                        />
                        <li style={{ color: "#000" }}>Amazon Echo</li>
                      </ul>
                    </div>
                    {this.state.isSolutionsHovering && (
                      <SolutionsBlockPages
                        activeCat={`control`}
                        handler={this.solutionsHover.bind(this)}
                      />
                    )}
                  </div>
                ) : (
                  <div>
                    <div className="page-nav">
                      <ul>
                        <li
                          style={{ margin: "0px" }}
                          onClick={this.solutionsHover.bind(this)}
                        >
                          <img
                            style={{
                              width: "6px",
                              marginLeft: "5px",
                              marginRight: "5px"
                            }}
                            src={require("../../../images/arrow-left.svg")}
                            alt=""
                          />

                          <HashLink>Решения</HashLink>
                        </li>
                      </ul>
                    </div>
                    {this.state.isSolutionsHovering && (
                      <SolutionsBlockPages
                        activeCat={`control`}
                        handler={this.solutionsHover.bind(this)}
                      />
                    )}
                  </div>
                )}

                <div className="ecosystem-header">
                  <h1>Amazon Echo</h1>
                </div>
              </Row>
            </Container>
          </header>
        </Container>
        <Container fluid className="custom-container">
          <Row className="ecosystem-row">
            <div className="ecosystem-description">
              <Col xl="12" lg="12" md="12">
                <h2>Основные возможности</h2>
              </Col>
              <Col xl="12" lg="12" md="12">
                <h3>Голосовой помощник</h3>
                <p>
                  После создания электронной книги Kindle, Amazon продолжил
                  попытки создания собственных умных устройств и приступил к
                  разработке Echo и помощника Амазон Алекса. Работы над
                  программными модулями ведутся специалистами дочерней компании
                  Lab126 в Калифиорнии Массачусетсе с 2010 года.
                </p>
                <p>
                  На первом этапе функциями Эхо могли воспользоваться только
                  приглашенные пользователи Amazon Prime. С 2015 года открыт
                  доступ для широкой клиентской аудитории в США на платной
                  основе. С 2016 года сервисом Амазон могут пользоваться жители
                  Германии и Великобритании. Впервые реклама Эхо появилась в
                  видеоролике, снятом компанией для Супербоула в 2016 году.
                </p>
              </Col>
            </div>
          </Row>

          <div className="ecosystem-description">
            <Row className="ecosystem-row">
              <Col xl="12" lg="12" md="12">
                <h3>Искусственный интеллект</h3>
                <p>
                  <img
                    data-src={AmazonEchoPod}
                    alt=""
                    class="lazy"
                    src="/images/image-preloader.png"
                  />
                  Echo с голосовым помощником Амазон Алекса ‒ это искусственный
                  интеллект, помещенный в собственное устройство. Прибор
                  представляет собой колонку, работающую по беспроводной
                  технологии Bluetooth или Wi-Fi. Преимущество использования
                  продукта в предоставлении возможности клиентам управлять
                  смарт-оборудованием с помощью персонального помощника Alexa,
                  который устанавливается на сторонние устройства и
                  взаимодействует с программными продуктами других
                  производителей.
                </p>
              </Col>
            </Row>
          </div>

          <Row className="ecosystem-row">
            <div className="ecosystem-description">
              <Col xl="12" lg="12" md="12">
                <p>
                  Ассистент работает по принципу известных продуктов Siri или
                  Google Assistant. Прибор всегда находится в дежурном приеме и
                  готов незамедлительно отреагировать на команду пользователя.
                  Управление голосовым ассистентом простое. Пользователю
                  достаточно произнести фразу, например, «Алекса, увеличь
                  яркость света на кухне», «Алекса, выключи телевизор» и желание
                  сразу выполняется. Alexa может работать с другими программами
                  и осуществлять покупки.
                </p>
                <p>
                  Смарт-колонки не поддерживают русский язык. О планах компании
                  разработать версию Амазон Алекса на русском неизвестно. Мы
                  используем программный продукт Эхо на английском. Есть
                  возможность установить диалект: американский или британский.
                  Система также работает на немецком и японском языках.
                </p>
              </Col>
            </div>
          </Row>
          <Row className="ecosystem-row">
            <div className="ecosystem-description ecosystem-description-image-wrapper">
              <Col xl="12" lg="12" md="12">
                <img
                  data-src={AmazonEchoTable}
                  alt=""
                  src="/images/image-preloader.png"
                  className="lazy ecosystem-description-image"
                />
              </Col>
            </div>
          </Row>
          <div className="ecosystem-description">
            <Row className="ecosystem-row">
              <Col xl="12" lg="12" md="12">
                <h3> Многофункциональность</h3>
                <p className="amazon-alexa-description">
                  Амазон Алекса ‒ это интеллектуальный голосовой помощник,
                  встроенный в электронный гаджет. Программный модуль отличается
                  функциональностью и способен:
                </p>
                <ul>
                  <li>отвечать на вопросы пользователей;</li>
                  <li>воспроизводить музыкальные композиции;</li>
                  <li>регулировать освещение; </li>
                  <li>включать/отключать электророзетки;</li>
                  <li>управлять аудио-, видео- и телевизионной аппаратурой;</li>
                  <li>контролировать работу системы отопления.</li>
                </ul>
                <p>
                  Многие функции Echo Amazon доступны в России. Исключением
                  являются покупки с использованием голосовых команд по причине
                  отсутствия поддержки русского языка. Не работает музыкальный
                  стриминговый сервис Amazon Music, который доступный только в
                  28 западных странах. Но, мы предлагаем достойную альтернативу
                  ‒ прекрасно функционирующие Spotify и Pandora.
                </p>
                <p>
                  Все остальные функции работают без сбоев. Пользователи могут
                  устанавливать российский адрес, следить за погодой и получать
                  ответы голосового ассистента на справочные вопросы.
                  Предоставляется возможность устанавливать сценарии управления
                  умным домом.
                </p>
              </Col>
            </Row>
          </div>
          <Row className="ecosystem-row">
            <div className="ecosystem-description ecosystem-description-image-wrapper">
              <Col xl="12" lg="12" md="12">
                <img
                  data-src={AmazonEchoDot}
                  alt=""
                  src="/images/image-preloader.png"
                  className="lazy ecosystem-description-image"
                />
              </Col>
            </div>
          </Row>

          <Row className="ecosystem-row">
            <div className="ecosystem-description">
              <Col xl="12" lg="12" md="12">
                <h2>Управление</h2>
              </Col>
              <Col xl="12" lg="12" md="12">
                <p>
                  Для контроля функционирования электронных приборов,
                  составляющие автоматизированную систему, приложение Google
                  Home умный дом устанавливается на мобильные гаджеты. С целью
                  дистанционного контроля применяется:
                </p>
              </Col>
            </div>
          </Row>

          {/* <div className="ecosystem-description ">
            <Row className="ecosystem-row">
              <Col
                xl="3"
                lg="3"
                md="4"
                sm="6"
                xs="12"
                className="ecosystem-devices"
              >
                <p>Смартфоны</p>
                <div>
                  <img data-src={Smartphone} alt="" />
                </div>
                <p
                  className="control-hover-text"
                  style={{ width: "60%", color: "#000000" }}
                >
                  Голосовой помощник Алекса и центр управления умным домом на
                  любом Android или iOS смартфоне.
                </p>
              </Col>
              <Col
                xl="3"
                lg="3"
                md="4"
                sm="6"
                xs="12"
                className="ecosystem-devices"
              >
                <p>Планшеты</p>
                <div>
                  <img data-src={Tablet} alt="" />
                </div>
                <p
                  className="control-hover-text"
                  style={{ width: "60%", color: "#000000" }}
                >
                  Управляйте всеми устройствами голосовым помощником Алекса с
                  помощью любого Android или iOS планшета.
                </p>
              </Col>
              <Col
                xl="3"
                lg="3"
                md="4"
                sm="6"
                xs="12"
                className="ecosystem-devices"
              >
                <p>Персональные компьютеры</p>
                <img data-src={PC} alt="" />
                <p
                  className="control-hover-text"
                  style={{ width: "60%", color: "#000000" }}
                >
                  Большие возможности для персноализации в интуитивно понятном
                  интерфейсе.
                </p>
              </Col>
              <Col
                xl="3"
                lg="3"
                md="4"
                sm="6"
                xs="12"
                className="ecosystem-devices"
              >
                <p>Умные часы</p>
                <img data-src={SmartWatch} alt="" />
                <p
                  className="control-hover-text"
                  style={{ width: "60%", color: "#000000" }}
                >
                  Умные часы не дадут пропустить уведомления и позволят
                  оперативно управлять системой.
                </p>
              </Col>
              <Col
                xl="3"
                lg="3"
                md="4"
                sm="6"
                xs="12"
                className="ecosystem-devices"
              >
                <p>Колонки Amazon Echo</p>
                <img data-src={SpeakerEcho} alt="" />
                <p
                  className="control-hover-text"
                  style={{ width: "60%", color: "#000000" }}
                >
                  Умная колонка от Amazon с голосовым помощником Алекса
                  позволяет управлять умными устройствами, музыкой и
                  приложениями.
                </p>
              </Col>
              <Col
                xl="3"
                lg="3"
                md="4"
                sm="6"
                xs="12"
                className="ecosystem-devices"
              >
                <p>Колонки с поддержкой Alexa</p>
                <img data-src={SpeakerAlexa} alt="" />
                <p
                  className="control-hover-text"
                  style={{ width: "60%", color: "#000000" }}
                >
                  Колонки с поддержкой голосового помощника Алекса от различных
                  производителей сильно расширяют выбор дизайна и качества
                  звучания.
                </p>
              </Col>
            </Row>
          </div> */}
        </Container>
        <AmazonDevices />
        <Container>
          <Row className="ecosystem-row">
            <div className="ecosystem-description">
              <Col xl="12" lg="12" md="12">
                <p>
                  Приложение отлично работает на всех моделях мобильных
                  приборов. Благодаря простому и понятному интерфейсу
                  пользователи получают доступ к функционалу программного модуля
                  и возможность управлять умными электронными устройствами в
                  доме или квартире.
                </p>
              </Col>
            </div>
          </Row>
        </Container>
        <ServicesBlock />
        <FeedbackFormSmall />
      </div>
    );
  }
}

export default AmazonEcho;
