import React, { Component } from "react";
import axios from "axios";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import { Row, Container, Col } from "react-bootstrap";
import FeedbackFormSmall from "../FeedbackForm/FeedbackFormSmall";
import FeedbackFormBig from "../FeedbackForm/FeedbackFormBig";
import BreadcrumbsConstructor from "./BreadcrumbsConstructor";
import TableConstructor from "./TableConstructor";
import TabsConstructor from "./TabsConstructor";
import BlockThumbnail from "../BlockThumbnail/BlockThumbnail";

import NotFound from "../NotFound/NotFound";
import ServicesBlock from "../ServicesBlock/ServicesBlock";
import format from 'date-fns/format';

class BlogConstructor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isBigForm: false,
      feedbackHeight: "170px",
      page: null,
      isNotFound: false,
      openDropdown: false,
      isSent: false,
      categories: [],
    };

    if (window.innerWidth > 576) this.state.isMobile = false;
    else this.state.isMobile = true;

    if (window.innerWidth > 992 && window.innerWidth < 1199)
      this.state.feedbackHeight = "287px";
    if (window.innerWidth > 769 && window.innerWidth < 991)
      this.state.feedbackHeight = "287px";

    if (window.innerWidth < 576) {
      this.state.feedbackHeight = "475px";
      this.state.breakWord = true;
    }
  }

  slicer(res) {
    let { blocks } = res.data.data;

    blocks = blocks.map(block => {
      const subtitles = block.subtitles.map((subtitle, i) => {
        const img = subtitle.images.find(img => img.position === "right");

        if (
          img &&
          block.subtitles[i + 1] &&
          !block.subtitles[i + 1].images.length
        ) {
          const deletedSub = block.subtitles.splice(i + 1, 1);
          return { ...subtitle, additions: deletedSub };
        } else {
          return subtitle;
        }
      });

      return {
        ...block,
        subtitles: [...subtitles].filter(el => el && true)
      };
    });

    const correctStructure = {
      ...res.data.data,
      blocks
    };

    this.setState({
      page: correctStructure,
      isNotFound: false,
      isSent: true
    });
  }

  componentDidMount() {
    Promise.all([
      axios
        .get(
          process.env.NODE_ENV === "production"
            ? `https://homechain.ai/s/api/blog/undefined`
            : `http://localhost:4000/api/blog/undefined`
        ),
      axios
        .get(
          process.env.NODE_ENV === "production"
            ? `https://homechain.ai/s/api/blog-item/${this.props.match.params.alias}`
            : `http://localhost:4000/api/blog-item/${this.props.match.params.alias}`
        )
    ])
      .then(([resCategories, resBlog]) => {
        this.setState({
          categories: resCategories.data.categories
        });
        this.slicer(resBlog);
      })
      .catch(err => {
        this.setState({
          isNotFound: true
        });
        console.error(err);
      });
  }

  componentDidUpdate(prevProps, PrevState) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      Promise.all([
        axios
          .get(
            process.env.NODE_ENV === "production"
              ? `https://homechain.ai/s/api/blog/undefined`
              : `http://localhost:4000/api/blog/undefined`
          ),
        axios
          .get(
            process.env.NODE_ENV === "production"
              ? `https://homechain.ai/s/api/blog-item/${this.props.match.params.alias}`
              : `http://localhost:4000/api/blog-item/${this.props.match.params.alias}`
          )
      ])
        .then(([resCategories, resBlog]) => {
          this.setState({
            categories: resCategories.data.categories
          });
          this.slicer(resBlog);
        })
        .catch(err => {
          this.setState({
            isNotFound: true
          });
          console.error(err);
        });

      this.setState({
        openDropdown: false
      });
    }
  }

  uppendTitle() {
    document.querySelector("head title").innerHTML = this.state.page.title;
  }

  uppendMeta() {
    let description = document.querySelector("meta[name=description]");
    let keywords = document.querySelector("meta[name=keywords]");
    if (description) description.content = this.state.page.description;
    else {
      description = document.createElement("meta");
      description.name = "description";
      description.content = this.state.page.description;
      document.querySelector("head").appendChild(description);
    }

    if (keywords) keywords.content = this.state.page.keywords;
    else {
      keywords = document.createElement("meta");
      keywords.name = "keywords";
      keywords.content = this.state.page.keywords;
      document.querySelector("head").appendChild(keywords);
    }


      const { categories } = this.state;
      const category = categories.find(category =>  this.state.page && this.state.page.categoryId === category._id);
      const articleSchemaContent = `
        {
          "@context": "https://schema.org",
          "@type": "BlogPosting",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://homechain.ai/umnyj-dom-blog/${category.alias}/${this.state.page.alias}"
          },
          "headline": "${this.state.page.name}",
          "description": "${this.state.page.description}",
          "image": "https://homechain.ai/s/storage/blog/${this.state.page.alias}/${this.state.page.backgroundImage}",  
          "author": {
            "@type": "Organization",
            "name": "Homechain Systems LLC"
          },  
          "publisher": {
            "@type": "Organization",
            "name": "Homechain Systems LLC",
            "logo": {
              "@type": "ImageObject",
              "url": "https://homechain.ai/images/logo.png",
              "width": 225,
              "height": 60
            }
          },
          "datePublished": "${format(this.state.page.date, 'yyyy-MM-dd')}",
          "dateModified": "${format(new Date(this.state.page.updatedAt), 'yyyy-MM-dd')}"
        }
        `;

      let articleSchema = document.querySelector("#article-schema");

      if (articleSchema) {
        articleSchema.text = articleSchemaContent
      } else {
        articleSchema = document.createElement('script');
        articleSchema.type = 'application/ld+json';
        articleSchema.setAttribute('id', 'article-schema');
        articleSchema.text = articleSchemaContent
      }
      document.querySelector("body").appendChild(articleSchema);
  }

  componentWillUnmount() {
    const articleSchema = document.querySelector("#article-schema");

    if (articleSchema) {
      articleSchema.parentNode.removeChild(articleSchema);
    }
  }

  openBigForm() {
    this.setState({ isBigForm: true });
  }

  hideBigForm = () => {
    this.setState({ isBigForm: false });
  };

  createUllist = ullistArray => {
    return (
      <div>
        {ullistArray.length > 0 && (
          <ul>
            {ullistArray.map(list => (
              <li> {list} </li>
            ))}
          </ul>
        )}
      </div>
    );
  };

  createLi = content => {
    const firstIndex = content.indexOf("**");
    const lastIndex = content.lastIndexOf("**");

    if (firstIndex + lastIndex > 1) {
      const lists = content
        .substr(firstIndex, lastIndex - firstIndex)
        .split("\n")
        .map(list => list.replace(/\*\*/g, ""));
      const contentBefore = content.slice(0, firstIndex);
      const contentAfter = content.slice(lastIndex + 2);

      return (
        <div>
          {contentBefore} 
          {lists && (
            <ul style={{ marginBottom: "0px" }}>
              {lists.map(list => (
                <li> {list} </li>
              ))}
            </ul>
          )}
          {contentAfter}  
        </div>
      );
    }

    const regex = /@\[(.+?)\s*\]\(([A-Za-z0-9-_.:/]+)\)/gm;
    const subst = '<a href="$2">$1</a>';
    const result = content.replace(regex, subst);

    return <div dangerouslySetInnerHTML={{ __html: result }} />;
  };

  createAdditions = additionsArray => {
    return additionsArray.map(add => (
      <div>
        <h3 style={{ marginBottom: "10px", marginTop: "10px" }}>{add.name}</h3>

        {this.createLi(add.content)}
      </div>
    ));
  };

  render() {
    const { categories } = this.state;
    const category = this.state.isNotFound ? null : categories.find(category =>  this.state.page && this.state.page.categoryId === category._id);

    return this.state.isNotFound ? (
      <NotFound />
    ) : (
      this.state.page && (
        <div>
          {this.uppendTitle()}
          {this.uppendMeta()}
          <Navbar
            activeBlog
          />
          <Container
            fluid
            className="page blogPage"
            style={{
              backgroundImage: `url("${
                process.env.NODE_ENV === "production"
                  ? "https://homechain.ai/s/storage/blog"
                  : "http://localhost:4000/storage/blog"
              }/${this.state.page.alias}/${this.state.page.backgroundImage}")`
            }}
          >
            <header>
              <Container fluid>
                <Row className="page-row custom-container blog-constructor">
                  <BreadcrumbsConstructor
                    mobile={this.state.isMobile}
                    path={window.location.pathname}
                    lastPath={this.state.page.name}
                    activeCat='Блог'
                    subCatName='Блог'
                    subSubCatName={category.name}
                  />
                  <div className="page-header">
                    <h1 className="blog-page__title">{this.state.page.name}</h1>
                  </div>
                </Row>
              </Container>
            </header>
          </Container>
			<Container style={{ padding: "0px" }} fluid>
				<div className='blog-constructor__info-wrapper'>
					<div className='blog-constructor__info'>
						<div>
							<div className='blog-constructor__info-header'>
								<div className='blog-constructor__info-logo'><img className='blog-constructor__info-logo-img' src="/images/logo.png" alt="logo"/></div>
								<div>Homechain Systems LLC</div>
							</div>
							<div className='blog-constructor__info-date'>{format(this.state.page.date, 'dd-MM-yyyy')} в разделе <Link to={`/umnyj-dom-blog/${category.alias}`}>{category.name}</Link></div>
						</div>
						<div className='blog-constructor__info-share'>
							<a
								/* eslint-disable-next-line max-len */
								href={`https://www.facebook.com/sharer/sharer.php?u=${`https://homechain.ai${this.props.location.pathname}`}`}
								rel="nofollow"
								target="_blank"
								className='blog-constructor__info-share-icon'
							>
								<img src={require("./images/facebook.svg")} alt="facebook" className='blog-constructor__info-share-icon-image'/>
							</a>
							<a
								/* eslint-disable-next-line max-len */
								href={`http://twitter.com/share?url=${`https://homechain.ai${this.props.location.pathname}`}`}
								rel="nofollow"
								target="_blank"
								className='blog-constructor__info-share-icon'
							>
								<img src={require("./images/twitter.svg")} alt="twitter" className='blog-constructor__info-share-icon-image'/>
							</a>
							<a
								className='blog-constructor__info-share-icon'
								href={`https://telegram.me/share/url?url=${`https://homechain.ai${this.props.location.pathname}`}`}>
								<img src={require("./images/telegram.svg")} alt="telegram" className='blog-constructor__info-share-icon-image'/>
							</a>
						</div>
					</div>
				</div>
			</Container>

          <Container style={{ padding: "0px" }} fluid>
            {this.state.page.blocks.map(block => (
              <div>
                <Row className="ecosystem-row">
                  <div
                    style={{ marginBottom: "0px" }}
                    className="ecosystem-description"
                  >
                    <Col className="custom-container" xl="12" lg="12" md="12">
                      <h2 style={{ marginTop: "0px" }}>{block.name}</h2>
                    </Col>
                  </div>
                </Row>
                <div>
                  {block.subtitles.map(subtitle => (
                    <div>
                      <Row
                        style={{ marginTop: "0px", marginBottom: "0px" }}
                        className="ecosystem-description"
                      >
                        <Col
                          className="custom-container"
                          xl="12"
                          lg="12"
                          md="12"
                          style={{ marginBottom: "20px" }}
                        >
                          {subtitle.name === "" ? (
                            <h3 style={{ marginBottom: "10px" }}>
                              {subtitle.name}
                            </h3>
                          ) : (
                            <h3 style={{ marginBottom: "10px" }}>
                              {subtitle.name}
                            </h3>
                          )}

                          <p style={{ marginBottom: "3px" }}>
                            {subtitle.images &&
                              subtitle.images.map(
                                img =>
                                  img.position === "right" && (
                                    <img
                                      src={`${
                                        process.env.NODE_ENV === "production"
                                          ? "https://homechain.ai/s/storage/blog"
                                          : "http://localhost:4000/storage/blog"
                                      }/${this.state.page.alias}/${img.name}`}
                                      alt={img.alt}
                                    />
                                  )
                              )}

                            {subtitle.content &&
                              this.createLi(subtitle.content)}
                            {subtitle.additions &&
                              this.createAdditions(subtitle.additions)}
                          </p>

                          {subtitle.images &&
                            subtitle.images.map(
                              img =>
                                img.position === "bottom" && (
                                  <Row>
                                    <img
                                      className="page-photo"
                                      style={{ width: "100%", height: "100%" }}
                                      src={`${
                                        process.env.NODE_ENV === "production"
                                          ? "https://homechain.ai/s/storage/blog"
                                          : "http://localhost:4000/storage/blog"
                                      }/${this.state.page.alias}/${img.name}`}
                                      alt={img.alt}
                                    />
                                  </Row>
                                )
                            )}
                          {subtitle.ullist &&
                            this.createUllist(subtitle.ullist)}
                        </Col>
                      </Row>

                      {subtitle.gallery.length > 0 && (
                        <Row
                          style={{ display: "flex", justifyContent: "center" }}
                          className="page-description"
                        >
                          {subtitle.gallery.map(img => (
                            <Col xl="3" lg="3" md="3" sm="6" xs="6">
                              <BlockThumbnail
                                name={img.description}
                                alt={img.alt}
                                imgSrc={`${
                                  process.env.NODE_ENV === "production"
                                    ? "https://homechain.ai/s/storage/blog"
                                    : "http://localhost:4000/storage/blog"
                                }/${this.state.page.alias}/${img.name}`}
                              />
                            </Col>
                          ))}
                        </Row>
                      )}

                      {subtitle.tables && (
                        <TableConstructor
                          style={{ marginBottom: "40px" }}
                          tables={Object.values(subtitle.tables)}
                        />
                      )}

                      {subtitle.tabs && (
                        <TabsConstructor tabs={subtitle.tabs} />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </Container>
          <ServicesBlock />
          <div>
            <div
              style={{
                transition: "all 2s ease",
                height: !this.state.isSent
                  ? this.state.isBigForm
                    ? "1205px"
                    : this.state.feedbackHeight
                  : "unset"
              }}
            >
              <div
                style={{
                  visibility: this.state.isBigForm ? "visible" : "hidden",
                  opacity: this.state.isBigForm ? "1" : "0",
                  transition: "all 2s ease"
                }}
              >
                {this.state.isBigForm && (
                  <FeedbackFormBig hideBig={this.hideBigForm} solution={true} />
                )}
              </div>
              <div
                style={{
                  visibility: !this.state.isBigForm ? "visible" : "hidden",
                  opacity: !this.state.isBigForm ? "1" : "0",
                  transition: "all 2s ease"
                }}
              >
                {!this.state.isBigForm && <FeedbackFormSmall />}
              </div>
            </div>
          </div>
        </div>
      )
    );
  }
}

export default BlogConstructor;
