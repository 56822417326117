const codes = [
  // CIS
  ["7", 3, 7],
  ["373", 2, 6],
  ["374", 2, 6],
  ["375", 2, 7],
  ["380", 2, 7],
  ["992", 3, 7],
  ["993", 2, 6],
  ["994", 2, 7],
  ["996", 3, 6],
  ["998", 2, 7],
  ["995", 3, 6],
  // Europe
  ["43", 3, 8],
  ["355", 2, 7],
  ["32", 1, 7],
  ["359", 3, 6],
  ["44", 2, 8],
  ["36", 2, 6],
  ["49", 2, 9],
  ["350", 2, 7],
  ["30", 2, 8],
  ["45", 2, 7],
  ["353", 2, 6],
  ["354", 1, 6],
  ["34", 2, 7],
  ["39", 2, 8],
  ["357", 2, 6],
  ["371", 2, 6],
  ["370", 3, 5],
  ["41", 2, 8],
  ["352", 2, 8],
  ["356", 1, 7],
  ["373", 2, 8],
  ["37", 2, 7],
  ["31", 2, 7],
  ["47", 1, 7],
  ["48", 2, 7],
  ["351", 2, 7],
  ["40", 2, 7],
  ["378", 2, 7],
  ["298", 2, 7],
  ["358", 2, 7],
  ["33", 2, 7],
  ["420", 2, 7],
  ["421", 2, 7],
  ["41", 2, 7],
  ["46", 2, 7],
  ["372", 2, 5],
  ["381", 2, 6],
  ["389", 2, 6],
  ["386", 2, 6],
  ["387", 2, 6],
  ["385", 2, 6],
  // Turkey
  ["90", 3, 7]
];

let prev = "";

export const phoneHandler = base => {
  if (prev.length >= base.length - 1 && /\)$/.test(base))
    base = base.replace(/\d\)$/, "");
  prev = base;

  let str = base.replace(/\D/gi, "");
  let code = null;
  let prefix = null;
  let length = 10;
  let pl = null; // prefix length
  let isValid = false;

  for (const cd of codes) {
    if (str.indexOf(cd[0]) === 0) {
      code = cd[0];
      pl = cd[1];
      length = cd[2] || 7;
      str = str.slice(code.length);

      break;
    }
  }

  if (code && str.length > 0) {
    prefix = str.slice(0, pl);
    str = str.slice(pl).slice(0, length);

    isValid = str.length === length;
  }

  str = str
    .split("")
    .map((n, i, s) => {
      if (i % 2 === 0 && i !== 0) return `${n}-`;
      return n;
    })
    .join("");

  return {
    phone: `+${code || str}${code && prefix ? " (" + prefix : ""}${
      code && prefix && prefix.length === pl ? ") " : ""
    }${prefix ? str : ""}`.replace(/-$/, ""),
    isValid
  };
};
