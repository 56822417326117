import React from "react";

class Shield extends React.Component {
  render() {
    return (
      <div>
        <svg
          width="35"
          height="45"
          viewBox="0 0 35 45"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M33.8522 9.20201L17.3151 0.390167C17.0697 0.25923 16.7757 0.261067 16.5322 0.394761L0.452659 9.2066C0.188947 9.35132 0.0253906 9.6279 0.0253906 9.92882V21.4393C0.0350387 30.9013 5.64099 39.4613 14.3104 43.2521L16.5423 44.2247C16.7509 44.3157 16.9884 44.3161 17.1974 44.2256L19.7124 43.1395C28.5426 39.431 34.2873 30.7883 34.2882 21.211V9.92882C34.2882 9.62468 34.1205 9.34489 33.8522 9.20201ZM32.6416 21.211C32.6398 30.1281 27.2897 38.174 19.0678 41.6248L19.0623 41.6276L16.8722 42.5731L14.9692 41.7429C6.89936 38.2145 1.68071 30.247 1.67152 21.4393V10.4163L16.9333 2.05192L32.6416 10.4222V21.211Z"
            fill="#818181"
          />
          <path
            d="M10.8594 20.9055C10.564 20.5595 10.0444 20.5186 9.69845 20.814C9.3525 21.109 9.31161 21.6291 9.60702 21.9746L13.956 27.0673C14.2463 27.4073 14.7549 27.4537 15.1013 27.1716L25.2299 18.9313C25.5827 18.6446 25.636 18.1259 25.3489 17.7731C25.0622 17.4207 24.5435 17.367 24.1907 17.6541L14.686 25.3863L10.8594 20.9055Z"
            fill="#818181"
          />
        </svg>
      </div>
    );
  }
}

export default Shield;
