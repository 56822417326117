import React from "react";

class BlockThumbnail extends React.Component {
  render() {
    return (
      <div className="block-thumbnail">
        <img
          data-src={this.props.imgSrc}
          alt={this.props.alt}
          class="lazy"
          src="/images/image-preloader.png"
        />
        <p className="block-thumbnail-text">{this.props.name}</p>
      </div>
    );
  }
}

export default BlockThumbnail;
