import React from "react";
import { Col } from "react-bootstrap";

import Fade from 'react-bootstrap/Fade';

class InfoBlock extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            blocks: [
                {
                    name: "Квартира",
                    imgSrc: require("../../images/Info/info-apartment.jpg"),
                    xl: "3",
                    lg: "4",
                    md: "6",
                    sm: "12"
                },
                {
                    name: "Загородный дом",
                    imgSrc: require("../../images/Info/info-countryhouse.jpg"),
                    xl: "9",
                    lg: "12",
                    md: "12",
                    sm: "12"
                },
                {
                    name: "Офисное здание",
                    imgSrc: require("../../images/Info/info-office.jpg"),
                    xl: "6",
                    lg: "12",
                    md: "12",
                    sm: "12"
                },
                {
                    name: "Жилой комплекс",
                    imgSrc: require("../../images/Info/info-complex.jpg"),
                    xl: "6",
                    lg: "4",
                    md: "6",
                    sm: "12"
                },
                {
                    name: "Стадион",
                    imgSrc: require("../../images/Info/info-stadium.jpg"),
                    xl: "3",
                    lg: "4",
                    md: "6",
                    sm: "12"
                },
                {
                    name: "Spa центр",
                    imgSrc: require("../../images/Info/info-spa.jpg"),
                    xl: "3",
                    lg: "4",
                    md: "6",
                    sm: "12"
                },
                {
                    name: "Отель",
                    imgSrc: require("../../images/Info/info-hotel.jpg"),
                    xl: "3",
                    lg: "4",
                    md: "6",
                    sm: "12"
                },
                {
                    name: "Производство",
                    imgSrc: require("../../images/Info/info-production.jpg"),
                    xl: "3",
                    lg: "4",
                    md: "6",
                    sm: "12"
                }
            ],
            blocksShow: 4,
            animation: false
        };
    }

    animate = () => {
        this.setState({
            animation: true
        })
    }

    render() {
        const { blocks } = this.state;
        const renderBlocks = blocks.map((item, i) => {
            return (
                <Fade in={this.animate} appear key={i} className="infoFade">
                    <Col xl="3" lg="6" md="6" sm="6" xs="12">
                        <div
                            style={{
                                boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.18)",
                                backgroundColor: "#fff"
                            }}
                        >
                            <div className="info-thumbnail info-block-thumbnail">
                                <div
                                    style={{
                                        backgroundImage: `url(${item.imgSrc})`,
                                        backgroundRepeat: "no-repeat",
                                        backgroundPosition: "center center",
                                        backgroundSize: "cover",
                                        width: "100%",
                                        height: "100%"
                                    }}
                                />
                            </div>
                            <div>
                                <h3 className={"info-block-thumbnail-name"}>{item.name}</h3>
                            </div>
                        </div>
                    </Col>
                </Fade>
            );
        });

        return <div className='info-block'>
            <div className="InfoBlocksContainer">
                {renderBlocks}
            </div>
        </div>;
    }
}

export default InfoBlock;
