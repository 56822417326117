import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";

import ChevronLeft from "../../../images/chevron-left.svg";

import ChevronLeftActive from "../../../images/chevron-left-active.svg";
import ChevronRightActive from "../../../images/chevron-right-active.svg";
import "../Control/control.css";

import classNames from "classnames";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        right: "0%"
      }}
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        left: "0%"
      }}
      onClick={onClick}
    />
  );
}

class AuthorizeStagesBlock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: window.innerWidth <= 991,
      isRenderBlock1: true,
      isRenderBlock2: false,
      isRenderBlock3: false,
      isRenderBlock4: false,
      isRenderBlock5: false,
      isRenderBlock6: false,
      isRenderBlock7: false,
      isRenderBlock8: false,
      blocks: [
        {
          id: "1",
          name: "Выезд"
        },
        {
          id: "2",
          name: "Проверка"
        },
        {
          id: "3",
          name: "Фиксация нарушений"
        },
        {
          id: "4",
          name: "Заполнение журнала"
        },
        {
          id: "5",
          name: "Контроль"
        },
        {
          id: "6",
          name: "Промежуточная приёмка"
        }
      ],
      meeting: [
        {
          id: "1",
          name: "Выезд",
          prev: "",
          next: "Проверка",
          description:
            "Выезд и работа на объекте согласно утвержденному графику."
        }
      ],
      proposition: [
        {
          id: "2",
          name: "Проверка",
          prev: "Выезд",
          next: "Фиксация нарушений",
          description:
            "Проверка инженерами соответствия выполненных монтажных операций нормативным требованиям."
        }
      ],
      project: [
        {
          id: "3",
          name: "Фиксация нарушений",
          prev: "Проверка",
          next: "Заполнение журнала",
          description:
            "Фиксация выявленных нарушений и несоответствий положениям проекта. Формирование указаний и сроков устранения недостатков."
        }
      ],
      inbox: [
        {
          id: "4",
          name: "Заполнение журнала",
          prev: "Фиксация нарушений",
          next: "Контроль",
          description: "Своевременное заполнение журнала авторского надзора."
        }
      ],
      builtin: [
        {
          id: "5",
          name: "Контроль",
          prev: "Заполнение журнала",
          next: "Промежуточная приёмка",
          description:
            "Контроль соблюдения строителями требований, указаний, а также предписаний проектной документации. Данные требования касаются также проверки применяемых строительных материалов."
        }
      ],
      setting: [
        {
          id: "6",
          name: "Промежуточная приёмка",
          prev: "Контроль",
          next: "",
          description:
            "Осуществление промежуточной приёмки проведенных работ и приемки всего комплекса."
        }
      ]
    };
  }

  renderBlock1 = (state) => {
      this.setState(this.toggleRenderBlock1);
  };

  renderBlock2 = (state) => {
      this.setState(this.toggleRenderBlock2);
  };

  renderBlock3 = (state) => {
      this.setState(this.toggleRenderBlock3);
  };

  renderBlock4 = (state) => {
      this.setState(this.toggleRenderBlock4);
  };

  renderBlock5 = (state) => {
      this.setState(this.toggleRenderBlock5);
  };

  renderBlock6 = (state) => {
      this.setState(this.toggleRenderBlock6);
  };

  toggleRenderBlock1(state) {
    return {
      isRenderBlock1: true,
      isRenderBlock2: false,
      isRenderBlock3: false,
      isRenderBlock4: false,
      isRenderBlock5: false,
      isRenderBlock6: false,
      isRenderBlock7: false,
      isRenderBlock8: false
    };
  }

  toggleRenderBlock2(state) {
    return {
      isRenderBlock1: false,
      isRenderBlock2: true,
      isRenderBlock3: false,
      isRenderBlock4: false,
      isRenderBlock5: false,
      isRenderBlock6: false,
      isRenderBlock7: false,
      isRenderBlock8: false
    };
  }
  toggleRenderBlock3(state) {
    return {
      isRenderBlock1: false,
      isRenderBlock2: false,
      isRenderBlock3: true,
      isRenderBlock4: false,
      isRenderBlock5: false,
      isRenderBlock6: false,
      isRenderBlock7: false,
      isRenderBlock8: false
    };
  }
  toggleRenderBlock4(state) {
    return {
      isRenderBlock1: false,
      isRenderBlock2: false,
      isRenderBlock3: false,
      isRenderBlock4: true,
      isRenderBlock5: false,
      isRenderBlock6: false,
      isRenderBlock7: false,
      isRenderBlock8: false
    };
  }
  toggleRenderBlock5(state) {
    return {
      isRenderBlock1: false,
      isRenderBlock2: false,
      isRenderBlock3: false,
      isRenderBlock4: false,
      isRenderBlock5: true,
      isRenderBlock6: false,
      isRenderBlock7: false,
      isRenderBlock8: false
    };
  }
  toggleRenderBlock6(state) {
    return {
      isRenderBlock1: false,
      isRenderBlock2: false,
      isRenderBlock3: false,
      isRenderBlock4: false,
      isRenderBlock5: false,
      isRenderBlock6: true,
      isRenderBlock7: false,
      isRenderBlock8: false
    };
  }
  toggleRenderBlock7(state) {
    return {
      isRenderBlock1: false,
      isRenderBlock2: false,
      isRenderBlock3: false,
      isRenderBlock4: false,
      isRenderBlock5: false,
      isRenderBlock6: false,
      isRenderBlock7: true,
      isRenderBlock8: false
    };
  }
  toggleRenderBlock8(state) {
    return {
      isRenderBlock1: false,
      isRenderBlock2: false,
      isRenderBlock3: false,
      isRenderBlock4: false,
      isRenderBlock5: false,
      isRenderBlock6: false,
      isRenderBlock7: false,
      isRenderBlock8: true
    };
  }

  render() {
    let settings = {
      infinite: false,
      autoplay: false,
      arrows: false,
      slidesToScroll: 1,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            arrows: true,
            slidesToShow: 6,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 768,
          settings: {
            arrows: true,
            slidesToShow: 5,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 576,
          settings: {
            arrows: true,
            slidesToShow: 4,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 420,
          settings: {
            arrows: true,
            slidesToShow: 3,
            slidesToScroll: 1
          }
        }
      ]
    };
    const renderBlock1 = this.state.meeting.map(item => {
      return (
        <Col
          key={item}
          xl="12"
          lg="12"
          md="12"
          sm="12"
          xs="12"
          className="authorize-description"
        >
          <div className="howwework-description-item">
            <button disabled className="arrow stages-button-active-left">
              <img src={ChevronLeft} alt="" />
            </button>
            <h6 className="h6-left">{item.prev}</h6>
            <h6 className="h6-right">{item.next}</h6>
            <button
              className="arrow active stages-button-active-right"
              onClick={this.renderBlock2}
            >
              <img src={ChevronRightActive} alt="" />
            </button>
            <p>{item.description}</p>
          </div>
        </Col>
      );
    });

    const renderBlock2 = this.state.proposition.map(item => {
      return (
        <Col
          key={item}
          xl="12"
          lg="12"
          md="12"
          sm="12"
          xs="12"
          className="authorize-description"
        >
          <div className="howwework-description-item">
            <button
              className="arrow stages-button-active-left"
              onClick={this.renderBlock1}
            >
              <img src={ChevronLeftActive} alt="" />
            </button>
            <h6 className="h6-left">{item.prev}</h6>
            <h6 className="h6-right">{item.next}</h6>
            <button
              className="arrow active stages-button-active-right"
              onClick={this.renderBlock3}
            >
              <img src={ChevronRightActive} alt="" />
            </button>
            <p>{item.description}</p>
          </div>
        </Col>
      );
    });

    const renderBlock3 = this.state.project.map(item => {
      return (
        <Col
          key={item}
          xl="12"
          lg="12"
          md="12"
          sm="12"
          xs="12"
          className="authorize-description"
        >
          <div className="howwework-description-item">
            <button
              className="arrow stages-button-active-left"
              onClick={this.renderBlock2}
            >
              <img src={ChevronLeftActive} alt="" />
            </button>
            <h6 className="h6-left">{item.prev}</h6>
            <h6 className="h6-right">{item.next}</h6>
            <button
              className="arrow active stages-button-active-right"
              onClick={this.renderBlock4}
            >
              <img src={ChevronRightActive} alt="" />
            </button>
            <p>{item.description}</p>
          </div>
        </Col>
      );
    });

    const renderBlock4 = this.state.inbox.map(item => {
      return (
        <Col
          key={item}
          xl="12"
          lg="12"
          md="12"
          sm="12"
          xs="12"
          className="authorize-description"
        >
          <div className="howwework-description-item">
            <button
              className="arrow stages-button-active-left"
              onClick={this.renderBlock3}
            >
              <img src={ChevronLeftActive} alt="" />
            </button>
            <h6 className="h6-left">{item.prev}</h6>
            <h6 className="h6-right">{item.next}</h6>
            <button
              className="arrow active stages-button-active-right"
              onClick={this.renderBlock5}
            >
              <img src={ChevronRightActive} alt="" />
            </button>
            <p>{item.description}</p>
          </div>
        </Col>
      );
    });

    const renderBlock5 = this.state.builtin.map(item => {
      return (
        <Col
          key={item}
          xl="12"
          lg="12"
          md="12"
          sm="12"
          xs="12"
          className="authorize-description"
        >
          <div className="howwework-description-item">
            <button
              className="arrow stages-button-active-left"
              onClick={this.renderBlock4}
            >
              <img src={ChevronLeftActive} alt="" />
            </button>
            <h6 className="h6-left">{item.prev}</h6>
            <h6 className="h6-right">{item.next}</h6>
            <button
              className="arrow active stages-button-active-right"
              onClick={this.renderBlock6}
            >
              <img src={ChevronRightActive} alt="" />
            </button>
            <p>{item.description}</p>
          </div>
        </Col>
      );
    });

    const renderBlock6 = this.state.setting.map(item => {
      return (
        <Col
          key={item}
          xl="12"
          lg="12"
          md="12"
          sm="12"
          xs="12"
          className="authorize-description"
        >
          <div className="howwework-description-item">
            <button
              className="arrow stages-button-active-left"
              onClick={this.renderBlock5}
            >
              <img src={ChevronLeftActive} alt="" />
            </button>
            <h6 className="h6-left">{item.prev}</h6>
            <h6 className="h6-right">{item.next}</h6>
            <button
              className="arrow active stages-button-active-right"
              onClick={this.renderBlock7}
            >
              <img src={ChevronRightActive} alt="" />
            </button>
            <p>{item.description}</p>
          </div>
        </Col>
      );
    });

    return (
      <div>
        <Container
          style={{ maxWidth: "unset" }}
          fluid
          className="howwework-block authorize-block"
        >
          <div className={"tabs-render-ssr"}>
            <div className={"tabs-render-ssr"}>
              <Slider {...settings}>
                <div>
                  <button
                    onClick={this.renderBlock1}
                    className={classNames('authorize-stage-button', { ['active']: this.state.isRenderBlock1 } )}
                  >
                    <h3 className={classNames('authorize-stage-button-title-number', { ['active']: this.state.isRenderBlock1 } )}>
                      {this.state.blocks[0].id}
                    </h3>
                    <p className={classNames('authorize-stage-button-title-number', { ['active']: this.state.isRenderBlock1 } )}>
                      {this.state.blocks[0].name}
                    </p>
                  </button>
                </div>
                <div>
                  <button
                    onClick={this.renderBlock2}
                    className={classNames('authorize-stage-button', { ['active']: this.state.isRenderBlock2 } )}
                  >
                    <h3 className={classNames('authorize-stage-button-title-number', { ['active']: this.state.isRenderBlock2 } )}>
                      {this.state.blocks[1].id}
                    </h3>
                    <p className={classNames('authorize-stage-button-title-number', { ['active']: this.state.isRenderBlock2 } )}>
                      {this.state.blocks[1].name}
                    </p>
                  </button>
                </div>
                <div>
                  <button
                    onClick={this.renderBlock3}
                    className={classNames('authorize-stage-button', { ['active']: this.state.isRenderBlock3 } )}
                  >
                    <h3 className={classNames('authorize-stage-button-title-number', { ['active']: this.state.isRenderBlock3 } )}>
                      {this.state.blocks[2].id}
                    </h3>
                    <p className={classNames('authorize-stage-button-title-number', { ['active']: this.state.isRenderBlock3 } )}>
                      {this.state.blocks[2].name}
                    </p>
                  </button>
                </div>
                <div>
                  <button
                    onClick={this.renderBlock4}
                    className={classNames('authorize-stage-button', { ['active']: this.state.isRenderBlock4 } )}
                  >
                    <h3 className={classNames('authorize-stage-button-title-number', { ['active']: this.state.isRenderBlock4 } )}>
                      {this.state.blocks[3].id}
                    </h3>
                    <p className={classNames('authorize-stage-button-title-number', { ['active']: this.state.isRenderBlock4 } )}>
                      {this.state.blocks[3].name}
                    </p>
                  </button>
                </div>
                <div>
                  <button
                    onClick={this.renderBlock5}
                    className={classNames('authorize-stage-button', { ['active']: this.state.isRenderBlock5 } )}
                  >
                    <h3 className={classNames('authorize-stage-button-title-number', { ['active']: this.state.isRenderBlock5 } )}>
                      {this.state.blocks[4].id}
                    </h3>
                    <p className={classNames('authorize-stage-button-title-number', { ['active']: this.state.isRenderBlock5 } )}>
                      {this.state.blocks[4].name}
                    </p>
                  </button>
                </div>
                <div>
                  <button
                    onClick={this.renderBlock6}
                    className={classNames('authorize-stage-button', { ['active']: this.state.isRenderBlock6 } )}
                  >
                    <h3 className={classNames('authorize-stage-button-title-number', { ['active']: this.state.isRenderBlock6 } )}>
                      {this.state.blocks[5].id}
                    </h3>
                    <p className={classNames('authorize-stage-button-title-number', { ['active']: this.state.isRenderBlock6 } )}>
                      {this.state.blocks[5].name}
                    </p>
                  </button>
                </div>
              </Slider>
            </div>
          </div>
        </Container>

        <Container fluid>
          <Row>{this.state.isRenderBlock1 && renderBlock1}</Row>
          <Row>{this.state.isRenderBlock2 && renderBlock2}</Row>
          <Row>{this.state.isRenderBlock3 && renderBlock3}</Row>
          <Row>{this.state.isRenderBlock4 && renderBlock4}</Row>
          <Row>{this.state.isRenderBlock5 && renderBlock5}</Row>
          <Row>{this.state.isRenderBlock6 && renderBlock6}</Row>
        </Container>
      </div>
    );
  }
}

export default AuthorizeStagesBlock;
