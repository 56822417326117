import React from "react";
import { Container, Col } from "react-bootstrap";
import Slider from "react-slick";

import "../../Tabs/Control/control.css";

function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: "block"
            }}
            onClick={onClick}
        />
    );
}

function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: "block"
            }}
            onClick={onClick}
        />
    );
}

class AppleDevices extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            renderResponsive: null,

            apple: [
                {
                    deviceName: "HomePod",
                    imgSrc: require("../../../images/AppleHomeKit/Devices/homepod.png"),
                    alt: "apple homekit на homepod",
                    text:
                        "Умная колонка, сочетающая в себе возможности голосового помощника и качественное звучание. Попросите Siri включить свет, запустить видео или любимую песню."
                },
                {
                    deviceName: "iPhone",
                    imgSrc: require("../../../images/AppleHomeKit/Devices/iphone.png"),
                    alt: "apple homekit на айфоне",
                    text: "Центр управления умным домом и голосовой ассисстент Siri всегда под рукой."
                },
                {
                    deviceName: "iPad",
                    imgSrc: require("../../../images/AppleHomeKit/Devices/ipad.png"),
                    alt: "apple homekit на айпаде",
                    text: "Управляйте умным домом с помощью iPad и Siri. Доступен настенный монтаж в качестве центра управления."
                },
                {
                    deviceName: "Apple TV",
                    imgSrc: require("../../../images/AppleHomeKit/Devices/apple-tv.png"),
                    alt: "apple homekit на эпл tv",
                    text: "Управляйте звуком и картинкой на всех устройствах. Наслаждайтесь любимым контентом."
                },
                {
                    deviceName: "MacBook",
                    imgSrc: require("../../../images/AppleHomeKit/Devices/macbook.png"),
                    alt: "apple homekit на macbook",
                    text: "Самый удобный способ настройки и создания сценариев."
                },
                {
                    deviceName: "Apple Watch",
                    imgSrc: require("../../../images/AppleHomeKit/Devices/apple-watch.png"),
                    alt: "apple homekit на эпл watch",
                    text: "Оперативное управление и контроль. С AppleWatch вы не пропустите ни одного уведомления."
                }
            ]
        };
    }

    render() {
        let settings = {
            infinite: false,
            autoplay: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1
                    }
                },

                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const renderApple = this.state.apple.map((item) => {
            return (
                <div key={item}>
                    <Col xl='3' lg='3' md='4' sm='6' xs='12' className='ecosystem-devices'>
                        <p className="ecosystem-devices-device-name">{item.deviceName}</p>
                        <div>
                            <img data-src={item.imgSrc} alt={item.alt} class='lazy' src='/images/image-preloader.png' />
                        </div>
                        <p className='control-hover-text'>{item.text}</p>
                    </Col>
                </div>
            );
        });

        return (
            <div>
                <Container fluid className='devices control-devices control-devices-bottom-space'>
                    <Slider {...settings}>{renderApple}</Slider>
                </Container>
            </div>
        );
    }
}

export default AppleDevices;
