import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Navbar from "../../../components/Navbar/Navbar";
import FeedbackFormSmallShowroom from "../../FeedbackForm/FeedbackFormSmallShowroom";
import FeedbackFormBig from "../../FeedbackForm/FeedbackFormBig";
import { HashLink } from "react-router-hash-link";

import classNames from "classnames";
import LoxoneBg from "../../../images/Loxone/loxone-bg.jpg";
import LoxoneBgDevice from "../../../images/Loxone/loxone-bg-device.png";

import ServicesBlock from "../../ServicesBlock/ServicesBlock";
import InfoBlock from "../../InfoBlock/InfoBlock";

import SolutionsBlockPages from "../../Solutions/SolutionsBlock/SolutionsBlockPages";
import LoxoneDevices from "./LoxoneDevices";
import FeaturesBlock from "../../FeaturesBlock/FeaturesBlock";
import ApplicationForm from "../../FeedbackForm/ApplicationForm";

const STANDARDS_ARRAY = [
  {
    src: "RS485.png",
    title: "RS485",
    text: " – стандарт физического уровня, широко используемый в промышленной автоматизации.  Расширение RS485 Extension."
  },
  {
    src: "RS232.png",
    title: "RS232",
    text: " – ещё один стандарт физического уровня, исторически применяемый в телекоммуникационном оборудовании. Расширение RS232 Extension."
  },
  {
    src: "Modbus.png",
    title: "Modbus",
    text: " – устаревший, но надёжный коммутационный протокол для связи устройств. Широко применим в климатических системах. Расширение Modbus Extension."
  },
  {
    src: "KNX.png",
    title: "KNX",
    text: " – единая шина автоматизации, разработанная в Европе. Бывшее название: EIB (European Installation Bus). На рынке представлено большое количество KNX оборудования от разных производителей.  Расширение KNX Extension."
  },
  {
    src: "DALI.png",
    title: "DALI",
    text: " – цифровой протокол, управляющий системами освещения. Часто используется в коммерческой недвижимости (офисы, отели, заводы). Расширение DALI Extension."
  },
  {
    src: "DMX.png",
    title: "DMX",
    text: " – цифровой стандарт управления светодиодами. Применяется для сложных систем многоцветной подсветки фасадов.  Расширение DMX Extension."
  },
  {
    src: "1-Wire.png",
    title: "1-Wire",
    text: " – шина для передачи данных, часто используемая для датчиков температуры. Расширение 1-Wire extension."
  }
];

const FEATURES_BLOCK_1 = [
  {
    src: "features1_1.png",
    title: "",
    text: "Избранные треки всегда на видном месте"
  },
  {
    src: "features1_2.png",
    title: "",
    text: "Упрощенный дизайн"
  },
  {
    src: "features1_3.png",
    title: "",
    text: "Ещё более быстрая навигация и управление через Ваше устройство"
  }
];

const FEATURES_BLOCK_2 = [
  {
    src: "features2_1.png",
    title: "Персонализация",
    text: "Полный контроль помещений и категорий: возможность смены наименований, иконок, групп и местоположений в интерфейсе с расширением возможностей."
  },
  {
    src: "features2_2.png",
    title: "Освещение",
    text: "Программа позволяет сменять режимы освещения, выбирать цвет и яркость света, управлять всеми группами освещения по отдельности и целиком (например, выключить весь свет)."
  },
  {
    src: "features2_3.png",
    title: "Режимы освещения",
    text: "Редактирование режимов освещения всегда доступно и осуществляется в несколько касаний экрана."
  },
  {
    src: "features2_4.png",
    title: "Палитра цветов",
    text: "Сохраняйте любимые цвета для освещения и используйте их в режимах или сценариях."
  },
  {
    src: "features2_5.png",
    title: "Шторы",
    text: "Автоматическое и ручное управление любым типом штор: от раздвижных до рольставней. Использование штор в системе климат контроля."
  },
  {
    src: "features2_6.png",
    title: "Климат контроль",
    text: "Самообучающаяся система от Loxone будет поддерживать установленную температуру без необходимости ручного управления и использования термостатов."
  },
  {
    src: "features2_7.png",
    title: "Безопасность",
    text: "Управление охранной сигнализацией: включение, отключение, ночной режим, текущее состояние и push уведомления."
  },
  {
    src: "features2_8.png",
    title: "Электроэнергия",
    text: "Идеальная программа для владельцев фотовольтаических систем. Удобный мониторинг выработки и поставок электроэнергии."
  },
  {
    src: "features2_9.png",
    title: "Погода",
    text: "Собственный погодный сервис учитывает погодные условия для управления устройствами автоматизации (например, поливом или отоплением) без сторонних приложений."
  }
];

const FEATURES_BLOCK_3 = [
  {
    src: "features3_1.png",
    title: "Статистика",
    text: "Информативные графики отображения детальной статистики потребления электроэнергии."
  },
  {
    src: "features3_2.png",
    title: "Календать режимов",
    text: "Создание событий и режима работы, например, режим «Отпуск» при отъезде на море. Ваш умный дом уже знает, что нужно делать, пока Вас нет."
  },
  {
    src: "features3_3.png",
    title: "Контроль доступа",
    text: "Удалённый мониторинг и контроль домофона. Получайте уведомления о звонке на Ваше устройство и общайтесь с человеком у двери из любой точки мира, например, с почтальоном или соседом. Архив записей позволяет узнать, кто и в какое время звонил."
  },
  {
    src: "features3_4.png",
    title: "Защита от пожара и протечек",
    text: "Удалённый мониторинг датчиков протечки и дыма и push уведомления о тревоге."
  },
  {
    src: "features3_5.png",
    title: "Будильник",
    text: "Просыпайтесь с комфортом: в назначенное время включится мягкий свет, откроются шторы и заиграет одна из любимых мелодий."
  },
  {
    src: "features3_6.png",
    title: "Бассейн",
    text: "Полная автоматизация бассейна: от графика освещения и температуры воды до автоматической чистки фильтра и полной замены воды."
  },
  {
    src: "features3_7.png",
    title: "Ворота",
    text: "Открывайте и закрывайте шлагбаумы, ворота и калитки. Отображение состояния позволит понять, не забыли ли Вы закрыть ворота без необходимости возвращаться."
  },
  {
    src: "features3_8.png",
    title: "Push уведомления",
    text: "Ни одно важное событие не будет пропущено: программа сообщит о звонке в дверь и сработке сигнализации."
  },
  {
    src: "features3_9.png",
    title: "Вентиляция",
    text: "Комплексная автоматизация системам вентиляции и климатической продукции, включая управление рекуперацией и показания датчиков CO2"
  },
  {
    src: "features3_10.png",
    title: "Пульт управления",
    text: "Возможность управления домашней техникой без заводского пульта управления."
  },
  {
    src: "features3_11.png",
    title: "Сауна",
    text: "Интеграция датчиков температуры, влажности и регулирование сауны: смена режимов, включение, отключение и активация просушки после использования."
  },
  {
    src: "features3_12.png",
    title: "Датчик открытия",
    text: "Одного касания экрана достаточно, чтобы увидеть, закрыты ли окна и двери."
  }
];

class Loxone extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: window.innerWidth <= 576,
      isSolutionsHovering: false,
      isBigForm: false,
      feedbackHeight: "170px",
      isSent: false
    };
  }


  openBigForm() {
    this.setState({ isBigForm: true });
  }

  hideBigForm = () => {
    this.setState({ isBigForm: false });
  };

  uppendTitle() {
    document.querySelector("head title").innerHTML =
      "Loxone | Умный дом в Москве | Homeсhain";
  }
  uppendMeta() {
    let description = document.querySelector("meta[name=description]");
    let keywords = document.querySelector("meta[name=keywords]");
    if (description) {
      description.content =
        'Loxone — интеллектуальная автоматизация. Умный дом в Москве "под ключ" - проектирование, комплектация, монтаж, пусконаладка. Homeсhain +7 (499) 113 10 10.';
    } else {
      description = document.createElement("meta");
      description.name = "description";
      description.content =
        'Loxone — интеллектуальная автоматизация. Умный дом в Москве "под ключ" - проектирование, комплектация, монтаж, пусконаладка. Homeсhain +7 (499) 113 10 10.';
      document.querySelector("head").appendChild(description);
    }

    if (keywords) {
      keywords.content = "loxone, умный дом loxone, loxone россия, loxone москва, loxone установка";
    } else {
      keywords = document.createElement("meta");
      keywords.name = "keywords";
      keywords.content = "loxone, умный дом loxone, loxone россия, loxone москва, loxone установка";
      document.querySelector("head").appendChild(keywords);
    }
  }

  solutionsHover() {
    this.setState(this.toggleSolutionsHover);
  }

  toggleDropdown() {
    return {
      isServicesHovering: false,
      isSolutionsHovering: false
    };
  }

  toggleSolutionsHover(state) {
    return {
      isSolutionsHovering: !state.isSolutionsHovering,
      isServicesHovering: false
    };
  }

  render() {
    return (
      <div className="loxone-root">
        {this.uppendTitle()}
        {this.uppendMeta()}
        <Navbar activeCat={"control"} activeSolutions={true} />
        <Container
          fluid
          className="ecosystem"
          style={{
            backgroundImage: `url(${LoxoneBg})`
          }}
        >
          <header className="loxone-header">
            <Container fluid>
              <img
                  src={LoxoneBgDevice}
                  alt=""
                  className="loxone-device"
              />
              <Row
                style={{ flexDirection: "column" }}
                className="ecosystem-row custom-container loxone-header-top"
              >
                {!this.state.isMobile ? (
                  <div>
                    <div className="ecosystem-nav">
                      <ul>
                        <li>
                          <Link to="/">Главная</Link>
                        </li>
                        <img
                          src={require("../../../images/arrow.svg")}
                          alt=""
                        />
                        <li onClick={this.solutionsHover.bind(this)}>
                          <HashLink>Решения</HashLink>
                        </li>
                        <img
                          src={require("../../../images/arrow.svg")}
                          alt=""
                        />
                        <li style={{ color: "#0F75BC" }}>
                          <Link to="/solutions/ecosystemi">Экосистемы</Link>
                        </li>
                        <img
                          src={require("../../../images/arrow.svg")}
                          alt=""
                        />
                        <li style={{ color: "#000" }}>Loxone</li>
                      </ul>
                    </div>
                    {this.state.isSolutionsHovering && (
                      <SolutionsBlockPages
                        activeCat={`control`}
                        handler={this.solutionsHover.bind(this)}
                      />
                    )}
                  </div>
                ) : (
                  <div>
                    <div className="page-nav">
                      <ul>
                        <li
                          style={{ margin: "0px" }}
                          onClick={this.solutionsHover.bind(this)}
                        >
                          <img
                            style={{
                              width: "6px",
                              marginLeft: "5px",
                              marginRight: "5px"
                            }}
                            src={require("../../../images/arrow-left.svg")}
                            alt=""
                          />

                          <HashLink>Решения</HashLink>
                        </li>
                      </ul>
                    </div>
                    {this.state.isSolutionsHovering && (
                      <SolutionsBlockPages
                        activeCat={`control`}
                        handler={this.solutionsHover.bind(this)}
                      />
                    )}
                  </div>
                )}

                <div className="ecosystem-header">
                  <h1>Loxone</h1>
                  <div className="ecosystem-buttons">
                    <HashLink smooth to='#feedback-big'>
                      <button
                          type="button"
                          className={'ecosystem-buttons-button button-apply hover-button'}
                          onClick={this.openBigForm.bind(this)}
                      >
                        Оставить заявку
                      </button>
                    </HashLink>
                    <HashLink smooth to='#feedback'>
                      <button
                          type="button"
                          className={'ecosystem-buttons-button button-showroom hover-button'}
                      >
                        Записаться в шоурум
                      </button>
                    </HashLink>
                  </div>
                </div>
              </Row>
            </Container>
          </header>
        </Container>
        <Container fluid className="custom-container">
          <Row className="ecosystem-row">
            <div className="ecosystem-description">
              <Col xl="12" lg="12" md="12">
                <h2>Австрийская автоматизация</h2>
                <p>
                  Loxone – комплексная система автоматизации с собственным программным обеспечением, оборудованием и контроллером. Система выделяется надёжностью и простотой эксплуатации.
                </p>
                <p>
                  Умный дом Loxone не имеет ограничений по масштабированию и способен автоматизировать любой объект:
                </p>
              </Col>
            </div>
          </Row>

          <div className="ecosystem-info-container">
            <InfoBlock />
          </div>

          <Row className="ecosystem-row">
            <div className="ecosystem-description-full-form">
              <Col xl="12" lg="12" md="12">
                <ApplicationForm align="center" openBigForm={this.openBigForm.bind(this)}/>
              </Col>
            </div>
          </Row>

          <Row className="ecosystem-row-video-bg">
            <div className="ecosystem-description">

              <Col xl="12" lg="12" md="12">
                <h2>Философия Loxone</h2>
              </Col>

              <Col xl="12" lg="12" md="12">
                <iframe width="880" height="495" className={"loxone-video"} src="https://www.youtube.com/embed/dGY97JZNyHA" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                >
                </iframe>
              </Col>

              <Col xl="12" lg="12" md="12">
                <p className='p-bold'>
                  Миссия Loxone – делегировать автоматике 50 000 повседневных задач человека в год.
                </p>
                <p>
                  Таким образом умный дом Loxone возвращает самый незаменимый жизненный ресурс – время. Время на близких людей, на занятие спортом, обучение и досуг.
                </p>
                <p>
                  Философия Loxone заключается в минимизации участия человека в достижении личного комфорта вне зависимости от типа действия: нажатие выключателя или касание дисплея смартфона.
                </p>
                <p>
                  Незаметность системы умный дом - еще одна цель. Огромные сенсорные панели, занимающие половину стены, проекционные дисплеи, контроллеры, множество кнопок и выключателей уместны в военном штабе, но не в жилом доме. Самым заметным элементом системы «умный дом может быть встроенный в стену планшет – устройство, являющееся центром управления.
                </p>
              </Col>

            </div>
          </Row>

          <div className="ecosystem-description">
            <Row className="ecosystem-row">
              <Col xl="12" lg="12" md="12">
                <div className="ecosystem-principles">
                  <h3>При построении автоматизации Loxone соблюдаются 3 принципа:</h3>
                  <div className="principles-items">
                    <div className="principle-item">
                      <div className="principle-item-image">
                        <img
                            src={require("../../../images/Loxone/icons/reliable.svg")}
                            alt=""
                            className=""
                        />
                      </div>
                      <div className="principle-item-title">Надежность</div>
                      <div className="principle-item-text">Автоматизация должна быть надёжной и максимально простой в эксплуатации.</div>
                    </div>
                    <div className="principle-item">
                      <div className="principle-item-image">
                        <img
                            src={require("../../../images/Loxone/icons/simple.svg")}
                            alt=""
                            className=""
                        />
                      </div>
                      <div className="principle-item-title">Простота</div>
                      <div className="principle-item-text">Одно приложение для всех задач.</div>
                    </div>
                    <div className="principle-item">
                      <div className="principle-item-image">
                        <img
                            src={require("../../../images/Loxone/icons/union.svg")}
                            alt=""
                            className=""
                        />
                      </div>
                      <div className="principle-item-title">Единство</div>
                      <div className="principle-item-text">Комплексное решение с единым контроллером лучше, чем набор отдельных решений.</div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <div className="ecosystem-description">
            <Row className="ecosystem-row">
              <Col xl="12" lg="12" md="12" className="ecosystem-middle-align">
                <h2>Мульти&shy;протокольность</h2>
                <p className="p-short">Помимо традиционных релейных аналоговых входов и выходов, система Loxone содержит два собственных протокола:</p>

                <div className="ecosystem-protocols">
                  <div className="ecosystem-protocol">
                    <div className="ecosystem-protocol-header">
                      <div className="ecosystem-protocol-icon">
                        <img
                            src={require("../../../images/Loxone/icons/tree.svg")}
                            alt=""
                        />
                      </div>
                      <p className="ecosystem-protocol-title">Tree</p>
                    </div>
                    <p className="ecosystem-protocol-text">
                      <span className="ecosystem-protocol-title">Tree</span>
                       – шина проводных устройств Loxone, от выключателя и сенсора CO2 до сервопривода и RGBW светильника. Идеально подходит для новых объектов (без ремонта). Подключение осуществляется через контроллер Loxone Miniserver или расширение Tree Extension.
                    </p>
                  </div>
                  <div className="ecosystem-protocol">
                    <div className="ecosystem-protocol-header">
                      <div className="ecosystem-protocol-icon">
                        <img
                            src={require("../../../images/Loxone/icons/air.svg")}
                            alt=""
                        />
                      </div>
                      <p className="ecosystem-protocol-title">Air</p>
                    </div>
                    <p className="ecosystem-protocol-text">
                      <span className="ecosystem-protocol-title">Air</span>
                       – радио протокол беспроводных устройств Loxone, от датчика дыма до шестиходового клапана для управления бассейном.  Подключается через контроллер Miniserver Go или расширение Loxone Air.
                    </p>
                  </div>
                </div>

                <p>Несмотря на огромную линейку продукции, способную решить любую задачу, Loxone не ограничивается собственными разработками и поддерживает обширное количество зарекомендовавших себя протоколов:</p>

                <div className="ecosystem-standards">
                  {
                    STANDARDS_ARRAY.map((standard, i) =>
                        <div className="ecosystem-standard" key={i}>
                          <div className="ecosystem-standard-icon">
                            <img
                                src={require(`../../../images/Loxone/protocols/${standard.src}`)}
                                alt=""
                                className=""
                            />
                          </div>
                          <p className="ecosystem-standard-text">
                            <span className="ecosystem-standard-title">{standard.title}</span>
                            {standard.text}
                          </p>
                        </div>
                    )
                  }
                </div>

                <p>Для каждого протокола Loxone разработал отдельное расширение, которое полноценного интегрирует всё подключенное оборудование в контроллер системы. Таким образом, устанавливаются только нужные расширения, что экономит общий бюджет инсталляции.</p>
              </Col>
            </Row>
          </div>
        </Container>

        <FeedbackFormSmallShowroom />

        <Container fluid className="custom-container">
          <div className="ecosystem-description ecosystem-interface">
            <Row className="ecosystem-row">
              <Col xl="12" lg="12" md="12" className="ecosystem-middle-align">
                <h2>Пользовательский интерфейс</h2>
                <p className="p-short">Одно приложение для всех систем</p>
                <p>Интерфейс Loxone даёт возможность управлять всеми системами от освещения и солнцезащиты до сауны и бассейна в едином интерфейсе. Интуитивное, понятное и простое управление квартирами, домами и коммерческой недвижимостью собрано в универсальном приложении. Приложение доступно для скачивания на любое устройство:</p>
                <div className="ecosystem-image-full">
                  <img
                      src={require(`../../../images/Loxone/loxone-image-section-three.png`)}
                      alt=""
                      className=""
                  />
                </div>
              </Col>
            </Row>
          </div>

          <div className="ecosystem-description">
            <Row className="ecosystem-row">
              <Col xl="12" lg="12" md="12">
                <p className="ecosystem-description-float-loxone">
                  <img
                    src={require("../../../images/Loxone/loxone-image-section-four-1.png")}
                    alt=""
                    class=""
                  />
                  <h3 className="h3-bold">Революционное управление</h3>
                  Пользовательская программа для умного дома Loxone позволяет производить точечную настройку автоматизации всех систем объекта и получать уведомления на Ваше устройство о важных событиях, например, о фиксации дыма или звонке в домофон.
                  <br/>
                  Программу можно использовать как стационарный центр управления. Для этого необходим iPad или аналогичное устройство, установленное в стену.
                </p>
              </Col>
            </Row>
          </div>
          <Row className="ecosystem-row">
            <div className="ecosystem-description">
              <Col xl="12" lg="12" md="12">
                <p className="ecosystem-description-float-loxone">
                  <img
                      src={require("../../../images/Loxone/loxone-image-section-four-2.png")}
                      alt=""
                      className=" ecosystem-description-leftimg"
                  />
                  <h3 className="h3-bold">Бесплатно навсегда</h3>
                  Программа и последующие обновления абсолютно бесплатны и не имеют ограничений по количеству пользователей. Контроль устройств становится более совершенным благодаря постоянному улучшению программного обеспечения Loxone.
                </p>
              </Col>
            </div>
          </Row>
          <div className="ecosystem-description">
            <Row className="ecosystem-row">
              <Col xl="12" lg="12" md="12">
                <p className="ecosystem-description-float-loxone">
                  <img
                      src={require("../../../images/Loxone/loxone-image-section-four-3.png")}
                      alt=""
                      className=""
                  />
                  <h3 className="h3-bold">Оптимизировано для коммерческих объектов</h3>
                  Программа с удобным интерфейсом позволяет легко создавать и редактировать группы пользователей с разными уровнями доступа.
                  <br/>
                  <br/>
                  Контроллер в сочетании с программой Loxone позволяют решить любую коммерческую задачу: от системы доступа на режимные объекты до управления индивидуальными шкафами в комнате для персонала.
                </p>
              </Col>
            </Row>
          </div>

          <div className="ecosystem-description ecosystem-margin-small">
            <Row className="ecosystem-row">
              <Col xl="12" lg="12" md="12" className="ecosystem-middle-align">
                <h3 className="h3-bold">Безграничная персонализация</h3>
                <p className="p-center">Система позволяет создавать собственный интерфейс. Данная опция удобна для визуализации планировок и схем подключения с наложенными элементами управления или показаниями датчиков. Не существует ограничений персонализации.</p>
                <div className="ecosystem-image-full">
                  <img
                      src={require(`../../../images/Loxone/loxone-image-section-five.png`)}
                      alt=""
                      className=""
                  />
                </div>
              </Col>
            </Row>
          </div>

          <div className="ecosystem-description ecosystem-margin-small">
            <Row className="ecosystem-row">
              <Col xl="12" lg="12" md="12">
                <h2>Революционный аудио мультирум</h2>
                <div className="ecosystem-image-full">
                  <img
                      src={require(`../../../images/Loxone/loxone-image-section-six.png`)}
                      alt=""
                      className=""
                  />
                </div>
                <p>
                  В сентябре 2020 Loxone презентовали революционное решение для рынка аудиосистем. Они объединили усилитель пассивных динамиков и сетевой проигрыватель в одно устройство – Loxone Audioserver, устанавливаемый на DIN рейку.
                  <br/>
                  <br/>
                </p>
                <p>
                  Продукция не имеет аналогов по размеру и стоимости: устройство шириной 16см, высотой 9см и глубиной 6см заменяет громоздкие усилители и сетевые проигрыватели, устанавливаемые в стойку, что отнимает полезное пространство.
                  <br/>
                  <br/>
                </p>
                <p>
                  <span className="p-bold">Устройство Audioserver способно управлять:</span>
                  - 2 стерео зонами или 4 моно зонами <br/>
                  - Сторонними акустическими системами, например, можно добавить в интерфейс домашний кинотеатр как аудио зону.
                  <br/>
                  <br/>
                </p>
                <p>
                  Для добавления дополнительных зон требуется расширение Stereo Extension, которое поддерживает 1 стерео зону или 2 моно зоны.
                  Вместе с релизом аудио сервера, Loxone обновил умный интерфейс управления аудиосистемой, имеющей прямую интеграцию с музыкальным сервисом Spotify.
                </p>

                <FeaturesBlock features={FEATURES_BLOCK_1} />

              </Col>
            </Row>
          </div>

          <div className="ecosystem-description ecosystem-margin-small">
            <Row className="ecosystem-row">
              <Col xl="12" lg="12" md="12">
                <h2>Единый центр управления</h2>
                <p>
                  Система Loxone предоставляет беспрецедентные возможности автоматизации без вмешательства человека: от управления освещением и настройками расписания обогрева до проверки охранной сигнализации или управления многокомнатным звуком.
                </p>
                <p>
                  Однако в любой момент можно взять управление в свои руки с помощью интуитивно-понятного приложения на Вашем устройстве.
                </p>
                <p>
                  Независимо от того, какое устройство Вы используете, смартфон, Apple Watch или iPad, контроль систем Loxone будет в одном приложении.
                </p>

                <FeaturesBlock features={FEATURES_BLOCK_2} />

              </Col>
            </Row>
          </div>

          <div className="ecosystem-description ecosystem-margin-both">
            <Row className="ecosystem-row">
              <Col xl="12" lg="12" md="12">
                <ApplicationForm align="left" openBigForm={this.openBigForm.bind(this)}/>
              </Col>
            </Row>
          </div>

          <div className="ecosystem-description">
            <Row className="ecosystem-row">
              <Col xl="12" lg="12" md="12">
                <FeaturesBlock features={FEATURES_BLOCK_3} />
              </Col>
            </Row>
          </div>

          <div className="ecosystem-description-gray">
            <Row className="ecosystem-row">
              <Col xl="12" lg="12" md="12" className="ecosystem-middle-align">
                <h2>Apple Watch</h2>
                <p className="p-center">
                  Полноценное управление умным домом Loxone
                  <br/>
                  без телефона с помощью продукции Apple.
                </p>
                <img
                    src={require("../../../images/Loxone/loxone-watch.png")}
                    alt=""
                    height="401"
                    className=""
                />
              </Col>
            </Row>
          </div>

          <div className="ecosystem-description">
            <Row className="ecosystem-row">
              <Col xl="12" lg="12" md="12">
                <h2>История Loxone</h2>
                <p>Компания основана австрийцами Томасом Мозером и Мартин Оллером в 2008. Создатели вдохновлены идеей централизованного управления домашними инженерными системами. Томас и Мартин искали подходящие решения для собственных домов, но доступное на рынке оборудование было непрактичным, дорогим и сложным в управлении.</p>
              </Col>
            </Row>
          </div>

          <div className="ecosystem-description ecosystem-margin-big">
            <Row className="ecosystem-row">
              <Col xl="12" lg="12" md="12">
                <p className="ecosystem-description-float-loxone-bottom">
                  <img
                      src={require("../../../images/Loxone/loxone-history-1.png")}
                      alt=""
                      className=" loxone-history-desktop-img"
                  />
                  <img
                      src={require("../../../images/Loxone/loxone-history-1-mobile.png")}
                      alt=""
                      className=" loxone-history-mobile-img"
                  />
                  <h3 className="h3-bold">Мартин Оллер</h3>
                  Основатель компании Loxone
                  <br/>
                  <br/>
                  «Мы хотели чрезвычайно умное решение, с простой установкой и доступной ценой. Хотели автоматическое включение освещения без необходимости нащупывать выключатель в темноте, если кто-то встал ночью. Систему, управляющую шторами в зависимости от текущего положения солнца и круглогодично поддерживающую комфортную температуру в каждом помещении.»
                </p>
              </Col>
            </Row>
          </div>
          <Row className="ecosystem-row">
            <div className="ecosystem-description">
              <Col xl="12" lg="12" md="12">
                <p className="ecosystem-description-float-loxone-bottom">
                  <img
                    src={require("../../../images/Loxone/loxone-history-2.png")}
                    alt=""
                    className=" ecosystem-description-leftimg loxone-history-desktop-img"
                  />
                  <img
                      src={require("../../../images/Loxone/loxone-history-2-mobile.png")}
                      alt=""
                      className=" ecosystem-description-leftimg loxone-history-mobile-img"
                  />
                  <h3 className="h3-bold">Томас Мозер</h3>
                  Основатель компании Loxone
                  <br/>
                  <br/>
                  «Loxone освобождает от кучи рутинных действий, вне зависимости от того, где я нахожусь: дома, в офисе или ухаживаю за растениями в саду. Возможности безграничны. Loxone освобождает время и придаёт уверенность, что все под контролем. Я хочу поделиться этим уникальным комфортом с миром.»
                </p>
              </Col>
            </div>
          </Row>

          <div className="ecosystem-description ecosystem-margin-small">
            <Row className="ecosystem-row">
              <Col xl="12" lg="12" md="12">
                <ApplicationForm align="center" openBigForm={this.openBigForm.bind(this)}/>
              </Col>
            </Row>
          </div>

          <Row className="ecosystem-row">
            <div className="ecosystem-description ecosystem-margin-small">
              <Col xl="12" lg="12" md="12">
                <h2>Управление</h2>
              </Col>
            </div>
          </Row>
        </Container>
        <LoxoneDevices />
        <ServicesBlock />
        <div
        className={classNames("feedback-form-container-loxone-wrapper", {
          ["feedback-form-container-loxone-wrapper-sent"]: this.state.isSent,
          ["feedback-form-container-loxone-wrapper-full"]: this.state.isBigForm
        })}>
          <div className={classNames('feedback-form-container', 'feedback-form-container-loxone', { ['visible-form']: this.state.isBigForm })}>
            {this.state.isBigForm && <FeedbackFormBig hideBig={this.hideBigForm} solution={true} />}
          </div>
        </div>
      </div>
    );
  }
}

export default Loxone;
