import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Navbar from "../../../components/Navbar/Navbar";
import FeedbackFormSmall from "../../FeedbackForm/FeedbackFormSmall";
import IndividualBg from "../../../images/Individual/individual-bg.png";
import IndividualHome from "../../../images/Individual/individual-home.png";
import IndividualTablet from "../../../images/Individual/individual-tablet.png";

import IndividualDevices from "./IndividualDevices";

import { HashLink } from "react-router-hash-link";
import SolutionsBlockPages from "../../Solutions/SolutionsBlock/SolutionsBlockPages";
import ServicesBlock from "../../ServicesBlock/ServicesBlock";

class Individual extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: window.innerWidth <= 576,
      activeCat: ""
    };
  }

  setActiveCat(category) {
    this.setState({ activeCat: category });
  }

  uppendTitle() {
    document.querySelector("head title").innerHTML =
      "Разработка индивидуальных систем управления | Умный дом в Москве | Homeсhain";
  }

  uppendMeta() {
    let description = document.querySelector("meta[name=description]");
    let keywords = document.querySelector("meta[name=keywords]");
    if (description) {
      description.content =
        'Разработка индивидуальных систем управления. Умный дом в Москве "под ключ" - проектирование, комплектация, монтаж, пусконаладка. Homeсhain +7 (499) 113 10 10.';
    } else {
      description = document.createElement("meta");
      description.name = "description";
      description.content =
        'Разработка индивидуальных систем управления. Умный дом в Москве "под ключ" - проектирование, комплектация, монтаж, пусконаладка. Homeсhain +7 (499) 113 10 10.';
      document.querySelector("head").appendChild(description);
    }

    if (keywords) {
      keywords.content = "приложение умный дом управление умным домом";
    } else {
      keywords = document.createElement("meta");
      keywords.name = "keywords";
      keywords.content = "приложение умный дом управление умным домом";
      document.querySelector("head").appendChild(keywords);
    }
  }

  solutionsHover() {
    this.setState(this.toggleSolutionsHover);
  }

  toggleDropdown() {
    return {
      isServicesHovering: false,
      isSolutionsHovering: false
    };
  }

  toggleSolutionsHover(state) {
    return {
      isSolutionsHovering: !state.isSolutionsHovering,
      isServicesHovering: false
    };
  }

  render() {
    return (
      <div>
        {this.uppendTitle()}
        {this.uppendMeta()}
        <Navbar activeCat={"control"} activeSolutions={true} />
        <Container
          fluid
          className="ecosystem"
          style={{
            backgroundImage: `url(${IndividualBg})`,
            marginBottom: "0px"
          }}
        >
          <header>
            <Container fluid>
              <Row
                className="ecosystem-row custom-container"
                style={{ flexDirection: "column" }}
              >
                {!this.state.isMobile ? (
                  <div>
                    <div className="ecosystem-nav">
                      <ul>
                        <li>
                          <Link to="/">Главная</Link>
                        </li>
                        <img
                          src={require("../../../images/arrow.svg")}
                          alt=""
                        />
                        <li onClick={this.solutionsHover.bind(this)}>
                          <HashLink>Решения</HashLink>
                        </li>
                        <img
                          src={require("../../../images/arrow.svg")}
                          alt=""
                        />
                        <li style={{ color: "#0F75BC" }}>
                          <Link to="/solutions/ecosystemi">Экосистемы</Link>
                        </li>
                        <img
                          src={require("../../../images/arrow.svg")}
                          alt=""
                        />
                        <li style={{ color: "#000" }}>
                          Индивидуальные решения
                        </li>
                      </ul>
                    </div>
                    {this.state.isSolutionsHovering && (
                      <SolutionsBlockPages
                        activeCat={`control`}
                        handler={this.solutionsHover.bind(this)}
                      />
                    )}
                  </div>
                ) : (
                  <div>
                    <div className="page-nav">
                      <ul>
                        <li
                          style={{ margin: "0px" }}
                          onClick={this.solutionsHover.bind(this)}
                        >
                          <img
                            style={{
                              width: "6px",
                              marginLeft: "5px",
                              marginRight: "5px"
                            }}
                            alt=""
                            src={require("../../../images/arrow-left.svg")}
                          />

                          <HashLink>Решения</HashLink>
                        </li>
                      </ul>
                    </div>
                    {this.state.isSolutionsHovering && (
                      <SolutionsBlockPages
                        activeCat={`control`}
                        handler={this.solutionsHover.bind(this)}
                      />
                    )}
                  </div>
                )}

                <div className="ecosystem-header ecosystem-header-space-top">
                  <h1 className="ecosystem-header-title">
                    Индивидуальные решения
                  </h1>
                  <img alt="" />
                </div>
              </Row>
            </Container>
          </header>
        </Container>
        <Container fluid className="custom-container">
          <Row className="ecosystem-row">
            <div className="ecosystem-description">
              <Col xl="12" lg="12" md="12">
                <h2 className="ecosystem-description-key-features-title">
                  Основные возможности
                </h2>
              </Col>
              <Col xl="12" lg="12" md="12">
                <h3>Лучшие решения по автоматизации</h3>
                <p>
                  По нашему убеждению, программные продукты Apple HomeKit,
                  Google Home и Amazon Echo являются лучшими прогрессивными
                  решениями для автоматизации умного дома или квартиры. Компании
                  Apple, Google и Amazon инвестируют огромные средства в
                  разработку приложений, голосовых ассистентов и экосистем.
                  Обеспечивается постоянная поддержка и обновление программного
                  обеспечения, а также модификация экосистем. Благодаря
                  постоянному совершенствованию систем управления крайне тяжело
                  превзойти их по дизайну, функционалу, поддержке и стабильности
                  функционирования. Но, несмотря на преимущества, приложения
                  умный дом не идеальны и имеют определенные недостатки.
                </p>
                <h3>Доступ к информации</h3>
                <p>
                  Первый недостаток в обеспечении доступа к данным. При
                  использовании сторонних ресурсов для управления электронными
                  устройствами, информация о вашем объекте хранится на серверах
                  компании-разработчика пользовательского софта. Факты
                  вмешательства сотрудников в работу оборудования отсутствуют,
                  но теоретически они имеют возможность просматривать, менять
                  настройки и даже управлять устройствами. Вполне очевидно, что
                  сотрудники Apple, Google или Amazon не будут включать ночью
                  телевизор или открывать шторы. Но теоретический риск
                  неприемлем для политически важных персон и режимных объектов.
                </p>
              </Col>
            </div>
          </Row>

          <Row className="ecosystem-row">
            <div className="ecosystem-description ecosystem-description-image-wrapper">
              <Col xl="12" lg="12" md="12">
                <img
                  data-src={IndividualHome}
                  alt=""
                  src="/images/image-preloader.png"
                  className="lazy ecosystem-description-image"
                />
              </Col>
            </div>
          </Row>
          <Row className="ecosystem-row">
            <div className="ecosystem-description">
              <Col xl="12" lg="12" md="12">
                <h3>Непредсказуемость обновлений</h3>
                <p>
                  Вторым недостатком является непредсказуемость обновлений.
                  Apple, Google и Amazon регулярно совершенствуют приложения
                  умный дом. Обновление программ и экосистем предоставляет
                  большие преимущества пользователям: улучшенный дизайн, новые
                  команды голосового ассистента и расширенный функционал.
                  Проблема заключается в том, что некоторое оборудование
                  перестает поддерживаться и не может нормально работать с
                  обновленной программой. Выход из ситуации ‒ замена
                  оборудования. Кроме того, пользователям придется разбираться с
                  обновленным интерфейсом и функционалом, хотя предыдущая версия
                  работала исправно и комфортно.
                </p>
                <h3>Отсутствие контроля</h3>
                <p>
                  Третий недостаток заключается в отсутствии возможности
                  оптимизировать функциональные возможности программы, изменить
                  меню или логотип. Адаптация пользовательского интерфейса под
                  ваши запросы для управления умным домом путем частичного
                  изменения также ограничена.
                </p>
                <h3>Ограничение доступа</h3>
                <p>
                  Четвертый недостаток (надеемся, что временный) имеет
                  политический фон. Приложения Apple, Google и Amazon
                  разработаны, поддерживаются и совершенствуются американскими
                  компаниями. В связи с натянутыми отношениями РФ и США, из-за
                  океана постоянно приходят сообщения о возможных новых
                  санкциях. Теоретически, пусть и крайне маловероятно, США могут
                  ограничить доступ к своим серверам для пользователей из
                  России, что означает потерю возможности управлять
                  оборудованием с помощью системы Apple, Google или Amazon.
                </p>
              </Col>
            </div>
          </Row>
          <div className="ecosystem-description">
            <Row className="ecosystem-row">
              <Col xl="12" lg="12" md="12">
                <h3>Собственная система</h3>
                <p>
                  <img
                    data-src={IndividualTablet}
                    alt=""
                    class="lazy"
                    src="/images/image-preloader.png"
                  />
                  Для клиентов, не принимающих риски, связанные с недостатками
                  передовых систем, мы готовы предложить разработку собственной
                  экосистемы в виде мобильного приложения умный дом, программы
                  для ПК или комплексного программного продукта для управления
                  смарт-устройствами. Homechain гарантирует соответствие дизайна
                  и функционального наполнения системы техническому заданию
                  заказчика. Данные про объект будут храниться на вашем сервере,
                  который мы установим в удобном месте.
                </p>
              </Col>
            </Row>
          </div>
          <Row className="ecosystem-row">
            <div className="ecosystem-description">
              <Col xl="12" lg="12" md="12">
                <h2>Управление</h2>
              </Col>
              <Col xl="12" lg="12" md="12">
                <p>
                  Контроль функциональности и удаленное управление электронным
                  оборудованием обеспечивается с мобильных устройств.
                  Разработанное нами приложение устанавливается на:
                </p>
              </Col>
            </div>
          </Row>
        </Container>

        <IndividualDevices />
        <ServicesBlock />
        <FeedbackFormSmall />
      </div>
    );
  }
}

export default Individual;
