import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Pagination from "react-paginating";
import Navbar from "../../components/Navbar/Navbar";

import ChevronLeftActive from "../../images/chevron-left-active.svg";
import ChevronRightActive from "../../images/chevron-right-active.svg";
import ChevronLeft from "../../images/chevron-left.svg";
import ChevronRight from "../../images/chevron-right.svg";
import axios from "axios";
import classNames from "classnames";

const limit = 4;
let pageCount = 4;

class Search extends React.Component {
  constructor(props) {
    super(props);
    const searchRequest = this.props.location.state
      ? this.props.location.state.searchRequest
      : "";
    this.state = {
      search: searchRequest,
      results: [],
      currentPage: 1,
      length: null,
      loading: false,
      pages: null,
      start: false,
      disabled: true,
      activeSearch: true,
      isMobile: window.innerWidth <= 576,
      categories: []
    };
  }

  componentWillMount() {
    axios
      .get(
        process.env.NODE_ENV === "production"
          ? `https://homechain.ai/s/api/blog/undefined`
          : `http://localhost:4000/api/blog/undefined`
      )
      .then((res) => {
        this.setState({ categories: res.data.categories });
      });

    this.submitSearch();
  }

  inputDataChange(e) {
    if (this.state.search === "") {
      this.setState({
        disabled: true
      });
    }
    this.setState({
      disabled: false,
      [e.target.name]: e.target.value
    });
  }

  submitSearch(e) {
    if (e) e.preventDefault();
    if (this.state.search) {
      this.setState({
        disabled: true,
        currentPage: 1,
        pages: 0,
        start: true
      });
      axios
        .get(
          process.env.NODE_ENV === "production"
            ? `https://homechain.ai/s/api/page/find/${this.state.search}`
            : `http://localhost:4000/api/page/find/${this.state.search}`
        )
        .then(res => {
          let data = res.data.data || null;
          this.setState({
            length: data.length
          });
          let r = [];
          let pagesCount = Math.ceil(data.length / limit);
          if (data.length > limit) {
            for (let i = 0; i < pagesCount; i++) {
              let arr = [];
              for (let j = 0; j < limit; j++) {
                if (data.length) arr.push(data.shift());
                else continue;
              }
              r.push(arr);
            }
          } else {
            r = [data];
          }

          this.setState({
            results: r,
            loading: true,
            disabled: false
          });
        })
        .catch(err => {
          this.setState({
            loading: false,
            disabled: false,
            pages: 0
          });
        });
    } else {
      this.setState({
        loading: false
      });
    }
  }

  handlePageChange = (page, e) => {
    this.setState({
      currentPage: page
    });
  };

  uppendTitle() {
    document.querySelector("head title").innerHTML = "Поиск";
  }

  render() {
    const total = this.state.results.length * limit;

    return (
      <div>
        {this.uppendTitle()}
        <Navbar activeSearch={this.state.activeSearch} />
        <Container fluid>
          <div className="page">
            <header style={{ height: "unset" }}>
              <Container fluid className="custom-container">
                <Row className="page-row">
                  {!this.state.isMobile && (
                    <div className="page-nav">
                      <ul>
                        <li>
                          <Link to="/">Главная</Link>
                        </li>
                        <img src={require("../../images/arrow.svg")} alt="" />
                        <li style={{ color: "#000" }}>Поиск</li>
                      </ul>
                    </div>
                  )}

                  {this.state.isMobile && (
                    <div className="page-nav">
                      <ul>
                        <img
                          style={{ width: "6px" }}
                          src={require("../../images/arrow-left.svg")}
                          alt=""
                        />
                        <li>
                          <Link to="/">Главная</Link>
                        </li>
                      </ul>
                    </div>
                  )}

                  <div className="search-header">
                    <h1 className="search-header-title">Поиск по сайту</h1>
                    <form
                      className="search-form"
                      onSubmit={this.submitSearch.bind(this)}
                    >
                      <div style={{ position: "relative" }}>
                        <input
                          name="search"
                          placeholder="Введите запрос"
                          value={this.state.value}
                          onChange={e => {
                            this.inputDataChange(e);
                          }}
                        />
                        <button
                          type="submit"
                          className={classNames('search-button', { ['disabled']: this.state.disabled } )}
                          disabled={this.state.disabled}
                        >
                          Поиск
                        </button>
                      </div>
                    </form>
                  </div>
                </Row>
              </Container>
            </header>
            <Container
              className="page-description"
              style={{ marginTop: "0px" }}
            >
              <Row>
                <Col xl="12">
                  <h2 className="search-results-title">
                    {this.state.loading && (
                      <span> Результаты поиска ({this.state.length})</span>
                    )}

                    {!this.state.loading && (
                      <div>
                        {this.state.start && (
                          <div className="search-nothing-found-title">
                            <span>Ничего не найдено</span>
                          </div>
                        )}
                      </div>
                    )}
                  </h2>
                </Col>
              </Row>

              <Row>
                <Col xl="12">
                  {this.state.loading && (
                    <div>
                      {this.state.results[this.state.currentPage - 1].map(
                        page => {
                          if (page.isBlog) {
                            const currentCategory = this.state.categories.find(category => category._id === page.categoryId);

                            return <Link
                              key={page.alias}
                              to={`/umnyj-dom-blog/${currentCategory && currentCategory.alias}/${page.alias}`}
                            >
                              <h3>{page.name}</h3>
                              <p>{page.content}</p>
                            </Link>
                          }

                          return page.subCat ? (
                            <Link
                              key={page.alias}
                              to={`/solutions/${page.subCat}/${page.alias}`}
                            >
                              <h3>{page.name}</h3>
                              <p>{page.content}</p>
                            </Link>
                          ) : (
                            <Link
                              key={page.alias}
                              to={`/services/${page.alias}`}
                            >
                              <h3>{page.name}</h3>
                              <p>{page.content}</p>
                            </Link>
                          )
                        }
                      )}
                    </div>
                  )}
                </Col>
              </Row>

              <Pagination
                total={total}
                limit={limit}
                pageCount={pageCount}
                currentPage={this.state.currentPage}
                pages={this.state.pages}
              >
                {({
                  pages,
                  currentPage,
                  hasNextPage,
                  hasPreviousPage,
                  previousPage,
                  nextPage,
                  getPageItemProps
                }) => (
                  <div className="pagination">
                    {this.state.loading && (
                      <div>
                        {hasPreviousPage && (
                          <button
                            className="arrow"
                            {...getPageItemProps({
                              pageValue: previousPage,
                              onPageChange: this.handlePageChange
                            })}
                          >
                            <img src={ChevronLeftActive} alt="" />
                          </button>
                        )}
                        {!hasPreviousPage && (
                          <button
                            disabled
                            className="arrow"
                            style={{
                              backgroundColor: "#f7f7f7",
                              boxShadow: "none"
                            }}
                            {...getPageItemProps({
                              pageValue: previousPage,
                              onPageChange: this.handlePageChange
                            })}
                          >
                            <img src={ChevronLeft} alt="" />
                          </button>
                        )}
                      </div>
                    )}

                    {this.state.loading &&
                      pages.map(page => {
                        let activePage = null;

                        if (currentPage === page) {
                          activePage = { color: "#0F75BC" };
                        }
                        return (
                          <button
                            {...getPageItemProps({
                              pageValue: page,
                              key: page,
                              style: activePage,
                              onPageChange: this.handlePageChange
                            })}
                          >
                            {page}
                          </button>
                        );
                      })}

                    {this.state.loading && (
                      <div>
                        {hasNextPage && (
                          <button
                            className="arrow"
                            {...getPageItemProps({
                              pageValue: nextPage,
                              onPageChange: this.handlePageChange
                            })}
                          >
                            <img src={ChevronRightActive} alt="" />
                          </button>
                        )}
                        {!hasNextPage && (
                          <button
                            disabled
                            className="arrow"
                            style={{
                              backgroundColor: "#f7f7f7",
                              boxShadow: "none"
                            }}
                            {...getPageItemProps({
                              pageValue: nextPage,
                              onPageChange: this.handlePageChange
                            })}
                          >
                            <img src={ChevronRight} alt="" />
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </Pagination>
            </Container>
          </div>
        </Container>
      </div>
    );
  }
}

export default withRouter(Search);
