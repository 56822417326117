import React from "react";
import Check from "../../../images/check.svg";
import { Container, Row, Col } from "react-bootstrap";

class Table extends React.Component {
  render() {
    return (
      <div className="services">
        <Container className="services-table">
          <Row>
            <Col xl="12" lg="12" md="12" sm="12">
              <h3 className="container-space-top-60">Состав предлагаемых пакетов</h3>
            </Col>
          </Row>
          <Row>
            <Col xl="12" lg="12" md="12" sm="12">
              <p>
                В ходе проектирования системы умный дом разрабатываются
                следующие документы:
              </p>
            </Col>
          </Row>
          <Row className="services-table-header-row">
            <Col xl="6" lg="6" md="6" sm="6" xs="6">
              <h4>Наименование документа</h4>
            </Col>
            <Col xl="3" lg="3" md="3" sm="3" xs="3">
              <h5>Базовый проект</h5>
            </Col>
            <Col xl="3" lg="3" md="3" sm="3" xs="3">
              <h5>Комплексный проект</h5>
            </Col>
          </Row>
          <Row className="services-table-row">
            <Col xl="6" lg="6" md="6" sm="6" xs="6">
              <h4>Проект автоматизации</h4>
            </Col>
            <Col xl="3" lg="3" md="3" sm="3" xs="3">
              <img data-src={Check} alt="" class="lazy" src="/images/image-preloader.png" />
            </Col>
            <Col xl="3" lg="3" md="3" sm="3" xs="3">
              <img data-src={Check} alt="" class="lazy" src="/images/image-preloader.png" />
            </Col>
          </Row>
          <Row className="services-table-row">
            <Col xl="6" lg="6" md="6" sm="6" xs="6">
              <h4>План расстановки элементов системы</h4>
            </Col>
            <Col xl="3" lg="3" md="3" sm="3" xs="3">
              <img data-src={Check} alt="" class="lazy" src="/images/image-preloader.png" />
            </Col>
            <Col xl="3" lg="3" md="3" sm="3" xs="3">
              <img data-src={Check} alt="" class="lazy" src="/images/image-preloader.png" />
            </Col>
          </Row>
          <Row className="services-table-row">
            <Col xl="6" lg="6" md="6" sm="6" xs="6">
              <h4>Схема питания и подключения устройств</h4>
            </Col>
            <Col xl="3" lg="3" md="3" sm="3" xs="3">
              <img data-src={Check} alt="" class="lazy" src="/images/image-preloader.png" />
            </Col>
            <Col xl="3" lg="3" md="3" sm="3" xs="3">
              <img data-src={Check} alt=""  class="lazy" src="/images/image-preloader.png"/>
            </Col>
          </Row>
          <Row className="services-table-row">
            <Col xl="6" lg="6" md="6" sm="6" xs="6">
              <h4>Смета оборудования</h4>
            </Col>
            <Col xl="3" lg="3" md="3" sm="3" xs="3">
              <img data-src={Check} alt="" class="lazy" src="/images/image-preloader.png" />
            </Col>
            <Col xl="3" lg="3" md="3" sm="3" xs="3">
              <img data-src={Check} alt="" class="lazy" src="/images/image-preloader.png" />
            </Col>
          </Row>
          <Row className="services-table-row">
            <Col xl="6" lg="6" md="6" sm="6" xs="6">
              <h4>Кабельный журнал и трассировка</h4>
            </Col>
            <Col xl="3" lg="3" md="3" sm="3" xs="3" />
            <Col xl="3" lg="3" md="3" sm="3" xs="3">
              <img data-src={Check} alt=""  class="lazy" src="/images/image-preloader.png"/>
            </Col>
          </Row>
          <Row className="services-table-row">
            <Col xl="6" lg="6" md="6" sm="6" xs="6">
              <h4>Схема подключения кабелей</h4>
            </Col>
            <Col xl="3" lg="3" md="3" sm="3" xs="3" />
            <Col xl="3" lg="3" md="3" sm="3" xs="3">
              <img data-src={Check} alt="" class="lazy" src="/images/image-preloader.png" />
            </Col>
          </Row>
          <Row className="services-table-row">
            <Col xl="6" lg="6" md="6" sm="6" xs="6">
              <h4>Проектный пакет по телекоммуникациям</h4>
            </Col>
            <Col xl="3" lg="3" md="3" sm="3" xs="3" />
            <Col xl="3" lg="3" md="3" sm="3" xs="3">
              <img data-src={Check} alt="" class="lazy" src="/images/image-preloader.png" />
            </Col>
          </Row>
          <Row className="services-table-row">
            <Col xl="6" lg="6" md="6" sm="6" xs="6">
              <h4>Схема расстановки телекоммуникационного оборудования</h4>
            </Col>
            <Col xl="3" lg="3" md="3" sm="3" xs="3" />
            <Col xl="3" lg="3" md="3" sm="3" xs="3">
              <img data-src={Check} alt="" class="lazy" src="/images/image-preloader.png" />
            </Col>
          </Row>
          <Row className="services-table-row">
            <Col xl="6" lg="6" md="6" sm="6" xs="6">
              <h4>План питания и подключения телекоммуникаций</h4>
            </Col>
            <Col xl="3" lg="3" md="3" sm="3" xs="3" />
            <Col xl="3" lg="3" md="3" sm="3" xs="3">
              <img data-src={Check} alt="" class="lazy" src="/images/image-preloader.png" />
            </Col>
          </Row>
          <Row className="services-table-row">
            <Col xl="6" lg="6" md="6" sm="6" xs="6">
              <h4>Проект электроснабжения</h4>
            </Col>
            <Col xl="3" lg="3" md="3" sm="3" xs="3" />
            <Col xl="3" lg="3" md="3" sm="3" xs="3">
              <img data-src={Check} alt="" class="lazy" src="/images/image-preloader.png" />
            </Col>
          </Row>
          <Row className="services-table-row">
            <Col xl="6" lg="6" md="6" sm="6" xs="6">
              <h4>Схема расстановки электроприборов</h4>
            </Col>
            <Col xl="3" lg="3" md="3" sm="3" xs="3" />
            <Col xl="3" lg="3" md="3" sm="3" xs="3">
              <img data-src={Check} alt="" class="lazy" src="/images/image-preloader.png" />
            </Col>
          </Row>
          <Row className="services-table-row">
            <Col xl="6" lg="6" md="6" sm="6" xs="6">
              <h4>План питания и подключения электроприборов</h4>
            </Col>
            <Col xl="3" lg="3" md="3" sm="3" xs="3" />
            <Col xl="3" lg="3" md="3" sm="3" xs="3">
              <img data-src={Check} alt="" class="lazy" src="/images/image-preloader.png" />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Table;
