import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";
import FeedbackFormSmall from "../FeedbackForm/FeedbackFormSmall";
import FeedbackFormBig from "../FeedbackForm/FeedbackFormBig";
import Table from "./Table/Table";
import Navbar from "../Navbar/Navbar";
import StagesBlock from "../../components/Tabs/Stages/StagesBlock";
import ServicesDropdownPages from "../Services/ServicesDropdown/ServicesDropdownPages";

class Project extends React.Component {
  constructor(props) {
    super(props);
    this.servicesHover = this.servicesHover.bind(this);
    this.state = {
      style: "borderTop: 2px solid",
      buttonOrder: false,
      activeServices: true,
      isSolutionsHovering: false,
      isServicesHovering: false,
      isMobile: window.innerWidth <= 576
    };
  }

  uppendTitle() {
    document.querySelector("head title").innerHTML =
      "Проектирование систем | Умный дом в Москве | Homeсhain";
  }

  uppendMeta() {
    let description = document.querySelector("meta[name=description]");
    let keywords = document.querySelector("meta[name=keywords]");
    if (description) {
      description.content =
        'Проектирование систем. Умный дом в Москве "под ключ" — проект, комплектация, монтаж, пусконаладка. Homeсhain +7 (499) 113 10 10.';
    } else {
      description = document.createElement("meta");
      description.name = "description";
      description.content =
        'Проектирование систем. Умный дом в Москве "под ключ" — проект, комплектация, монтаж, пусконаладка. Homeсhain +7 (499) 113 10 10.';
      document.querySelector("head").appendChild(description);
    }

    if (keywords) {
      keywords.content =
        "проектирование умного дома, проектирование системы умный дом";
    } else {
      keywords = document.createElement("meta");
      keywords.name = "keywords";
      keywords.content =
        "проектирование умного дома, проектирование системы умный дом";
      document.querySelector("head").appendChild(keywords);
    }
  }

  servicesHover(state) {
    this.setState(this.toggleServicesHover);
  }

  toggleDropdown(state) {
    return {
      isServicesHovering: false,
      isSolutionsHovering: false
    };
  }

  toggleServicesHover(state) {
    return {
      isServicesHovering: !state.isServicesHovering,
      isSolutionsHovering: false
    };
  }

  render() {
    return (
      <div>
        {this.uppendTitle()}
        {this.uppendMeta()}
        <Navbar activeServices={this.state.activeServices} />
        <Container
          fluid
          className="page"
          style={{
            backgroundImage: `url(${require("../../images/Services/Project/project-bg.png")})`
          }}
        >
          <header>
            <Container fluid>
              <Row className="page-row custom-container">
                {!this.state.isMobile && (
                  <div className="page-nav">
                    <ul>
                      <li>
                        <Link to="/">Главная</Link>
                      </li>
                      <img
                        data-src={require("../../images/arrow.svg")}
                        alt=""
                        class="lazy"
                        src="/images/image-preloader.png"
                      />
                      <li onClick={this.servicesHover.bind(this)}>
                        <HashLink>Услуги</HashLink>
                      </li>
                      <img
                        data-src={require("../../images/arrow.svg")}
                        alt=""
                        class="lazy"
                        src="/images/image-preloader.png"
                      />
                      <li style={{ color: "#181818" }}>Проектирование</li>
                    </ul>
                  </div>
                )}

                {this.state.isMobile && (
                  <div className="page-nav">
                    <ul>
                      <img
                        style={{ width: "6px" }}
                        src={require("../../images/arrow-left.svg")}
                        alt=""
                      />
                      <li onClick={this.servicesHover.bind(this)}>
                        <HashLink>Услуги</HashLink>
                      </li>
                    </ul>
                  </div>
                )}

                <div className="page-header">
                  <h1>Проектирование</h1>
                  <HashLink smooth to="#feedback-big">
                    <button className="hover-button">Оставить заявку</button>
                  </HashLink>
                </div>
              </Row>
              {this.state.isServicesHovering && (
                <ServicesDropdownPages
                  handler={this.servicesHover.bind(this)}
                />
              )}
            </Container>
          </header>
        </Container>
        <Container fluid className="custom-container">
          <div className="ecosystem-description">
            <Row className="ecosystem-row">
              <Col xl="12" lg="12" md="12">
                <h3>
                  Планирование электроснабжения и телекоммуникации объекта
                </h3>

                <p className="text-block-without-space">
                  <img
                    src={require("../../images/Services/Project/project-img.png")}
                    alt="проектирование умного дома"
                  />
                  Проектирование умного дома является первым этапом
                  автоматизации жилья или офиса компании. От грамотности и
                  качества разработки проектной документации зависит
                  эффективность и надежность электропитания здания, а также
                  безопасность использования технологичного оборудования.
                  Главная цель, преследуемая на этапе составления планирующих
                  документов, заключается в создании должного уровня комфорта
                  для хозяев жилья и гостей, а также удобства в использовании
                  электронных приборов.
                  <span />
                  Проект умного дома представляет собой гибрид документации по
                  оснащению объекта электропитанием и телекоммуникациями, в
                  который вносятся устройства автоматизации и закладываются
                  сценарии управления, адаптированные к реальным условиям
                  эксплуатации. Мы можем сделать проект на основании имеющейся
                  проектной документации по электропитанию и разводке
                  телекоммуникаций, в том числе и на готовый ремонт. Также
                  разрабатываем проекты с нуля, на голый объект, путем
                  разработки всей необходимой документации по электропитанию и
                  телекоммуникациям, соответствующей пожеланиям заказчиков и
                  учитывающей особенности здания.
                </p>
              </Col>
            </Row>
          </div>
          <div className="ecosystem-description">
            <Row className="ecosystem-row">
              <Col xl="12" lg="12" md="12">
                <h3>Выгодные методики</h3>
                <p>
                  <img
                    src={require("../../images/Services/Project/project-img-2.png")}
                    alt="проектирование системы умный дом"
                  />
                  Многие дизайнеры и инженеры разрабатывают проекты по прокладке
                  электрики, не являясь профессионалами или не зная передовых
                  технологий, и особенностей функционирования устройств.
                  Зачастую клиенты обращаются к нам с готовым дизайн-проектом,
                  содержащим устаревшие и не оптимизированные решения. Мы видим
                  явные ошибки, которые нужно исправлять. В дальнейшем такая
                  проектная документация требует уточнения и переработки. При
                  базовом проектировании умного дома мы рекомендуем приступать к
                  проекту, одновременно с расстановкой мебели и дизайн-проектом.
                  <span />
                  Такой метод позволяет оперативно вносить все необходимые
                  рекомендации по электроснабжению до принятия объекта в
                  эксплуатацию. Поэтому заказчикам не придется тратить
                  дополнительные средства и время на модификацию или
                  совершенствование, и внесение коррективов в проектную
                  документацию.
                </p>
              </Col>
            </Row>
          </div>
          <Row style={{ marginTop: "0px" }} className="page-description">
            <Col xl="12">
              <h3>Технологичность проектов</h3>
              <p>
                Нашими специалистами выполняется проектирование проводных систем
                умный дом, идеально подходящих для помещений без ремонта, а
                также беспроводных решений, которые можно реализовать на
                объектах с готовым ремонтом. Кроме того, мы ведем работу над
                созданием гибридной системы, позволяющей объединить стабильность
                проводных и гибкость беспроводных решений.
              </p>
            </Col>
          </Row>
        </Container>
        <Table />
        <FeedbackFormSmall />

        <Container fluid className="custom-container">
          <Row className="page-description container-space-top-60">
            <Col xl="12">
              <h3>Основные этапы проектирования системы умный дом</h3>
              <p>
                Кроме детального расчета стоимости умного дома мы можем
                проверить любую смету и дизайн-проект с целью выбора и поставки
                необходимого оборудования для комплектации объектов.
                Обеспечиваем максимальное соответствие эксплуатационных
                параметров и функциональных возможностей электронной аппаратуры
                требованиям заказчиков.
              </p>
              <p>
                Кроме своевременных поставок технологичных устройств компанией
                предоставляется консультация касательно технических
                характеристик, а также даются квалифицированные советы по
                практическому использованию оборудования. Обеспечиваем выезд
                наших специалистов на объект с целью выполнения необходимых
                замеров.
              </p>
            </Col>
          </Row>
        </Container>
        <StagesBlock />
        <FeedbackFormBig bigForm={true} />
      </div>
    );
  }
}

export default Project;
