import React, { lazy, Suspense } from "react";
import yall from "yall-js";

import Footer from "./components/Footer/Footer";
import style from "bootstrap/dist/css/bootstrap.css";
import "./components/Tabs/Control/control.css";
import "./App.sass";

import { Route, Switch, withRouter } from "react-router-dom";

import ScrollToTopEveryTransition from "./components/ScrollTop/ScrollTopEveryTransition";
import Main from "./components/Main/Main";

import BlogPage from "./components/BlogPage/BlogPage.jsx";
import BlogConstructor from "./components/BlogConstructor/BlogConstructor.jsx";
import Cooperation from "./components/Cooperation/Cooperation";
import Contacts from "./components/Contacts/Contacts";
import Search from "./components/Search/Search";
import NotFound from "./components/NotFound/NotFound";
import Constructor from "./components/Solutions/Constructor/Constructor";
import CategoryPageConstructor from "./components/Solutions/Constructor/CategoryPageConstructor";

import Control from "./components/Control/Control";
import AmazonEcho from "./components/Ecosystems/AmazonEcho/AmazonEcho";
import AppleHomeKit from "./components/Ecosystems/AppleHomeKit/AppleHomeKit";
import GoogleHome from "./components/Ecosystems/GoogleHome/GoogleHome";
import Yandex from "./components/Ecosystems/Yandex/Yandex";
import Individual from "./components/Ecosystems/Individual/Individual";
import Loxone from "./components/Ecosystems/Loxone/Loxone";

import Painting from "./components/Services/Painting";
import Audit from "./components/Services/Audit";
import Authorize from "./components/Services/Authorize";
import Delivery from "./components/Services/Delivery";
import Support from "./components/Services/Support";
import Starting from "./components/Services/Starting";
import Builtin from "./components/Services/Builtin";
import BuiltinV2 from "./components/Services/BuiltinV2/BuiltinV2";
import Project from "./components/Services/Project";

// const Preloader = () =>{
//   return(
//       <div>
//         <img data-src={preloader} alt="preloader"/>
//       </div>
//   )
// };//поставить нормальный прелоудер и выкинуть в отдельный компонент

class App extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeCat: "",
      delimiterState: null,
    };
  }

  componentDidMount() {
    const script = document.createElement("script");
    document.ondragstart = noselect;
    document.onselectstart = noselect;
    function noselect() {
      return false;
    }
    this.checkingPathForFooter();

    yall({
      observeChanges: true,
      observeRootSelector: ".App",
    });

    if (process.env.NODE_ENV === "production") {
      window.jivo_onMessageSent = () => {
        console.log("Событие чата");
        window.ym(54938986, "reachGoal", "start_chat");
      };
    }
  }

  checkingPathForFooter() {
    const delimiterState =
      this.props.location.pathname.match(/[\/]/g).length >= 3;

    this.setState({ delimiterState });
  }

  setActiveCat(path) {
    this.setState({ activeCat: path });
  }

  render() {
    return (
      <div className="App">
        {/*<ErrorBoundary>*/}
        <ScrollToTopEveryTransition>
          <Switch>
            <Route
              exact
              path="/"
              render={() => <Main activeCat={this.state.activeCat} />}
            />
            {/* <Route exact path='/portfolio' component={Portfolio} /> */}
            <Route exact path="/cooperation" component={Cooperation} />
            <Route exact path="/contacts" component={Contacts} />
            <Route exact path="/search" component={Search} />
            <Route
              exact
              path="/services/pokraska-izdelij-iz-plastika"
              component={Painting}
            />
            <Route
              exact
              path="/services/umnyj-dom-stoimost"
              component={Delivery}
            />
            <Route
              exact
              path="/services/audit-inzhenernyh-sistem"
              component={Audit}
            />
            <Route
              exact
              path="/services/umnyj-dom-informaciya"
              component={Support}
            />
            <Route
              exact
              path="/services/podklyuchenie-sistemy-umnyj-dom"
              component={Starting}
            />
            <Route
              exact
              path="/services/umnyj-dom-ustanovka"
              component={BuiltinV2}
            />
            <Route
              exact
              path="/services/proektirovanie-umnogo-doma"
              component={Project}
            />
            <Route
              exact
              path="/services/avtorskij-nadzor-za-proektom"
              component={Authorize}
            />
            <Route
              exact
              path="/solutions/ecosystemi/apple-homekit"
              component={AppleHomeKit}
            />
            <Route
              exact
              path="/solutions/ecosystemi/prilozhenije-google-home"
              component={GoogleHome}
            />
            <Route
              exact
              path="/solutions/ecosystemi/alexa-amazon"
              component={AmazonEcho}
            />
            <Route
              exact
              path="/solutions/ecosystemi/yandex-umnyj-dom"
              component={Yandex}
            />
            <Route
              exact
              path="/solutions/ecosystemi/razrabotka-prilozhenij"
              component={Individual}
            />
            <Route
              exact
              path="/solutions/ecosystemi/loxone"
              component={Loxone}
            />
            <Route exact path="/solutions/ecosystemi" component={Control} />
            <Route exact path="/umnyj-dom-blog" component={BlogPage} />
            <Route
              exact
              path="/umnyj-dom-blog/:categoryAlias"
              component={BlogPage}
            />
            <Route
              exact
              path="/umnyj-dom-blog/:categoryAlias/:alias"
              component={BlogConstructor}
            />
            <Route
              exact
              path="/solutions/:subcat/:page"
              component={Constructor}
            />
            <Route
              exact
              path="/solutions/:subcat"
              component={CategoryPageConstructor}
            />
            <Route exact path="*" component={NotFound} />
          </Switch>
          {this.checkingPathForFooter()}
          <Footer
            class={"footer"}
            activeCat={this.activeCat}
            setActiveCat={this.setActiveCat.bind(this)}
            delimiterState={this.state.delimiterState}
          />
        </ScrollToTopEveryTransition>
        {/*</ErrorBoundary>*/}
      </div>
    );
  }
}

export default withRouter(App);
